import { drawerAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

import { customColors, customColorsRaw } from '../../theme/customColors';

const { bg, border, text } = customColors;
const $boxShadow = cssVar('drawer-box-shadow');
const $headerSpacing = cssVar('drawer-header-spacing');
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers([...drawerAnatomy.keys, 'headerContent']);

const baseStyle = definePartsStyle({
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    p: 6,
    color: text.primary,
    fontFamily: 'body',
    fontSize: 'base',
    fontWeight: 'normal',
    lineHeight: 'base',
  },
  closeButton: {
    position: false,
    right: false,
    top: false,
  },
  dialog: {
    [$boxShadow.variable]: 'shadows.modals',
    bg: bg.primary,
    rounded: 'lg',
    m: 8,
  },
  header: {
    [$headerSpacing.variable]: 'space.3',
    display: 'flex',
    gap: $headerSpacing.reference,
    alignItems: 'center',
    py: 3,
    pl: 6,
    pr: 4,
    borderBottom: '1px solid',
    borderBottomColor: border.primary,
  },
  headerContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    gap: $headerSpacing.reference,
  },
  overlay: {
    bg: `rgb(${customColorsRaw.bg.overlay} / 0.5)`,
  },
});

const sizes = {
  sm: definePartsStyle({ dialog: { w: '448px' } }),
  md: definePartsStyle({ dialog: { w: '512px' } }),
  lg: definePartsStyle({ dialog: { w: '672px' } }),
};

export const DrawerStyles = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: { size: 'md' },
});
