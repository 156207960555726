import { BillingPlanTier } from './graphqlTypes/types';

// TODO(Taylor): remove this once mapping has been updated

type Tier = BillingPlanTier | null | undefined;

type MapTierArgs = Tier;
type MapTierResults = Exclude<Tier, BillingPlanTier.USAGE_BASED> | 'SERVERLESS';

/**
 * A helper to map the current USAGE_BASED enums to SERVERLESS
 * where visible to users
 *
 * @param tier the billing plan tier to map
 * @returns will return a tier type
 */
export const mapTier = (tier: MapTierArgs): MapTierResults => {
  if (tier === BillingPlanTier.USAGE_BASED) return 'SERVERLESS';

  return tier;
};
