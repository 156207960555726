import React from 'react';

import { UpgradeModal as UpgradeModalV2 } from 'src/app/account/upgradeModal/UpgradeModal';
import { LazyUrlModal } from 'src/components/common/LazyUrlModal';
import { URLModal } from 'src/components/common/URLModal';
import { HigherLimitRequest } from 'src/components/higherLimitRequest/HigherLimitRequest';
import { SupportRequest } from 'src/components/support-request/SupportRequest';
import { useCurrentPlan } from 'src/hooks/currentPlanV2Migration';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import Config from 'src/lib/config';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

import { EnterpriseUpgradeModal } from '../account/enterpriseUpgradeModal/EnterpriseUpgradeModal';
import { ConvertAccountModal } from '../account/orgSettingsPage/components/planCard/components/convertAccountModal';
import { StripeProvider } from '../account/upgradeModal/StripeProvider';
import { NewOrganizationModal, UpgradeModal } from '../onboarding/components';
// NOTE: these modals are only rendered for AppLayout, which doesn't
// include the embeddable explorer.
// If you want to add a modal that those components need, it needs to go in `RoutedApp`
export const AppModals = () => {
  const accountId = useCurrentAccountId()[0] || '';
  const planTier = useCurrentPlan({
    accountId,
  }).currentPlan?.tier;

  const planKind = useCurrentPlan({
    accountId,
  }).currentPlan?.kind;

  return (
    <>
      {accountId && (
        <URLModal name={Config.modals.upgrade}>
          {({ isOpen, closeModal }) => (
            <UpgradeModal
              isOpen={isOpen}
              onClose={closeModal}
              accountId={accountId}
            />
          )}
        </URLModal>
      )}
      <LazyUrlModal name={Config.modals.newOrganization}>
        {({ closeModal, isOpen }) => (
          <NewOrganizationModal onClose={closeModal} isOpen={isOpen} />
        )}
      </LazyUrlModal>
      <URLModal name={Config.modals.supportRequest}>
        {({ isOpen, closeModal }) => (
          <SupportRequest isOpen={isOpen} closeModal={closeModal} />
        )}
      </URLModal>
      <LazyUrlModal name={Config.modals.requestHigherLimit}>
        {({ closeModal, isOpen }) => (
          <HigherLimitRequest onClose={closeModal} isOpen={isOpen} />
        )}
      </LazyUrlModal>
      <LazyUrlModal name={Config.modals.billingUpgrade}>
        {({ closeModal, isOpen }) => (
          <>
            {isOpen && (
              <StripeProvider>
                <UpgradeModalV2 isOpen onClose={closeModal} />
              </StripeProvider>
            )}
          </>
        )}
      </LazyUrlModal>
      {/* Plan check - Convert to Apollo One modal should only be visible to
          COMMUNITY plan */}
      {planKind === GraphQLTypes.BillingPlanKind.COMMUNITY && (
        <LazyUrlModal name={Config.modals.convertAccount}>
          {({ closeModal, isOpen }) => (
            <ConvertAccountModal
              onClose={closeModal}
              isOpen={isOpen}
              accountId={accountId}
            />
          )}
        </LazyUrlModal>
      )}
      {/* Plan check - Enterprise info modal should only be visible to
          SERVERLESS_PAID plans and users on the TEAM tier */}
      {(planKind === GraphQLTypes.BillingPlanKind.SERVERLESS_PAID ||
        planTier === GraphQLTypes.BillingPlanTier.TEAM) && (
        <LazyUrlModal name={Config.modals.enterpriseUpgrade}>
          {({ closeModal, isOpen }) => (
            <EnterpriseUpgradeModal onClose={closeModal} isOpen={isOpen} />
          )}
        </LazyUrlModal>
      )}
    </>
  );
};
