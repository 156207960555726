import {
  Popover as ChakraPopover,
  PopoverProps as ChakraPopoverProps,
} from '@chakra-ui/react';
import React from 'react';

import { customColorsRaw } from '../../theme/customColors';
import { OmitChakraProps } from '../../utils/typeUtils';

export type PopoverProps = OmitChakraProps<
  ChakraPopoverProps,
  'styleConfig' | 'orientation' | 'colorScheme'
>;

export const Popover = (props: PopoverProps) => (
  <ChakraPopover {...props} arrowShadowColor={customColorsRaw.border.primary} />
);
