/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
export const Rocket2 = React.forwardRef<
  SVGSVGElement,
  Omit<React.SVGProps<SVGSVGElement>, "css">
>((props, ref) => (
  <svg
    viewBox="0 0 182 92"
    ref={ref}
    css={{
      color: "#F4F6F8",
      height: "92px",
      width: "182px",
    }}
    {...props}
  >
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <path
        fill="currentColor"
        stroke="currentColor"
        d="M90 0c49.706 0 90 40.294 90 90H0C0 40.294 40.294 0 90 0z"
      />
      <path fill="#74B0F4" d="M72 83h36v2a4 4 0 0 1-4 4H76a4 4 0 0 1-4-4v-2z" />
      <path
        stroke="#464646"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M69 76.5V57a4.5 4.5 0 0 1 4.5-4.5A4.5 4.5 0 0 1 78 57v10.5m24 0V57a4.5 4.5 0 0 1 4.5-4.5A4.5 4.5 0 0 1 111 57v19.5M78 57v-1.5c0-6.628 5.372-12 12-12s12 5.372 12 12V57"
      />
      <path
        stroke="#464646"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M108 85.5c0 1.656-1.344 3-3 3H75c-1.656 0-3-1.344-3-3v-3c0-.944.444-1.834 1.2-2.4l8.4-6.3A6.004 6.004 0 0 0 84 69V58.82a6.14 6.14 0 0 1 5.96-6.318c.014 0 .026 0 .04-.002a6 6 0 0 1 6 6V69c0 1.888.89 3.666 2.4 4.8l8.4 6.3a2.998 2.998 0 0 1 1.2 2.4v3zM88.5 60h3M90 79.5v9"
      />
      <path
        fill="#F18686"
        stroke="#464646"
        strokeWidth={2}
        d="M102 60h9v5h-9zm-33 0h9v5h-9z"
      />
      <path stroke="#464646" strokeWidth={2} d="M72 84h36" />
      <path
        fill="#CAD0D8"
        d="M53 28c.581 0 1.053.509 1.053 1.137l-.001 1.81h1.812c.627 0 1.136.471 1.136 1.053 0 .581-.509 1.053-1.136 1.053l-1.812-.001v1.812c0 .627-.47 1.136-1.052 1.136-.581 0-1.053-.509-1.053-1.136v-1.812h-1.81c-.628 0-1.137-.47-1.137-1.052 0-.582.509-1.053 1.136-1.053h1.811v-1.81c0-.628.472-1.137 1.053-1.137zm68 10c.581 0 1.053.509 1.053 1.137l-.001 1.81h1.812c.627 0 1.136.471 1.136 1.053 0 .581-.509 1.053-1.136 1.053l-1.812-.001v1.812c0 .627-.47 1.136-1.052 1.136-.581 0-1.053-.509-1.053-1.136v-1.812h-1.81c-.628 0-1.137-.47-1.137-1.052 0-.582.509-1.053 1.136-1.053h1.811v-1.81c0-.628.472-1.137 1.053-1.137zm30 37c.581 0 1.053.509 1.053 1.137l-.001 1.81h1.812c.627 0 1.136.471 1.136 1.053 0 .581-.509 1.053-1.136 1.053l-1.812-.001v1.812c0 .627-.47 1.136-1.052 1.136-.581 0-1.053-.509-1.053-1.136v-1.812h-1.81c-.628 0-1.137-.47-1.137-1.052 0-.582.509-1.053 1.136-1.053h1.811v-1.81c0-.628.472-1.137 1.053-1.137z"
      />
      <circle cx={32.5} cy={67.5} r={1.5} fill="#CAD0D8" />
      <circle cx={50.5} cy={55.5} r={1.5} fill="#CAD0D8" />
      <circle cx={138.5} cy={30.5} r={1.5} fill="#CAD0D8" />
      <circle cx={32.5} cy={34.5} r={1.5} fill="#CAD0D8" />
      <circle cx={12.5} cy={81.5} r={1.5} fill="#CAD0D8" />
      <circle cx={86.5} cy={15.5} r={1.5} fill="#CAD0D8" />
      <circle cx={76.5} cy={35.5} r={1.5} fill="#CAD0D8" />
      <circle cx={123.5} cy={16.5} r={1.5} fill="#CAD0D8" />
      <circle cx={149.5} cy={47.5} r={1.5} fill="#CAD0D8" />
      <circle cx={166.5} cy={69.5} r={1.5} fill="#CAD0D8" />
      <circle cx={127.5} cy={78.5} r={1.5} fill="#CAD0D8" />
      <g transform="translate(100 24)">
        <circle cx={2.5} cy={2.5} r={2.5} fill="#CAD0D8" />
        <circle cx={2.5} cy={2.5} r={1} fill="currentColor" />
      </g>
      <g transform="translate(64 12)">
        <circle cx={2.5} cy={2.5} r={2.5} fill="#CAD0D8" />
        <circle cx={2.5} cy={2.5} r={1} fill="currentColor" />
      </g>
      <g transform="translate(132 58)">
        <circle cx={2.5} cy={2.5} r={2.5} fill="#CAD0D8" />
        <circle cx={2.5} cy={2.5} r={1} fill="currentColor" />
      </g>
      <g transform="translate(47 75)">
        <circle cx={2.5} cy={2.5} r={2.5} fill="#CAD0D8" />
        <circle cx={2.5} cy={2.5} r={1} fill="currentColor" />
      </g>
      <g transform="translate(20 52)">
        <circle cx={2.5} cy={2.5} r={2.5} fill="#CAD0D8" />
        <circle cx={2.5} cy={2.5} r={1} fill="currentColor" />
      </g>
    </g>
  </svg>
));
