import { useEffect } from 'react';
import { useHistory } from 'react-router';

import * as onboardingRoutes from 'src/app/onboarding/routes';
import { usePerKeyLocalStorage } from 'src/hooks/usePerKeyLocalStorage';
import { openStudioLinkFromEmbedInNewTab } from 'src/lib/routers/embedded';
import { setOverlayClosedLocation } from 'src/lib/routing';

import {
  DEV_TOOLS_AUTHENTICATE_WITH_GRAPHREF,
  IncomingEmbedMessageEvent,
  SET_PARTIAL_AUTHENTICATION_TOKEN_FOR_PARENT,
  STUDIO_USER_TOKEN_FOR_EMBED,
  sendPostMessageFromEmbedToParent,
} from '../graph/explorerPage/helpers/postMessageHelpers';

import { constructEmbedLocalStorageKey } from './constructEmbedLocalStorageKey';
import { splitEmbedToken } from './embedApiTokenHelpers';

export const useListenForAuthResponse = () => {
  const [embedAuthenticationDetails] = usePerKeyLocalStorage({
    initialValue: undefined,
    key: document.referrer,
    localStorageKey: 'embedAuthenticationDetails',
  });

  const [_, __, setPartialEmbedUserApiTokenByKey] = usePerKeyLocalStorage({
    localStorageKey: 'partialEmbedUserApiTokens',
    key: '',
    initialValue: undefined,
  });
  const history = useHistory();

  useEffect(() => {
    const handleAuthenticatedPostMessage = (
      event: IncomingEmbedMessageEvent,
    ) => {
      if (event.data.name === DEV_TOOLS_AUTHENTICATE_WITH_GRAPHREF) {
        const embedOrigin = embedAuthenticationDetails?.origin;
        if (embedOrigin)
          openStudioLinkFromEmbedInNewTab(
            onboardingRoutes.embedAuthenticationRouteConfig.path({
              origin: embedOrigin,
              embedSubdomain: 'explorer',
              graphRef: event.data.graphRef,
              type: 'prod',
            }),
          );
      }
      if (
        event.data.name === STUDIO_USER_TOKEN_FOR_EMBED &&
        (event.origin === 'https://studio.apollographql.com' ||
          event.origin === 'https://studio-staging.apollographql.com')
      ) {
        // Why are we using the graphRef from this message here instead of the
        // graphRef in the url? For Apollo Client dev tools, we don't have the
        // graphRef until we get this PM, because the user inputs it and dev tools
        // sends it to us.
        // This graphRef is required if it exists in all STUDIO_USER_TOKEN_FOR_EMBED
        // messages, so we can be sure that we will have it.
        const embedLocalStorageKey = embedAuthenticationDetails?.origin
          ? constructEmbedLocalStorageKey({
              origin: embedAuthenticationDetails.origin,
              graphRef: event.data.graphRef,
            })
          : undefined;

        if (embedLocalStorageKey) {
          const { id, token } = event.data;
          const { partialEmbedToken, partialParentToken } =
            splitEmbedToken(token);
          sendPostMessageFromEmbedToParent({
            name: SET_PARTIAL_AUTHENTICATION_TOKEN_FOR_PARENT,
            localStorageKey: embedLocalStorageKey,
            partialToken: partialParentToken,
            graphRef: event.data.graphRef,
          });
          setPartialEmbedUserApiTokenByKey(
            {
              id,
              partialToken: partialEmbedToken,
            },
            embedLocalStorageKey,
          );
          // close any modals that has log in CTAs - like the OC save operation modal
          history.replace(setOverlayClosedLocation(history.location));
          window.location.reload();
        }
      }
    };
    window.addEventListener('message', handleAuthenticatedPostMessage);

    return () => {
      window.removeEventListener('message', handleAuthenticatedPostMessage);
    };
  }, [embedAuthenticationDetails, history, setPartialEmbedUserApiTokenByKey]);
};
