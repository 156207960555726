import { Alert } from '@apollo/orbit';
import React from 'react';

type Props = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'title'
>;

/**
 * Confirmation message with a green background and check icon
 *
 * Renders `children` as the content
 *
 * @see https://zpl.io/bAWQ4WB
 */
export const ConfirmationMessage = ({
  children,
  className,
  ...otherProps
}: Props) => (
  <Alert {...otherProps} className={className} status="success">
    {children}
  </Alert>
);
