import { gql } from '@apollo/client';
import { Avatar, Card, CardBody } from '@apollo/orbit';
import React from 'react';

import { GraphQLTypes } from 'src/lib/graphqlTypes';

export const AuthorizeEmbedCardUserFragment = gql`
  fragment AuthorizeEmbedCardUserFragment on User {
    id
    avatarUrl
    fullName
    email
  }
`;
interface Props {
  user: GraphQLTypes.AuthorizeEmbedCardUserFragment | null;
  children: React.ReactNode;
}

export const AuthorizeEmbedCard = ({ user, children }: Props) => (
  <Card variant="unstyled" className="size-full">
    <CardBody className="items-center justify-center">
      <div>
        <div className="mb-10 flex w-full rounded-t-lg bg-indigo-darkest p-6 text-inverted">
          {user && (
            <Avatar
              src={user.avatarUrl ?? undefined}
              name={user.fullName}
              size="lg"
            />
          )}

          <div className="my-auto ml-7">
            {user && user.fullName}
            <br />
            {user && user.email}
          </div>
        </div>
        <div className="mx-9">{children}</div>
      </div>
    </CardBody>
  </Card>
);
