import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import * as routes from 'src/app/account/routes';
import { useDefaultAccountDetails } from 'src/app/onboarding/hooks';
import { Loading } from 'src/components/common/loading/Loading';
import { useShowToast } from 'src/components/toast/Toast';
import { useDefaultPlan } from 'src/hooks/useDefaultPlan';
import { useShouldSeeSupergraphAccountView } from 'src/hooks/useShouldSeeSupergraphAccountView';
import {
  OrgProvisionerUserQuery,
  OrgProvisionerUserQueryVariables,
} from 'src/lib/graphqlTypes/types';

export const OrgProvisioner = () => {
  const defaultPlanId = useDefaultPlan();

  const { data: userData } = useQuery(gql<
    OrgProvisionerUserQuery,
    OrgProvisionerUserQueryVariables
  >`
    query OrgProvisionerUserQuery {
      me {
        id
        ... on User {
          fullName
        }
      }
    }
  `);

  const [createOrg] = useMutation(
    gql`
      mutation OrgProvisionerCreateOrgMutation(
        $accountId: ID!
        $planId: String
      ) {
        newAccount(id: $accountId, planId: $planId) {
          id
        }
      }
    `,
    {
      onError: (error) => {
        throw error;
      },
    },
  );
  const history = useHistory();
  const fullName =
    userData?.me?.__typename === 'User' ? userData.me.fullName : '';
  const userId =
    userData?.me?.__typename === 'User' ? userData.me.id : undefined;

  const { accountId } = useDefaultAccountDetails({
    fullName,
    accountName: '',
    accountId: '',
  });
  const apolloClient = useApolloClient();
  const { showToasts } = useShowToast();
  const { shouldSeeSupergraphAccountView } =
    useShouldSeeSupergraphAccountView();

  useEffect(() => {
    async function provisionOrg() {
      if (!accountId.length) {
        return;
      }
      await createOrg({
        variables: {
          accountId,
          planId: defaultPlanId,
        },
      }).then(() => {
        apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'me' });
        apolloClient.cache.evict({
          id: apolloClient.cache.identify({
            __typename: 'User',
            id: userId,
          }),
        });
      });
      showToasts({
        heading: `We've created an organization for you`,
        level: 'info',
        stableId: 'auto-create-org',
        message:
          "To use graphs in Studio outside of Sandbox, you need an organization. We've created this personal organization for you to get started!",
      });
      history.push(routes.graphs.path({ orgId: accountId }));
    }
    provisionOrg();
  }, [
    createOrg,
    accountId,
    apolloClient.cache,
    userId,
    showToasts,
    history,
    shouldSeeSupergraphAccountView,
    defaultPlanId,
  ]);

  return <Loading />;
};
