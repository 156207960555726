import { Link } from '@apollo/orbit';
import React from 'react';

import { PricingRouter } from 'src/lib/routers/pricing';

export const PricingLink = ({
  pathKey = 'Home',
  className,
  children = 'Pricing Page',
}: {
  pathKey?: Parameters<typeof PricingRouter.path>[0];
  children?: React.ReactNode;
  className?: string;
}) => (
  <Link isExternal to={PricingRouter.path(pathKey)} className={className}>
    {children}
  </Link>
);
