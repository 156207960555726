/* eslint-disable local/no-conditional-class-names */
import cx from 'classnames';
import React, { ReactNode } from 'react';

import { Text } from '../Text/Text';

export interface ModalDescriptionProps {
  className?: string;
  children?: ReactNode;
}

export const ModalDescription = ({
  className,
  children,
}: ModalDescriptionProps) => {
  return (
    <Text
      className={cx('orbit-modal__description', className)}
      variant="subtitle"
    >
      {children}
    </Text>
  );
};
