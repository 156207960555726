import { gql, useQuery } from '@apollo/client';

import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

import { GraphRef } from './useGraphRef';

export const useIsPublicMemberOnly = ({
  graphRef,
}: {
  graphRef: GraphRef | null;
}) => {
  const { data } = useQuery<
    GraphQLTypes.UseIsPublicMemberOnlyQuery,
    GraphQLTypes.UseIsPublicMemberOnlyQueryVariables
  >(
    gql`
      query UseIsPublicMemberOnlyQuery($graphId: ID!, $variant: String!) {
        service(id: $graphId) {
          id
          account {
            id
          }
          variant(name: $variant) {
            id
            isPublic
          }
        }
      }
    `,
    {
      variables: {
        graphId: graphRef?.graphId as string,
        variant: graphRef?.graphVariant as string,
      },
      skip: !graphRef,
      context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
    },
  );
  // If the graph has *any* public variants we will be able to fetch the graph,
  // what we care about should be if we have access to this *variant*
  const hasVariantAccess = !!data?.service?.variant;
  // If you dont have account access (the account came back null) you are a
  // public member
  const isPublicMemberOnly = hasVariantAccess && !data?.service?.account;
  return isPublicMemberOnly;
};
