import { captureException } from '@sentry/react';
import React, { useRef } from 'react';
import { matchPath, useLocation } from 'react-router';

import { graphRefToString } from 'src/app/graph/hooks/useGraphRef';
import { variantRouteConfig } from 'src/app/graph/routes';
import { EventCategory } from 'src/lib/analytics';

import { useCurrentAccountId } from './useCurrentAccountId';
import { useIsAppOfflineContext } from './useIsAppOffline';
import { readFromLocalStorage, writeToLocalStorage } from './useLocalStorage';

export type TrackingEvent<Category extends EventCategory = EventCategory> = {
  /**
   * The broad human readable category these events fall under. Eg the page an
   * action was taken on, or the workflow it was part of.
   */
  category: Category;
  /**
   * The distinct action that was taken/triggered. Something like
   * 'clicked_some_button', 'loaded_some_page' etc.
   */
  action: string;
  /**
   * A dynamic value to designate a flavor of the action, or a target of the
   * action. Something like a graph ref, sandbox endpoint, login style
   * (github/sso/first party), etc.
   */
  label?: string;
  /**
   * A second dynamic value to designate a flavor of the action, or a target of the
   * action. Something like a graph ref, sandbox endpoint, login style
   * (github/sso/first party), etc.
   */
  secondaryLabel?: string;
  /**
   * A third dynamic value to designate a flavor of the action, or a target of the
   * action. Something like a graph ref, sandbox endpoint, login style
   * (github/sso/first party), etc.
   */
  tertiaryLabel?: string;
  /**
   * The organization id of the user performing this action, if logged in with an org
   */
  orgId?: string;
  /**
   * The graph id associated with this action
   */
  graphId?: string;
  /**
   * The graph variant associated with this action
   */
  graphVariant?: string;
  /**
   * @deprecated Since we're trying to adhere to UA's Anatomy of Events, `value` should be a metric rather than a dimension.
   *
   * Keeping this field to avoid breaking dashboards expecting this value for events we've already created, but we should stop using it so we could one day migrate off
   */
  legacyValueDimension?: string | number;
  // * A numeric value for any aggregates over the action. Something like the
  // * number of invites sent etc.
  value?: number;
  isAutoupdatingSchema?: Category extends
    | 'Sandbox - Local'
    | 'Sandbox - Remote'
    | 'Explorer'
    | 'Embeddable Explorer'
    ? boolean
    : never;
  /**
   * The page path that the user click through into the studio signup flow from
   * this value is being set using a cookie via the @apollo/signup-tracer package
   * https://github.com/apollographql/dxe/tree/main/packages/signup-tracer
   */
  referringPath?: string;
  /**
   * User email - this was added in order to make use of enhanced conversions
   * for Google ads
   */
  email?: string | null;
  /**
   * Additional context for the event. This is limited to 100 characters and
   * follows a "schema" defined by the category of the event. It should be a
   * comma-separated list of values, where the location of each value has a
   * semantic meaning.
   */
  context?: string;
};

/*
 *
 * Track custom GA events
 *
 * This function will fire events to the new unified GA4 property that we have
 * installed across all of the .apollographql.com web properties in order
 * to track users across all sites as one session.
 * This should be used moving forward to track any user events we may be
 * interested in measuring within Google Analytics.
 *
 * The current Segment analytics integration will live alongside this until
 * Segment can be pulled and all current events are migrated to this new func.
 *
 * GA property: https://analytics.google.com/analytics/web/?authuser=1#/p263258241/reports/defaulthome
 * (ping @jay if you don't have access and would like it)
 *
 * All tracked events will be logged in the following spreadsheet:
 * https://apollographql.quip.com/RKb7AF5Zc1ft/ApolloGrapQLcom-GA4-Events#PbcACAADysU
 *
 */
export const useTrackCustomEvent = () => {
  const isOffline = useIsAppOfflineContext();
  const offlineRef = React.useRef(isOffline);
  offlineRef.current = isOffline;
  const [currentAccountId] = useCurrentAccountId();
  // currentAccountId is in a ref to avoid putting in the dep array for `trackCustomEvent`
  const currentAccountIdRef = useRef(currentAccountId);
  currentAccountIdRef.current = currentAccountId;

  const location = useLocation();

  const matchPathResult = matchPath<{
    graphId?: string;
    graphVariant?: string;
  }>(location.pathname, variantRouteConfig.definition);
  const graphIdFromUrl =
    matchPathResult && 'graphId' in matchPathResult.params
      ? matchPathResult.params.graphId
      : undefined;
  const graphVariantFromUrl =
    matchPathResult && 'graphVariant' in matchPathResult.params
      ? matchPathResult.params.graphVariant
      : undefined;

  // graphId & graphVariant are in refs to avoid putting in the dep array for `trackCustomEvent`
  const graphIdRef = useRef(graphIdFromUrl);
  graphIdRef.current = graphIdFromUrl;
  const graphVariantRef = useRef(
    typeof graphVariantFromUrl === 'string' ? graphVariantFromUrl : undefined,
  );
  graphVariantRef.current =
    typeof graphVariantFromUrl === 'string' ? graphVariantFromUrl : undefined;

  const trackCustomEvent = React.useCallback(
    <Category extends EventCategory>(event: TrackingEvent<Category>) => {
      const offline = offlineRef.current;
      const orgId = event.orgId || currentAccountIdRef.current;
      const graphId = event.graphId || graphIdRef.current;
      const graphVariant = event.graphVariant || graphVariantRef.current;

      internalTrackCustomEvent({
        event: {
          ...event,
          ...(orgId
            ? {
                orgId,
              }
            : {}),
          ...(graphId
            ? {
                graphId,
              }
            : {}),
          ...(graphVariant
            ? {
                graphVariant,
              }
            : {}),
        },
        offline,
      });
    },
    // This dependency array should always be empty. We don't want to trigger multiple
    // events to be fired b/c of rerenders when trackCustomEvent changes
    [],
  );
  return trackCustomEvent;
};

/**
 * This should primarily be an internal function. This is exported specifically
 * to be used to track event at page load outside of the React cycle.
 */
export function internalTrackCustomEvent<Category extends EventCategory>({
  event,
  offline,
}: {
  event: TrackingEvent<Category>;
  offline: boolean;
}) {
  if (offline || !window.gtag) {
    writeToLocalStorage('trackingEventsToBeSent', (prevEvents) => [
      ...prevEvents,
      {
        ...event,
        dateInMilliseconds: Date.now(),
        offline,
      },
    ]);
  } else {
    setTimeout(trackCustomEventsFromLocalStorage);
    sendEvent(event);
  }
}

const trackCustomEventsFromLocalStorage = () => {
  if (!window.gtag) return;
  const trackingEventsToBeSent = readFromLocalStorage('trackingEventsToBeSent');

  trackingEventsToBeSent.forEach((event) => {
    if (event) {
      // TODO(Maya): investigate if this works & uncomment if it does
      // window.gtag('set', Config.gaTrackingID, {
      //   // eslint-disable-next-line @typescript-eslint/naming-convention
      //   timestamp_micros: event.dateInMilliseconds * 1000, // convert milli to micro
      // });
      sendEvent(event);
    }
  });
  if (trackingEventsToBeSent.length) {
    writeToLocalStorage('trackingEventsToBeSent', []);
  }
  // TODO(Maya): investigate if this works & uncomment if it does
  // window.gtag('set', Config.gaTrackingID, {
  //   // eslint-disable-next-line @typescript-eslint/naming-convention
  //   timestamp_micros: undefined,
  // });
};

function sendEvent<Category extends EventCategory>(
  event: TrackingEvent<Category> & {
    offline?: boolean;
  },
) {
  const {
    action,
    category,
    graphId,
    graphVariant,
    label,
    orgId,
    secondaryLabel,
    tertiaryLabel,
    value,
    offline,
    legacyValueDimension,
    referringPath,
    email,
    context,
  } = event;

  if (context && context.length > 100) {
    captureException(
      new Error(
        `Event \`context\` is too long, max length is 100 characters. It will be truncated automatically by Google Analytics. Event: ${category}:${action}, context: ${context}`,
      ),
    );
  }
  // using 'event_' here so the events will register the values correctly in ga
  const eventParams = {
    event_category: category, // eslint-disable-line @typescript-eslint/naming-convention
    event_label: label, // eslint-disable-line @typescript-eslint/naming-convention
    // eslint-disable-next-line @typescript-eslint/naming-convention
    event_secondary_label: secondaryLabel,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    event_tertiary_label: tertiaryLabel,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    event_value:
      typeof legacyValueDimension === 'number'
        ? String(legacyValueDimension)
        : legacyValueDimension,
    orgId,
    graphRef: graphId
      ? graphRefToString({
          graphId,
          // there might be events where the graphVariant is omitted, we still want to record graphId@
          graphVariant: graphVariant ?? '',
        })
      : undefined,
    value,
    offline,
    ...('isAutoupdatingSchema' in event
      ? { isAutoupdatingSchema: event.isAutoupdatingSchema }
      : {}),
    version: process.env.GIT_HASH,
    referringPath,
    email,
    context,
  };
  window.gtag('event', action, eventParams);
}
