import { AlertBanner, Link } from '@apollo/orbit';
import React from 'react';

import { useCurrentSubscription } from 'src/hooks/useCurrentSubscription';
import { format } from 'src/lib/format';
import { getDaysRemainingOnTrial } from 'src/lib/getDaysRemainingOnTrial';

interface Props {
  accountId: string;
  learnMoreHref?: string;
}

export const MIN_DAYS_REMAINING = 7;

export const TrialAlertBanner = ({ accountId, learnMoreHref }: Props) => {
  const currentSubscriptionResult = useCurrentSubscription({
    accountId,
    skip: !accountId,
  });

  const trialExpirationDate =
    currentSubscriptionResult?.currentSubscription?.trialExpiresAt;

  if (!trialExpirationDate) return null;

  const daysRemaining = getDaysRemainingOnTrial(trialExpirationDate);

  if (daysRemaining <= 0) return null;

  if (daysRemaining <= MIN_DAYS_REMAINING) {
    const isOnLastDay = daysRemaining <= 1;

    return (
      <AlertBanner status={isOnLastDay ? 'warning' : 'info'}>
        You have {`${daysRemaining} ${format.pluralize(daysRemaining, 'day')}`}{' '}
        remaining on your trial.
        <>
          {learnMoreHref ? (
            <>
              {' '}
              To learn more{' '}
              <Link isExternal to={learnMoreHref}>
                click here
              </Link>
              .
            </>
          ) : null}
        </>
      </AlertBanner>
    );
  }

  return null;
};
