import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
} from '@apollo/orbit';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';

import { TermsOfServiceLink } from 'src/components/termsOfServiceLink/TermsOfServiceLink';
import { URLField } from 'src/components/urlField/URLField';

import { FormValues } from '../UpgradeModal';

export const OrgDetails = () => {
  const { handleChange, handleBlur, values, setFieldValue, errors, touched } =
    useFormikContext<FormValues>();

  const handleUrlFieldChange = useCallback(
    (url) => setFieldValue('companyDomain', url),
    [setFieldValue],
  );
  return (
    <ModalBody>
      <h1 className="font-heading text-lg font-medium text-heading">
        Organization details
      </h1>
      <p className="text-base font-normal ">
        We need to confirm a few details about your organization before we can
        continue.
      </p>
      <FormControl
        isInvalid={!!touched.companyName && !!errors.companyName}
        isRequired
      >
        <FormLabel>Company Name</FormLabel>
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          name="companyName"
          value={values.companyName}
        />
        <FormErrorMessage>{errors.companyName}</FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={!!touched.companyDomain && !!errors.companyDomain}
        isRequired
      >
        <FormLabel>Company Domain</FormLabel>
        <URLField
          onChange={handleUrlFieldChange}
          onBlur={handleBlur}
          name="companyDomain"
          value={values.companyDomain}
          isSocket={false}
          placeholder="http://example.com"
        />
        <FormErrorMessage>{errors.companyDomain}</FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={
          !!touched.billingContactEmail && !!errors.billingContactEmail
        }
        isRequired
      >
        <FormLabel>Billing Contact Email</FormLabel>
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          name="billingContactEmail"
          value={values.billingContactEmail}
        />
        <FormErrorMessage>{errors.billingContactEmail}</FormErrorMessage>
      </FormControl>

      <FormControl
        isRequired
        isInvalid={!!touched.acceptsTerms && !!errors.acceptsTerms}
      >
        <Checkbox
          onChange={handleChange}
          name="acceptsTerms"
          isChecked={values.acceptsTerms}
        >
          I agree to Apollo's <TermsOfServiceLink />
        </Checkbox>
        <FormErrorMessage>{errors.acceptsTerms}</FormErrorMessage>
      </FormControl>
    </ModalBody>
  );
};
