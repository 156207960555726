import React from 'react';

import { EventCategory } from 'src/lib/analytics';

import { useTrackCustomEvent } from './useTrackCustomEvent';

export function useInitializeClickAnalytics() {
  const trackCustomEvent = useTrackCustomEvent();
  React.useEffect(() => {
    const onClick = (event: MouseEvent) => {
      if (
        !(
          event.target instanceof HTMLElement ||
          event.target instanceof SVGElement
        )
      )
        return;
      let elementWithAnalyticsLabel: HTMLElement | SVGElement | null = null;
      if ('analyticsLabel' in event.target.dataset) {
        elementWithAnalyticsLabel = event.target;
      } else {
        elementWithAnalyticsLabel = event.target.closest(
          '[data-analytics-label]',
        );
      }

      if (elementWithAnalyticsLabel) {
        const category =
          (elementWithAnalyticsLabel.dataset
            .analyticsCategory as EventCategory) || 'Unspecified';
        const orgId = elementWithAnalyticsLabel.dataset.analyticsOrgid;
        trackCustomEvent({
          action: 'Click',
          category,
          orgId,
          label: elementWithAnalyticsLabel.dataset.analyticsLabel,
          secondaryLabel:
            elementWithAnalyticsLabel.dataset.analyticsSecondaryLabel,
          tertiaryLabel:
            elementWithAnalyticsLabel.dataset.analyticsTertiaryLabel,
        });
      }
    };

    document.addEventListener('click', onClick, {
      // capture clicks in the capture phase (instead of the bubbling phase)
      // to avoid being thwarted by e.stopPropagation
      capture: true,
    });
    return () => document.removeEventListener('click', onClick);
  }, [trackCustomEvent]);
}
