import { welcome } from 'src/app/onboarding/routes';
import { typeToUserSegment } from 'src/app/onboarding/views/welcome/utils';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { readFromLocalStorage } from 'src/hooks/useLocalStorage';
import { absoluteURL } from 'src/lib/absoluteURL';
import {
  EventCategory,
  getGaClientId,
  setTrackingCookie,
} from 'src/lib/analytics';
import Config from 'src/lib/config';
import { BackendRouter } from 'src/lib/routers/backend';

import { useTrackCustomEvent } from './useTrackCustomEvent';

type Provider = 'github' | 'pingone';

export const useHandleProviderSubmit = ({
  provider,
  callbackUrl,
  setLoggingIn,
  type,
}: {
  provider: Provider;
  callbackUrl: string;
  setLoggingIn?: React.Dispatch<React.SetStateAction<boolean>>;
  type: Parameters<typeof welcome.path>[0]['type'];
}) => {
  const [, setCurrentAccountId] = useCurrentAccountId();
  const trackCustomEvent = useTrackCustomEvent();

  const handleProviderSubmit = async () => {
    setLoggingIn?.(true);

    await setCurrentAccountId(null);

    try {
      // referrer is sometimes set at runtime, so make sure cookie is up to date.
      const referrer = readFromLocalStorage('utm')?.referrer;
      if (referrer) {
        setTrackingCookie(Config.cookies.Referrer, referrer);
      }

      const trackingGoogleClientId = await getGaClientId();
      if (trackingGoogleClientId) {
        setTrackingCookie(
          Config.cookies.GoogleClientId,
          trackingGoogleClientId,
        );
      }

      setTrackingCookie(Config.cookies.UserSegment, typeToUserSegment(type));

      BackendRouter.go('LoginWithProviderAndCallback', {
        provider,
        callbackUrl: absoluteURL(callbackUrl),
      });
    } finally {
      // do nothing, just wait for redirect
    }
  };
  trackCustomEvent({
    action: 'sign_in_flow',
    // Old event, this style of category is deprecated
    category: 'Sign in with provider' as EventCategory,
    label: 'Studio sign in flow',
    legacyValueDimension: provider,
  });

  return handleProviderSubmit;
};
