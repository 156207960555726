import { Badge, Button } from '@apollo/orbit';
import classnames from 'classnames';
import React from 'react';

import { assertUnreachable } from 'src/lib/assertUnreachable';

interface CTAProps {
  onClick: React.MouseEventHandler;
  isLoading?: boolean;
  type?: 'button' | 'submit';
  content: string;
}

interface Props {
  tag: string;
  title: string;
  description: string;
  details: string[];
  ctaProps: CTAProps;
  variant: 'light' | 'dark';
  className?: string;
}

export const PlanDetailCard = ({
  tag,
  title,
  description,
  details,
  ctaProps: { onClick, type = 'button', content, isLoading },
  variant = 'dark',
  className,
}: Props) => (
  <div
    className={classnames(
      'flex flex-1 flex-col gap-4 rounded-2xl border border-brand-primary bg-[#ffffff66] p-6 text-base font-normal text-black',
      className,
    )}
  >
    <Badge
      variant={
        variant === 'dark'
          ? 'brandedDark'
          : variant === 'light'
            ? 'brandedLight'
            : assertUnreachable(variant)
      }
    >
      {tag}
    </Badge>
    <p className="font-heading text-2xl font-medium">{title}</p>
    <p>{description}</p>
    <ul className="mb-2 ml-6 flex flex-col gap-2">
      {details.map((detail) => (
        <li className="list-disc" key={detail}>
          {detail}
        </li>
      ))}
    </ul>
    <Button
      onClick={onClick}
      type={type}
      className="mt-auto rounded-lg p-4"
      isLoading={isLoading}
      loadingText={content}
      variant={
        variant === 'dark'
          ? 'brandDark'
          : variant === 'light'
            ? 'brandLight'
            : assertUnreachable(variant)
      }
    >
      {content}
    </Button>
  </div>
);
