import produce from 'immer';
import { isEqual, omit, pick } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

import { assertUnreachable } from 'src/lib/assertUnreachable';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

import {
  GraphIdentifier,
  usePerGraphIdentifierLocalStorage,
} from '../../usePerGraphIdentifierLocalStorage';
import { HeaderEntry } from '../useHeadersManagerContext/shared';

import {
  CollectionEntryIdState,
  CollectionEntryState,
  SharedCollectionEntryState,
} from './collectionEntryState';

export type SavedOperationLocalStateEntry = CollectionEntryIdState &
  // We need to pass around this piece of state to know when
  // headers have been modified. We don't count injected headers
  // from different origins as modified.
  SharedCollectionEntryState & {
    operationModified: boolean;
    variablesModified: boolean;
    headersModified: boolean;
    preflightOperationScriptModified: boolean;
    postflightOperationScriptModified: boolean;
    savedOperationState: CollectionEntryState;
    isShared: boolean;
  };

export type SavedOperationLocalState = Record<
  string,
  SavedOperationLocalStateEntry | undefined
>;

type UpdateFunction<Value> = (
  newValueOrUpdate: Value | ((currentValue: Value) => Value),
) => void;

const STABLE_EMPTY_IDENTIFIER_OBJECT: SavedOperationLocalState = {};

type OpenSavedOperation = {
  type: 'OpenSavedOperation';
  collectionId: string;
  collectionEntryId: string;
  savedOperationState: CollectionEntryState;
  isShared: boolean;
} & SharedCollectionEntryState;
type SaveOperation = {
  type: 'SaveOperation';
  operationTabId: string;
  collectionId: string;
  collectionEntryId: string;
  collectionEntryState: CollectionEntryState;
  isShared: boolean;
} & SharedCollectionEntryState;
type UpdateRemoteSavedValue = {
  type: 'UpdateRemoteSavedValue';
  operationTabId: string;
  savedEntryState: CollectionEntryState;
};
type CloseOperation = {
  type: 'CloseOperation';
  operationTabId: string;
};
type ModifyLocalOperation = {
  type: 'ModifyLocalOperation';
  operationTabId: string;
  collectionEntryState: Partial<CollectionEntryState>;
};
type DeleteOperation = { type: 'DeleteOperation'; collectionEntryId: string };
type DeleteCollection = { type: 'DeleteCollection'; collectionId: string };
export type SavedOperationAction =
  | OpenSavedOperation
  | SaveOperation
  | UpdateRemoteSavedValue
  | CloseOperation
  | ModifyLocalOperation
  | DeleteOperation
  | DeleteCollection;

export function convertRemoteStateToLocalState(
  remoteEntry: Pick<
    GraphQLTypes.OperationCollectionEntryState,
    'body' | 'headers' | 'variables' | 'script' | 'postflightOperationScript'
  >,
): CollectionEntryState {
  return {
    operation: remoteEntry.body,
    variables: remoteEntry.variables ?? '',
    headers:
      remoteEntry.headers?.map(
        (header): HeaderEntry => ({
          headerName: header.name,
          value: header.value,
          key: Math.random(),
          checked: true,
        }),
      ) ?? [],
    preflightOperationScript: remoteEntry.script ?? '',
    postflightOperationScript: remoteEntry.postflightOperationScript ?? '',
  };
}

/**
 * 'saved operation local state' here refers to a local representation of the
 * saved operation states on the backend, filtered to include only the
 * operations that are currently open. It also manages whether the operation has
 * local modifications. This hook is responsible for adding/removing saved
 * operations as they are opened/closed/deleted, marking them as modified, and
 * syncing them when new information comes from the backend (logic for that
 * exists in useSyncSavedOperationsOnLoad).
 */
export function useSavedOperationLocalState({
  createNewTab,
  setSelectedTab,
  graphIdentifier,
}: {
  createNewTab: (collectionEntryState: CollectionEntryState | null) => string;
  setSelectedTab: UpdateFunction<string | undefined>;
  graphIdentifier: GraphIdentifier;
}): {
  savedOperationLocalState: SavedOperationLocalState;
  dispatchSavedOperationLocalStateAction: (
    action: SavedOperationAction,
  ) => void;
} {
  const [savedOperationLocalState, setSavedOperationLocalState] =
    usePerGraphIdentifierLocalStorage({
      key: 'savedOperationLocalState',
      graphIdentifier,
      stableInitialValue: STABLE_EMPTY_IDENTIFIER_OBJECT,
    });

  const reduceSavedOperations = React.useCallback(
    (
      currentSavedOperationLocalState: SavedOperationLocalState,
      action: SavedOperationAction,
    ): SavedOperationLocalState => {
      switch (action.type) {
        case 'OpenSavedOperation': {
          const {
            collectionId,
            collectionEntryId,
            sharedCollectionOriginVariantRef,
            sharedCollectionOriginVariantHeaders,
            savedOperationState,
            isShared,
          } = action;

          // Find already open tab
          const matchingEntry = Object.entries(
            currentSavedOperationLocalState,
          ).find((entry) => entry[1]?.collectionEntryId === collectionEntryId);
          if (matchingEntry) {
            setSelectedTab(matchingEntry[0]);
            return currentSavedOperationLocalState;
          }

          // Otherwise open a new tab
          const operationTabId = createNewTab(savedOperationState);

          return produce(
            currentSavedOperationLocalState,
            (draftSavedOperationEntries) => {
              // eslint-disable-next-line no-param-reassign
              draftSavedOperationEntries[operationTabId] = {
                collectionEntryId,
                collectionId,
                sharedCollectionOriginVariantRef,
                operationModified: false,
                variablesModified: false,
                headersModified: false,
                preflightOperationScriptModified: false,
                postflightOperationScriptModified: false,
                savedOperationState,
                isShared,
                sharedCollectionOriginVariantHeaders,
              };
            },
          );
        }
        case 'SaveOperation': {
          const {
            operationTabId,
            collectionEntryId,
            collectionId,
            collectionEntryState,
            sharedCollectionOriginVariantHeaders,
            sharedCollectionOriginVariantRef,
            isShared,
          } = action;

          return produce(
            currentSavedOperationLocalState,
            (draftSavedOperationEntries) => {
              // eslint-disable-next-line no-param-reassign
              draftSavedOperationEntries[operationTabId] = {
                collectionEntryId,
                collectionId,
                sharedCollectionOriginVariantRef,
                sharedCollectionOriginVariantHeaders,
                operationModified: false,
                variablesModified: false,
                headersModified: false,
                preflightOperationScriptModified: false,
                postflightOperationScriptModified: false,
                savedOperationState: collectionEntryState,
                isShared,
              };
            },
          );
        }
        case 'UpdateRemoteSavedValue': {
          const { operationTabId, savedEntryState } = action;

          const currentInformation =
            currentSavedOperationLocalState[operationTabId];
          if (!currentInformation) {
            return currentSavedOperationLocalState;
          }

          return produce(
            currentSavedOperationLocalState,
            (draftSavedOperationEntries) => {
              // eslint-disable-next-line no-param-reassign
              draftSavedOperationEntries[operationTabId] = {
                ...currentInformation,
                savedOperationState: savedEntryState,
              };
            },
          );
        }
        case 'CloseOperation': {
          return omit(currentSavedOperationLocalState, action.operationTabId);
        }
        case 'ModifyLocalOperation': {
          const {
            operationTabId,
            collectionEntryState: {
              operation,
              variables,
              headers,
              preflightOperationScript: script,
              postflightOperationScript,
            },
          } = action;
          const currentInformation =
            currentSavedOperationLocalState[operationTabId];
          if (!currentInformation) {
            return currentSavedOperationLocalState;
          }

          const currentState = currentInformation.savedOperationState;
          const operationModified =
            operation === undefined
              ? currentInformation.operationModified
              : operation !== currentState.operation;
          const variablesModified =
            variables === undefined
              ? currentInformation.variablesModified
              : variables !== currentState.variables;

          const filterOutSharedInjectedHeaders = (
            arr: { headerName: string; value: string }[],
          ) =>
            arr.filter(
              (header) =>
                !currentInformation.sharedCollectionOriginVariantHeaders?.some(
                  (sharedHeader) =>
                    sharedHeader.headerName === header.headerName &&
                    sharedHeader.value === header.value,
                ),
            );
          const headersModified =
            headers === undefined
              ? currentInformation.headersModified
              : !isEqual(
                  filterOutSharedInjectedHeaders(
                    headers.map((header) =>
                      pick(header, ['headerName', 'value']),
                    ),
                  ),
                  filterOutSharedInjectedHeaders(
                    currentState.headers?.map((header) =>
                      pick(header, ['headerName', 'value']),
                    ) ?? [],
                  ),
                );

          const scriptModified =
            script === undefined
              ? currentInformation.preflightOperationScriptModified
              : script !== currentState.preflightOperationScript;
          const postflightOperationScriptModified =
            postflightOperationScript === undefined
              ? currentInformation.postflightOperationScriptModified
              : postflightOperationScript !==
                currentState.postflightOperationScript;
          if (
            operationModified === currentInformation.operationModified &&
            variablesModified === currentInformation.variablesModified &&
            headersModified === currentInformation.headersModified &&
            scriptModified ===
              currentInformation.preflightOperationScriptModified &&
            postflightOperationScriptModified ===
              currentInformation.postflightOperationScriptModified
          ) {
            return currentSavedOperationLocalState;
          }

          return produce(
            currentSavedOperationLocalState,
            (draftSavedOperationEntries) => {
              // eslint-disable-next-line no-param-reassign
              draftSavedOperationEntries[operationTabId] = {
                ...currentInformation,
                operationModified,
                variablesModified,
                headersModified,
                preflightOperationScriptModified: scriptModified,
                postflightOperationScriptModified,
              };
            },
          );
        }
        case 'DeleteOperation': {
          const { collectionEntryId } = action;

          const matchingTab = Object.entries(
            currentSavedOperationLocalState,
          ).find(
            ([_, savedEntry]) =>
              savedEntry?.collectionEntryId === collectionEntryId,
          );
          if (!matchingTab) {
            return currentSavedOperationLocalState;
          }
          return produce(
            currentSavedOperationLocalState,
            (draftSavedOperationEntries) => {
              // eslint-disable-next-line no-param-reassign
              delete draftSavedOperationEntries[matchingTab[0]];
            },
          );
        }
        case 'DeleteCollection': {
          const { collectionId } = action;

          const matchingTabs = Object.entries(
            currentSavedOperationLocalState,
          ).filter(
            ([_, savedEntry]) => savedEntry?.collectionId === collectionId,
          );
          if (matchingTabs.length === 0) {
            return currentSavedOperationLocalState;
          }
          return produce(
            currentSavedOperationLocalState,
            (draftSavedOperationEntries) => {
              matchingTabs.forEach((matchingTab) => {
                // eslint-disable-next-line no-param-reassign
                delete draftSavedOperationEntries[matchingTab[0]];
              });
            },
          );
        }
        default:
          return assertUnreachable(action);
      }
    },
    [createNewTab, setSelectedTab],
  );
  const dispatchSavedOperationLocalStateAction = React.useCallback(
    (action: SavedOperationAction) => {
      // `reduceSavedOperations` here has side effects that set the active tab,
      // and create new operations. We want to batch these updates together so
      // the updates trigger a single rerender.
      ReactDOM.unstable_batchedUpdates(() => {
        setSavedOperationLocalState((currentSavedOperationLocalState) =>
          reduceSavedOperations(currentSavedOperationLocalState, action),
        );
      });
    },
    [reduceSavedOperations, setSavedOperationLocalState],
  );

  return {
    savedOperationLocalState,
    dispatchSavedOperationLocalStateAction,
  };
}
