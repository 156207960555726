import { isEmbeddableSandboxRoute } from 'src/app/embeddableSandbox/isEmbeddableSandboxRoute';

export type AmplitudeHelpersShouldTrackUserParams = {
  excludeRouteFromTracking: boolean;
  amplitudeConfiguration: {
    trackingEnabled: boolean;
    trackEmbeddedUsers: boolean;
  };
};

export const shouldOptOutOfAmplitude = (
  args: AmplitudeHelpersShouldTrackUserParams,
) => {
  return !shouldAmplitudeTrackUser(args);
};

const shouldAmplitudeTrackUser = ({
  excludeRouteFromTracking,
  amplitudeConfiguration,
}: AmplitudeHelpersShouldTrackUserParams) => {
  if (excludeRouteFromTracking || !amplitudeConfiguration.trackingEnabled) {
    return false;
  }

  if (isEmbeddableSandboxRoute()) {
    return amplitudeConfiguration.trackEmbeddedUsers;
  }

  return true;
};
