// Adapted by @benjamn from the crypto-hash npm package:
// https://github.com/sindresorhus/crypto-hash/blob/master/browser.js

function create(algorithm: string) {
  return (data: Uint8Array | string): Promise<string> => {
    const buffer =
      typeof data === 'string' ? new TextEncoder().encode(data) : data;
    return crypto.subtle.digest(algorithm, buffer).then(bufferToHex);
  };
}

// Precompute a lookup table of every hex octet, represented as a
// two-character string padded with any necessary leading 0s.
const octets: string[] = [];
for (let i = 0; i <= 0xff; ++i) {
  octets[i] = `00${i.toString(16)}`.slice(-2);
}
function byteToOctet(byte: number) {
  return octets[byte];
}
function bufferToHex(buffer: ArrayBuffer): string {
  return Array.prototype.map.call(new Uint8Array(buffer), byteToOctet).join('');
}

export const sha256 = create('SHA-256');
