import { anatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

import type { ToastStatus } from './shared';

const { bg, border, icon } = customColors;
const toastAnatomy = anatomy('toast').parts(
  'container',
  'icon',
  'footer',
  'closeButton',
  'title',
  'body',
);

const COLORS = {
  info: icon.info,
  error: icon.error,
  success: icon.success,
  loading: false,
} satisfies Record<ToastStatus, string | false>;

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(toastAnatomy.keys);

const baseStyle = definePartsStyle(({ status }) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateAreas: '"icon title close-button" ". body ." ". footer footer"',
    alignItems: 'center',
    gridRowGap: 1,
    gridColumnGap: 2,
    position: 'relative',
    px: 4,
    py: 6,
    boxShadow: 'toasts',
    rounded: 'base',
    border: '1px solid',
    borderColor: border.primary,
    bg: bg.primary,
    w: '400px',
  },
  closeButton: {
    gridArea: 'close-button',
  },
  footer: {
    gridArea: 'footer',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    mt: 3,
    '&:has(> :nth-child(2))': {
      justifyContent: 'space-between',
    },
  },
  icon: {
    gridArea: 'icon',
    display: 'block',
    color: COLORS[status as ToastStatus],
  },
  title: {
    gridArea: 'title',
  },
  body: {
    gridArea: 'body',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
}));

export const ToastStyles = defineMultiStyleConfig({ baseStyle });
