import { popoverAnatomy } from '@chakra-ui/anatomy';
import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';
import { baseStyle as tooltipBaseStyle } from '../Tooltip/Tooltip.style';

const { bg, border, icon, text } = customColors;

// https://github.com/chakra-ui/chakra-ui/issues/4695#issuecomment-991023319
export const $arrowBg = cssVar('popper-arrow-bg');

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(popoverAnatomy.keys);

const content = defineStyle({
  width: 'xs',
  minWidth: '18.5rem',
  border: '1px solid',
  borderColor: border.primary,
  bg: bg.primary,
  color: text.primary,
  [$arrowBg.variable]: bg.primary,
  rounded: 'base',
  padding: 0,
  _focusVisible: {
    outlineColor: border.focused,
    boxShadow: false,
  },
});

const header = defineStyle({
  display: 'flex',
  py: 2,
  px: 3,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 2,
  alignSelf: 'stretch',
  fontFamily: 'heading',
  fontSize: 'base',
  fontStyle: 'normal',
  fontWeight: 'medium',
  lineHeight: 'base',
  borderBottom: `1px solid ${border.primary}`,
  color: text.heading,
});

const body = defineStyle({
  color: text.primary,
  fontFamily: 'body',
  fontSize: 'base',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'base',
  py: 2,
  px: 3,
});

const footer = defineStyle({
  borderTopColor: 'transparent',
  display: 'flex',
  justifyContent: 'end',
  gap: 2,
  pt: 0,
  px: 3,
  pb: 3,
});

const arrow = defineStyle({
  // do not override styles here
});

const closeButton = defineStyle({
  position: 'relative',
  color: icon.primary,
  top: 0,
  right: 0,
});

const baseStyle = definePartsStyle({
  content,
  header,
  closeButton,
  body,
  footer,
  arrow,
});

const tooltip = definePartsStyle({
  content: {
    width: false,
    minWidth: false,
    maxW: 'xs',
    ...tooltipBaseStyle,
  },
  header: {
    color: text.white,
  },
  body: {
    color: text.white,
  },
  footer: {
    color: text.white,
  },
});

export const PopoverStyle: ComponentStyleConfig = defineMultiStyleConfig({
  sizes: { sm: {} },
  baseStyle,
  variants: {
    tooltip,
  },
});
