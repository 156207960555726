import { gql, useQuery } from '@apollo/client';

import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
export const accountServicesQuery = gql`
  query AccountServicesQuery($accountId: ID!) {
    account(id: $accountId) {
      id
      services {
        id
        graphType
      }
    }
  }
`;
export const useAccountHasSupergraphs = ({
  accountId,
}: {
  accountId: string | undefined;
}) => {
  const { data, loading, error } = useQuery<
    GraphQLTypes.AccountServicesQuery,
    GraphQLTypes.AccountServicesQueryVariables
  >(accountServicesQuery, {
    variables: { accountId: accountId as string },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
    skip: !accountId,
  });

  const hasSupergraphs = !!data?.account?.services.some(
    ({ graphType }) => graphType === GraphQLTypes.GraphType.CLOUD_SUPERGRAPH,
  );
  const hasClassicGraphs = !!data?.account?.services.some(
    ({ graphType }) => graphType === GraphQLTypes.GraphType.CLASSIC,
  );
  const hasGraphs = data?.account?.services && data.account.services.length > 0;

  return { loading, hasSupergraphs, hasClassicGraphs, hasGraphs, error };
};
