import { ValueOf } from '@local/utlity-types';
import { LocationDescriptorObject } from 'history';
import { omit } from 'lodash';
import { parse, stringify } from 'query-string';

import { CollectionEntryState } from 'src/app/graph/explorerPage/hooks/useExplorerState/useEditorTabState/collectionEntryState';
import { GraphRef } from 'src/app/graph/hooks/useGraphRef';

import Config from './config';

export type AnyQueryParamKey = ValueOf<typeof Config.queryParameters>;
export type AnyQueryParams = Partial<
  Record<
    AnyQueryParamKey,
    string | number | object | null | undefined | boolean
  >
>;

/**
 * Modify a search string to add / remove params. Use template arguments to
 * narrow the types of query params allowed. This is useful in combination with
 * a type that describes the query params for a given page / feature.
 *
 * Note: Consider using RouteConfig.locationFrom instead which also handles
 * patching query params.
 *
 * @param currentQueryString stringified params to modify, usually a
 * Location.search
 * @param valuesToSet map of param key to value. i.e. to include paramKey=value
 * in the search string, pass { paramKey: 'value' }
 * @param valuesToUnset list of param keys to remove from the search string
 */
export const mergeQueryParams = <
  T extends AnyQueryParams = AnyQueryParams,
  K extends keyof T = keyof T,
>(
  currentQueryString: string,
  valuesToSet: Partial<T>,
  valuesToUnset?: K[],
) => {
  const params = { ...parse(currentQueryString), ...valuesToSet };
  if (valuesToUnset) {
    valuesToUnset.forEach((key) => {
      delete params[key];
    });
  }
  return stringify(params);
};

export type ModalName = (typeof Config.modals)[keyof typeof Config.modals];

// TODO(Maya): can we just pass state to location? instead of manually typing these?
const MODALS_WITH_STATE: {
  [Key in keyof AdditionalModalState]: (keyof AdditionalModalState[Key])[];
} = {
  'create-personal-operation-collection': ['graphRef'],
  'create-shared-operation-collection': ['graphRef'],
  'delete-operation-collection': ['collectionId'],
  'duplicate-operation-collection': ['collectionId'],
  'delete-operation-collection-entry': ['collectionId', 'collectionEntryId'],
  'rename-operation-collection-entry': ['collectionId', 'collectionEntryId'],
  'save-operation-collection-entry': [
    'operationTabId',
    'collectionEntryState',
    'graphRef',
  ],
  'view-collection-entry-details': ['collectionId', 'collectionEntryId'],
};
export type AdditionalModalState = {
  'create-personal-operation-collection': { graphRef: GraphRef | null };
  'create-shared-operation-collection': { graphRef: GraphRef };
  'delete-operation-collection': { collectionId: string };
  'duplicate-operation-collection': { collectionId: string };
  'delete-operation-collection-entry': {
    collectionId: string;
    collectionEntryId: string;
  };
  'rename-operation-collection-entry': {
    collectionId: string;
    collectionEntryId: string;
  };
  'save-operation-collection-entry': {
    operationTabId: string;
    collectionEntryState: CollectionEntryState;
    graphRef: GraphRef | null;
  };
  'view-collection-entry-details': {
    collectionId: string;
    collectionEntryId: string;
  };
};

/**
 * Given an existing location, create a new location with modal name
 * `overlayName` populated
 * @param location Original location to extend values from. The original
 * location will not be modified
 * @param overlayName Name of the overlay to modify in the location
 */
export function setOverlayVisibleLocation<
  T extends LocationDescriptorObject,
  Modal extends ModalName,
>({
  location,
  overlayName,
  additionalSearchParameters = {},
  additionalState,
}: {
  location: Readonly<T>;
  overlayName: Modal;
  additionalSearchParameters?: AnyQueryParams;
} & (Modal extends keyof AdditionalModalState
  ? { additionalState: AdditionalModalState[Modal] }
  : { additionalState?: undefined })): T {
  return {
    ...location,
    search: mergeQueryParams(location.search ?? '', {
      ...additionalSearchParameters,
      [Config.queryParameters.Overlay]: overlayName,
    }),
    state: additionalState ?? location.state,
  };
}

/**
 * Given an existing location, create a new location with no overlays showing
 * @param location Original location to extend values from. The original
 * location will not be modified
 */
export function setOverlayClosedLocation<T extends LocationDescriptorObject>(
  location: Readonly<T>,
  additionalSearchParameters: AnyQueryParamKey[] = [],
): T {
  const state = location.state;
  const overlay =
    location.search && parse(location.search)[Config.queryParameters.Overlay];
  return {
    ...location,
    search: mergeQueryParams(location.search ?? '', {}, [
      Config.queryParameters.Overlay,
      ...additionalSearchParameters,
    ]),
    state:
      overlay &&
      typeof overlay === 'string' &&
      overlay in MODALS_WITH_STATE &&
      typeof state === 'object' &&
      state
        ? omit(
            state as object,
            ...MODALS_WITH_STATE[overlay as keyof AdditionalModalState],
          )
        : state,
  };
}
