import { useReactiveVar } from '@apollo/client';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
} from '@apollo/orbit';
import { uniqueId } from 'lodash';
import moment from 'moment';
import React from 'react';

import { useShowToast } from 'src/components/toast/Toast';
import { useTrackCustomEvent } from 'src/hooks/useTrackCustomEvent';
import {
  shouldShowBlockingReloadPromptVar,
  shouldShowDismissableReloadPromptVar,
} from 'src/serviceWorkerSetup/serviceWorkerReactiveVariables';
import { triggerWorkerUpdateAndReload } from 'src/serviceWorkerSetup/serviceWorkerRegistration';

/**
 * This component manages the prompt to update your service worker. This has two
 * hooks into it, the reactive variables `shouldShowDismissableReloadPrompt` and
 * `shouldShowBlockingReloadPrompt`.
 *
 * When `shouldShowDismissableReloadPrompt` is truthy, a dismissable toast will
 * show up asking if you want to update your worker.
 *
 * When `shouldShowBlockingReloadPrompt` is truthy, a modal will open
 * show up asking if you want to update your worker, or wait five minutes.
 * Delaying five minutes will open a non dismissble toast that lets you update,
 * if you don't click this within five minutes, the modal will be reopened.
 */
export const ServiceWorkerRefreshPrompts = () => {
  const shouldShowDismissableReloadPrompt = useReactiveVar(
    shouldShowDismissableReloadPromptVar,
  );
  const shouldShowBlockingReloadPrompt = useReactiveVar(
    shouldShowBlockingReloadPromptVar,
  );

  const { showToasts, hideToasts } = useShowToast();
  const stableToastId = React.useMemo(() => uniqueId(), []);
  const trackCustomEvent = useTrackCustomEvent();

  const showWorkerReloadToast = React.useCallback(
    ({ isForcefulPrompt }: { isForcefulPrompt: boolean }) => {
      showToasts({
        stableId: stableToastId,
        heading: 'App updates are available',
        message: (
          <span>
            {isForcefulPrompt ? (
              <>
                We have updated the app to resolve some issues with the previous
                version. Please refresh now to get the latest! ✨
              </>
            ) : (
              <>
                The data is fresh, but the app is out of date. Refresh to get
                the latest and greatest! ✨
              </>
            )}
            <br />
            <i>(Note: all open Studio tabs will refresh.)</i>
          </span>
        ),
        level: 'info',
        dismissable: !isForcefulPrompt,
        onDismiss: () => {
          trackCustomEvent({
            category: 'Service Workers',
            action: 'update_available_toast_dismissed',
          });
        },
        actions: (closeToast) => (
          <Button
            className="mr-2"
            onClick={() => {
              trackCustomEvent({
                category: 'Service Workers',
                action: isForcefulPrompt
                  ? 'forced_update_available_toast_clicked'
                  : 'update_available_toast_clicked',
              });
              triggerWorkerUpdateAndReload();
              closeToast();
            }}
          >
            Refresh
          </Button>
        ),
      });
    },
    [showToasts, stableToastId, trackCustomEvent],
  );

  const [shouldShowForceRefreshModal, setShouldShowForceRefreshModal] =
    React.useState(false);

  React.useEffect(() => {
    if (shouldShowBlockingReloadPrompt) {
      setShouldShowForceRefreshModal(true);
    } else if (shouldShowDismissableReloadPrompt) {
      showWorkerReloadToast({ isForcefulPrompt: false });
    } else {
      hideToasts(stableToastId);
    }
  }, [
    shouldShowBlockingReloadPrompt,
    shouldShowDismissableReloadPrompt,
    showWorkerReloadToast,
    hideToasts,
    stableToastId,
  ]);

  React.useEffect(() => {
    return () => hideToasts(stableToastId);
  }, [stableToastId, hideToasts]);

  const refreshTimeout = React.useRef<NodeJS.Timeout>();
  const delayRefreshFiveMinutes = React.useCallback(() => {
    trackCustomEvent({
      category: 'Service Workers',
      action: 'delay_worker_refresh',
    });
    setShouldShowForceRefreshModal(false);
    showWorkerReloadToast({ isForcefulPrompt: true });
    if (refreshTimeout.current) {
      clearTimeout(refreshTimeout.current);
    }
    refreshTimeout.current = setTimeout(
      () => setShouldShowForceRefreshModal(true),
      moment.duration(5, 'minutes').asMilliseconds(),
    );
  }, [trackCustomEvent, showWorkerReloadToast]);
  React.useEffect(
    () => () => {
      if (refreshTimeout.current) {
        clearTimeout(refreshTimeout.current);
      }
    },
    [],
  );

  return (
    <Modal
      isOpen={shouldShowForceRefreshModal}
      onClose={delayRefreshFiveMinutes}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalTitle>We've updated Studio</ModalTitle>
        </ModalHeader>
        <ModalBody>
          Your data is fresh, but the app is out of date. Reload all tabs now to
          get the latest and greatest or click "Reload in 5 minutes" to save
          your changes first.
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              variant="secondary"
              onClick={delayRefreshFiveMinutes}
              type="button"
            >
              Reload in 5 minutes
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                trackCustomEvent({
                  category: 'Service Workers',
                  action: 'update_available_modal_clicked',
                });
                triggerWorkerUpdateAndReload();
              }}
              type="submit"
            >
              Reload now
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
