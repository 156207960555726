import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const { bg, border, code } = customColors;

const customParts = ['container', 'button', 'text'] as const;
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(customParts);

const baseStyle = definePartsStyle({
  container: {
    display: 'inline-flex',
    h: 6,
    px: 2,
    py: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    flexShrink: 0,
    rounded: 'base',
    border: '1px solid',
    borderColor: border.primary,
    background: bg.secondary,
  },
  text: {
    textOverflow: 'hidden',
    isTruncated: true,
  },
  button: {
    p: 0,
    h: undefined,
    minW: undefined,
  },
});

const mono = definePartsStyle({
  text: {
    color: code.a,
  },
  container: {
    color: code.a,
  },
});

const fullColor = definePartsStyle({
  text: {
    color: code.f,
  },
  container: {
    color: code.f,
  },
});

export const CodeStyles = defineMultiStyleConfig({
  baseStyle,
  variants: {
    mono,
    fullColor,
  },
  defaultProps: { variant: 'mono' },
});
