import { gql, useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';

import { useIsAppInBackgroundContext } from 'src/app/appLayout/hooks/useIsAppInBackground';
import { GraphRef, graphRefToString } from 'src/app/graph/hooks/useGraphRef';
import { useIsLoggedIn } from 'src/app/graph/hooks/useIsLoggedIn';
import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

export const getRouterIsInitiating = ({
  routerStatus,
  graphType,
  latestLaunch,
}: {
  routerStatus: GraphQLTypes.RouterStatus | undefined;
  graphType: GraphQLTypes.GraphType;
  latestLaunch:
    | {
        id: string;
        status: GraphQLTypes.LaunchStatus;
      }
    | undefined
    | null;
}) => {
  return !!(
    /**
     * The routerStatus is undefined when it hasn't been initialized yet,
     * and then when it has a status we only call it initiating when that status
     * is creating.
     */
    (
      (routerStatus === undefined ||
        routerStatus === GraphQLTypes.RouterStatus.CREATING) &&
      /**
       * just double checking this is a cloud supergraph
       */
      graphType === GraphQLTypes.GraphType.CLOUD_SUPERGRAPH &&
      /**
       * The launch will be populated before the router starts initializing in the backend,
       * but we poll, so we can't guarantee the launch comes back first.
       * The launch in an initializing state may be undefined (pre initialize),
       * initiating (latestLaunch.status === GraphQLTypes.LaunchStatus.LAUNCH_INITIATED),
       * or completed (latestLaunch.status === GraphQLTypes.LaunchStatus.LAUNCH_COMPLETED).
       * The router could be undefined or CREATING with either of these states when the
       * router is initializing. If the launch failed, the router is not initializing.
       */
      (!latestLaunch ||
        latestLaunch.status !== GraphQLTypes.LaunchStatus.LAUNCH_FAILED)
    )
  );
};

export const useIsCloudEndpointInitiating = ({
  graphRef,
}: {
  graphRef: GraphRef | null;
}) => {
  const [hasBeenPollingFor5Min, setHasBeenPollingFor5Min] = useState(false);
  const [routerIsInitiating, setRouterIsInitiating] = useState(false);
  const [
    initialInitiatingFetchHasResolved,
    setInitialInitiatingFetchHasResolved,
  ] = useState(false);
  const appIsInBackground = useIsAppInBackgroundContext();
  const { isLoggedIn } = useIsLoggedIn();

  /**
   * Add a catch all 5 min polling max, in case a graph
   * gets stuck in a state we don't expect. It shouldn't take 5 min
   * to speed up
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasBeenPollingFor5Min(true);
      setRouterIsInitiating(false);
    }, 300_000); // 5 min in ms
    return () => clearTimeout(timeout);
  }, []);

  const skip =
    !graphRef ||
    !isLoggedIn ||
    (initialInitiatingFetchHasResolved && !routerIsInitiating) ||
    appIsInBackground;

  useEffect(() => {
    if (skip) {
      setHasBeenPollingFor5Min(false);
    }
  }, [skip]);

  const { data, loading, refetch } = useQuery(
    gql<
      GraphQLTypes.UseIsCloudEndpointInitiating,
      GraphQLTypes.UseIsCloudEndpointInitiatingVariables
    >`
      query UseIsCloudEndpointInitiating($graphRef: ID!) {
        variant(ref: $graphRef) {
          ... on GraphVariant {
            id
            router {
              id
              routerUrl # include this in the fetch so the url is populated in the cache as soon as the status changes
              status
            }
            latestLaunch {
              id
              status
              build {
                result {
                  ... on BuildFailure {
                    errorMessages {
                      message
                    }
                  }
                }
              }
            }
            graph {
              id
              graphType
            }
          }
        }
      }
    `,
    {
      variables: {
        graphRef: graphRefToString(graphRef as GraphRef),
      },
      pollInterval: 3000,
      skip: skip || hasBeenPollingFor5Min,
      context: appLinkContext({
        catchErrors: [
          ignorePermissionsErrors,
          // the cloud router variants have different rules than our old variant rules
          // if we see this error, its a classic graph, ignore it
          (error) => error.message.includes('does not match a valid pattern'),
        ],
      }),
    },
  );

  const variant =
    data?.variant?.__typename === 'GraphVariant' ? data.variant : undefined;

  const [cachedBuildErrors, setCachedBuildErrors] = useState<
    | GraphQLTypes.UseIsCloudEndpointInitiating_variant_GraphVariant_latestLaunch_build_result_BuildFailure_errorMessages[]
    | undefined
  >();

  // this needs to be in state & set via use effect
  // because once we skip a query data becomes undefined
  const [cachedRouterStatus, setCachedRouterStatus] = useState(
    variant?.router?.status,
  );

  useEffect(() => {
    if (!loading && variant) {
      setCachedRouterStatus(variant.router?.status);
      setInitialInitiatingFetchHasResolved(true);
      setRouterIsInitiating(
        getRouterIsInitiating({
          graphType: variant.graph.graphType,
          latestLaunch: variant.latestLaunch,
          routerStatus: variant.router?.status,
        }),
      );
      setCachedBuildErrors(
        variant.latestLaunch?.build?.result?.__typename === 'BuildFailure'
          ? variant.latestLaunch.build.result.errorMessages
          : undefined,
      );
    }
  }, [loading, variant]);

  return useMemo(
    () => ({
      routerIsInitiating,
      loading,
      routerStatus: variant?.router?.status ?? cachedRouterStatus,
      buildErrors: cachedBuildErrors,
      // Due to the latency of provisioning a router when re-creating a router (i.e.
      // re-provisioning an AWS-hosted router provisioning can take up to
      // 5 minutes), we do not rely on the return value of the mutation
      // to determine whether a router is actually being provisioned.
      // We need to make a query for the router, complete checks to understand its
      // true state, and also poll for the latest information on the router status
      // until the router has been provisioned. To do so we leverage the existing
      // isCloudEndpointInitiating hook that can trigger this query via a refetch
      // call.
      refetch,
    }),
    [
      cachedRouterStatus,
      loading,
      cachedBuildErrors,
      routerIsInitiating,
      variant?.router?.status,
      refetch,
    ],
  );
};

const IsCloudEndpointInitiatingContext = React.createContext<ReturnType<
  typeof useIsCloudEndpointInitiating
> | null>(null);

export const IsCloudEndpointInitiatingContextProvider = ({
  children,
  graphRef,
}: {
  children: React.ReactNode;
  graphRef: GraphRef | null;
}) => (
  <IsCloudEndpointInitiatingContext.Provider
    value={useIsCloudEndpointInitiating({ graphRef })}
  >
    {children}
  </IsCloudEndpointInitiatingContext.Provider>
);

export const useIsCloudEndpointInitiatingContext = () => {
  const context = React.useContext(IsCloudEndpointInitiatingContext);
  if (!context) {
    throw new Error(
      'useIsCloudEndpointInitiatingContext must be used within a UseIsCloudEndpointInitiatingContextProvider',
    );
  }
  return context;
};
