import Config from './config';

/**
 * Take a relative or absolute URL and return a relative URL
 */
export function relativeURL(href: string) {
  const url = new URL(href, Config.absoluteUrl);

  return `${url.pathname}${url.search}`;
}
