import { forwardRef } from '@chakra-ui/react';
import React from 'react';

import { Text, TextProps } from '../Text/Text';

export type CardDescriptionProps = Omit<
  TextProps<'p'>,
  'size' | 'variant' | '__orbitStyleConfig'
>;

export const CardDescription = forwardRef<CardDescriptionProps, 'p'>(
  (props, ref) => {
    return <Text {...props} ref={ref} variant="subtitle" />;
  },
);
