import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const { code, text } = customColors;

const baseStyle = defineStyle({
  color: text.primary,
  fontFamily: 'body',
});

const variants = {
  body: defineStyle({
    fontWeight: 'normal',
  }),
  bodySb: defineStyle({
    fontWeight: 'semibold',
  }),
  label: defineStyle({
    color: text.secondary,
    fontSize: 'xs',
    fontWeight: 'bold',
    lineHeight: 'xs',
    textTransform: 'uppercase',
  }),
  code: defineStyle({
    color: code.a,
    fontFamily: 'mono',
    fontWeight: 450,
  }),
  codeSb: defineStyle({
    color: code.a,
    fontFamily: 'mono',
    fontWeight: 'semibold',
  }),
  subtitle: defineStyle({
    color: text.secondary,
    fontSize: 'base',
    fontWeight: 'normal',
    lineHeight: 'base',
  }),
};

const sizes = {
  base: defineStyle({
    fontSize: 'base',
    lineHeight: 'base',
  }),
  sm: defineStyle({
    fontSize: 'sm',
    lineHeight: 'sm',
  }),
  '2xl': defineStyle({
    fontSize: '2xl',
    lineHeight: '2xl',
  }),
};

export const TextStyles = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: { variant: 'body', size: 'base' },
});
