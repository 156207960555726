import { gql, useMutation } from '@apollo/client';

import {
  UpdateSurvey,
  UpdateSurveyVariables,
} from 'src/lib/graphqlTypes/types';

export const updateSurveyMutation = gql<UpdateSurvey, UpdateSurveyVariables>`
  mutation UpdateSurvey(
    $orgId: String!
    $surveyId: String!
    $surveyState: [SurveyQuestionInput!]!
    $surveyIdVersion: Int!
  ) {
    updateSurvey(
      internalAccountId: $orgId
      surveyId: $surveyId
      surveyState: $surveyState
      surveyIdVersion: $surveyIdVersion
    ) {
      id
      questionAnswers {
        questionKey
        answerValue
      }
    }
  }
`;

export const useUpdateSurveyMutation = () => useMutation(updateSurveyMutation);
