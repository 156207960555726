import {
  MenuDivider as ChakraMenuDivider,
  MenuDividerProps as ChakraMenuDividerProps,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type MenuDividerProps = OmitChakraProps<ChakraMenuDividerProps>;

export const MenuDivider = (props: MenuDividerProps) => {
  return <ChakraMenuDivider {...props} />;
};
