import { stepperAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const { bg, border, text } = customColors;

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(stepperAnatomy.keys);

const $stepperAccentColor = cssVar('stepper-accent-color');

const baseStyle = definePartsStyle({
  stepper: {
    bg: bg.secondary,
    rounded: 'lg',
    p: 6,
    [$stepperAccentColor.variable]: border.focused,
    gap: 6,
    height: 'fit-content',
  },
  step: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    gap: 3,
    alignItems: 'center',
  },
  title: {
    fontSize: 'md',
    fontWeight: 'semibold',
    lineHeight: 'base',
    textOverflow: 'ellipsis',
    color: text.primary,
    '&[data-status="incomplete"]': {
      color: text.disabled,
    },
  },
  description: {
    fontSize: 'sm',
    fontWeight: 'normal',
    lineHeight: 'shorter',
    color: text.primary,
    '&[data-status="incomplete"]': {
      color: text.disabled,
    },
  },
  indicator: {
    '&[data-status="incomplete"]': {
      borderColor: border.disabled,
    },
    '&[data-status="complete"]': {
      bg: bg.notice,
      borderColor: border.neutral,
      borderWidth: 2,
    },
  },
  separator: {
    bg: border.primary,
    [$stepperAccentColor.variable]: border.focused,
    minHeight: '1rem',
  },
  icon: {},
  number: {
    fontFamily: 'heading',
    '&[data-status="active"]': {
      color: text.heading,
    },
    '&[data-status="incomplete"]': {
      color: text.secondary,
    },
    '&[data-status="complete"]': {
      color: text.inverted,
    },
  },
});

export const StepperStyles = defineMultiStyleConfig({ baseStyle, sizes: {} });
