import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { cssVar } from '@chakra-ui/theme-tools';

import { customColors } from '../../theme/customColors';

const $bg = cssVar('tooltip-bg');
const $arrowBg = cssVar('popper-arrow-bg');

export const baseStyle = defineStyle({
  [$bg.variable]: customColors.bg.black,
  bg: [$bg.reference],
  [$arrowBg.variable]: [$bg.reference],
  color: customColors.text.white,
  zIndex: 'tooltip',
  px: 2,
  py: 1,
  rounded: 'base',
  fontSize: 'sm',
  fontFamily: 'body',
  fontWeight: 'normal',
  lineHeight: 'sm',
  boxShadow: 'popovers',
  border: '1px solid',
  borderColor: customColors.border.black,
});

export const TooltipStyle = defineStyleConfig({
  baseStyle,
});
