/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
interface Props extends Omit<React.SVGProps<SVGSVGElement>, "css"> {
  weight?: "thin" | "normal";
}
export const IconErrorSolid = React.forwardRef<SVGSVGElement, Props>(
  (
    { fill = "none", stroke = "currentColor", weight = "normal", ...props },
    ref,
  ) => (
    <svg
      viewBox="0 0 20 20"
      ref={ref}
      css={css`
        * {
          vector-effect: non-scaling-stroke;
        }
        overflow: visible;
        height: 20px;
      `}
      {...props}
    >
      <path
        fill="currentColor"
        d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm3.9 13.9c-.3.3-.8.3-1.1.1l-.1-.1-2.7-2.7-2.7 2.7c-.3.3-.9.3-1.2 0-.3-.3-.3-.8-.1-1.1l.1-.1L8.8 10 6.1 7.3c-.3-.3-.3-.9 0-1.2.3-.3.8-.3 1.1-.1l.1.1L10 8.8l2.7-2.7c.3-.3.9-.3 1.2 0 .3.3.3.8.1 1.1l-.1.1-2.7 2.7 2.7 2.7c.3.4.3.9 0 1.2z"
      />
      <path
        fill="transparent"
        d="M13.9 6.1c.3.3.3.8.1 1.1l-.1.1-2.7 2.7 2.7 2.7c.3.3.3.9 0 1.2-.3.3-.8.3-1.1.1l-.1-.1-2.7-2.7-2.7 2.7c-.3.3-.9.3-1.2 0-.3-.3-.3-.8-.1-1.1l.1-.1L8.8 10 6.1 7.3c-.3-.3-.3-.9 0-1.2.3-.3.8-.3 1.1-.1l.1.1L10 8.8l2.7-2.7c.4-.3.9-.3 1.2 0z"
        className="inner"
      />
    </svg>
  ),
);
