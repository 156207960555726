/* eslint-disable local/no-conditional-class-names */
import cx from 'classnames';
import React, { ReactNode } from 'react';

import { Heading } from '../Heading/Heading';

export interface ModalTitleProps {
  className?: string;
  children?: ReactNode;
}

export const ModalTitle = ({ className, children }: ModalTitleProps) => {
  return (
    <Heading size="xl" className={cx('orbit-modal__title', className)}>
      {children}
    </Heading>
  );
};
