import { defineStyle } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const { border, bg } = customColors;

export const MenuHeaderStyles = defineStyle({
  zIndex: 1,
  bg: bg.primary,
  borderBottom: '1px solid transparent',
  px: 6,
  py: 2,
  gap: 2,
  display: 'grid',
  gridColumnGap: 6,
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
  '& > .chakra-button__group, & > .chakra-button': {
    gridRow: '1',
    gridColumnStart: '2',
    alignItems: 'center',
    alignSelf: 'start',
  },
  '& > :not(.chakra-button__group, .chakra-button)': {
    gridColumnStart: '1',
  },
  '& > .chakra-form-control': {
    gridRow: '2',
    gridColumn: '1 / -1',
    gridColumnStart: '1',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'start',
  },
  '& .chakra-form__label': {
    fontSize: 'sm',
    lineHeight: 'sm',
    fontWeight: 'semibold',
  },
  '&:has(> .chakra-form-control, > .chakra-input)': {
    borderBottomColor: border.primary,
    pb: 4,
  },
  '&:has(+ [data-scroll-location="middle"], + [data-scroll-location="bottom"])':
    {
      borderBottomColor: border.primary,
    },
});
