import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';

import { Loading } from 'src/components/common/loading/Loading';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { clearLocalStorage } from 'src/hooks/useLocalStorage';
import Config from 'src/lib/config';
import { BackendRouter } from 'src/lib/routers';

export const LogoutPage = () => {
  const [, setCurrentAccountId] = useCurrentAccountId();

  useEffect(() => {
    setCurrentAccountId(null).then(clearLocalStorage);
    Sentry.configureScope((scope) => scope.clear());

    BackendRouter.go('Logout', { callbackUrl: Config.absoluteUrl });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
};
