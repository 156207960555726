import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import {
  UseCurrentSubscriptionQuery,
  UseCurrentSubscriptionQueryVariables,
} from 'src/lib/graphqlTypes/types';

export function useCurrentSubscription({
  skip = false,
  ...variables
}: { skip?: boolean } & UseCurrentSubscriptionQueryVariables) {
  const currentSubscriptionResult = useQuery(
    gql<UseCurrentSubscriptionQuery, UseCurrentSubscriptionQueryVariables>`
      query UseCurrentSubscriptionQuery($accountId: ID!) {
        account(id: $accountId) {
          id
          isLocked
          currentSubscription {
            id: uuid
            canceledAt
            autoRenew
            pricePerSeatInUsdCents
            pricePerUnitInUsdCents
            currentPeriodStartedAt
            currentPeriodEndsAt
            state
            trialExpiresAt
            maxSelfHostedRequestsPerMonth
            maxServerlessRequestsPerMonth
          }
        }
      }
    `,
    {
      variables,
      skip,
      context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
    },
  );

  return useMemo(() => {
    return {
      currentSubscription:
        currentSubscriptionResult.data?.account?.currentSubscription,
      isLocked: !!currentSubscriptionResult.data?.account?.isLocked,
      ...currentSubscriptionResult,
    };
  }, [currentSubscriptionResult]);
}
