import React from 'react';
import { Link } from 'react-router-dom';

import * as onboardingRoutes from 'src/app/onboarding/routes';
import { usePerKeyLocalStorage } from 'src/hooks/usePerKeyLocalStorage';
import { relativeURL } from 'src/lib/relativeURL';
import { openStudioLinkFromEmbedInNewTab } from 'src/lib/routers/embedded';

import { isEmbeddableExplorerRoute } from '../embeddableExplorer/isEmbeddableExplorerRoute';
import { isEmbeddableSandboxRoute } from '../embeddableSandbox/isEmbeddableSandboxRoute';
import { graphRefToString, useGraphRef } from '../graph/hooks/useGraphRef';

/**
 * For all the log in CTAs in Sandbox, we want to take you to the /embed-authentication
 * route instead of the login route. If you aren't logged in on Studio you will
 * be redirected to the login route, but otherwise you need to authenticate for this origin
 */
export const MaybeEmbeddedLoginLink = ({
  children,
  userSegmentType,
  ...props
}: {
  children?: React.ReactNode;
  userSegmentType: Parameters<typeof onboardingRoutes.login.path>[0]['type'];
  className?: string;
}) => {
  const graphRef = useGraphRef();
  const graphRefString = graphRefToString(graphRef) ?? undefined;
  const isEmbeddableSandbox = isEmbeddableSandboxRoute();
  const isEmbeddableExplorer = isEmbeddableExplorerRoute();
  const isEmbedded = isEmbeddableExplorer || isEmbeddableSandbox;
  // we store the embedAuthenticationDetails by document.referrer
  const [embedAuthenticationDetails] = usePerKeyLocalStorage({
    initialValue: undefined,
    key: document.referrer,
    localStorageKey: 'embedAuthenticationDetails',
  });

  return isEmbedded ? (
    embedAuthenticationDetails ? (
      <button
        type="button"
        {...props}
        onClick={() =>
          openStudioLinkFromEmbedInNewTab(
            onboardingRoutes.embedAuthenticationRouteConfig.path({
              origin: embedAuthenticationDetails.origin,
              graphRef: graphRefString,
              type: userSegmentType,
              embedSubdomain: isEmbeddableSandbox ? 'sandbox' : 'explorer',
              inviteToken: embedAuthenticationDetails.inviteToken,
              accountId: embedAuthenticationDetails.accountId,
            }),
          )
        }
      >
        {children}
      </button>
    ) : (
      // If we don't have the origin, we can't ask you to log in.
      // We should always have an origin, because the embed pages load unless it is set
      <div {...props}>{children}</div>
    )
  ) : (
    <Link
      {...props}
      to={onboardingRoutes.login.location({
        from: relativeURL(window.location.href),
        type: userSegmentType,
      })}
    >
      {children}
    </Link>
  );
};
