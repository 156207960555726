import { AlertDescription, Box, useAlertStyles } from '@chakra-ui/react';
import React from 'react';

import { AlertIcon, AlertIconProps } from '../AlertIcon/AlertIcon';

export interface AlertContentProps extends AlertIconProps {
  children: React.ReactNode;
}

export const AlertContent = ({ children, status, icon }: AlertContentProps) => {
  const styles = useAlertStyles();

  return (
    <Box __css={styles.content}>
      <AlertIcon status={status} icon={icon} />
      <AlertDescription>{children}</AlertDescription>
    </Box>
  );
};
