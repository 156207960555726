import { gql } from '@apollo/client';
import moment, { Moment } from 'moment';

import { useCurrentPlan } from 'src/hooks/currentPlanV2Migration';
import { assertUnreachable } from 'src/lib/assertUnreachable';
import {
  ISOTimestamp,
  isISOTimestamp,
  isTimeSecondsAgo,
} from 'src/lib/date/date';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { Timestamp } from 'src/lib/graphqlTypes/customScalarTypes';

/**
 * Handles conversion of a GraphQL Timestamp type to an absolute time.
 * The timestamp can be passed in as either an ISOTimestamp (string formatted to ISO specs)
 * or a TimeSecondsAgo (as defined in our API as a string starting with `-`)
 * which indicates an offset from a specific time.
 * If the `time` param passed in is a TimeSecondsAgo type, use the
 * `offsetFromTime` param as the basis for the offset in order to determine the
 *  absolute time.
 * @param time
 * @param offsetFromTime
 * @returns a Moment object representing an absolute time.
 */
export function toAbsoluteMoment(
  time: Timestamp['output'],
  offsetFromTime: Timestamp['output'],
) {
  // We have a bug in our data model that has allowed '0's to creep in.
  // temporarily handle this situation by treating it the same as '-0'.
  return isTimeSecondsAgo(time) || time === '0'
    ? moment(offsetFromTime).add(
        typeof time === 'number' ? time : parseInt(time, 10),
        'seconds',
      )
    : moment(time);
}

export enum TimeRangeOverlap {
  None,
  Partial,
  Full,
}

export interface StatsTimeRangeResult {
  queryableTimeRange: {
    timeFrom: ISOTimestamp;
    timeTo: ISOTimestamp;
  } | null;
  errorMessage?: string;
  overlap: TimeRangeOverlap;
  maxRangeInDays: number;
  maxRangeInDaysForChecks: number;
}

export const statsWindowFromValidationWindowOperationsCheckFragment = gql`
  fragment StatsWindowFromValidationWindowOperationsCheckFragment on CheckWorkflow {
    id
    createdAt
    validationConfig {
      from
      to
    }
  }
`;

export function statsWindowFromValidationWindow(
  currentPlan: ReturnType<typeof useCurrentPlan>['currentPlan'] | null,
  operationsWorkflow: GraphQLTypes.StatsWindowFromValidationWindowOperationsCheckFragment | null,
  // allow current time to be passed in (mainly for tests)
  now: Moment = moment(),
): StatsTimeRangeResult {
  const { from: validationTimeFrom, to: validationTimeTo } =
    operationsWorkflow?.validationConfig ?? {};
  const offsetFromTime = operationsWorkflow?.createdAt;
  const planMaxRangeInDays = currentPlan?.maxRangeInDays ?? 0;
  const planMaxRangeInDaysForChecks = currentPlan?.maxRangeInDaysForChecks ?? 0;
  const retentionStartsAt = now.subtract(planMaxRangeInDays, 'days');
  if (
    !validationTimeFrom ||
    !validationTimeTo ||
    validationTimeFrom === 'undefined' ||
    validationTimeTo === 'undefined' ||
    !planMaxRangeInDays ||
    !offsetFromTime
  ) {
    return {
      queryableTimeRange: null,
      errorMessage: 'Metrics are not available for this time range',
      overlap: TimeRangeOverlap.None,
      maxRangeInDays: planMaxRangeInDays ?? 0,
      maxRangeInDaysForChecks: planMaxRangeInDaysForChecks ?? 0,
    };
  }
  const validationFromMoment = toAbsoluteMoment(
    validationTimeFrom,
    offsetFromTime,
  );
  const validationToMoment = toAbsoluteMoment(validationTimeTo, offsetFromTime);
  let result;
  // something is wrong in the upstream check configuration (i.e. incorrectly
  // formatted timestamp values)
  if (!validationFromMoment.isValid() || !validationToMoment.isValid()) {
    result = {
      queryableTimeRange: null,
      overlap: TimeRangeOverlap.None,
      errorMessage: `Metrics unavailable: time range configuration is invalid`,
      maxRangeInDays: planMaxRangeInDays ?? 0,
      maxRangeInDaysForChecks: planMaxRangeInDaysForChecks ?? 0,
    };
  }
  // validation window completely outside retention window, return an error
  else if (validationToMoment.isBefore(retentionStartsAt)) {
    result = {
      queryableTimeRange: null,
      overlap: TimeRangeOverlap.None,
      errorMessage: `Metrics are outside your ${planMaxRangeInDays}-day retention period`,
      maxRangeInDays: planMaxRangeInDays ?? 0,
      maxRangeInDaysForChecks: planMaxRangeInDaysForChecks ?? 0,
    };
  }
  // validation window partially outside retention window, clamp to retention
  else if (validationFromMoment.isBefore(retentionStartsAt)) {
    result = {
      queryableTimeRange: {
        timeFrom: retentionStartsAt.toISOString(),
        timeTo: validationToMoment.toISOString(),
      },
      overlap: TimeRangeOverlap.Partial,
      maxRangeInDays: planMaxRangeInDays ?? 0,
      maxRangeInDaysForChecks: planMaxRangeInDaysForChecks ?? 0,
    };
  }
  // validation window within retention window
  else {
    result = {
      queryableTimeRange: {
        timeFrom: validationFromMoment.toISOString(),
        timeTo: validationToMoment.toISOString(),
      },
      overlap: TimeRangeOverlap.Full,
      maxRangeInDays: planMaxRangeInDays ?? 0,
      maxRangeInDaysForChecks: planMaxRangeInDaysForChecks ?? 0,
    };
  }
  if (result.queryableTimeRange) {
    // this is somewhat pedantic, but serves to enforce the ISO-ness
    // going forward.
    if (!isISOTimestamp(result.queryableTimeRange.timeFrom)) {
      assertUnreachable(result.queryableTimeRange.timeFrom);
    }
    if (!isISOTimestamp(result.queryableTimeRange.timeTo)) {
      assertUnreachable(result.queryableTimeRange.timeTo);
    }
  }
  return result;
}
