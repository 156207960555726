import { gql } from '@apollo/client';

import { GraphQLTypes } from 'src/lib/graphqlTypes';

export const LD_ME_QUERY = gql<
  GraphQLTypes.LD_ME_QUERY,
  GraphQLTypes.LD_ME_QUERYVariables
>`
  query LD_ME_QUERY {
    me {
      id
      ... on User {
        fullName
        email
      }
    }
  }
`;

export const LD_ACCOUNT_QUERY = gql<
  GraphQLTypes.LD_ACCOUNT_QUERY,
  GraphQLTypes.LD_ACCOUNT_QUERYVariables
>`
  query LD_ACCOUNT_QUERY($accountId: ID!) {
    account(id: $accountId) {
      internalID
      id
      createdAt
    }
  }
`;

export const CURRENT_USER_FRAGMENT = gql`
  fragment LaunchDarklyProvider_currentUser on Query {
    me {
      id
      ... on User {
        email
      }
    }
  }
`;
