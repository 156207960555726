import { IntrospectionQuery } from 'graphql';
import _ from 'lodash';

import {
  INTROSPECTION_QUERY_WITH_HEADERS,
  IncomingEmbedMessageEvent,
  SCHEMA_ERROR,
  SCHEMA_RESPONSE,
  sendPostMessageFromEmbedToParent,
} from '../../helpers/postMessageHelpers';

import {
  SchemaResponse,
  SubgraphSdlAndSchemaResponse,
  SubgraphSdlResponse,
} from './useSchema/useSchemaFromEndpointIntrospection/schemaLoader';

export const performPostMessageIntrospectionRequest = async ({
  processedHeaders,
  sandboxEndpointUrl,
  introspectionQueryString,
  includeCookies,
}: {
  processedHeaders: Record<string, string>;
  sandboxEndpointUrl: string;
  introspectionQueryString: string;
  includeCookies: boolean;
}): Promise<SchemaResponse> => {
  const stableOperationId = _.uniqueId();

  sendPostMessageFromEmbedToParent({
    name: INTROSPECTION_QUERY_WITH_HEADERS,
    introspectionRequestBody: JSON.stringify({
      query: introspectionQueryString,
      operationName: 'IntrospectionQuery',
    }),
    includeCookies,
    introspectionRequestHeaders: processedHeaders,
    sandboxEndpointUrl,
    operationId: stableOperationId,
  });

  // Listen to parent for response and dispose event listener when message received
  const response: {
    data?: IntrospectionQuery;
    error?: string;
    errors?: { message: string }[];
  } = await new Promise((resolve) => {
    const handlePostMessageResponse = (event: IncomingEmbedMessageEvent) => {
      if (
        event.data.name === SCHEMA_RESPONSE &&
        (!event.data.operationId ||
          event.data.operationId === stableOperationId)
      ) {
        window.removeEventListener('message', handlePostMessageResponse);
        if (typeof event.data.schema !== 'string') {
          resolve({
            data: event.data.schema,
          });
        } else {
          resolve({
            errors: [
              {
                message:
                  'Something went wrong, please contact support if you see this. The shape of the schema introspection response from the embed was unexpected.',
              },
            ],
          });
        }
      } else if (
        event.data.name === SCHEMA_ERROR &&
        (!event.data.operationId ||
          event.data.operationId === stableOperationId)
      ) {
        window.removeEventListener('message', handlePostMessageResponse);
        resolve({
          errors: event.data.errors,
        });
      }
    };
    window.addEventListener('message', handlePostMessageResponse);
  });

  return response;
};

export const performPostMessageSubgraphAndSchemaIntrospectionRequest = async ({
  processedHeaders,
  sandboxEndpointUrl,
  introspectionQueryStringWithSubgraphQuery,
  includeCookies,
}: {
  processedHeaders: Record<string, string>;
  sandboxEndpointUrl: string;
  introspectionQueryStringWithSubgraphQuery: string;
  includeCookies: boolean;
}): Promise<SubgraphSdlAndSchemaResponse> => {
  const stableOperationId = _.uniqueId();

  sendPostMessageFromEmbedToParent({
    name: INTROSPECTION_QUERY_WITH_HEADERS,
    introspectionRequestBody: JSON.stringify({
      query: introspectionQueryStringWithSubgraphQuery,
      operationName: 'IntrospectionQuery',
    }),
    includeCookies,
    introspectionRequestHeaders: processedHeaders,
    sandboxEndpointUrl,
    operationId: stableOperationId,
  });

  // Listen to parent for response and dispose event listener when message received
  const response: SubgraphSdlAndSchemaResponse = await new Promise(
    (resolve) => {
      const handlePostMessageResponse = (event: IncomingEmbedMessageEvent) => {
        if (
          event.data.name === SCHEMA_RESPONSE &&
          (!event.data.operationId ||
            event.data.operationId === stableOperationId)
        ) {
          window.removeEventListener('message', handlePostMessageResponse);
          if (
            event.data.schema &&
            // we send the response.data via the schema field,
            // but in this case it has both the __schema and the _service { sdl } fields
            typeof event.data.schema !== 'string' &&
            '_service' in event.data.schema
          ) {
            const introspectionResponse: {
              data?: IntrospectionQuery;
              errors?: { message: string }[];
            } = {
              data: {
                __schema: event.data.schema.__schema,
              },
            };
            const subgraphSdlIntrospectionResponse: SubgraphSdlResponse = {
              data: {
                _service: event.data.schema._service,
              },
            };
            resolve([introspectionResponse, subgraphSdlIntrospectionResponse]);
          } else {
            resolve([
              {
                errors: [
                  {
                    message:
                      'Something went wrong, please contact support if you see this. The shape of the subgraph sdl and schema introspection response from the embed was unexpected.',
                  },
                ],
              },
              {
                errors: [
                  {
                    message:
                      'Something went wrong, please contact support if you see this. The shape of the subgraph sdl and schema introspection response from the embed was unexpected.',
                  },
                ],
              },
            ]);
          }
        } else if (
          event.data.name === SCHEMA_ERROR &&
          (!event.data.operationId ||
            event.data.operationId === stableOperationId)
        ) {
          window.removeEventListener('message', handlePostMessageResponse);
          resolve([
            {
              errors: event.data.errors ?? [
                ...(event.data.error ? [{ message: event.data.error }] : []),
              ],
            },
            {
              errors: event.data.errors ?? [
                ...(event.data.error ? [{ message: event.data.error }] : []),
              ],
            },
          ]);
        }
      };
      window.addEventListener('message', handlePostMessageResponse);
    },
  );

  return response;
};
