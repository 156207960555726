/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
interface Props extends Omit<React.SVGProps<SVGSVGElement>, "css"> {
  weight?: "thin" | "normal";
}
export const IconSuccessSolid = React.forwardRef<SVGSVGElement, Props>(
  (
    { fill = "none", stroke = "currentColor", weight = "normal", ...props },
    ref,
  ) => (
    <svg
      viewBox="0 0 24 24"
      ref={ref}
      css={css`
        * {
          vector-effect: non-scaling-stroke;
        }
        overflow: visible;
        height: 24px;
      `}
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm6.9 8.2l-6.8 9.3c-.3.4-1 .5-1.4.2l-4.9-3.9c-.4-.3-.5-1-.2-1.4s1-.5 1.4-.2l4.1 3.3L17.3 7c.3-.5.9-.6 1.4-.3.5.3.6 1 .2 1.5.1 0 0 0 0 0z"
      />
      <path
        fill="transparent"
        d="M18.9 8.2l-6.8 9.3c-.3.4-1 .5-1.4.2l-4.9-3.9c-.4-.3-.5-1-.2-1.4s1-.5 1.4-.2l4.1 3.3L17.3 7c.3-.5.9-.6 1.4-.3.5.3.6 1 .2 1.5.1 0 0 0 0 0z"
        className="inner"
      />
    </svg>
  ),
);
