import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const invalidStyles = defineStyle({
  border: '2px solid',
  borderColor: customColors.border.error,
  boxShadow: 'unset',
});

const focusStyles = defineStyle({
  border: '2px solid',
  borderColor: customColors.border.focused,
  boxShadow: 'unset',
});

const disabledStyles = defineStyle({
  opacity: 1,
  bg: customColors.bg.disabled,
  borderColor: customColors.border.disabled,
  color: customColors.text.disabled,
  _placeholder: {
    color: customColors.text.disabled,
  },
});

export const field = defineStyle({
  // Add custom base styles here
  border: '1px solid',
  borderColor: customColors.border.primary,
  color: customColors.text.primary,
  isolation: 'isolate',
  bg: customColors.bg.input,
  _placeholder: {
    color: customColors.text.placeholder,
  },
  _disabled: {
    ...disabledStyles,
    _hover: disabledStyles,
  },
  _focus: {
    ...focusStyles,
    _hover: focusStyles,
  },
  _hover: {
    border: '1px solid',
    borderColor: customColors.border.hover,
    _focus: focusStyles,
    // cancels hover styles when hovering over invalid inputs
    _invalid: invalidStyles,
  },
  _invalid: {
    ...invalidStyles,
    _focus: invalidStyles,
  },
});

const element = defineStyle({
  color: customColors.icon.primary,
  _disabled: {
    color: customColors.icon.disabled,
  },
});

const addon = defineStyle({
  paddingInlineEnd: 0,
  paddingInlineStart: 0,
  bg: customColors.bg.neutral,
  color: customColors.text.neutral,
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
  fontWeight: 'semibold',
  fontSize: '0.75rem',
  lineHeight: '1rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const baseStyle = definePartsStyle({
  field,
  element,
  addon,
});

const xs = defineStyle({
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
});

const sm = defineStyle({
  fontSize: '1rem',
  lineHeight: '1.5rem',
});

const md = defineStyle({
  fontSize: '1rem',
  lineHeight: '1.5rem',
});

const lg = defineStyle({
  fontSize: '1rem',
  lineHeight: '1.5rem',
});

export const sizes = {
  xs: definePartsStyle({
    field: {
      ...xs,
      borderRadius: '0.25rem',
    },
    addon: xs,
    element: xs,
  }),
  sm: definePartsStyle({
    field: {
      ...sm,
      borderRadius: '0.25rem',
    },
    addon: sm,
    element: sm,
  }),
  md: definePartsStyle({
    field: {
      ...md,
      borderRadius: '0.5rem',
    },
    addon: {
      ...md,
      borderRadius: '0.5rem',
    },
    element: md,
  }),
  lg: definePartsStyle({
    field: {
      ...lg,
      borderRadius: '0.5rem',
    },
    addon: {
      ...lg,
      borderRadius: '0.5rem',
    },
    element: lg,
  }),
};

const outline = definePartsStyle({
  field,
  element,
  addon,
});

const hiddenField = defineStyle({
  background: 'transparent',
  color: 'inherit',
  borderColor: 'transparent',
  border: '1px solid transparent',
});

const hidden = definePartsStyle({
  field: {
    ...field,
    ...hiddenField,
    _disabled: {
      ...disabledStyles,
      _hover: disabledStyles,
    },
    _focus: {
      ...hiddenField,
      _hover: hiddenField,
    },
    _hover: {
      ...hiddenField,
      _focus: hiddenField,
      // cancels hover styles when hovering over invalid inputs
      _invalid: hiddenField,
    },
    _invalid: {
      ...hiddenField,
      _focus: hiddenField,
    },
  },
  element: {
    ...element,
    color: customColors.icon.primary,
  },
  addon: {
    ...addon,
    bg: 'transparent',
    color: customColors.text.primary,
    borderColor: 'transparent',
  },
});

export const InputStyles = defineMultiStyleConfig({
  baseStyle,
  variants: {
    hidden,
    outline,
  },
  sizes,
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
});
