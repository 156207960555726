import { useEffect, useRef, useState } from 'react';

// copied from https://usehooks.com/useDebounce/
export function useDebouncedValue<T>(value: T, delay: number, leading = false) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  const hasTimeoutRef = useRef(false);

  useEffect(
    () => {
      if (!hasTimeoutRef.current && leading) {
        setDebouncedValue(value);
      }
      hasTimeoutRef.current = true;
      // Update debounced value after delay
      let id: NodeJS.Timeout | null = setTimeout(() => {
        setDebouncedValue(value);
        id = null;
        hasTimeoutRef.current = false;
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        if (id) {
          clearTimeout(id);
          id = null;
        }
      };
    },
    [value, delay, leading], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}
