/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
export const Telescope = React.forwardRef<
  SVGSVGElement,
  Omit<React.SVGProps<SVGSVGElement>, "css">
>((props, ref) => (
  <svg
    viewBox="0 0 182 92"
    ref={ref}
    css={{
      color: "#F4F6F8",
      height: "92px",
      width: "182px",
    }}
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)">
        <path
          fill="currentColor"
          stroke="currentColor"
          d="M90 0c49.706 0 90 40.294 90 90H0C0 40.294 40.294 0 90 0z"
        />
        <path
          fill="#CAD0D8"
          d="M53 31c.581 0 1.053.509 1.053 1.137l-.001 1.81h1.812c.627 0 1.136.471 1.136 1.053 0 .581-.509 1.053-1.136 1.053l-1.812-.001v1.812c0 .627-.47 1.136-1.052 1.136-.581 0-1.053-.509-1.053-1.136v-1.812h-1.81c-.628 0-1.137-.47-1.137-1.052 0-.582.509-1.053 1.136-1.053h1.811v-1.81c0-.628.472-1.137 1.053-1.137zm75-3c.581 0 1.053.509 1.053 1.137l-.001 1.81h1.812c.627 0 1.136.471 1.136 1.053 0 .581-.509 1.053-1.136 1.053l-1.812-.001v1.812c0 .627-.47 1.136-1.052 1.136-.581 0-1.053-.509-1.053-1.136v-1.812h-1.81c-.628 0-1.137-.47-1.137-1.052 0-.582.509-1.053 1.136-1.053h1.811v-1.81c0-.628.472-1.137 1.053-1.137zm37 50c.581 0 1.053.509 1.053 1.137l-.001 1.81h1.812c.627 0 1.136.471 1.136 1.053 0 .581-.509 1.053-1.136 1.053l-1.812-.001v1.812c0 .627-.47 1.136-1.052 1.136-.581 0-1.053-.509-1.053-1.136v-1.812h-1.81c-.628 0-1.137-.47-1.137-1.052 0-.582.509-1.053 1.136-1.053h1.811v-1.81c0-.628.472-1.137 1.053-1.137z"
        />
        <circle cx={32.5} cy={67.5} r={1.5} fill="#CAD0D8" />
        <circle cx={57.5} cy={55.5} r={1.5} fill="#CAD0D8" />
        <circle cx={32.5} cy={34.5} r={1.5} fill="#CAD0D8" />
        <circle cx={12.5} cy={81.5} r={1.5} fill="#CAD0D8" />
        <circle cx={83.5} cy={16.5} r={1.5} fill="#CAD0D8" />
        <circle cx={76.5} cy={40.5} r={1.5} fill="#CAD0D8" />
        <circle cx={123.5} cy={16.5} r={1.5} fill="#CAD0D8" />
        <circle cx={149.5} cy={47.5} r={1.5} fill="#CAD0D8" />
        <circle cx={160.5} cy={62.5} r={1.5} fill="#CAD0D8" />
        <circle cx={138.5} cy={65.5} r={1.5} fill="#CAD0D8" />
        <g transform="translate(100 24)">
          <circle cx={2.5} cy={2.5} r={2.5} fill="#CAD0D8" />
          <circle cx={2.5} cy={2.5} r={1} fill="currentColor" />
        </g>
        <g transform="translate(67 8)">
          <circle cx={2.5} cy={2.5} r={2.5} fill="#CAD0D8" />
          <circle cx={2.5} cy={2.5} r={1} fill="currentColor" />
        </g>
        <g transform="translate(120 71)">
          <circle cx={2.5} cy={2.5} r={2.5} fill="#CAD0D8" />
          <circle cx={2.5} cy={2.5} r={1} fill="currentColor" />
        </g>
        <g transform="translate(47 75)">
          <circle cx={2.5} cy={2.5} r={2.5} fill="#CAD0D8" />
          <circle cx={2.5} cy={2.5} r={1} fill="currentColor" />
        </g>
        <g transform="translate(21 55)">
          <circle cx={2.5} cy={2.5} r={2.5} fill="#CAD0D8" />
          <circle cx={2.5} cy={2.5} r={1} fill="currentColor" />
        </g>
      </g>
      <path
        stroke="#464646"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M86.55 68.736l-8.34 4.19a3.946 3.946 0 0 1-5.298-1.752l-.002-.004a3.946 3.946 0 0 1 1.752-5.298l.004-.002 12.348-6.2 2.366 4.71m-1.48-2.952a3.944 3.944 0 0 1 1.752-5.298s.002 0 .002-.002l12.35-6.2 5.318 10.584-10.224 5.136M68.5 73.388l4.41-2.218"
      />
      <path
        fill="#F18686"
        d="M104.644 46.384l1.764-.886 7.094 14.118-1.764.886a3.946 3.946 0 0 1-5.3-1.754l-3.548-7.06a3.95 3.95 0 0 1 1.754-5.304z"
      />
      <path
        fill="#74B0F4"
        d="M97.86 66.8a6.001 6.001 0 1 1-8.062-2.66 5.998 5.998 0 0 1 8.058 2.658c.004 0 .004.002.004.002z"
      />
      <path
        stroke="#464646"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M104.644 46.384l1.764-.886 7.094 14.118-1.764.886a3.946 3.946 0 0 1-5.3-1.754l-3.548-7.06a3.95 3.95 0 0 1 1.754-5.304zM97.86 66.8a6.001 6.001 0 1 1-8.062-2.66 5.998 5.998 0 0 1 8.058 2.658c.004 0 .004.002.004.002zm-5.36 8.7v15m-13.5 0l9.182-16.834M106 90.5l-9.182-16.834"
      />
    </g>
  </svg>
));
