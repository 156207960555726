import { breadcrumbAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const { border, icon, text } = customColors;
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(breadcrumbAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    // The class [&_ol]:pl-0 is necessary to remove the reset css
    // `padding-inline-start` that pushes all lists in by 40px.
    '& ol': { pl: 0 },
  },
  separator: {
    color: text.secondary,
    mx: 2,
    '> svg': {
      color: icon.secondary,
      display: 'block',
    },
  },
  item: {
    color: text.primary,
    fontWeight: 'normal',
    fontSize: 'base',
    lineHeight: 'base',
  },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 2,
    '&[aria-current="page"]': {
      fontWeight: 'semibold',
    },
    _active: {
      fontWeight: 'semibold',
    },
    _focusVisible: {
      outlineColor: border.focused,
      boxShadow: 'unset',
      rounded: 'sm',
    },
    '&:not([aria-current="page"])': {
      _hover: {
        color: text.secondary,
      },
      _disabled: {
        color: text.disabled,
        cursor: 'not-allowed',
        textDecoration: 'none',
      },
    },
  },
});

export const BreadcrumbStyle = defineMultiStyleConfig({
  baseStyle,
});
