import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';

import { ErrorBoundary } from 'src/components/common/errorBoundary/ErrorBoundary';
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage';
import { useCurrentPlan } from 'src/hooks/currentPlanV2Migration';
import { useCheckPlanTypes } from 'src/hooks/useCheckPlanTypes';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import Config from 'src/lib/config';
import {
  CreateSetupIntentMutation,
  CreateSetupIntentMutationVariables,
} from 'src/lib/graphqlTypes/types';

import { getInternalOrgIdQuery } from '../generalOrgQueries/GeneralOrgQueries';

const {
  settings: { stripePublicKey },
} = Config;

// After Stripe and Metronome accounts have been created and associated with the user,
// we can go ahead and create a Stripe "Setup Intent", which returns a client secret
// that the Stripe Element component needs to load.
const createSetupIntentMutation = gql<
  CreateSetupIntentMutation,
  CreateSetupIntentMutationVariables
>`
  mutation CreateSetupIntentMutation($internalAccountId: ID!) {
    billing {
      createSetupIntent(internalAccountId: $internalAccountId) {
        ... on SetupIntentSuccess {
          clientSecret
        }
        ... on PermissionError {
          message
        }
        ... on NotFoundError {
          message
        }
      }
    }
  }
`;

interface UpgradeLayoutProps {
  children: ReactJSXElement;
}

const appearance = {
  theme: 'stripe' as 'none' | 'stripe' | 'night' | 'flat' | undefined,
};

export const StripeProvider = ({ children }: UpgradeLayoutProps) => {
  const [clientSecret, setClientSecret] = useState<string>();
  const [error, setErrors] = useState<ApolloError>();

  const stripePromise = loadStripe(stripePublicKey);

  const accountId = useCurrentAccountId()[0] || '';
  const currentPlanResults = useCurrentPlan({ accountId, skip: !accountId });

  const { data } = useQuery(getInternalOrgIdQuery, {
    context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
    variables: { accountId },
    skip: !accountId,
  });

  const [createSetupIntent, { called }] = useMutation(
    createSetupIntentMutation,
  );

  const internalAccountId = data?.organization?.internalID;
  const { isUsageBasedPlan } = useCheckPlanTypes({
    tier: currentPlanResults?.currentPlan?.tier,
    kind: currentPlanResults?.currentPlan?.kind,
  });

  useEffect(() => {
    if (internalAccountId && isUsageBasedPlan && !called) {
      createSetupIntent({
        variables: { internalAccountId },
      })
        .then((result) => {
          const createSetupIntentResult =
            result.data?.billing?.createSetupIntent;
          if (createSetupIntentResult?.__typename === 'SetupIntentSuccess') {
            setClientSecret(createSetupIntentResult.clientSecret);
          } else {
            setErrors(
              new ApolloError({
                errorMessage:
                  createSetupIntentResult?.message ||
                  'Failed to create setup intent',
              }),
            );
          }
        })
        .catch((err) => {
          setErrors(err);
        });
    }
  }, [called, createSetupIntent, error, internalAccountId, isUsageBasedPlan]);

  return (
    <ErrorBoundary>
      {error ? (
        <ErrorMessage error={error} />
      ) : clientSecret ? (
        <Elements options={{ appearance, clientSecret }} stripe={stripePromise}>
          {children}
        </Elements>
      ) : null}
    </ErrorBoundary>
  );
};
