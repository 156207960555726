import { ExternalRouter } from 'src/lib/external-router';

import Config from '../config';

const { apolloGitHubUrl } = Config;

export const ApolloGitHubRouter = new ExternalRouter(apolloGitHubUrl, {
  ServerChangelog: 'apollo-server/blob/main/packages/server/CHANGELOG.md',
  RouterChangelog: 'router/blob/main/CHANGELOG.md',
  RouterReleases: 'router/releases',
});
