/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
interface Props extends Omit<React.SVGProps<SVGSVGElement>, "css"> {
  weight?: "thin" | "normal";
}
export const IconInfoSolid = React.forwardRef<SVGSVGElement, Props>(
  (
    { fill = "none", stroke = "currentColor", weight = "normal", ...props },
    ref,
  ) => (
    <svg
      viewBox="0 0 24 24"
      ref={ref}
      css={css`
        * {
          vector-effect: non-scaling-stroke;
        }
        overflow: visible;
        height: 24px;
      `}
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 0c6.6 0 12 5.4 12 12s-5.4 12-12 12S0 18.6 0 12 5.4 0 12 0zm.2 5c-.8 0-1.5.7-1.5 1.5S11.4 8 12.2 8s1.5-.7 1.5-1.5S13.1 5 12.2 5zm2.3 13.5c.6 0 1-.4 1-1s-.4-1-1-1h-.8c-.1 0-.2-.1-.2-.2v-4.8c0-1.1-.9-2-2-2h-1c-.6 0-1 .4-1 1s.4 1 1 1h.8c.1 0 .2.1.2.2v4.5c0 .1-.1.2-.2.2h-.8c-.6 0-1 .4-1 1s.4 1 1 1h4z"
      />
      <path
        fill="transparent"
        d="M12.2 5c-.8 0-1.5.7-1.5 1.5S11.4 8 12.2 8s1.5-.7 1.5-1.5S13.1 5 12.2 5zm2.3 13.5c.6 0 1-.4 1-1s-.4-1-1-1h-.8c-.1 0-.2-.1-.2-.2v-4.8c0-1.1-.9-2-2-2h-1c-.6 0-1 .4-1 1s.4 1 1 1h.8c.1 0 .2.1.2.2v4.5c0 .1-.1.2-.2.2h-.8c-.6 0-1 .4-1 1s.4 1 1 1h4z"
        className="inner"
      />
    </svg>
  ),
);
