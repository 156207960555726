import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom-v5-compat';

import { changelogRouteConfig } from 'src/app/graph/routes';
import { useShowToast } from 'src/components/toast/Toast';
import { absoluteURL } from 'src/lib/absoluteURL';
import { locationToPath } from 'src/lib/routeConfig/locationToPath';
import { AnyRouteConfig } from 'src/lib/routeConfig/RouteConfig';

export function LegacyChangelogRedirect({
  fromRouteConfig,
}: {
  fromRouteConfig: AnyRouteConfig;
}) {
  const location = useLocation();
  const { showToasts } = useShowToast();
  const redirectLocation = changelogRouteConfig.locationFrom({
    location,
    fromRouteConfig,
  });
  const redirectPath = locationToPath(redirectLocation);
  useEffect(() => {
    showToasts({
      stableId: redirectPath,
      heading: 'The Changelog has moved',
      level: 'info',
      message: `The Changelog has been moved from ${absoluteURL(
        locationToPath(location),
      )} to ${absoluteURL(
        redirectPath,
      )}, you have been redirected to the new location.`,
    });
  }, [location, redirectPath, showToasts]);
  return <Navigate replace={true} to={redirectLocation} />;
}
