export const isValidUrl = (
  value: string | undefined,
  options?: { protocols?: string[] },
) => {
  if (!value) return false;

  const { protocols: schemes = ['http', 'https'] } = options ?? {};
  try {
    const parsed = new URL(value);
    return schemes.includes(parsed.protocol.replace(/:$/, ''));
  } catch (err) {
    return false;
  }
};
