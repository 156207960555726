import {
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  ThemingProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export interface TextareaProps
  extends OmitChakraProps<
    ChakraTextareaProps,
    'disabled' | 'required' | 'isRequired' | keyof ThemingProps
  > {
  size?: 'md' | 'lg';
  variant?: 'hidden' | 'outline';
}

export const Textarea = forwardRef<TextareaProps, 'textarea'>(
  ({ className, children, ...props }, ref) => {
    return <ChakraTextarea {...props} ref={ref} />;
  },
);
