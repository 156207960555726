import { Link } from '@apollo/orbit';
import React, { ComponentProps } from 'react';

import * as orgRoutes from 'src/app/account/routes';
import { useGraphVisibility } from 'src/app/graph/hooks/useGraphVisibility';
import { Tooltip } from 'src/components/common/tooltip/Tooltip';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import Config from 'src/lib/config';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { DocsRouter } from 'src/lib/routers';

import { permissionWrapperFactory } from '../permissionWrapperFactory';

export const PermissionWarningContents = ({
  isGraphBlock,
  isProtectedVariantBlock,
  accountRole,
  graphRole,
}: {
  isGraphBlock?: boolean;
  isProtectedVariantBlock?: boolean;
  accountRole?: GraphQLTypes.UserPermission | undefined;
  graphRole?: GraphQLTypes.UserPermission | undefined;
}) => {
  const role = isGraphBlock ? graphRole : accountRole;
  const [currentAccountId] = useCurrentAccountId();

  const graphVisibilityType = useGraphVisibility();
  const isPublicRoute = graphVisibilityType === 'public';

  if (role) {
    const roleDisplay = Config.roleNameByRoleEnum[role] ?? role;
    const roleArticle = roleDisplay.match(/^[aeiou]/i) ? 'an' : 'a';
    return (
      <span>
        As {roleArticle}{' '}
        <Link to={DocsRouter.path('UserRoles')} isExternal>
          {roleDisplay}
        </Link>{' '}
        on this {isGraphBlock ? 'graph' : 'organization'}, you do not have
        permission to do this
        {isProtectedVariantBlock && ' on protected variants'}. Please contact
        one of your{' '}
        {currentAccountId ? (
          <Link to={orgRoutes.members.location({ orgId: currentAccountId })}>
            org {isGraphBlock ? 'or graph ' : ''}admins
          </Link>
        ) : (
          'org admins'
        )}
        .
      </span>
    );
  }
  return (
    <span>
      You do not have permission to do this. Please contact{' '}
      {isPublicRoute ? (
        <>the sender of the graph link.</>
      ) : (
        <>one of your org admins.</>
      )}
    </span>
  );
};

const PermissionWarningWrapper = ({
  placement,
  loading,
  ...props
}: {
  warning?: React.ReactNode;
  loading?: boolean;
  children?: ComponentProps<typeof Tooltip>['children'];
} & ComponentProps<typeof PermissionWarningContents> &
  Pick<ComponentProps<typeof Tooltip>, 'placement'>) => {
  return (
    <Tooltip
      label={
        props.warning === undefined ? (
          <PermissionWarningContents {...props} />
        ) : (
          props.warning
        )
      }
      {...(placement && { placement })}
      interactive
      disabled={loading}
    >
      {props.children ?? <span />}
    </Tooltip>
  );
};

export const PermissionWarning = permissionWrapperFactory(
  PermissionWarningWrapper,
);
