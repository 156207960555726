import { ExecutionResult, GraphQLError, IntrospectionQuery } from 'graphql';

import {
  MultipartSubscriptionResponse,
  ResponseData,
} from '../hooks/useExplorerState/performOperation/performOperation';
import {
  GraphQLSubscriptionLibraryChoice,
  SocketStatus,
} from '../hooks/useExplorerState/useSubscriptions';

import { JSONObject } from './types';

export const EXPLORER_LISTENING_FOR_SCHEMA = 'ExplorerListeningForSchema';
export const EXPLORER_LISTENING_FOR_STATE = 'ExplorerListeningForState';
export const EXPLORER_REQUEST = 'ExplorerRequest';
export const EXPLORER_RESPONSE = 'ExplorerResponse';
export const EXPLORER_SUBSCRIPTION_REQUEST = 'ExplorerSubscriptionRequest';
export const EXPLORER_SUBSCRIPTION_RESPONSE = 'ExplorerSubscriptionResponse';
export const EXPLORER_SUBSCRIPTION_TERMINATION =
  'ExplorerSubscriptionTermination';
export const EXPLORER_SET_SOCKET_ERROR = 'ExplorerSetSocketError';
export const EXPLORER_SET_SOCKET_STATUS = 'ExplorerSetSocketStatus';
export const SET_OPERATION = 'SetOperation';
export const SCHEMA_ERROR = 'SchemaError';
export const SCHEMA_RESPONSE = 'SchemaResponse';
export const EXPLORER_LISTENING_FOR_HANDSHAKE = 'ExplorerListeningForHandshake';
export const HANDSHAKE_RESPONSE = 'HandshakeResponse';
export const INTROSPECTION_QUERY_WITH_HEADERS = 'IntrospectionQueryWithHeaders';
export const STUDIO_USER_TOKEN_FOR_EMBED = 'StudioUserTokenForEmbed';
export const SET_PARTIAL_AUTHENTICATION_TOKEN_FOR_PARENT =
  'SetPartialAuthenticationTokenForParent';
export const TRIGGER_LOGOUT_IN_PARENT = 'TriggerLogoutInParent';
export const EXPLORER_LISTENING_FOR_PARTIAL_TOKEN =
  'ExplorerListeningForPartialToken';
export const PARTIAL_AUTHENTICATION_TOKEN_RESPONSE =
  'PartialAuthenticationTokenResponse';
export const PARENT_LOGOUT_SUCCESS = 'ParentLogoutSuccess';
export const DEV_TOOLS_AUTHENTICATE_WITH_GRAPHREF =
  'DevTools_AuthenticateWithGraphRef';
export const PREFLIGHT_OAUTH_PROVIDER_RESPONSE =
  'PreflightOAuthProviderResponse';
export const PREFLIGHT_OAUTH_RESPONSE = 'PreflightOAuthResponse';
export const PREFLIGHT_OAUTH_REQUEST = 'PreflightOAuthRequest';

export type ResponseError = {
  message: string;
  stack?: string;
};

export type ExplorerResponse = ResponseData & {
  incremental?: Array<
    ResponseData & { path: NonNullable<ResponseData['path']> }
  >;
  error?: ResponseError;
  status?: number;
  headers?: Record<string, string> | MinLengthArray<1, Record<string, string>>;
  hasNext?: boolean;
  size?: number;
};

export type ExplorerSubscriptionResponse =
  // websocket response
  | {
      data?: ExecutionResult<JSONObject>;
      error?: Error;
      errors?: GraphQLError[];
    }
  // http multipart response options below
  | MultipartSubscriptionResponse
  | {
      data: null;
      // this only exists in the PM MultipartSubscriptionResponse
      // type, not in the one in explorer, because we want to send
      // caught errors like CORS errors through to the embed
      error?: ResponseError;
      status?: number;
      headers?: Record<string, string> | Record<string, string>[];
    };

type SubgraphIntrospectionQuery = IntrospectionQuery & {
  _service: { sdl: string };
};

export type IncomingEmbedMessageEvent =
  | MessageEvent<{
      name: typeof PREFLIGHT_OAUTH_RESPONSE;
      queryParams: string;
    }>
  | MessageEvent<{
      name: typeof SCHEMA_ERROR;
      error?: string;
      errors?: Array<GraphQLError>;
      operationId?: string; // devtools & old versions of @apollo/sandbox won't send us an operationId
    }>
  | MessageEvent<{
      name: typeof SCHEMA_RESPONSE;
      schema:
        | IntrospectionQuery
        | SubgraphIntrospectionQuery
        | string
        | undefined;
      operationId?: string; // devtools & old versions of @apollo/sandbox won't send us an operationId
    }>
  | MessageEvent<{
      name: typeof HANDSHAKE_RESPONSE;
      inviteToken?: string;
      accountId?: string;
      parentHref?: string;
    }>
  | MessageEvent<{
      name: typeof EXPLORER_RESPONSE;
      operationId: string;
      response: ExplorerResponse;
    }>
  | MessageEvent<{
      name: typeof SET_OPERATION;
      operation: string;
      variables: string;
    }>
  | MessageEvent<{
      name: typeof EXPLORER_SUBSCRIPTION_RESPONSE;
      operationId: string;
      response: ExplorerSubscriptionResponse;
    }>
  | MessageEvent<{
      name: typeof EXPLORER_SET_SOCKET_ERROR;
      error: Error | undefined;
    }>
  | MessageEvent<{
      name: typeof EXPLORER_SET_SOCKET_STATUS;
      status: SocketStatus;
    }>
  | MessageEvent<{
      name: typeof STUDIO_USER_TOKEN_FOR_EMBED;
      id: string;
      token: string;
      graphRef: string;
    }>
  | MessageEvent<{
      name: typeof PARTIAL_AUTHENTICATION_TOKEN_RESPONSE;
      partialToken: string;
    }>
  | MessageEvent<{
      name: typeof DEV_TOOLS_AUTHENTICATE_WITH_GRAPHREF;
      graphRef: string;
    }>
  | MessageEvent<{ name: typeof PARENT_LOGOUT_SUCCESS }>;

export type ExplorerRequest = {
  name: typeof EXPLORER_REQUEST;
  operationId: string;
  operationName?: string;
  operation: string;
  variables?: JSONObject;
  headers: Record<string, string>;
  includeCookies: boolean;
  sandboxEndpointUrl?: string;
  // this is optional only for dev tools & manual schema embeds,
  // which don't use the graphRef url
  endpointUrl?: string;
  fileVariables?: {
    variableKey: string;
    files: { arrayBuffer: ArrayBuffer; fileName: string }[];
    isMultiFile: boolean;
  }[];
};

export type ExplorerSubscriptionRequest = {
  name: typeof EXPLORER_SUBSCRIPTION_REQUEST;
  operationId: string;
  operation: string;
  variables: JSONObject;
  operationName?: string;
  headers: Record<string, string>;
  subscriptionUrl?: string;
  protocol: GraphQLSubscriptionLibraryChoice;
  // only used for multipart protocol
  httpMultipartParams: {
    includeCookies: boolean | undefined;
  };
};

export type ExplorerSubscriptionTermination = {
  name: typeof EXPLORER_SUBSCRIPTION_TERMINATION;
  operationId: string;
};

export type ExplorerListening = {
  name:
    | typeof EXPLORER_LISTENING_FOR_SCHEMA
    | typeof EXPLORER_LISTENING_FOR_STATE
    | typeof EXPLORER_LISTENING_FOR_HANDSHAKE;
};

type ExplorerListeningForPartialToken = {
  name: typeof EXPLORER_LISTENING_FOR_PARTIAL_TOKEN;
  localStorageKey?: string;
};

export type ExplorerIntrospectionQueryWithHeaders = {
  name: typeof INTROSPECTION_QUERY_WITH_HEADERS;
  introspectionRequestBody: string;
  introspectionRequestHeaders: Record<string, string>;
  includeCookies: boolean;
  sandboxEndpointUrl?: string;
  operationId: string;
};

export type PreflightOAuthRequest = {
  name: typeof PREFLIGHT_OAUTH_REQUEST;
  oauthUrl: string;
};

type SetPartialAuthenticationTokenForParent = {
  name: typeof SET_PARTIAL_AUTHENTICATION_TOKEN_FOR_PARENT;
  localStorageKey: string;
  partialToken: string;
  graphRef: string;
};

type TriggerLogoutInParent = {
  name: typeof TRIGGER_LOGOUT_IN_PARENT;
  localStorageKey: string;
};

export type OutgoingEmbedMessage =
  | ExplorerRequest
  | ExplorerSubscriptionRequest
  | ExplorerSubscriptionTermination
  | ExplorerListening
  | ExplorerListeningForPartialToken
  | ExplorerIntrospectionQueryWithHeaders
  | SetPartialAuthenticationTokenForParent
  | TriggerLogoutInParent
  | PreflightOAuthRequest;

export const sendPostMessageFromEmbedToParent = (
  message: OutgoingEmbedMessage,
  transfer?: Transferable[],
) =>
  window.parent.postMessage(
    message,
    // NOTE: This needs to target all origins because we don't know who will be
    // embedding the page
    '*',
    transfer,
  );
