import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import React from 'react';

import { themeOverrides } from './themeOverrides';

export const theme = extendTheme(themeOverrides);

export const ThemeProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => (
  <ChakraProvider resetCSS={false} theme={theme}>
    {children}
  </ChakraProvider>
);
