import {
  UseToastOptions as ChakraUseToastOptions,
  ToastId,
  useToast as useChakraToast,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { ToastOptions } from '../components/Toast/shared';
import type { ToastStatus } from '../components/Toast/shared';
import { Toast } from '../components/Toast/Toast';

type ChakraToast = ReturnType<typeof useChakraToast>;

type PatchedToast<ToastFn> = ToastFn & {
  [K in Exclude<keyof ChakraToast, 'promise' | 'update'>]: ChakraToast[K];
} & {
  promise: <Result extends unknown, Err extends Error = Error>(
    promise: Promise<Result>,
    options: {
      success: MaybeFunction<ToastPromiseOption, [result: Result]>;
      error: MaybeFunction<ToastPromiseOption, [error: Err]>;
      loading: ToastPromiseOption;
    },
  ) => void;
  update: (id: ToastId, options: Omit<ToastOptions, 'id'>) => void;
};

type MaybeFunction<T, Args extends unknown[] = []> = T | ((...args: Args) => T);
type ToastPromiseOption = Omit<ToastOptions, 'status'>;

export const useToast = (defaultOptions?: Partial<ToastOptions>) => {
  const toast = useChakraToast({ duration: 8000, ...defaultOptions });

  return useMemo(() => {
    function overrideForbiddenOptions(
      opts: Partial<ToastOptions>,
    ): ChakraUseToastOptions {
      return {
        ...opts,
        position: 'bottom-right',
        containerStyle: undefined,
        icon: undefined,
        render: ({ status, ...props }) => (
          <Toast
            {...props}
            status={status as ToastStatus}
            render={opts.render ?? defaultOptions?.render}
          />
        ),
      };
    }

    const patchedToast = (options: ToastOptions) => {
      return toast(overrideForbiddenOptions(options));
    };

    const patchedToastWithHelpers = Object.assign(
      patchedToast,
      toast,
    ) as PatchedToast<typeof patchedToast> & {};

    patchedToastWithHelpers.promise = (promise, options) => {
      return toast.promise(promise, {
        success: (result) => {
          return typeof options.success === 'function'
            ? overrideForbiddenOptions(options.success(result))
            : overrideForbiddenOptions(options.success);
        },
        error: (error) => {
          return typeof options.error === 'function'
            ? overrideForbiddenOptions(options.error(error as never))
            : overrideForbiddenOptions(options.error);
        },
        loading: overrideForbiddenOptions(options.loading),
      });
    };

    patchedToastWithHelpers.update = (id, options) => {
      return toast.update(id, overrideForbiddenOptions(options));
    };

    return patchedToastWithHelpers;
  }, [toast, defaultOptions]);
};

export { ToastStatus };
