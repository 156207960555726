import IconInfoSolid from '@apollo/icons/default/IconInfoSolid.svg';
import IconSuccessSolid from '@apollo/icons/default/IconSuccessSolid.svg';
import IconWarningSolid from '@apollo/icons/default/IconWarningSolid.svg';
import {
  FormHelperText as ChakraFormHelperText,
  chakra,
  forwardRef,
  useFormControlStyles,
} from '@chakra-ui/react';
import React from 'react';

import { customColors } from '../../theme/customColors';
import { Spinner } from '../Spinner/Spinner';

export type HelperTextIconType = 'info' | 'success' | 'warning' | 'loading';

export interface FormHelperTextProps {
  /**
   * The type of icon to display before the form helper text.
   */
  iconType?: HelperTextIconType;
  /**
   * Contains the helper text.
   */
  children: React.ReactNode;
}

const ChakraIconInfoSolid = chakra(IconInfoSolid);
const ChakraIconSuccessSolid = chakra(IconSuccessSolid);
const ChakraIconWarningSolid = chakra(IconWarningSolid);

const HelperTextIcon = ({ iconType }: { iconType: HelperTextIconType }) => {
  const styles = useFormControlStyles();

  if (!iconType) return null;

  if (iconType === 'info')
    return (
      <ChakraIconInfoSolid
        data-testid="info-icon"
        __css={{
          ...styles.helperTextIcon,
          color: customColors.icon.info,
        }}
      />
    );

  if (iconType === 'success')
    return (
      <ChakraIconSuccessSolid
        data-testid="success-icon"
        __css={{
          ...styles.helperTextIcon,
          color: customColors.icon.success,
        }}
      />
    );

  if (iconType === 'warning')
    return (
      <ChakraIconWarningSolid
        data-testid="warning-icon"
        __css={{
          ...styles.helperTextIcon,
          color: customColors.icon.warning,
        }}
      />
    );

  if (iconType === 'loading')
    return (
      <chakra.div data-testid="loading-icon" __css={styles.helperTextIcon}>
        <Spinner size="xs" />
      </chakra.div>
    );
  return null;
};

/**
 * This component should be used when giving more info about a form's section
 * (see component's story for examples). If you need to display an error message,
 * please use the FormErrorMessage component.
 */
export const FormHelperText = forwardRef<FormHelperTextProps, 'div'>(
  ({ iconType, children }, ref) => {
    const styles = useFormControlStyles();

    return (
      <ChakraFormHelperText ref={ref}>
        {!!iconType && <HelperTextIcon iconType={iconType} />}
        <chakra.div __css={styles.helperTextContent}>{children}</chakra.div>
      </ChakraFormHelperText>
    );
  },
);
