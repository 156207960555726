import { Button, ButtonGroup, ModalFooter } from '@apollo/orbit';
import { useStripe } from '@stripe/react-stripe-js';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useWizard } from 'react-use-wizard';

import { Tooltip } from 'src/components/common/tooltip/Tooltip';
import { PricingLink } from 'src/components/pricingLink/PricingLink';

import { NewUpgradeConfigStep, NewUserUpgradeConfigSteps } from '../steps';
import { FormValues } from '../UpgradeModal';

export function UpgradeModalFooter({
  loading,
  validPaymentCard,
}: {
  loading: boolean;
  validPaymentCard: boolean;
}) {
  const {
    previousStep,
    nextStep,
    isFirstStep,
    isLastStep,
    isLoading,
    activeStep,
  } = useWizard();
  const stripe = useStripe();
  const currentStep = NewUserUpgradeConfigSteps[activeStep];

  const { errors, validateForm, handleSubmit, values, isSubmitting } =
    useFormikContext<FormValues>();

  useEffect(() => {
    validateForm();
  }, [activeStep, validateForm]);

  const orgDetailErrors: Array<string | undefined> = [
    errors?.companyName,
    errors?.companyDomain,
    errors?.billingContactEmail,
    errors?.acceptsTerms || !values?.acceptsTerms
      ? `You must accept Apollo's Terms of Service`
      : undefined,
  ];
  const paymentDetailsErrors: Array<string | undefined> = [
    errors?.firstName,
    errors?.lastName,
    !validPaymentCard ? 'You must enter a valid card' : undefined,
  ];

  const billingDetailsErrors: Array<string | undefined> = [
    errors?.streetAddress,
    errors?.aptSuiteBldg,
    errors?.city,
    errors?.stateProvReg,
    errors?.country,
    errors?.zip,
    errors?.vat,
  ];
  const hasOrgDetailsErrors: boolean = orgDetailErrors.some(Boolean);
  const hasPaymentDetailsErrors: boolean = paymentDetailsErrors.some(Boolean);
  const hasBillingDetailsErrors: boolean = billingDetailsErrors.some(Boolean);

  const getErrorMessages = (): Array<string | undefined> => {
    switch (currentStep) {
      case NewUpgradeConfigStep.OrgDetails:
        if (hasOrgDetailsErrors) return orgDetailErrors;
        break;
      case NewUpgradeConfigStep.Payment:
        if (hasPaymentDetailsErrors) return paymentDetailsErrors;
        break;
      case NewUpgradeConfigStep.Billing:
        if (hasBillingDetailsErrors) return billingDetailsErrors;
        break;
      default:
        return [];
    }
    return [];
  };
  const errorMessages = getErrorMessages().filter(Boolean);
  const hasErrors = errorMessages.length > 0;
  return (
    <ModalFooter>
      <PricingLink>View Pricing Details</PricingLink>
      <ButtonGroup>
        {!isFirstStep && (
          <Button variant="secondary" type="button" onClick={previousStep}>
            Back
          </Button>
        )}
        <Tooltip
          label={
            <div>
              <b>Please fix the following to continue:</b>{' '}
              <div className="flex flex-col">
                {errorMessages.map((val) => (
                  <div key={val}>{val}</div>
                ))}
              </div>
            </div>
          }
          disabled={!hasErrors}
        >
          <Button
            variant="primary"
            type={isLastStep ? 'submit' : 'button'}
            loadingText={isLastStep ? 'Confirm & Subscribe' : 'Continue'}
            isLoading={loading || isLoading || isSubmitting}
            isDisabled={!stripe || hasErrors}
            onClick={isLastStep ? () => handleSubmit() : nextStep}
          >
            {isLastStep ? 'Confirm & Subscribe' : 'Continue'}
          </Button>
        </Tooltip>
      </ButtonGroup>
    </ModalFooter>
  );
}
