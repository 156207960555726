import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const { bg, border, text } = customColors;
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const deselectedStyle = {
  background: 'transparent',
  borderColor: border.deselected,
};

const selectedStyle = {
  borderColor: bg.selected,
  background: bg.selected,
};

const baseStyle = definePartsStyle({
  control: {
    ...deselectedStyle,
    _before: {
      background: bg.white,
    },
    _checked: {
      ...selectedStyle,
      _hover: selectedStyle,
      _before: {
        background: bg.white,
      },
    },
    _disabled: {
      ...deselectedStyle,
      pointerEvents: 'none',
      _checked: selectedStyle,
    },
  },
  container: {
    gap: '0.5rem',
    _disabled: {
      opacity: 0.4,
    },
  },
  label: {
    color: text.primary,
    marginLeft: 0,
    _disabled: {
      opacity: null,
    },
  },
});

export const RadioStyles = defineMultiStyleConfig({
  baseStyle,
  sizes: {},
  variants: {},
});
