import { defineStyle } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const { border, bg } = customColors;

export const MenuListStyles = defineStyle({
  height: 'full',
  width: 'full',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  px: 2,
  py: 0,
  '@supports (scrollbar-width: thin)': {
    scrollbarColor: `${border.primary} ${bg.primary}`,
    scrollbarWidth: 'thin',
  },
  '&:only-child > .orbit-menu__group:first-child:has(> .orbit-menu__group_header)':
    {
      pt: 2,
    },
  '&:not(:only-child)': {
    pt: 2,
  },
});
