import { Alert } from '@apollo/orbit';
import React from 'react';

export class BuildGraphQLSchemaError extends Error {
  sdl: string;

  constructor(message: string, sdl: string) {
    // references https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#example
    super(message); // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    // References "ES6 Custom Error Class" in https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BuildGraphQLSchemaError);
    }

    this.name = 'BuildGraphQLSchemaError';

    // Custom debugging information
    this.sdl = sdl;
  }
}

export const BuildGraphQLSchemaErrorPrompt = ({
  error,
}: {
  error: BuildGraphQLSchemaError;
}) => (
  <div>
    <h2 className="mb-2">Error Building schema</h2>
    <Alert className="mb-4" status="error">
      {error.message}
    </Alert>
    <p>
      We encountered errors attempting to build a schema from the following SDL:
    </p>
    <code
      className="max-w-lg overflow-auto whitespace-pre text-left font-mono text-sm"
      style={{ maxHeight: 300 }}
    >
      {error.sdl}
    </code>
    <p>Please try addressing the errors and register a new schema</p>
  </div>
);
