import type History from 'history';
import { parse, stringify } from 'query-string';

/**
 * Take a `LocationDescriptorObject` and return a `string` to use for path
 * matching
 */
export function locationToPath(
  location: History.LocationDescriptorObject,
): string {
  // Use `stringify(parse())` to strip out any weirdness, like extra
  // leading`?`s
  return `${location.pathname}${
    location.search ? `?${stringify(parse(location.search))}` : ''
  }${location.hash ? `#${location.hash}` : ''}`;
}
