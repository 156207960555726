import { PopoverTrigger as ChakraPopoverTrigger } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

export interface PopoverTriggerProps {
  children: ReactNode;
}

export const PopoverTrigger = (props: PopoverTriggerProps) => {
  return <ChakraPopoverTrigger {...props} />;
};
