import { ComponentStyleConfig } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

/* We want the border to blend with the bg to avoid UI shifts */
const selectedBorderColor = customColors.bg.selected;

/* Used for checked and indeterminate states */
const controlStyle = {
  borderColor: selectedBorderColor,
  bg: customColors.bg.selected,
  color: customColors.icon.white,

  _hover: {
    borderColor: selectedBorderColor,
    bg: customColors.bg.selected,
  },
  _active: {
    borderColor: selectedBorderColor,
    bg: customColors.bg.selected,
  },
  _disabled: {
    borderColor: selectedBorderColor,
    bg: customColors.bg.selected,
    opacity: 0.4,
    color: customColors.text.white,
  },
  _focus: {
    borderColor: selectedBorderColor,
    bg: customColors.bg.selected,
  },

  _invalid: {
    borderColor: selectedBorderColor,
    bg: customColors.bg.selected,
  },
};

export const CheckboxStyles: ComponentStyleConfig = {
  parts: ['control', 'label', 'icon'],
  baseStyle: {
    control: {
      display: 'inline-flex',
      width: '1rem',
      height: '1rem',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '0.75rem',
      flexShrink: 0,
      borderRadius: '0.25rem',
      border: `2px solid`,
      bg: 'transparent',
      color: customColors.text.white,
      borderColor: customColors.border.deselected,
      _checked: controlStyle,
      _indeterminate: controlStyle,
      _disabled: {
        borderColor: customColors.border.disabled,
        bg: 'transparent',
        color: customColors.text.white,
      },
      _focus: {
        borderColor: selectedBorderColor,
      },
      _invalid: {
        borderColor: customColors.border.error,
      },
    },
    label: {
      color: customColors.text.primary,
      fontFamily: 'var(--font-body)',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.5rem',
      _disabled: {
        opacity: 1,
        color: customColors.text.disabled,
      },
    },
  },
};
