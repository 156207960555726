import {
  RadioGroup as ChakraRadioGroup,
  RadioGroupProps as ChakraRadioGroupProps,
  ThemingProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type RadioGroupProps = OmitChakraProps<
  ChakraRadioGroupProps,
  keyof ThemingProps
>;

export const RadioGroup = forwardRef<RadioGroupProps, 'div'>((props, ref) => {
  return <ChakraRadioGroup {...props} ref={ref} />;
});
