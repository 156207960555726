import { parse } from 'query-string';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom-v5-compat';

import {
  variantHomepageRouteConfig,
  variantRouteConfig,
} from 'src/app/graph/routes';
import Config from 'src/lib/config';
import { mergeQueryParams } from 'src/lib/routing';

export function VariantDefaultRedirectWithLegacySupport() {
  const location = useLocation();
  const parsedQueryParams = parse(location.search);
  const activeTab =
    parsedQueryParams[Config.queryParameters.ActiveReportPaneTab];
  // if we land on the default route with a known metrics tab param,
  // treat this as a metrics redirect but keep query params.
  if (
    activeTab &&
    !Array.isArray(activeTab) &&
    Config.metricsTabs.map((tab) => tab.value).includes(activeTab)
  ) {
    return (
      <Navigate
        replace={true}
        to={{
          ...location,
          pathname: `${location.pathname.replace(/\/$/, '')}/operations`,
        }}
      />
    );
  }
  // otherwise redirect to the default page
  const defaultLocation = variantHomepageRouteConfig.locationFrom({
    location,
    fromRouteConfig: variantRouteConfig,
  });
  return (
    <Navigate
      replace={true}
      to={{
        ...defaultLocation,
        search: mergeQueryParams(
          location.search,
          parse(defaultLocation.search),
        ),
      }}
    />
  );
}
