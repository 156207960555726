import { ChakraTheme } from '@chakra-ui/react';

import { AccordionStyles as Accordion } from '../components/Accordion/Accordion.style';
import { AdmonitionStyles as Admonition } from '../components/Admonition/Admonition.style';
import { AlertStyles as Alert } from '../components/Alert/Alert.style';
import { ApolloLogoStyles as ApolloLogo } from '../components/ApolloLogo/ApolloLogo.style';
import { AvatarStyle as Avatar } from '../components/Avatar/Avatar.style';
import { BadgeStyles as Badge } from '../components/Badge/Badge.style';
import { BreadcrumbStyle as Breadcrumb } from '../components/Breadcrumb/Breadcrumb.style';
import { ButtonStyles as Button } from '../components/Button/Button.style';
import { CardStyles as Card } from '../components/Card/Card.style';
import { CheckboxStyles as Checkbox } from '../components/Checkbox/Checkbox.style';
import { CodeStyles as Code } from '../components/Code/Code.style';
import { DividerStyles as Divider } from '../components/Divider/Divider.style';
import { DrawerStyles as Drawer } from '../components/Drawer/Drawer.style';
import { FormControlStyles as Form } from '../components/FormControl/FormControl.style';
import { FormErrorMessageStyles as FormErrorMessage } from '../components/FormErrorMessage/FormErrorMessage.style';
import { FormLabelStyles as FormLabel } from '../components/FormLabel/FormLabel.style';
import { GroupStyles as Group } from '../components/Group/Group.style';
import { HeadingStyles as Heading } from '../components/Heading/Heading.style';
import { InputStyles as Input } from '../components/Input/Input.style';
import { KbdStyles as Kbd } from '../components/Kbd/Kbd.style';
import { KbdComboStyles as KbdCombo } from '../components/KbdCombo/KbdCombo.style';
import { LinkStyles as Link } from '../components/Link/Link.style';
import { ListStyles as List } from '../components/List/List.style';
import { MenuStyles as Menu } from '../components/Menu/Menu.style';
import { ModalStyles as Modal } from '../components/Modal/Modal.style';
import { PaginationStyles as Pagination } from '../components/Pagination/Pagination.style';
import { PopoverStyle as Popover } from '../components/Popover/Popover.style';
import { ProgressStyles as Progress } from '../components/Progress/Progress.style';
import { RadioStyles as Radio } from '../components/Radio/Radio.style';
import { SelectStyles as Select } from '../components/Select/Select.style';
import { SliderStyles as Slider } from '../components/Slider/Slider.style';
import { SpinnerStyle as Spinner } from '../components/Spinner/Spinner.style';
import { StatusBadgeStyles as StatusBadge } from '../components/StatusBadge/StatusBadge.style';
import { StepperStyles as Stepper } from '../components/Stepper/Stepper.style';
import { SwitchStyles as Switch } from '../components/Switch/Switch.style';
import { TableStyles as Table } from '../components/Table/Table.style';
import { TabsStyles as Tabs } from '../components/Tabs/Tabs.style';
import { TagStyles as Tag } from '../components/Tag/Tag.style';
import { TextStyles as Text } from '../components/Text/Text.style';
import { TextareaStyles as Textarea } from '../components/Textarea/Textarea.style';
import { ToastStyles as Toast } from '../components/Toast/Toast.style';
import { TooltipStyle as Tooltip } from '../components/Tooltip/Tooltip.style';
import { WizardStyle as Wizard } from '../components/Wizard/Wizard.style';

import { customColors } from './customColors';

// Default Theme reference: https://v2.chakra-ui.com/docs/styled-system/theme
export const themeOverrides: Partial<ChakraTheme> = {
  components: {
    Accordion,
    Admonition,
    Alert,
    ApolloLogo,
    Avatar,
    Badge,
    Breadcrumb,
    Button,
    Card,
    Checkbox,
    Code,
    Divider,
    Drawer,
    // Form is the key for FormControl
    Form,
    FormError: FormErrorMessage,
    FormLabel,
    Group,
    Heading,
    Input,
    InputGroup: {
      baseStyle: {
        isolation: 'isolate',

        group: {
          isolation: 'isolate',
        },
      },
    },
    Kbd,
    KbdCombo,
    Link,
    List,
    Menu,
    Modal,
    Pagination,
    Popover,
    Progress,
    Radio,
    Select,
    Slider,
    Spinner,
    StatusBadge,
    Stepper,
    Switch,
    Table,
    Tabs,
    Tag,
    Text,
    Textarea,
    Toast,
    Tooltip,
    Wizard,
  },
  colors: customColors,
  fonts: {
    heading: 'Aeonik, sans-serif',
    body: 'Inter, sans-serif',
    mono: '"Fira Code", monospace',
  },
  fontWeights: {
    medium: 500,
  },
  fontSizes: {
    base: '1rem',
  },
  lineHeights: {
    '3xl': '2.25rem',
    '2xl': '2rem',
    xl: '1.75rem',
    lg: '1.75rem',
    base: '1.5rem',
    sm: '1.25rem',
    xs: '1rem',
  },
  shadows: {
    buttons:
      '0px 0px 0px 1px rgba(18, 21, 26, 0.20) inset, 0px -1px 0px 0px rgba(18, 21, 26, 0.05) inset, 0px 2px 2px 0px rgba(18, 21, 26, 0.12) inset, 0px 1px 4px 0px rgba(18, 21, 26, 0.08)',
    cards: '0px 1px 3px 0px rgba(0, 0, 0, 0.06)',
    modals:
      '0px 16px 32px 0px rgba(0, 0, 0, 0.12) 0px 0px 0px 0px rgba(18, 21, 26, 0.04)',
    popovers:
      '0px 4px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 0px 0px rgba(18, 21, 26, 0.04)',
    toasts:
      '0px 8px 16px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(18, 21, 26, 0.04)',
  },
  styles: {
    global: {
      body: {
        fontFamily: false,
        lineHeight: false,
        color: false,
        bg: false,
      },
      '*::placeholder': {
        color: false,
      },
      '*, *::before, &::after': {
        borderColor: false,
        wordWrap: false,
      },
    },
  },
};
