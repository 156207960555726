import React from 'react';

export const Form = React.forwardRef<
  HTMLFormElement,
  React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >
>(({ onSubmit, children, ...props }, ref) => {
  return (
    // Disabling this line since this is the ONLY place where we can use <form />
    // eslint-disable-next-line react/forbid-elements
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit?.(event);
      }}
      ref={ref}
      {...props}
    >
      {children}
    </form>
  );
});
