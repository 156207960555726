import { customColors } from '@apollo/orbit';
import { CSSProperties } from 'react';

const { text, bg, code } = customColors;

export const prismStudioThemeLight: {
  [key: string]: CSSProperties;
} = {
  'code[class*="language-"]': {
    color: text.primary,
    background: bg.primary,
    fontFamily: 'var(--font-mono)',
    fontSize: '1em',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    wordWrap: 'normal',
    lineHeight: '1.5',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
  },
  'pre[class*="language-"]': {
    color: text.primary,
    background: bg.primary,
    fontFamily: 'var(--font-mono)',
    fontSize: '1em',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    wordWrap: 'normal',
    lineHeight: '1.5',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
    padding: '1em',
    margin: '.5em 0',
    overflow: 'auto',
  },
  'pre[class*="language-"]::-moz-selection': {
    textShadow: 'none',
    background: bg.info,
  },
  'pre[class*="language-"] ::-moz-selection': {
    textShadow: 'none',
    background: bg.info,
  },
  'code[class*="language-"]::-moz-selection': {
    textShadow: 'none',
    background: bg.info,
  },
  'code[class*="language-"] ::-moz-selection': {
    textShadow: 'none',
    background: bg.info,
  },
  'pre[class*="language-"]::selection': {
    textShadow: 'none',
    background: bg.info,
  },
  'pre[class*="language-"] ::selection': {
    textShadow: 'none',
    background: bg.info,
  },
  'code[class*="language-"]::selection': {
    textShadow: 'none',
    background: bg.info,
  },
  'code[class*="language-"] ::selection': {
    textShadow: 'none',
    background: bg.info,
  },
  ':not(pre) > code[class*="language-"]': {
    background: bg.black,
    padding: '.1em',
    borderRadius: '.3em',
    whiteSpace: 'normal',
  },
  comment: {
    color: code.f,
  },
  prolog: {
    color: code.a,
  },
  doctype: {
    color: code.a,
  },
  cdata: {
    color: code.a,
  },
  punctuation: {
    color: code.a,
  },
  namespace: {
    opacity: '.7',
  },
  property: {
    color: code.d,
  },

  boolean: {
    color: code.c,
  },
  number: {
    color: code.c,
  },
  'attr-name': {
    color: code.d,
  },
  string: {
    color: code.g,
  },
  char: {
    color: code.b,
  },
  operator: {
    color: code.a,
  },
  keyword: {
    color: code.b,
  },
  function: {
    color: code.e,
  },
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
  variable: {
    color: code.g,
  },
};
