/* eslint-disable @typescript-eslint/naming-convention */
import {
  ApolloError,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';
import { Modal, ModalContent, ModalHeader, ModalOverlay } from '@apollo/orbit';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Wizard } from 'react-use-wizard';
import * as Yup from 'yup';

import { BillingContactEmailModalAccountFragment } from 'src/components/billingContactEmail/emailSection/billingContactEmailModal/BillingContactEmailModal';
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage';
import { WizardHeader } from 'src/components/wizard/WizardHeader';
import { useCurrentPlan } from 'src/hooks/currentPlanV2Migration';
import { useCheckPlanTypes } from 'src/hooks/useCheckPlanTypes';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { useUpdateBillingContactEmailMutation } from 'src/hooks/useUpdateBillingContactEmailMutation';
import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import { triggerFullPageConfetti } from 'src/lib/confetti';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

import { getInternalOrgIdQuery } from '../generalOrgQueries/GeneralOrgQueries';

import { Billing } from './components/Billing';
import { OrgDetails } from './components/OrgDetails';
import { Overview } from './components/Overview';
import { Payment } from './components/Payment';
import { Success } from './components/Success';
import { UpgradeModalFooter } from './components/UpgradeFooter';
import { NewUserUpgradeConfigSteps } from './steps';
import {
  getOrgDetailsQuery,
  startUsageBasedPlanMutation,
  trackTermsAcceptedMutation,
} from './StripeMutations';
import { Values } from './types';
import { validationSchema } from './validationSchema';

export type FormValues = Yup.InferType<typeof validationSchema>;

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const UpgradeModal = ({ onClose, isOpen }: Props) => {
  // third party hooks
  const client = useApolloClient();
  const stripe = useStripe();
  const elements = useElements();

  // custom hooks
  const accountId = useCurrentAccountId()[0] || '';
  const currentPlanResults = useCurrentPlan({ accountId, skip: !accountId });

  const { isUsageBasedPlan, isPaidPlan } = useCheckPlanTypes({
    kind: currentPlanResults.currentPlan?.kind,
    tier: currentPlanResults.currentPlan?.tier,
  });

  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(
    null,
  );
  //  queries
  const { data } = useQuery(getOrgDetailsQuery, {
    context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
    variables: { accountId },
  });
  const { data: orgData } = useQuery(getInternalOrgIdQuery, {
    context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
    variables: { accountId },
  });

  // mutations
  const [trackTermsAccepted, { loading: termsLoading, error: termsError }] =
    useMutation(trackTermsAcceptedMutation);

  const [startUsageBasedPlan, { error, loading }] = useMutation(
    startUsageBasedPlanMutation,
  );

  const [updateBillingContactEmail, { loading: updateEmailLoading }] =
    useUpdateBillingContactEmailMutation({ errorHandler: setErrorMessage });

  const organization = data?.organization || null;

  const existingBillingValues: GraphQLTypes.GetOrgDetailsQuery_organization_billingInfo | null =
    organization?.billingInfo || null;

  const initialValues = {
    companyName: organization?.name ?? '',
    companyDomain: organization?.companyUrl ?? '',
    billingContactEmail:
      data?.account?.billingContactEmail ||
      (data?.me && 'email' in data.me && data.me.email) ||
      '',
    firstName: existingBillingValues?.name?.split(' ')[0] ?? '',
    lastName: existingBillingValues?.name?.split(' ')[1] ?? '',
    streetAddress: existingBillingValues?.address?.address1 ?? '',
    aptSuiteBldg: existingBillingValues?.address?.address2 ?? '',
    city: existingBillingValues?.address?.city ?? '',
    stateProvReg: existingBillingValues?.address?.state ?? '',
    zip: existingBillingValues?.address?.zip ?? '',
    country: existingBillingValues?.address?.country ?? '',
    vat: existingBillingValues?.vatNumber ?? '',
    acceptsTerms: false,
  };

  const [validPaymentCard, setValidPaymentCard] = useState<boolean>(false);
  const handlePaymentFormSubmit = async (values: Values) => {
    if (!stripe || !elements || !values.acceptsTerms) {
      // Stripe.js has not yet loaded or have not accepted ToS.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    try {
      await stripe
        .confirmSetup({
          // `Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            payment_method_data: {
              billing_details: {
                name: `${values.firstName} ${values.lastName}`,
                address: {
                  line1: values.streetAddress,
                  line2: values.aptSuiteBldg,
                  city: values.city,
                  state: values.stateProvReg,
                  country: values.country,
                  postal_code: values.zip,
                },
              },
            },
          },
          redirect: 'if_required',
        })
        .then((result) => {
          /*
           Handling errors per Stripe's recommendation:
           "If the authorization fails, the Promise will resolve with an {error} object that describes\
           the failure. When the error type is card_error or validation_error, you can display the\
           error message in error.message directly to your user."
           (https://stripe.com/docs/js/setup_intents/confirm_setup)
          */
          if (result.error) {
            if (
              result.error.type === 'card_error' ||
              result.error.type === 'validation_error'
            ) {
              setErrorMessage(result.error.message);
            } else {
              setErrorMessage(
                'An error occurred while trying to process your payment information. Please try again.',
              );
            }
          } else {
            trackTermsAccepted({
              variables: {
                accountId,
                at: new Date().toISOString(),
              },
            })
              .then(() => {
                if (termsError) {
                  setErrorMessage(termsError.message);
                  return;
                }
                updateBillingContactEmail({
                  variables: {
                    accountId,
                    email: values.billingContactEmail,
                  },
                  update(store) {
                    store.writeFragment({
                      id: `Account:${accountId}`,
                      fragment: BillingContactEmailModalAccountFragment,
                      fragmentName: 'BillingContactEmailModalAccountFragment',
                      data: {
                        __typename: 'Account',
                        id: accountId,
                        billingContactEmail: values.billingContactEmail,
                      },
                    });
                  },
                }).then(() => {
                  if (
                    result?.setupIntent?.payment_method &&
                    orgData?.organization?.internalID
                  ) {
                    startUsageBasedPlan({
                      variables: {
                        paymentMethodId:
                          result.setupIntent.payment_method.toString(),
                        internalAccountId: orgData.organization.internalID,
                      },
                    })
                      .then((results) => {
                        if (results.errors) {
                          setErrorMessage(results?.errors[0]?.message);
                        } else if (results.data) {
                          triggerFullPageConfetti();

                          client.cache.evict({
                            id: client.cache.identify({
                              __typename: organization?.__typename,
                              id: organization?.id,
                            }),
                            fieldName: 'billingInfo',
                          });

                          client.cache.evict({
                            id: client.cache.identify({
                              __typename: organization?.__typename,
                              id: organization?.id,
                            }),
                            fieldName: 'currentPlan',
                          });

                          client.cache.evict({
                            id: client.cache.identify({
                              __typename: organization?.__typename,
                              id: organization?.id,
                            }),
                            fieldName: 'currentSubscription',
                          });

                          client.cache.gc();
                        }
                      })
                      .catch((e) => {
                        setErrorMessage(e?.message);
                      });
                  }
                });
              })
              .catch((e) => {
                setErrorMessage(e?.message);
              });
          }
        })
        .catch((e) => {
          setErrorMessage(e?.message);
        });
    } catch (err) {
      setErrorMessage(
        err instanceof Error ? err.message : 'An unexpected error has occurred',
      );
    }
  };

  const upgradeSuccess = isUsageBasedPlan && isPaidPlan;

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        {upgradeSuccess ? (
          <Success onClose={onClose} />
        ) : (
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values) => handlePaymentFormSubmit(values)}
          >
            {/* TODO(react-18): As a result of a typescript error, placeholder is required here. Will be cleaned up in DEBT-167 */}
            <Form noValidate placeholder={undefined}>
              {error || errorMessage ? (
                <ErrorMessage
                  className="mb-8"
                  error={
                    error ||
                    new ApolloError({
                      errorMessage:
                        errorMessage || 'An unknown error has occurred',
                    })
                  }
                />
              ) : null}
              <Wizard
                header={
                  <ModalHeader>
                    <WizardHeader
                      wizardSteps={NewUserUpgradeConfigSteps}
                      className="rounded-lg border border-silver bg-silver-lighter p-4"
                    />
                  </ModalHeader>
                }
                footer={
                  <UpgradeModalFooter
                    validPaymentCard={validPaymentCard}
                    loading={loading || termsLoading || updateEmailLoading}
                  />
                }
              >
                <Overview />
                <OrgDetails />
                <Billing />
                <Payment>
                  <PaymentElement
                    onChange={(event) => {
                      if (!event.complete) setValidPaymentCard(false);
                      else {
                        setValidPaymentCard(true);
                      }
                    }}
                    options={{
                      terms: { card: 'never' },
                      fields: {
                        billingDetails: {
                          address: 'never',
                        },
                      },
                    }}
                  />
                </Payment>
              </Wizard>
            </Form>
          </Formik>
        )}
      </ModalContent>
    </Modal>
  );
};
