import type { ReactiveVar } from '@apollo/client';
import React from 'react';

// This hook is not meant for general use and only exists to patch the old
// Apollo Client <= 3.7 useReactiveVar behavior for the useLocalStorage hook.
// Upgrading Apollo Client > 3.8.2 has introduced some issues with explorer that
// cause infinite loops due to the switch to useSyncExternalStore in Apollo
// Client's useReactiveVar (see https://github.com/mdg-private/studio-ui/pull/11140
// for more details on the bug and https://github.com/apollographql/apollo-client/pull/10072
// for more information on the Apollo Client change).
//
// This adds back the 3.7 implementation for useReactiveVar to try and alleviate
// the issue until we can implement a better long-term solution.
export function useLegacyReactiveVar<T>(rv: ReactiveVar<T>): T {
  const value = rv();

  // We don't actually care what useState thinks the value of the variable
  // is, so we take only the update function from the returned array.
  const setValue = React.useState(value)[1];

  // We subscribe to variable updates on initial mount and when the value has
  // changed. This avoids a subtle bug in React.StrictMode where multiple
  // listeners are added, leading to inconsistent updates.
  React.useEffect(() => {
    const probablySameValue = rv();
    if (value !== probablySameValue) {
      // If the value of rv has already changed, we don't need to listen for the
      // next change, because we can report this change immediately.
      setValue(probablySameValue);
    } else {
      return rv.onNextChange(setValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return value;
}
