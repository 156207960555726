export const isSupportedInVersion = (
  minSupportedVersion: string,
  currentVersion?: string,
) => {
  return (
    (currentVersion &&
      parseInt(currentVersion.replaceAll('.', ''), 10) >=
        parseInt(minSupportedVersion.replaceAll('.', ''), 10)) ||
    false
  );
};
