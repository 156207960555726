import { AlertCard } from '@apollo/space-kit/AlertCard';
import classnames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { uniqueId } from 'lodash';
import React, { useMemo } from 'react';

/**
 * Representation of a toast that will be displayed
 *
 * All properties are readonly to keep this structure immutable
 */
export interface ToastType {
  /**
   * Optional id for this toast, upon receiving a new toast with a matching id
   * to an existing entry, this will replace that entry
   */
  stableId?: string;
  /**
   * Indicates icon and color of the toast message
   *
   * Directly references Space Kit's `AlertCard` `type` prop
   */
  readonly level: React.ComponentProps<typeof AlertCard>['type'];

  /**
   * Message to be shown in the toast
   */
  readonly heading: React.ComponentProps<typeof AlertCard>['heading'];
  /**
   * A way to pass actions to the Toast
   */
  readonly actions?:
    | React.ComponentProps<typeof AlertCard>['actions']
    | ((
        closeToast: () => void,
      ) => NonNullable<React.ComponentProps<typeof AlertCard>['actions']>);

  /**
   * Message to be shown in the toast
   */
  readonly message: NonNullable<
    React.ComponentProps<typeof AlertCard>['children']
  >;

  /**
   * Whether or not to include the 'x' in this toast.
   * If you pass false, `onClose` does nothin
   */
  readonly dismissable?: boolean;

  /**
   * Callback when 'x' is pressed
   */
  readonly onDismiss?: () => void;

  /**
   * the theme for the toast : dark or light
   * https://space-kit.netlify.app/?path=/docs/components-alertcard--info-dark
   */
  readonly theme?: 'dark' | 'light';
}

/**
 * Representation of a toast to be displayed
 */
class Toast implements ToastType {
  /**
   * Internally generated unique `key`
   *
   * This is intended to differentiate two toasts with all the same props from
   * each other.
   */
  public readonly id: string;
  public readonly level;
  public readonly heading;
  public readonly actions;
  public readonly message;
  public readonly theme;
  public readonly dismissable;
  public readonly onDismiss;

  constructor({
    stableId,
    heading,
    actions,
    message,
    level,
    theme = 'light',
    dismissable,
    onDismiss,
  }: ToastType) {
    this.id = stableId ?? uniqueId();

    this.level = level;
    this.heading = heading;
    this.actions = actions;
    this.message = message;
    this.theme = theme;
    this.dismissable = dismissable;
    this.onDismiss = onDismiss;
  }
}

/**
 * Representation of a toast scope
 *
 * For the time being, there can only be one. We will eventually allow nesting.
 */
interface ToastScope {
  /**
   * Name of the toast scope
   */
  name: string;

  /**
   * Show one or more toasts
   *
   * If no toasts are passed then `toasts` will not be modified.
   */
  showToasts: (
    ...toasts: ReadonlyArray<ToastType>
  ) => undefined | ReadonlyArray<string>;

  /**
   * Hide one or more toasts
   *
   * Can be called repeatedly safely. If there will be no change to `toasts`
   * when called, `toasts` will remain `===` identical.
   */
  hideToasts: (...toastIds: ReadonlyArray<string>) => void;

  /**
   * Collection of all toasts to be displayed
   *
   * This is a readonly array to enforce immutability. When we want to add or
   * remove toasts we need to create a new array so React knows to render
   * changes.
   */
  toasts: ReadonlyArray<Toast>;
}

/**
 * Context for toast scope
 *
 * While the definition includes `undefined`, this will not actually be allowed
 * when consuming the context.
 *
 * @see https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */
const ToastScopeContext = React.createContext<ToastScope | undefined>(
  undefined,
);

/**
 * Read the current scope
 *
 * A value of `undefined` is allowed and will indicate there is no scope.
 */
function useToastScope(): ToastScope | undefined {
  return React.useContext(ToastScopeContext);
}

/**
 * Return a function used to create and display a toast
 *
 * Calling the returned function multiple times will create multiple toasts
 */
export function useShowToast(): Pick<ToastScope, 'showToasts' | 'hideToasts'> {
  const toastScope = React.useContext(ToastScopeContext);

  if (!toastScope) {
    throw new Error(
      '`useShowToast` must be used inside of a `ToastScopeProvider`',
    );
  }

  const showToasts = toastScope.showToasts;
  const hideToasts = toastScope.hideToasts;
  return React.useMemo(
    () => ({ showToasts, hideToasts }),
    [showToasts, hideToasts],
  );
}

interface ToastScopeProviderProps {
  name: string;
  children: React.ReactNode;
}

export const ToastScopeProvider = ({
  children,
  name,
}: ToastScopeProviderProps) => {
  const parentScope = React.useContext(ToastScopeContext);

  if (parentScope) {
    throw new Error(
      'Nested `ToastScopeProvider`s is not allowed as they cannot yet be merged safely',
    );
  }

  const [toasts, setToasts] = React.useState<ToastScope['toasts']>([]);

  /**
   * returns a function for hiding this toast
   */
  const showToasts = React.useCallback<ToastScope['showToasts']>(
    (...toastsToAdd) => {
      // Don't call setter if we're not trying to add any toasts to ensure
      // `toasts` will remain referentially equal to the previous value.
      if (toastsToAdd.length === 0) {
        return;
      }

      const newToasts = toastsToAdd.map((toastToAdd) => new Toast(toastToAdd));
      setToasts((previousToasts) => {
        return newToasts.reduce((allToasts, newToast) => {
          const existingToastMatchIndex = allToasts.findIndex(
            (existingToast) => existingToast.id === newToast.id,
          );
          if (existingToastMatchIndex === -1) {
            return allToasts.concat(newToast);
          }
          const newAllToasts = [...allToasts];
          newAllToasts.splice(existingToastMatchIndex, 1, newToast);
          return newAllToasts;
        }, previousToasts);
      });

      return newToasts.map((newToast) => newToast.id);
    },
    [],
  );

  const hideToasts = React.useCallback<ToastScope['hideToasts']>(
    (...toastIdsToRemove) => {
      setToasts((previousToasts) => {
        // If none of the toasts we're calling to remove are present in the
        // toast list, then return the original value so `toasts` won't change
        // and won't cause a re-render. We have to do this check inside of the
        // `setToasts` function because we need access to the current version of
        // `toasts` and the only safe way to do that is inside of the callback.
        // @see https://reactjs.org/docs/hooks-reference.html#functional-updates
        if (
          !toastIdsToRemove.some((toastIdToRemove) =>
            previousToasts.some(
              (previousToast) => previousToast.id === toastIdToRemove,
            ),
          )
        ) {
          return previousToasts;
        }

        return previousToasts.filter(
          (previousToast) => !toastIdsToRemove.includes(previousToast.id),
        );
      });
    },
    [],
  );

  return (
    <ToastScopeContext.Provider
      value={useMemo(
        () => ({
          name,
          toasts,
          showToasts,
          hideToasts,
        }),
        [name, toasts, showToasts, hideToasts],
      )}
    >
      {children}
    </ToastScopeContext.Provider>
  );
};

/**
 * Component rendering the toasts for the application
 */
export const Toasts = ({
  className,
  style,
}: Pick<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'className' | 'style'
>) => {
  const toastScope = useToastScope();
  if (!toastScope) {
    throw new Error(
      'You must render `Toasts` must be within a `ToastScopeProvider`',
    );
  }

  // The animations were derived from
  // https://codesandbox.io/s/framer-motion-notifications-5cvo9
  return (
    <div
      className={classnames(
        'pointer-events-none fixed bottom-0 right-0 z-[1700] flex h-full flex-col justify-end p-2',
        className,
      )}
      style={style}
    >
      <AnimatePresence initial={false}>
        {toastScope.toasts.map((toast) => {
          const closeToast = () => {
            if (!toastScope.toasts.includes(toast)) {
              return;
            }

            toastScope.hideToasts(toast.id);
          };

          return (
            <AlertCard
              dismissable={toast.dismissable}
              theme={toast.theme}
              as={
                <motion.section
                  layout
                  initial={{ opacity: 0, y: '20%' }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: {
                      ease: [0.25, 1, 0.5, 1],
                    },
                  }}
                  exit={{
                    opacity: 0,
                    y: '20%',
                    transition: {
                      ease: [0.5, 0, 0.75, 0],
                    },
                  }}
                  transition={{
                    duration: 0.2,

                    type: 'tween',
                  }}
                />
              }
              key={`toast-${toast.id}`}
              className="pointer-events-auto relative m-2 w-96 break-words"
              onClose={() => {
                closeToast();
                toast.onDismiss?.();
              }}
              heading={toast.heading}
              actions={
                typeof toast.actions === 'function'
                  ? toast.actions(closeToast)
                  : toast.actions
              }
              type={toast.level}
            >
              {toast.message}
            </AlertCard>
          );
        })}
      </AnimatePresence>
    </div>
  );
};
