import Config from './config';

/**
 * make window.grecaptcha available
 */
export const injectRecaptchaEnterpriseScript = () => {
  const scriptElement = document.createElement('script');

  scriptElement.setAttribute(
    'src',
    `https://www.google.com/recaptcha/api.js?render=${Config.settings.recaptchaSiteKey}`,
  );

  document.head.appendChild(scriptElement);
};

export const getInvisibleRecaptchaToken = ({
  action,
}: {
  action: string;
}): Promise<string> =>
  new Promise((resolve, reject) => {
    window.grecaptcha.ready(async () => {
      if (!Config.settings.recaptchaSiteKey) {
        reject(new Error('recaptchaSiteKey has no value'));
        return;
      }
      const recaptchaToken = await window.grecaptcha.execute(
        Config.settings.recaptchaSiteKey,
        {
          action,
        },
      );
      resolve(recaptchaToken);
    });
  });
