import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { GraphQLError } from 'graphql';
import React from 'react';

import { GlobalHeader } from 'src/app/appLayout/components/globalHeader/GlobalHeader';
import { ErrorPage } from 'src/components/errorPage/ErrorPage';
import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';

import { NotFound } from '../notFound/NotFound';

interface Props {
  children: React.ReactNode;
  renderError?: (error: Error) => React.ReactNode;
  extraErrorData?: () => Record<string, unknown>;
  sentryErrorLevel?: Sentry.SeverityLevel;
}

const getCaptureOptions = (extraErrorData: object) => {
  const stringifiedExtraErrorData = JSON.stringify(extraErrorData);

  // ensure that extra error data wouldn't cause payload to exceed size limit.
  // 50000 was arrived at via trial and error, by running the following snippet
  // and observing the network tab to see when requests start to fail.
  // the largest sent payload was found to have a length of 60950, so 50k
  // should give us about a 10k buffer
  // Sentry.captureException(new Error('test payload size'), {extra: {document: new Array(60000).fill('a').join('')}})
  // more info about payload size limits can be found:
  // https://docs.sentry.io/platforms/javascript/enriching-events/context/#size-limitations
  const limit = 50000;
  if (stringifiedExtraErrorData.length < limit) {
    return {
      extra: extraErrorData,
    };
  }

  return {
    extra: {
      truncated: true,
      truncatedExtraErrorData: stringifiedExtraErrorData.slice(0, limit),
    },
  };
};

export const ErrorBoundary = ({
  children,
  extraErrorData,
  renderError,
  sentryErrorLevel = 'fatal',
}: Props) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope, error) => {
      scope.setLevel(sentryErrorLevel);

      const url = window.FS?.getCurrentSessionURL?.(true);
      if (url)
        scope.setContext(
          // When renaming this field, also update the field name in the field name in Sentry's Safe Fields config
          // https://sentry.io/settings/apollograph/projects/engine-frontend/security-and-privacy/
          'fullstory',
          { url },
        );

      let extraData = extraErrorData?.();
      if (error instanceof ApolloError) {
        extraData = extraData ?? {};
        extraData.graphQLErrors = JSON.stringify(error.graphQLErrors);
        extraData.clientErrors = error.clientErrors;
        extraData.networkError = error.networkError;
        extraData.extraInfo = error.extraInfo;
      }
      if (extraData) scope.setExtras(getCaptureOptions(extraData));
    }}
    fallback={({ error, eventId }) => {
      if (ignorePermissionsErrors(error as GraphQLError)) {
        // when the error is due to insufficient permissions, render the global nav to allow sudo fireflower to be available
        return (
          <>
            <GlobalHeader leftNavContext="collapsed" />
            <NotFound />
          </>
        );
      }

      return renderError ? (
        <>{renderError(error)}</>
      ) : (
        <ErrorPage error={error} errorId={eventId} />
      );
    }}
  >
    {children}
  </Sentry.ErrorBoundary>
);
