/*
 * This file contains team contact information. It can be used either way making
 * new RouteConfigs with the ownership key, or by adding a `data-team="${teamName}"
 * data attribute to an element.
 */

function makeSlackChannelHref(channelId: string) {
  return `slack://channel?team=T024JTSAP&id=${channelId}` as const;
}

const nebula = {
  teamName: 'Nebula',
  contactTeam: makeSlackChannelHref('C01TUF3JJUQ'),
};

const pipelines = {
  teamName: 'Pipelines',
  contactTeam: makeSlackChannelHref('C05HU3Q6XJS'),
};

export const teamContactInfo = {
  Unowned: {
    teamName: 'Missing team!',
    contactTeam: makeSlackChannelHref('CR62ZA5E0'),
  },
  nebula,
  growth: {
    teamName: 'Growth',
    contactTeam: makeSlackChannelHref('C07KVCAANP7'),
  },
  astro: {
    teamName: 'Astro',
    contactTeam: makeSlackChannelHref('C01UYT99JVA'),
  },

  insights: {
    teamName: 'Insights',
    contactTeam: makeSlackChannelHref('C027T87B1JN'),
  },
  proposals: {
    ...nebula,
    contactTeam: makeSlackChannelHref(`C04QW230BQC`),
  },
  betelgeuse: {
    teamName: 'Betelgeuse',
    contactTeam: makeSlackChannelHref('C03EF3YS872'),
  },
  pipelines,
  launches: {
    ...pipelines,
    contactTeam: makeSlackChannelHref('C02AYD4P0K0'),
  },
  contracts: {
    ...pipelines,
    contactTeam: makeSlackChannelHref('C02HZCH18MU'),
  },
  technicalSupport: {
    teamName: 'Technical Support',
    contactTeam: makeSlackChannelHref('C9CP2S811'),
  },
  dxe: {
    teamName: 'Developer Education',
    contactTeam: makeSlackChannelHref('C01FWPAUSPK'),
  },
  tooling: {
    teamName: 'Graph Tooling',
    contactTeam: makeSlackChannelHref('C07S9EK5RQU'),
  },
} satisfies Record<
  string,
  {
    teamName?: string;
    contactTeam: `slack://${string}`;
  }
>;

export type Team = keyof typeof teamContactInfo;
export function isTeam(name: string | null | undefined): name is Team {
  return !!name && name in teamContactInfo;
}

declare module 'react' {
  // Typecheck for html elements
  interface DOMAttributes<T> {
    readonly ['data-team']?: Team;
  }
  // Typecheck values to components that will pass the value through
  interface Attributes {
    readonly ['data-team']?: Team;
  }
}
