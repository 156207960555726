import { useRouteParams } from 'src/hooks/useRouteParams';
import Config from 'src/lib/config';
import { BackendRouter } from 'src/lib/routers';

import { loginWithProvider } from '../onboarding/routes';

export function LoginWithProvider() {
  const { provider } = useRouteParams(loginWithProvider);
  BackendRouter.go('LoginWithProviderAndCallback', {
    provider,
    callbackUrl: Config.absoluteUrl,
  });
  return null;
}
