/* eslint-disable tailwindcss/no-custom-classname */

import {
  forwardRef,
  usePopoverContext,
  usePopoverStyles,
} from '@chakra-ui/react';
import { callAllHandlers } from '@chakra-ui/utils';
import classnames from 'classnames';
import React, { MouseEvent } from 'react';

import { CloseButton, CloseButtonProps } from '../CloseButton/CloseButton';

export type PopoverCloseButtonProps = Omit<CloseButtonProps, 'size'>;

export const PopoverCloseButton = forwardRef<PopoverCloseButtonProps, 'button'>(
  ({ onClick, className, ...props }: CloseButtonProps, ref) => {
    const { onClose } = usePopoverContext();
    const styles = usePopoverStyles();

    return (
      <CloseButton
        variant="hidden"
        {...props}
        className={classnames('chakra-popover__close-btn', className)}
        size="sm"
        ref={ref}
        onClick={callAllHandlers(
          onClick,
          (event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            onClose();
          },
        )}
        sx={styles.closeButton}
      />
    );
  },
);
