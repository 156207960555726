import {
  Radio as ChakraRadio,
  RadioProps as ChakraRadioProps,
  SystemStyleObject,
  ThemingProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export interface RadioProps
  extends OmitChakraProps<ChakraRadioProps, keyof ThemingProps> {
  // HTML inputs have a valid `size` prop, but we want to omit that here as
  // well. Unfortunately we have to declare it in some way or Chakra complains
  // that the `size` type is incompatible with its base radio component.
  size?: never;

  /**
   * Additional style config needed for component that wraps `Radio`. This
   * should only be used internally by other Orbit components. Please use
   * `className` for all styling.
   *
   * @internal
   */
  __orbitStyleConfig?: SystemStyleObject;
}

export const Radio = forwardRef<RadioProps, 'input'>(
  ({ __orbitStyleConfig, ...props }, ref) => {
    return <ChakraRadio {...props} ref={ref} sx={__orbitStyleConfig} />;
  },
);
