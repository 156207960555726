/* eslint-disable tailwindcss/no-custom-classname */

import { ToastId, chakra, useMultiStyleConfig } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import { CloseButton } from '../CloseButton/CloseButton';
import { ToastBody } from '../ToastBody/ToastBody';
import { ToastIcon } from '../ToastIcon/ToastIcon';
import { ToastTitle } from '../ToastTitle/ToastTitle';

import type { ToastOptions } from './shared';
import { ToastStylesProvider } from './Toast.context';

interface ToastProps extends ToastOptions {
  onClose: () => void;
  render?: (props: { id?: ToastId; onClose: () => void }) => ReactNode;
}

export const Toast = ({
  id,
  onClose,
  description,
  title,
  status,
  isClosable = true,
  render,
}: ToastProps) => {
  const styles = useMultiStyleConfig('Toast', { status });

  return (
    <ToastStylesProvider value={styles}>
      <chakra.div __css={styles.container} className="toast-container">
        {isClosable && (
          <CloseButton
            size="sm"
            className="toast-close-button"
            onClick={onClose}
            __orbitStyleConfig={styles.closeButton}
          />
        )}
        <ToastIcon status={status} />
        {render ? (
          render({ id, onClose })
        ) : (
          <>
            <ToastTitle>{title}</ToastTitle>
            {description && <ToastBody>{description}</ToastBody>}
          </>
        )}
      </chakra.div>
    </ToastStylesProvider>
  );
};
