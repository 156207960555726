import { mapValues } from 'lodash';
import pathToRegExp from 'path-to-regexp';

interface PathDescriptor {
  toPath: pathToRegExp.PathFunction;
}

/**
 * A utility that encapsulates a set of routable destinations.
 * Takes advantage of path-to-regexp to generate and cache
 * a path building function, which can then be used to build a URL.
 * @param basePath the baseline root URL to build from
 */
export const ExternalRouter = class<RouteName extends string> {
  // public for Cypress
  _routes: Record<RouteName, PathDescriptor>;
  private _basePath: string;
  constructor(basePath: string, routeDefinitions: Record<RouteName, string>) {
    this._routes = mapValues(routeDefinitions, (path: string) => ({
      toPath: pathToRegExp.compile(path),
    })) as Record<RouteName, PathDescriptor>;
    this._basePath = basePath.endsWith('/') ? basePath : `${basePath}/`;
  }
  /**
   * Redirects to the location specified by the built path.
   * @param name name of the route to use
   * @param params the parameters to apply to the path
   */
  go(name: RouteName, params?: object) {
    window.location.href = this.path(name, params);
  }
  /**
   * Returns the location specified as a result of path building.
   * @param name name of the route to use
   * @param params the parameters to apply to the path
   */
  path(name: RouteName, params?: object) {
    const entry = this._routes[name];
    if (!entry) {
      throw new Error(`Path not found for ${name}`);
    }
    return `${this._basePath}${entry.toPath(params)}`;
  }
};
