import {
  Modal as ChakraModal,
  ModalProps as ChakraModalProps,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export interface ModalProps
  extends OmitChakraProps<
    ChakraModalProps,
    'styleConfig' | 'variant' | 'styleConfig' | 'orientation'
  > {
  // Unfortunately this prop gets caught up in ChakraProps so we need to add it
  // back in manually
  scrollBehavior?: ChakraModalProps['scrollBehavior'];
}

export const Modal = ({ isCentered = true, ...props }: ModalProps) => (
  <ChakraModal isCentered={isCentered} {...props} />
);
