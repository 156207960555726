import { emotionCacheProviderFactory } from '@apollo/space-kit/emotionCacheProviderFactory';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import * as Sentry from '@sentry/react';
import React from 'react';

const studioStyleEl =
  document.querySelector<HTMLElement>('#studioEmotionStyleContainer') ??
  undefined;
const spaceKitStyleEl = document.querySelector<HTMLStyleElement>(
  '#spaceKitEmotionStyleContainer',
);
const hasMissingElement = !studioStyleEl || !spaceKitStyleEl;

if (hasMissingElement) {
  Sentry.captureException(
    new Error('html is missing emotion style containers'),
    {
      extra: {
        hasStudioContainer: !!studioStyleEl,
        hasSpaceKitContainer: !!spaceKitStyleEl,
      },
    },
  );
}
const studioEmotionCache = createCache({
  key: 'studio',
  container: studioStyleEl,
});
const EmotionCacheProvider = emotionCacheProviderFactory(spaceKitStyleEl);

export function EmotionProvider({
  children,
}: {
  children: React.ReactElement;
}) {
  return (
    <CacheProvider value={studioEmotionCache}>
      <EmotionCacheProvider>{children}</EmotionCacheProvider>
    </CacheProvider>
  );
}
