import { colors } from '@apollo/brand';
import IconSuccess from '@apollo/icons/default/IconSuccess.svg';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@apollo/orbit';
import { Location } from 'history';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ClickableText } from 'src/components/common/clickableText/ClickableText';
import { Loading } from 'src/components/common/loading/Loading';
import { useCurrentPlan } from 'src/hooks/currentPlanV2Migration';
import Config from 'src/lib/config';
import { PricingRouter } from 'src/lib/routers';
import { setOverlayVisibleLocation } from 'src/lib/routing';

import * as ModulePNG from './module.png';
import * as SatellitePNG from './satellite.png';

const {
  modals: { newOrganization },
} = Config;

export const upgradeRoute = (location: Location) =>
  setOverlayVisibleLocation({
    location: { ...location, pathname: '/' },
    overlayName: 'upgrade',
  });

export const UpgradeModal = ({
  isOpen,
  onClose,
  accountId,
}: {
  isOpen: boolean;
  onClose: () => void;
  accountId: string;
}) => {
  const location = useLocation();
  const { loading, isOnFreeTier } = useCurrentPlan({
    skip: !accountId,
    accountId,
  });

  const showServerless = isOnFreeTier;

  return (
    <Modal isOpen={isOpen} size="5xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="w-min">
        <ModalBody className="!p-0">
          {loading ? (
            <div className="p-12">
              <Loading />
            </div>
          ) : (
            <div className="flex">
              {/* Serverless */}
              {showServerless && (
                <div className="w-[500px] flex-1 border-r border-primary">
                  <section
                    className="relative h-48 bg-contain bg-right bg-no-repeat p-8 text-white"
                    style={{
                      backgroundImage: `url(${SatellitePNG.default}), linear-gradient(160deg, ${colors.tokens.brand.primary.base}, ${colors.tokens.brand.primary.dark})`,
                    }}
                  >
                    <h2 className="flex items-center">
                      <span className="mb-2 pr-4 text-2xl font-semibold">
                        Serverless
                      </span>
                    </h2>
                    <h4 className="absolute right-8 top-9 my-2 text-sm font-semibold">
                      For Developers
                    </h4>
                    <p>
                      Everything you need to get on the supergraph and go into
                      production in minutes.
                    </p>
                    <div className="mt-4">
                      <div className="inline-block rounded-full bg-neutral px-2 text-sm font-semibold text-neutral">
                        $0 up to 10M ops per month + $15 per 1M additional
                        operations
                      </div>
                    </div>
                  </section>
                  <section className="min-h-[4rem] px-8 py-6">
                    <h4 className="mb-2 text-sm font-semibold">
                      Unlock the full power of GraphQL:
                    </h4>
                    <ul className="flex flex-col gap-2">
                      {[
                        'Complete cloud-native supergraph platform',
                        'Modular graph development',
                        'Advanced query capabilities like @defer',
                        'Full set of CI/CD, observability, and collaboration tools',
                        'Embeddable Explorer to share your graph with anyone, even non-developers',
                        'Shared infrastructure with up to 100 TPS',
                      ].map((option) => {
                        return (
                          <li
                            className="flex items-center gap-2 align-middle text-base"
                            key={option}
                          >
                            <IconSuccess className="mb-auto mt-1 flex-none self-start text-icon-info" />
                            {option}
                          </li>
                        );
                      })}
                    </ul>
                    <Alert status="warning" className="mt-9">
                      This plan is currently unavailable for this organization,
                      but we are actively working to change that. To get started
                      with Serverless today, please{' '}
                      <ClickableText
                        className="font-semibold underline"
                        as={
                          <Link
                            to={setOverlayVisibleLocation({
                              location,
                              overlayName: newOrganization,
                            })}
                          />
                        }
                      >
                        create a new organization
                      </ClickableText>
                      .
                    </Alert>
                    <div className="mt-1 text-center">
                      <Button
                        variant="buttonLink"
                        size="sm"
                        as="a"
                        href={PricingRouter.path('Home')}
                        target="blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </Button>
                    </div>
                  </section>
                </div>
              )}

              {/* Enterprise */}
              <div className="w-[500px] flex-1">
                <section
                  className="relative h-48 bg-info bg-contain bg-right p-8 text-white"
                  style={{
                    backgroundImage: `url(${ModulePNG.default}), linear-gradient(160deg, ${colors.tokens.bg.beta.dark}, ${colors.tokens.bg.betaHover.dark})`,
                  }}
                >
                  <h2 className="flex items-center">
                    <span className="mb-2 pr-4 text-2xl font-semibold">
                      Enterprise
                    </span>
                  </h2>
                  <h4 className="absolute right-8 top-9 my-2 text-sm font-semibold">
                    For Graph Architects
                  </h4>
                  <p>
                    Advanced governance and hosting options for company-wide
                    adoption of the supergraph
                  </p>
                  <p className="mt-4 inline-block rounded-full bg-neutral px-2 text-sm font-semibold text-neutral">
                    Custom Monthly Queries
                  </p>
                </section>
                <section className="px-8 py-6">
                  <h4 className="mb-2 text-sm font-semibold">
                    Scale the supergraph org-wide:
                  </h4>
                  <ul className="flex flex-col gap-2">
                    {[
                      'Advanced hosting options',
                      'Granular access controls',
                      'User roles & permissions',
                      'Single sign-on (SSO)',
                      'Audit logs',
                      'Full Apollo SLA (GraphOS + OSS)',
                      'Dedicated success team',
                      'Support and solutions expertise from implementation to production',
                    ].map((option) => {
                      return (
                        <li
                          className="flex items-center gap-2 align-middle text-base"
                          key={option}
                        >
                          <IconSuccess className="mb-auto mt-1 flex-none self-start text-icon-beta" />
                          {option}
                        </li>
                      );
                    })}
                  </ul>
                  <div className="mt-6 text-center">
                    <Button
                      variant="primary"
                      as="a"
                      href="https://apollographql.com/contact-sales"
                      target="blank"
                      rel="noopener noreferrer"
                    >
                      Contact Us
                    </Button>
                  </div>
                  <div className="mt-1 text-center">
                    <Button
                      variant="buttonLink"
                      size="sm"
                      as="a"
                      href={PricingRouter.path('Home')}
                      target="blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </Button>
                  </div>
                </section>
              </div>
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
