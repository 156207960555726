import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
  ThemingProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type FormControlProps = OmitChakraProps<
  ChakraFormControlProps,
  keyof ThemingProps
>;

export const FormControl = forwardRef<FormControlProps, 'div'>((props, ref) => {
  return <ChakraFormControl {...props} ref={ref} />;
});
