import { AlertBanner, Link } from '@apollo/orbit';
import { captureEvent } from '@sentry/react';
import React, { useEffect, useState } from 'react';
import {
  StatusPageScheduledMaintenanceEntry,
  StatusPageScheduledMaintenanceResponse,
} from 'typings/StatusPageScheduledMaintenance';

import { LocalTimestamp } from 'src/components/timestamp/Timestamp';
import { useLocalStorage } from 'src/hooks/useLocalStorage';

export const ScheduledMaintenances = () => {
  const [scheduledMaintenances, setScheduledMaintenances] = useState<
    Array<StatusPageScheduledMaintenanceEntry>
  >([]);
  const [dismissedMaintenanceIds, setDismissedMaintenanceIds] = useLocalStorage(
    'dismissedMaintenanceIds',
  );

  useEffect(() => {
    fetch('https://status.apollographql.com/api/v2/scheduled-maintenances.json')
      .then((response) => response.json())
      .then((data: StatusPageScheduledMaintenanceResponse) => {
        setScheduledMaintenances(data.scheduled_maintenances);
      })
      .catch(() =>
        captureEvent(new Error('Failed to fetch scheduled maintainence')),
      );
  }, []);

  const scheduledMaintenancesToShow = scheduledMaintenances.filter(
    (scheduledMaintenance) =>
      // don't show resolved maintenance windows
      !scheduledMaintenance.resolved_at &&
      !dismissedMaintenanceIds.includes(scheduledMaintenance.id),
  );

  if (scheduledMaintenancesToShow.length === 0) {
    return null;
  }

  return (
    <AlertBanner
      status="info"
      isDismissible
      onDismiss={() => {
        setDismissedMaintenanceIds([
          ...scheduledMaintenancesToShow.map(({ id }) => id),
          ...dismissedMaintenanceIds,
        ]);
      }}
    >
      {scheduledMaintenancesToShow.map((scheduledMaintenance) => {
        // incident updates are sorted to show most recent updates first
        // e.g. [{status: "in_progress"}, {status: "scheduled"}]
        const maintenanceBodyText =
          scheduledMaintenance.incident_updates[0]?.body;
        const shortenMaintenanceBody =
          scheduledMaintenancesToShow.length > 1 ||
          (maintenanceBodyText && maintenanceBodyText.length > 180);

        return (
          <React.Fragment key={scheduledMaintenance.id}>
            <div>
              Scheduled maintenance between{' '}
              <LocalTimestamp
                value={new Date(scheduledMaintenance.scheduled_for)}
                className="font-semibold"
              />{' '}
              and{' '}
              <LocalTimestamp
                value={new Date(scheduledMaintenance.scheduled_until)}
                className="font-semibold"
              />
              {shortenMaintenanceBody && (
                <>
                  .{' '}
                  <Link
                    isExternal
                    to={`https://status.apollographql.com/incidents/${scheduledMaintenance.id}`}
                  >
                    Learn more
                  </Link>
                </>
              )}
            </div>
            {!shortenMaintenanceBody && <p>{maintenanceBodyText}</p>}
          </React.Fragment>
        );
      })}
    </AlertBanner>
  );
};
