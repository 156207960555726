import { chakra, forwardRef, useMenuStyles } from '@chakra-ui/react';
import classnames from 'classnames';
import React, { useCallback, useState } from 'react';

import { useScrollLocation } from '../../hooks/useScrollLocation';

export type MenuListProps = {
  className?: string;
  children: React.ReactNode;
};

/**
 * Used as a wrapper for the main body of the menu.
 *
 * Should be used as the parent for all menu groups and items.
 */
export const MenuList = forwardRef<MenuListProps, 'div'>(
  ({ className, ...props }, ref) => {
    const [menuListRef, setRef] = useState<HTMLDivElement>();
    const scrollLocation = useScrollLocation(menuListRef);

    const styles = useMenuStyles();

    const updateRef = useCallback((element: HTMLDivElement) => {
      setRef(element);
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(element);
      } else {
        // eslint-disable-next-line no-param-reassign
        ref.current = element;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <chakra.div
        {...props}
        __css={styles.listContainer}
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={classnames('orbit-menu__list', className)}
        data-scroll-location={scrollLocation}
        ref={updateRef}
      />
    );
  },
);
