import { Link } from '@apollo/orbit';
import React from 'react';

const privacyPolicyPdfUrl =
  'https://www.apollographql.com/Apollo-Privacy-Policy.pdf';

export const PrivacyPolicyLink = ({
  className,
  href = privacyPolicyPdfUrl,
}: {
  className?: string;
  href?: string;
}) => (
  <Link className={className} to={href} isExternal>
    Privacy Policy
  </Link>
);
