import { progressAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { customColors, customColorsRaw } from '../../theme/customColors';

const { bg, border } = customColors;
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(progressAnatomy.keys);

const baseStyle = definePartsStyle({
  filledTrack: {
    backgroundColor: border.info,
    '&[data-indeterminate]': {
      bgColor: 'unset',
      bgImage: `linear-gradient(90deg, rgb(${customColorsRaw.border.info} / 0) 0%, ${border.info} 50%, rgb(${customColorsRaw.border.info} / 0) 100%)`,
    },
    '[data-overlimit="true"] &': {
      bg: border.error,
    },
  },
  track: {
    border: '1px solid',
    borderColor: border.primary,
    h: 3,
    borderLeftRadius: 'full',
    borderRightRadius: 'full',
    // Unfortunately chakra overrides `borderRadius` for the `filledTrack`
    // styles due to how it implements the component, so we need to override it
    // ourselves from the parent. Chakra doesn't provide a stable class name to
    // work with so we have to target the tag here instead.
    '& > div': {
      borderRadius: 'none',
    },
  },
});

const variantOverLimit = definePartsStyle({
  track: {
    bg: border.error,
  },
});

const variantDefault = definePartsStyle({
  track: {
    bg: bg.secondary,
  },
});

export const ProgressStyles = defineMultiStyleConfig({
  baseStyle,
  variants: { default: variantDefault, overLimit: variantOverLimit },
  defaultProps: { variant: 'default' },
});
