import IconMinus from '@apollo/icons/default/IconMinus.svg';
import IconPlus from '@apollo/icons/default/IconPlus.svg';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Textarea,
  UseRadioProps,
  useRadio,
  useRadioGroup,
} from '@apollo/orbit';
// TODO: Remove use of `Square` component
// eslint-disable-next-line no-restricted-imports
import { Square } from '@chakra-ui/react';
import classnames from 'classnames';
import { useField } from 'formik';
import React, { useEffect, useRef, useState } from 'react';

import { ClickableText } from 'src/components/common/clickableText/ClickableText';

import { QUESTION_KEY } from '../../constants';

export interface OptionProps {
  label: string;
  value: string;
  helperText?: string;
  icon?: React.ComponentType<{
    className?: string;
    weight?: 'normal' | 'thin';
  }> | null;
}

export interface QuestionProps {
  label: string;
  options?: OptionProps[];
  name: QUESTION_KEY;
  isInvalid?: boolean;
  value: string | undefined;
  onAnswerChange: () => void;
  className?: string;
}

type RadioCardProps = OptionProps & UseRadioProps;

const RadioCard = (
  props: RadioCardProps & {
    onAnswerChange: () => void;
  },
) => {
  const {
    isChecked,
    icon: Icon,
    label,
    helperText,
    name = '',
    onAnswerChange,
    onChange,
  } = props;
  const radioBtnRef = useRef<HTMLInputElement | null>(null);
  const [field] = useField({ name, type: 'radio' });
  const { getInputProps } = useRadio({
    ...props,
    onChange: (value) => {
      onAnswerChange();
      field.onChange(value);
      onChange?.(value);
    },
  });

  const input = getInputProps();
  return (
    <div
      onClick={() => {
        if (isChecked) onAnswerChange();
      }}
      onKeyDown={(e) => {
        if (isChecked && e.key === ' ') onAnswerChange();
      }}
    >
      <label
        htmlFor={input.id}
        className={classnames(
          'focus-within:focus-ring group flex w-full cursor-pointer flex-row rounded-xl border border-brand-primary px-4 py-6 hover:bg-brand-primary hover:text-brand-tertiary hover:opacity-90',
          isChecked
            ? 'bg-brand-primary text-brand-tertiary'
            : 'bg-brand-tertiary text-brand-primary',
        )}
      >
        <input {...input} ref={radioBtnRef} />
        {Icon && (
          <Square
            className={classnames(
              'mr-4 rounded-lg border group-hover:border-brand-tertiary',
              isChecked
                ? 'border-brand-tertiary opacity-90'
                : 'border-brand-primary',
            )}
          >
            <Icon className="m-2 size-4" weight="thin" />
          </Square>
        )}
        <div className="flex flex-col">
          <div className="my-auto text-base font-normal">{label}</div>
          {helperText && (
            <FormHelperText
              className={classnames(
                'mt-0 group-hover:text-brand-tertiary',
                isChecked ? 'text-brand-tertiary' : 'text-brand-primary',
              )}
            >
              {helperText}
            </FormHelperText>
          )}
        </div>
      </label>
    </div>
  );
};

const CustomTextInput = (
  props: RadioCardProps & { onAnswerChange: () => void },
) => {
  const {
    isChecked,
    label,
    helperText,
    name = '',
    onAnswerChange,
    value,
  } = props;
  const [isExpanded, setIsExpanded] = useState(isChecked);
  const [field] = useField({ name, type: 'text' });

  useEffect(() => {
    if (isExpanded && !isChecked && field.value !== value) {
      setIsExpanded(false);
    }
  }, [isExpanded, isChecked, field.value, value]);

  return (
    <div>
      <ClickableText
        className="focus-visible:focus-ring flex items-center rounded p-1 align-middle text-link"
        onClick={() => {
          field.onChange({ target: { name: field.name, value: '' } });
          setIsExpanded((prev) => !prev);
        }}
      >
        Something else
        {isExpanded ? (
          <IconMinus className="ml-2" />
        ) : (
          <IconPlus className="ml-2" />
        )}
      </ClickableText>
      {isExpanded && (
        <FormControl className="my-1 sm:my-2">
          <FormLabel>{label}</FormLabel>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          <Textarea
            className="mt-2 resize-none"
            {...field}
            onBlur={(e) => {
              field.onBlur(e);
              onAnswerChange();
            }}
          />
        </FormControl>
      )}
    </div>
  );
};

export const SurveyQuestion = ({
  label,
  options = [],
  name,
  value,
  isInvalid,
  onAnswerChange,
  className,
}: QuestionProps) => {
  const [field] = useField({ name, type: 'radio' });
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange: field.onChange,
  });
  const group = getRootProps();
  const groupHasAnswer = !!value;

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel
        className={classnames(
          'mx-auto mb-8 text-2xl font-medium text-brand-primary',
          className,
        )}
      >
        {label}
      </FormLabel>
      <fieldset
        className="flex flex-col gap-4"
        {...group}
        aria-labelledby={label}
      >
        {options.map((option) => {
          const isChecked =
            groupHasAnswer &&
            (option.value
              ? option.value === value
              : !options.find((o) => o.value === value));
          const radio = getRadioProps({
            value: option.value,
          });

          return !option.value ? (
            <CustomTextInput
              key={option.value}
              {...radio}
              {...option}
              isChecked={isChecked}
              onAnswerChange={onAnswerChange}
            />
          ) : (
            <RadioCard
              key={option.value}
              {...radio}
              {...option}
              isChecked={isChecked}
              onAnswerChange={onAnswerChange}
            />
          );
        })}
      </fieldset>
    </FormControl>
  );
};
