import { LocationDescriptor } from 'history';
import React from 'react';
import { Link } from 'react-router-dom';

import { locationToPath } from 'src/lib/routeConfig/locationToPath';
import { getStudioLinkFromEmbed } from 'src/lib/routers/embedded';

import { isEmbeddableExplorerRoute } from '../embeddableExplorer/isEmbeddableExplorerRoute';
import { isEmbeddableSandboxRoute } from '../embeddableSandbox/isEmbeddableSandboxRoute';

/**
 *
 * Many of the links we have in Studio we want to outlink if we are in the
 * embedded Sandbox or embedded Explorer. This function checks if we are in
 * an embedded environment opens a new tab for those links if so. If not, this
 * is a regular react-router-dom Link.
 */
export const OutlinkForEmbed = React.forwardRef<
  HTMLAnchorElement,
  // if this is an embedded page, we ignore your target and rel props
  Pick<React.HTMLProps<HTMLAnchorElement>, 'className' | 'target' | 'rel'> & {
    to: LocationDescriptor<unknown>;
    children?: React.ReactNode;
  }
>(({ to, children, ...props }, ref) => {
  const isEmbedded = isEmbeddableExplorerRoute() || isEmbeddableSandboxRoute();
  const path: string = typeof to === 'object' && !!to ? locationToPath(to) : to;
  const { rel, ...nonRelProps } = props;
  return isEmbedded ? (
    <a
      ref={ref}
      href={getStudioLinkFromEmbed(path)}
      target="_blank"
      rel="noopener noreferrer"
      {...nonRelProps}
    >
      {children}
    </a>
  ) : (
    <Link ref={ref} to={to} {...props}>
      {children}
    </Link>
  );
});
