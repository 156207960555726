import { gql, useMutation, useQuery } from '@apollo/client';
import { Divider, Spinner } from '@apollo/orbit';
import React, { useEffect, useState } from 'react';

import { OnboardingLayout } from 'src/app/onboarding/components';
import { userEmailPreferencesRoute } from 'src/app/routedApp/routes';
import { useRouteParams } from 'src/hooks/useRouteParams';
import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { RouteConfigParams } from 'src/lib/routeConfig/RouteConfig';

import { Banner } from './banner/Banner';
import { EmailPreferenceRow } from './emailPreferenceRow/EmailPreferenceRow';
import { toggleSubscription } from './subscriptionHelpers';

const emailPreferencesPageQuery = gql`
  query UI__EmailPreferencesPageQuery($email: String!, $token: String!) {
    emailPreferences(email: $email, token: $token) {
      email
      subscriptions
      unsubscribedFromAll
    }
  }
`;

const setSubscriptionsMutation = gql`
  mutation SetSubscriptionsMutation(
    $email: String!
    $token: String!
    $subscriptions: [EmailCategory!]!
  ) {
    setSubscriptions(
      email: $email
      token: $token
      subscriptions: $subscriptions
    ) {
      email
      subscriptions
      unsubscribedFromAll
    }
  }
`;

const unsubscribeFromAllMutation = gql`
  mutation UnsubscribeFromAllMutation($email: String!, $token: String!) {
    unsubscribeFromAll(email: $email, token: $token) {
      email
      subscriptions
      unsubscribedFromAll
    }
  }
`;

interface Message {
  type: 'error' | 'confirmation';
  message: string;
}

interface Params {
  email: string;
  token: string;
}
/**
 * Typeguard to assert that our email and token params
 * are both non empty strings
 */
function isValidParams(
  params: RouteConfigParams<typeof userEmailPreferencesRoute>,
): params is typeof params & Params {
  return Boolean(params.email) && Boolean(params.token);
}

/**
 * This component serves to spread a single emailCategory prop into
 * the key, isSubscribed, and onToggle usages. This means we can't
 * have a case where we end up with a mismatch between these props.
 */
interface WrappedEmailPreferenceRowProps
  extends Omit<
    React.ComponentProps<typeof EmailPreferenceRow>,
    'isToggled' | 'onToggle'
  > {
  /**
   * Category of the email. Will be used for toggling
   */
  emailCategory: GraphQLTypes.EmailCategory;
  /**
   * Current email preferences
   */
  emailPreferences?: GraphQLTypes.UI__EmailPreferencesPageQuery_emailPreferences | null;
  /**
   * Callback to set unsubscriptions when a ceategory gets toggled
   */
  setSubscriptions: (
    params: Params,
    newSubscriptions: GraphQLTypes.EmailCategory[],
  ) => Promise<void>;
  params: RouteConfigParams<typeof userEmailPreferencesRoute>;
}
const WrappedEmailPreferenceRow = ({
  emailCategory,
  emailPreferences,
  setSubscriptions,
  params,
  ...otherProps
}: WrappedEmailPreferenceRowProps) => (
  <EmailPreferenceRow
    key={emailCategory}
    isToggled={Boolean(
      emailPreferences &&
        emailPreferences.subscriptions.includes(emailCategory) &&
        !emailPreferences.unsubscribedFromAll,
    )}
    onToggle={() => {
      if (isValidParams(params) && emailPreferences) {
        setSubscriptions(
          params,
          toggleSubscription(emailPreferences, emailCategory),
        );
      }
    }}
    disabled={!isValidParams(params) || !emailPreferences}
    {...otherProps}
  />
);

export const EmailPreferencesPage = () => {
  const params = useRouteParams(userEmailPreferencesRoute);

  const [setSubscriptions] = useMutation<
    GraphQLTypes.SetSubscriptionsMutation,
    GraphQLTypes.SetSubscriptionsMutationVariables
  >(setSubscriptionsMutation);

  const [unsubscribeFromAll] = useMutation<
    GraphQLTypes.UnsubscribeFromAllMutation,
    GraphQLTypes.UnsubscribeFromAllMutationVariables
  >(unsubscribeFromAllMutation);

  // Keep track of the current banner message
  const [message, setMessage] = useState<Message | null>(null);
  useEffect(() => {
    // When a message first comes up, set a timeout to clear
    // the message three seconds later
    if (message) {
      const currentMessage = message;
      const timeout = setTimeout(() => {
        // Only clear the message if this is the most
        // recent timeout
        if (message === currentMessage) {
          setMessage(null);
        }
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [message]);

  const { data, loading } = useQuery<
    GraphQLTypes.UI__EmailPreferencesPageQuery,
    GraphQLTypes.UI__EmailPreferencesPageQueryVariables
  >(emailPreferencesPageQuery, {
    skip: !params.token || !params.email,
    variables: {
      // Safe since this is skipped unless these are not null
      email: params.email as NonNullable<typeof params.email>,
      token: params.token as NonNullable<typeof params.token>,
    },
    context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
  });
  const emailPreferences = data?.emailPreferences;

  // Fire a mutation, return an optimistic response,
  // and display the result as a banner
  const handleMutationResult = async (mutation: () => Promise<void>) => {
    try {
      await mutation();
      setMessage({
        type: 'confirmation',
        message: 'Your email preferences have been updated.',
      });
    } catch (error) {
      setMessage({
        type: 'error',
        message: 'Error updating unsubscribe settings.',
      });
    }
  };

  const setSubscriptionsAndHandleResult = async (
    nonNullParams: Params,
    newSubscriptions: GraphQLTypes.EmailCategory[],
  ) =>
    handleMutationResult(async () => {
      const result = await setSubscriptions({
        variables: {
          ...nonNullParams,
          subscriptions: newSubscriptions,
        },
      });
      if (!result.data?.setSubscriptions) {
        throw new Error('Error updating preferences');
      }
    });

  const unsubscribeFromAllAndHandleResult = async (nonNullParams: Params) =>
    handleMutationResult(async () => {
      const result = await unsubscribeFromAll({ variables: nonNullParams });
      if (!result.data?.unsubscribeFromAll) {
        throw new Error('Error updating preferences');
      }
    });

  // Display mutation result or failed to load data error
  const displayMessage: Message | null =
    !emailPreferences && !loading
      ? {
          type: 'error',
          message:
            'Failed to load unsubscribe settings. Make sure you have the most recent unsubscribe link.',
        }
      : message;

  return (
    <OnboardingLayout>
      {displayMessage && (
        <Banner type={displayMessage.type}>{displayMessage.message}</Banner>
      )}
      <div className="mb-2 text-3xl font-semibold text-black">
        Email Preferences
      </div>
      <p className="mb-8 text-base text-black">
        {params.email ? (
          <>
            For <b>{params.email}</b>, tell
          </>
        ) : (
          'Tell'
        )}{' '}
        us which emails you'd like to receive. And don't worry, you can change
        this any time.
      </p>
      {loading ? (
        <Spinner className="mx-auto mb-16 mt-20" data-testid="spinner" />
      ) : (
        <>
          <WrappedEmailPreferenceRow
            params={params}
            name="Educational Information"
            description="Tips about Apollo products and how to get the most out of the Apollo service."
            emailCategory={GraphQLTypes.EmailCategory.EDUCATIONAL}
            emailPreferences={emailPreferences}
            setSubscriptions={setSubscriptionsAndHandleResult}
          />
          <Divider className="my-8" />
          <EmailPreferenceRow
            name="Unsubscribe from all emails"
            description="I would like to be removed from all future email lists and communication. Please note that you will continue to recieve critical account emails, such as password reset emails, from Apollo at this address."
            key="All"
            isToggled={emailPreferences?.unsubscribedFromAll ?? false}
            onToggle={() => {
              if (isValidParams(params)) {
                if (emailPreferences?.unsubscribedFromAll) {
                  setSubscriptionsAndHandleResult(params, []);
                } else {
                  unsubscribeFromAllAndHandleResult(params);
                }
              }
            }}
            disabled={!isValidParams(params) || !emailPreferences}
          />
        </>
      )}
    </OnboardingLayout>
  );
};
