import { makeVar, useReactiveVar } from '@apollo/client';

// We opted to not expose this directly and instead provide wrapper methods. We
// did this because I don’t think reactive variables are super well understood.
// This is creating an extra layer of abstraction but I think it’s fine since
// people shouldn’t need to dive into the details here often and it's not too
// hard to unpack when you do actually look at it.
const newWorkerVersionAvailableVar = makeVar(false);

export function triggerNewWorkerVersionAvailable() {
  newWorkerVersionAvailableVar(true);
}

export function useNewWorkerVersionAvailable() {
  const newWorkerVersionAvailable = useReactiveVar(
    newWorkerVersionAvailableVar,
  );

  return newWorkerVersionAvailable;
}

// Opting to wrap the triggers for ease of understanding. Abstracting the UI
// usage ended up more confusing overall than using the reactive variables.
export const shouldShowDismissableReloadPromptVar = makeVar(false);
export const shouldShowBlockingReloadPromptVar = makeVar(false);

export function triggerDismissableReloadPrompt() {
  shouldShowDismissableReloadPromptVar(true);
}

export function triggerBlockingReloadPrompt() {
  shouldShowBlockingReloadPromptVar(true);
}
