import { GraphQLError, GraphQLSchema, printSchema } from 'graphql';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useEmbeddableSandboxConfig } from 'src/app/embeddableSandbox/useEmbeddableSandboxConfig';
import { ToastType, useShowToast } from 'src/components/toast/Toast';
import { useDebouncedValue } from 'src/hooks/useDebouncedValue';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { useRouteParams } from 'src/hooks/useRouteParams';
import { assertUnreachable } from 'src/lib/assertUnreachable';
import { buildSchemaLeniently } from 'src/lib/buildSchemaLeniently';
import { Timestamp } from 'src/lib/graphqlTypes/customScalarTypes';
import { isLocalUrl } from 'src/lib/isLocalUrl';
import { catchAllRouteConfig } from 'src/lib/routeConfig/catchAllRoute';

import { SandboxConnectionSettings } from '../explorerPage/connectionSettingsModal/sandboxConnectionSettingsModal/SandboxConnectionSettingsModal';
import {
  EXPLORER_LISTENING_FOR_SCHEMA,
  IncomingEmbedMessageEvent,
  SCHEMA_ERROR,
  SCHEMA_RESPONSE,
  sendPostMessageFromEmbedToParent,
} from '../explorerPage/helpers/postMessageHelpers';
import { useHeadersManagerContext } from '../explorerPage/hooks/useExplorerState/useHeadersManagerContext/useHeadersManagerContext';
import { defaultSchemaBuilder } from '../explorerPage/hooks/useExplorerState/useSchema/useSchemaFromEndpointIntrospection/schemaLoader';
import {
  IntrospectionFailure,
  useSchemaFromEndpointIntrospection,
} from '../explorerPage/hooks/useExplorerState/useSchema/useSchemaFromEndpointIntrospection/useSchemaFromEndpointIntrospection';
import { useSchemaFromRegistry } from '../explorerPage/hooks/useExplorerState/useSchema/useSchemaFromRegistry/useSchemaFromRegistry';
import { sandboxGraphRouteConfig } from '../routes';

import {
  Reachability,
  useSetEndpointReachability,
} from './useEndpointReachability';
import { GraphRef } from './useGraphRef';

function getPollInterval(endpoint: string | null | undefined) {
  if (!endpoint) return 1_000;
  return isLocalUrl(endpoint) ? 1_000 : 5_000;
}

interface SchemaState {
  createdAt: Timestamp['output'] | undefined;
  hash: string | undefined;
  activeSchema: GraphQLSchema | undefined;
  supergraphSchema: GraphQLSchema | undefined;
  supergraphSchemaDocument: string | undefined;
  // for an introspected schema, if that endpoint is a subgraph,
  // we return the original subgraph sdl in addition to the graphql schema with added directives
  subgraphSdl?: string | undefined;
  error: IntrospectionFailure | Error | readonly GraphQLError[] | undefined;
  loadingSchema: boolean;
  initialLoadingSchema: boolean;
  refreshSchema?: () => void;
}

export const SchemaContext = React.createContext<SchemaState | null>(null);

function useActiveMemoizedSchema(mostRecentSchema: GraphQLSchema | undefined) {
  const [activeSchema, setActiveSchema] = useState<GraphQLSchema>();
  useEffect(() => {
    if (
      !mostRecentSchema ||
      !activeSchema ||
      printSchema(activeSchema) !== printSchema(mostRecentSchema)
    ) {
      setActiveSchema(mostRecentSchema);
    }
  }, [activeSchema, mostRecentSchema]);
  return activeSchema;
}

function getReachabilityFromIntrospectionFailure(
  error: IntrospectionFailure | undefined,
): Reachability {
  switch (error) {
    case undefined:
      return 'reachable';
    case 'noEndpointProvided':
      return 'noEndpoint';
    case 'unableToReachEndpoint':
      return 'possibleCorsError';
    // Treat introspection errors as unreachable instead of 'possibleCorsError'
    // because we know the introspection recieved some response so shouldn't be
    // a cors issue
    case 'introspectionDisabled':
    case 'invalidIntrospectionResponse':
    case 'invalidIntrospectionSchema':
      return 'unreachable';
    default:
      assertUnreachable(error);
  }
}

const getIntrospectionFailureToastParams = ({
  toastId,
  introspectionError,
  errorMessage,
}: {
  toastId?: string;
  introspectionError: IntrospectionFailure;
  errorMessage?: string | undefined;
}): ToastType => {
  if (
    introspectionError === 'unableToReachEndpoint' ||
    introspectionError === 'noEndpointProvided'
  ) {
    return {
      stableId: toastId,
      heading: `Schema Introspection Failure`,
      level: 'error',
      message: <>We couldn't reach your endpoint</>,
    };
  } else if (introspectionError === 'invalidIntrospectionResponse') {
    return {
      stableId: toastId,
      heading: `Schema Introspection Failure`,
      level: 'error',
      message: (
        <>
          The introspection query failed.
          {errorMessage ? (
            <p className="mt-2 font-mono">{errorMessage}</p>
          ) : (
            ' Please reload and try again.'
          )}
        </>
      ),
    };
  } else if (introspectionError === 'invalidIntrospectionSchema') {
    // You can also get here if the build step fails
    return {
      stableId: toastId,
      heading: `Schema Introspection Failure`,
      level: 'error',
      message: `We couldn't parse your graphql schema, or building your schema failed. The expected format is either IntrospectionQuery or SDL string.`,
    };
  } else if (introspectionError === 'introspectionDisabled') {
    return {
      stableId: toastId,
      heading: `Schema Introspection Failure`,
      level: 'error',
      message:
        'Introspection is disabled on this endpoint. Enable introspection to populate your schema.',
    };
  } else {
    return {
      stableId: toastId,
      heading: `Schema Introspection Failure`,
      level: 'error',
      message:
        errorMessage ??
        'Something went wrong trying to introspect your schema.',
    };
  }
};

export const PostMessageSchemaProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { showToasts } = useShowToast();
  const [activeSchema, setActiveSchema] = useState<GraphQLSchema | undefined>(
    undefined,
  );

  const handleSchemaResponsePostMessage = React.useCallback(
    (event: IncomingEmbedMessageEvent) => {
      if (
        event.data.name === SCHEMA_ERROR

        // we don't check the operationId here b/c dev tools & the old embeds won't send us one
        // and those are the only clients using this schema provider
      ) {
        const errorToShow = event.data.errors?.length
          ? JSON.stringify(event.data.errors)
          : event.data.error;
        if (event.data.errors) {
          showToasts(
            getIntrospectionFailureToastParams({
              introspectionError: 'introspectionDisabled',
              errorMessage: errorToShow,
            }),
          );
        } else {
          showToasts(
            getIntrospectionFailureToastParams({
              introspectionError: 'invalidIntrospectionResponse',
              errorMessage: errorToShow,
            }),
          );
        }
        return;
      } else if (
        event.data.name === SCHEMA_RESPONSE
        // we don't check the operationId here b/c dev tools & the old embeds won't send us one
        // and those are the only clients using this schema provider
      ) {
        if (
          event.data.schema &&
          typeof event.data.schema !== 'string' &&
          !('__schema' in event.data.schema)
        ) {
          showToasts(
            getIntrospectionFailureToastParams({
              introspectionError: 'invalidIntrospectionSchema',
            }),
          );
          return;
        }
        try {
          const schema = event.data.schema
            ? typeof event.data.schema === 'string'
              ? buildSchemaLeniently(event.data.schema).schema
              : defaultSchemaBuilder(event.data.schema)
            : undefined;
          setActiveSchema(schema);
        } catch (e) {
          const error = e as Error;
          // eslint-disable-next-line no-console
          console.error(error.toString());
          showToasts(
            getIntrospectionFailureToastParams({
              introspectionError: 'invalidIntrospectionSchema',
              errorMessage: error.message,
            }),
          );
          return;
        }
      }
      return undefined;
    },
    [showToasts],
  );

  React.useEffect(() => {
    window.addEventListener('message', handleSchemaResponsePostMessage);

    // We need to send the parent page a message when the embedded explorer is listening
    // for a schema so that the parent can send us their schema via post message.
    // This is more reliable than the iframe onload function
    sendPostMessageFromEmbedToParent({
      name: EXPLORER_LISTENING_FOR_SCHEMA,
    });
    return () => {
      window.removeEventListener('message', handleSchemaResponsePostMessage);
    };
  }, [handleSchemaResponsePostMessage]);
  const schemaState = React.useMemo(
    () => ({
      activeSchema,
      supergraphSchema: undefined,
      supergraphSchemaDocument: undefined,
      error: undefined,
      createdAt: undefined,
      hash: undefined,
      loadingSchema: !activeSchema,
      initialLoadingSchema: !activeSchema,
    }),
    [activeSchema],
  );

  return (
    <SchemaContext.Provider value={schemaState}>
      {children}
    </SchemaContext.Provider>
  );
};

// Adds a `SchemaContext.provider` based off introspection. Updates the current
// `ReachabilityContext` based off introspection query results.
// All requests go through the parent page via post messaging to parent & parent
// post messaging responses back to us.
export const PostMessageIntrospectionSchemaProvider = ({
  sandboxConnectionSettings,
  children,
}: {
  sandboxConnectionSettings: SandboxConnectionSettings;
  children?: React.ReactNode;
}) => {
  const { endpoint: endpointForSandbox } = useRouteParams(
    sandboxGraphRouteConfig,
    catchAllRouteConfig,
  );
  const schemaErrorToastId = 'schema-error-toast';
  const { showToasts, hideToasts } = useShowToast();

  const [sandboxUrl] = useLocalStorage('sandboxUrl');
  const pollInterval = useMemo(() => getPollInterval(sandboxUrl), [sandboxUrl]);

  const {
    interpolateEnvVariablesIntoHeaders,
    checkedSharedHeaders,
    checkedHeaders,
  } = useHeadersManagerContext();
  const { sendOperationHeadersInIntrospection } = useEmbeddableSandboxConfig();

  const getNextStableHeaders = useCallback(() => {
    return interpolateEnvVariablesIntoHeaders(
      sendOperationHeadersInIntrospection
        ? checkedHeaders
        : checkedSharedHeaders,
    );
  }, [
    checkedHeaders,
    checkedSharedHeaders,
    interpolateEnvVariablesIntoHeaders,
    sendOperationHeadersInIntrospection,
  ]);
  const [stableHeaders, setStableHeaders] = useState(getNextStableHeaders);

  useEffect(() => {
    setStableHeaders((currentStableHeaders) => {
      const nextStableHeaders = getNextStableHeaders();
      if (!_.isEqual(currentStableHeaders, nextStableHeaders)) {
        return nextStableHeaders;
      }
      return currentStableHeaders;
    });
  }, [getNextStableHeaders]);

  const shouldAutoUpdateIntrospectionSchema =
    sandboxConnectionSettings.shouldAutoUpdateSchema;

  const {
    schema,
    subgraphSdl,
    introspectionFailureType: error,
    isLoading,
    isInitialLoading,
    refreshSchema,
  } = useSchemaFromEndpointIntrospection({
    uri: sandboxUrl,
    skipPolling: !shouldAutoUpdateIntrospectionSchema,
    pollInterval,
    stableHeaders,
    includeCookies: sandboxConnectionSettings.sendCookies,
    shouldPostMessageRequests: true,
    // if there is an endpoint in the url, it is about to be written to localStorage
    // don't make a request to the stale locally stored endpoint
    skip: !!endpointForSandbox && sandboxUrl !== endpointForSandbox,
    pollForSubgraphSdlFirst: false,
    updateOnConfigChange: false,
  });

  const activeSchema = useActiveMemoizedSchema(schema);

  useEffect(() => {
    if (error) {
      hideToasts(schemaErrorToastId);
      showToasts(
        getIntrospectionFailureToastParams({
          introspectionError: error,
          toastId: schemaErrorToastId,
        }),
      );
    } else {
      hideToasts(schemaErrorToastId);
    }
  }, [error, hideToasts, showToasts]);

  const schemaState = React.useMemo(
    () => ({
      activeSchema,
      supergraphSchema: undefined,
      supergraphSchemaDocument: undefined,
      error,
      createdAt: undefined,
      hash: undefined,
      loadingSchema: isLoading,
      initialLoadingSchema: isInitialLoading,
      subgraphSdl,
      refreshSchema,
    }),
    [
      activeSchema,
      error,
      isLoading,
      isInitialLoading,
      subgraphSdl,
      refreshSchema,
    ],
  );

  const setEndpointReachability = useSetEndpointReachability();
  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!shouldAutoUpdateIntrospectionSchema) {
      setEndpointReachability('unknown');
      return;
    }
    setEndpointReachability(getReachabilityFromIntrospectionFailure(error));
  }, [
    error,
    isLoading,
    setEndpointReachability,
    shouldAutoUpdateIntrospectionSchema,
  ]);

  return (
    <SchemaContext.Provider value={schemaState}>
      {children}
    </SchemaContext.Provider>
  );
};

// Adds a `SchemaContext.provider` based off introspection. Updates the current
// `ReachabilityContext` based off introspection query results.
export const IntrospectionSchemaProvider = ({
  sandboxConnectionSettings,
  children,
}: {
  sandboxConnectionSettings: SandboxConnectionSettings;
  children?: React.ReactNode;
}) => {
  const { endpoint: endpointForSandbox } = useRouteParams(
    sandboxGraphRouteConfig,
    catchAllRouteConfig,
  );
  const [sandboxUrl] = useLocalStorage('sandboxUrl');
  const pollInterval = useMemo(() => getPollInterval(sandboxUrl), [sandboxUrl]);

  const { interpolateEnvVariablesIntoHeaders, checkedHeaders } =
    useHeadersManagerContext();

  const getNextStableHeaders = useCallback(() => {
    return interpolateEnvVariablesIntoHeaders(checkedHeaders);
  }, [checkedHeaders, interpolateEnvVariablesIntoHeaders]);
  const [stableHeaders, setStableHeaders] = useState(getNextStableHeaders);

  useEffect(() => {
    setStableHeaders((currentStableHeaders) => {
      const nextStableHeaders = getNextStableHeaders();
      if (!_.isEqual(currentStableHeaders, nextStableHeaders)) {
        return nextStableHeaders;
      }
      return currentStableHeaders;
    });
  }, [getNextStableHeaders]);

  const debouncedStableHeaders = useDebouncedValue(stableHeaders, 300);

  const shouldAutoUpdateIntrospectionSchema =
    sandboxConnectionSettings.shouldAutoUpdateSchema;

  const {
    schema,
    subgraphSdl,
    introspectionFailureType: error,
    isLoading,
    isInitialLoading,
    refreshSchema,
  } = useSchemaFromEndpointIntrospection({
    uri: sandboxUrl,
    skipPolling: !shouldAutoUpdateIntrospectionSchema,
    pollInterval,
    stableHeaders: debouncedStableHeaders,
    includeCookies: sandboxConnectionSettings.sendCookies,
    // if there is an endpoint in the url, it is about to be written to localStorage
    // don't make a request to the stale locally stored endpoint
    skip: !!endpointForSandbox && sandboxUrl !== endpointForSandbox,
    // In Sandbox, don't send subgraph introspection requests to endpoints unless we
    // know they are a subgraph. We don't want users to see logged errors on their AS
    // servers on introspection when using Sandbox
    pollForSubgraphSdlFirst: false,
    updateOnConfigChange: false,
  });
  const activeSchema = useActiveMemoizedSchema(schema);

  const schemaState = React.useMemo(
    () => ({
      activeSchema,
      supergraphSchema: undefined,
      supergraphSchemaDocument: undefined,
      error,
      createdAt: undefined,
      hash: undefined,
      loadingSchema: isLoading,
      initialLoadingSchema: isInitialLoading,
      subgraphSdl,
      refreshSchema,
    }),
    [
      activeSchema,
      error,
      isLoading,
      isInitialLoading,
      subgraphSdl,
      refreshSchema,
    ],
  );

  const setEndpointReachability = useSetEndpointReachability();
  useEffect(() => {
    if (isLoading) {
      return;
    }
    setEndpointReachability(getReachabilityFromIntrospectionFailure(error));
  }, [
    error,
    isLoading,
    setEndpointReachability,
    shouldAutoUpdateIntrospectionSchema,
  ]);

  return (
    <SchemaContext.Provider value={schemaState}>
      {children}
    </SchemaContext.Provider>
  );
};

// Adds a `SchemaContext.provider` based off the registry.
export const RegisteredSchemaProvider = ({
  graphRef,
  children,
}: {
  graphRef: GraphRef;
  children?: React.ReactNode;
}) => {
  const {
    schema,
    error,
    createdAt,
    hash,
    supergraphSchema,
    supergraphSchemaDocument,
    loading,
  } = useSchemaFromRegistry({
    graphRef,
    skip: false,
  });

  const schemaState = React.useMemo(
    () => ({
      activeSchema: schema,
      supergraphSchema,
      supergraphSchemaDocument,
      error,
      createdAt,
      hash,
      loadingSchema: loading,
      initialLoadingSchema: loading,
    }),
    [
      schema,
      error,
      createdAt,
      hash,
      loading,
      supergraphSchema,
      supergraphSchemaDocument,
    ],
  );

  return (
    <SchemaContext.Provider value={schemaState}>
      {children}
    </SchemaContext.Provider>
  );
};

export const useSchema = () => {
  const context = React.useContext(SchemaContext);
  if (!context) {
    throw new Error('useSchema must be used within a SchemaProvider');
  }
  return context;
};
