import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router';

import { useQueryParam } from 'src/hooks/useQueryParams';
import { AnyQueryParamKey, setOverlayClosedLocation } from 'src/lib/routing';

import { Props } from './URLModal';

/**
 * ⚠️ The children will continue to be rendered after the modal is closed, consumers are required to handle their own visibility
 *
 * Automatically render a modal when the `overlay` param in the URL matches `name`.
 *
 * The first render of the modal is lazy, so it won't be rendered until the URL matches,
 * after that it will be always be rendered. It's up to the consumer to manage visibility with the passed isOpen value
 */
export const LazyUrlModal = ({ name, children }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const overlayParam = useQueryParam('overlay');

  const closeModal = React.useCallback(
    (_event, additionalSearchParametersToUnset?: AnyQueryParamKey[]) => {
      history.replace(
        setOverlayClosedLocation(location, additionalSearchParametersToUnset),
      );
    },
    [location, history],
  );

  const isOpen = overlayParam === name;
  const hasBeenVisibleRef = useRef(isOpen);
  hasBeenVisibleRef.current = hasBeenVisibleRef.current || isOpen;
  return hasBeenVisibleRef.current ? (
    <>{children({ closeModal, isOpen })}</>
  ) : null;
};
