import {
  Menu as ChakraMenu,
  MenuProps as ChakraMenuProps,
  ThemingProps,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type MenuProps = OmitChakraProps<ChakraMenuProps, keyof ThemingProps>;

export const Menu = (props: MenuProps) => {
  return <ChakraMenu {...props} />;
};
