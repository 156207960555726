import { useMemo, useSyncExternalStore } from 'react';

export type ScrollLocation = 'top' | 'middle' | 'bottom';

/**
 * Returns the current scroll location of the element.
 *
 * If the element is not scrollable, or the passed in element is `null`, `null`
 * is returned.
 */

export function useScrollLocation(element: HTMLElement | null | undefined) {
  const { subscribe, getSnapshot } = useMemo(
    () => createScrollPositionStore(element),
    [element],
  );

  return useSyncExternalStore(subscribe, getSnapshot);
}

interface ScrollLocationStore {
  subscribe: (update: () => void) => () => void;
  getSnapshot: () => ScrollLocation | null;
}

const emptyStore: ScrollLocationStore = {
  subscribe: () => () => {},
  getSnapshot: () => null,
};

function createScrollPositionStore(
  element: HTMLElement | null | undefined,
): ScrollLocationStore {
  if (!element) {
    return emptyStore;
  }

  let value = getScrollLocation(element);

  return {
    subscribe: (update: () => void) => {
      const controller = new AbortController();

      function updateScroll() {
        requestAnimationFrame(() => {
          const scrollLocation = getScrollLocation(element as HTMLElement);

          if (value !== scrollLocation) {
            value = scrollLocation;
            update();
          }
        });
      }

      element.addEventListener('scroll', updateScroll, {
        signal: controller.signal,
      });

      // Resizing the window may cause the content to be scrollable or not depending
      // on whether the new viewport size can fit the content. We account for this
      // by recomputing the scroll position.
      window.addEventListener('resize', updateScroll, {
        signal: controller.signal,
      });

      return () => controller.abort();
    },
    getSnapshot: () => value,
  };
}

function getScrollLocation(element: HTMLElement): ScrollLocation | null {
  const scrolledToTop = element.scrollTop === 0;
  const scrolledToBottom =
    element.scrollHeight - element.scrollTop - element.clientHeight < 1;

  if (scrolledToTop && scrolledToBottom) {
    return null;
  }

  if (scrolledToTop) {
    return 'top';
  }

  if (scrolledToBottom) {
    return 'bottom';
  }

  return 'middle';
}
