/* eslint-disable @typescript-eslint/naming-convention */

import IconClients from '@apollo/icons/large/IconClients.svg';
import IconGraphQL from '@apollo/icons/large/IconGraphQL.svg';
import IconHelp from '@apollo/icons/large/IconHelp.svg';
import IconSearch from '@apollo/icons/large/IconSearch.svg';
import IconVariant from '@apollo/icons/large/IconVariant.svg';

import { Icon as IconBrainCog } from 'src/assets/icon-brain-cog.svg';
import { Icon as IconProofOfConcept } from 'src/assets/icon-proof-of-concept.svg';
import { Icon as IconRocketOrbit } from 'src/assets/icon-rocket-orbit.svg';
import { notProduction } from 'src/lib/notProduction';

import { QuestionProps } from './components/surveyQuestion/SurveyQuestion';

export const DEMO_API_ENDPOINT =
  'https://swapi-graphql.netlify.app/.netlify/functions/index';

export const CLOUD_ROUTER_ENDPOINT_DOMAIN_NAME = notProduction
  ? 'staging.starstuff.dev/graphql'
  : 'apollographos.net';

export type SurveyQuestionType = Omit<
  QuestionProps,
  'value' | 'onAnswerChange'
> & {
  checkIfVisible?: (
    answer: Record<QUESTION_KEY, string | undefined>,
  ) => boolean;
};

export type QUESTION_KEY = 'primary_goal' | 'current_use_case';

export const QUESTION_BANK: Record<QUESTION_KEY, SurveyQuestionType> = {
  primary_goal: {
    label: 'What’s your primary goal with GraphQL?',
    name: 'primary_goal',
    options: [
      {
        label: 'Replace a BFF (backend for frontend) service',
        value: 'replace_bff',
        icon: IconVariant,
      },
      {
        label: 'Build a mobile app, web app, or client application',
        value: 'build',
        icon: IconClients,
      },
      {
        label: 'Explore an API',
        value: 'explore',
        icon: IconSearch,
      },
      {
        label: 'I’m not sure',
        value: 'unsure',
        icon: IconHelp,
      },
    ],
  },
  current_use_case: {
    label: 'How are you currently using GraphQL?',
    name: 'current_use_case',
    options: [
      {
        label: 'I have a GraphQL API in production',
        value: 'running_in_production',
        icon: IconRocketOrbit,
      },
      {
        label: 'I have a GraphQL API running locally',
        value: 'running_locally',
        icon: IconGraphQL,
      },
      {
        label: 'I’m building a proof of concept',
        value: 'proof_of_concept',
        icon: IconProofOfConcept,
      },
      {
        label: 'I’m just getting started',
        value: 'getting_started',
        icon: IconBrainCog,
      },
    ],
  },
};

export const SURVEYS: Record<string, QUESTION_KEY[]> = {
  unified_onboarding: ['primary_goal', 'current_use_case'],
};
