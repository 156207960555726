import {
  BuildSchemaOptions,
  DirectiveDefinitionNode,
  GraphQLSchema,
  ParseOptions,
  ScalarTypeDefinitionNode,
  StringValueNode,
  buildSchema,
  print,
} from 'graphql';

import { BuildGraphQLSchemaError } from './errors/buildSchemaError/BuildSchemaError';

/**
 * Users occasionally register incomplete schemas that are missing directive
 * TODO: We can remove this once we can ensure schema documents returned from the backend
 * are guaranteed to be valid wrt buildSchema
 */
export const buildSchemaLeniently = (
  schema: string,
  options?: BuildSchemaOptions &
    ParseOptions & {
      additionalDirectiveDefinitions?: {
        [directiveName: string]: DirectiveDefinitionNode;
      };
      additionalTypeDefinitions?: {
        [typeName: string]: ScalarTypeDefinitionNode;
      };
    },
): {
  schema: GraphQLSchema;
  additionalDirectiveDefinitions?: {
    [directiveName: string]: DirectiveDefinitionNode;
  };
  additionalTypeDefinitions?: {
    [typeName: string]: ScalarTypeDefinitionNode;
  };
} => {
  try {
    return {
      ...options,
      schema: buildSchema(
        schema +
          Object.values({
            ...options?.additionalDirectiveDefinitions,
            ...options?.additionalTypeDefinitions,
          })
            .map(print)
            .join('\n'),
        options,
      ),
    };
  } catch (e) {
    const errorMessage = (e as Error).message;
    const unknownDirectiveRegExpExecArray =
      unknownDirectiveRegex.exec(errorMessage);

    if (unknownDirectiveRegExpExecArray?.groups?.directiveName) {
      // handle recognized directives by adding them with minimal data
      const directiveName =
        unknownDirectiveRegExpExecArray.groups.directiveName;
      return buildSchemaLeniently(schema, {
        ...options,
        additionalDirectiveDefinitions: {
          ...options?.additionalDirectiveDefinitions,
          [directiveName]: {
            kind: 'DirectiveDefinition',
            description: placeholderDirectiveDescriptionNode,
            name: {
              kind: 'Name',
              value: directiveName,
            },
            repeatable: true,
            locations: [
              {
                kind: 'Name',
                value: 'FIELD_DEFINITION',
              },
            ],
          },
        },
      });
    }

    const unsupportedLocationRegexExecArray =
      unsupportdDirectiveLocationRegex.exec(errorMessage);
    const unsupportedLocationRegexExecArrayGroups =
      unsupportedLocationRegexExecArray?.groups;

    if (unsupportedLocationRegexExecArrayGroups) {
      const { directiveName = '', locationName = '' } =
        unsupportedLocationRegexExecArrayGroups;
      const directiveDefinition =
        options?.additionalDirectiveDefinitions?.[directiveName];

      if (directiveDefinition) {
        return buildSchemaLeniently(schema, {
          ...options,
          additionalDirectiveDefinitions: {
            ...options?.additionalDirectiveDefinitions,
            [directiveName]: {
              ...directiveDefinition,
              locations: [
                ...directiveDefinition.locations,
                {
                  kind: 'Name',
                  value: locationName,
                },
              ],
            },
          },
        });
      }
    }

    const unknownArgumentRegexExecArray =
      unknownArgumentRegex.exec(errorMessage);
    const unknownArgumentRegexExecArrayGroups =
      unknownArgumentRegexExecArray?.groups;

    if (unknownArgumentRegexExecArrayGroups) {
      const { directiveName = '', argName = '' } =
        unknownArgumentRegexExecArrayGroups;
      const directiveDefinition =
        options?.additionalDirectiveDefinitions?.[directiveName];
      if (directiveDefinition) {
        return buildSchemaLeniently(schema, {
          ...options,
          additionalDirectiveDefinitions: {
            ...options.additionalDirectiveDefinitions,
            [directiveName]: {
              ...directiveDefinition,
              arguments: [
                ...(directiveDefinition.arguments ?? []),
                {
                  kind: 'InputValueDefinition',
                  name: {
                    kind: 'Name',
                    value: argName,
                  },
                  type: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'String',
                    },
                  },
                },
              ],
            },
          },
        });
      }
    }

    const unknownTypeRegexExecArray = unknownTypeRegex.exec(errorMessage);
    const unknownTypeRegexExecArrayGroups = unknownTypeRegexExecArray?.groups;

    if (unknownTypeRegexExecArrayGroups) {
      const { typeName = '' } = unknownTypeRegexExecArrayGroups;
      return buildSchemaLeniently(schema, {
        ...options,
        additionalTypeDefinitions: {
          ...options?.additionalTypeDefinitions,
          [typeName]: {
            kind: 'ScalarTypeDefinition' as const,
            name: { kind: 'Name' as const, value: typeName },
          },
        },
      });
    }

    throw new BuildGraphQLSchemaError(errorMessage, schema);
  }
};

const placeholderDirectiveDescriptionNode: StringValueNode = {
  kind: 'StringValue',
  value: `This directive was used in your schema, but no definition was provided  \n This definition was inserted as a placeholder for presentation only`,
};

const unknownDirectiveRegex = /Unknown directive "@(?<directiveName>.+?)"/;
const unsupportdDirectiveLocationRegex =
  /Directive "@(?<directiveName>.+)" may not be used on (?<locationName>.+?)\./;
const unknownArgumentRegex =
  /Unknown argument "(?<argName>.+)" on directive "@(?<directiveName>.+?)"/;
const unknownTypeRegex = /Unknown type: "(?<typeName>.+)"/;
