import { sliderAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const { bg, border, text } = customColors;
const $bg = cssVar('slider-bg');
const $thumbSize = cssVar('slider-thumb-size');
const $trackSize = cssVar('slider-track-size');

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(sliderAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    _disabled: {
      opacity: false,
    },
  },
  filledTrack: {
    [$bg.variable]: border.info,
  },
  mark: {
    color: text.primary,
    fontFamily: 'body',
    fontSize: 'sm',
    fontWeight: 'normal',
    lineHeight: 'sm',
    mt: 3,
    transform: 'translateX(-50%)',
    '&[style*="left: 0"]': {
      transform: 'unset',
    },
    '&[style*="left: 100%"]': {
      transform: 'translateX(-100%)',
    },
    _disabled: {
      color: text.disabled,
    },
  },
  thumb: {
    bg: bg.white,
    boxShadow: 'base',
    _disabled: {
      bg: bg.disabled,
    },
  },
  track: {
    [$bg.variable]: bg.neutral,
    _disabled: {
      [$bg.variable]: bg.disabled,
      // Unfortunately _disabled doesn't work inside filledTrack, so we target
      // it here instead.
      '> .chakra-slider__filled-track': {
        [$bg.variable]: 'transparent',
      },
    },
  },
});

const sizeMd = definePartsStyle({
  container: {
    [$thumbSize.variable]: '10px',
    [$trackSize.variable]: '2px',
  },
});

export const SliderStyles = defineMultiStyleConfig({
  baseStyle,
  sizes: { md: sizeMd },
});
