import React from 'react';

import { ConfirmationMessage } from 'src/components/confirmationMessage/ConfirmationMessage';
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage';
import { assertUnreachable } from 'src/lib/assertUnreachable';

interface BannerProps {
  type: 'error' | 'confirmation';
  children: React.ReactNode;
}

export const Banner = ({ type, children }: BannerProps) => {
  switch (type) {
    case 'error':
      return (
        <ErrorMessage className="mb-2 mt-3 w-full">{children}</ErrorMessage>
      );
    case 'confirmation':
      return (
        <ConfirmationMessage className="mb-2 mt-3 w-full">
          {children}
        </ConfirmationMessage>
      );
    default:
      throw assertUnreachable(type);
  }
};
