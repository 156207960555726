import IconChevronDown from '@apollo/icons/default/IconChevronDown.svg';
import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type SelectProps = OmitChakraProps<
  ChakraSelectProps,
  'styleConfig' | 'orientation' | 'colorScheme'
>;

/**
 * The Select component is meant to be used nested within a `form` tag.
 * Use the `Menu` component for all other uses cases.
 */
export const Select = forwardRef<SelectProps, 'select'>(
  ({ icon = <IconChevronDown />, ...props }, ref) => (
    <ChakraSelect {...props} icon={icon} ref={ref} />
  ),
);
