import {
  Tooltip as ChakraTooltip,
  TooltipProps as ChakraTooltipProps,
  ThemingProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type TooltipProps = OmitChakraProps<
  ChakraTooltipProps,
  'motionProps' | keyof ThemingProps
>;

export const Tooltip = forwardRef<TooltipProps, 'div'>((props, ref) => {
  return <ChakraTooltip {...props} ref={ref} />;
});
