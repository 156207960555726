import {
  FormLabel as ChakraFormLabel,
  FormLabelProps as ChakraFormLabelProps,
  ThemingProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type FormLabelProps = OmitChakraProps<
  ChakraFormLabelProps,
  keyof ThemingProps
>;

export const FormLabel = forwardRef<FormLabelProps, 'div'>((props, ref) => {
  return <ChakraFormLabel {...props} ref={ref} />;
});
