import {
  Card,
  CardBody,
  CardDescription,
  CardHeader,
  CardTitle,
  Link,
} from '@apollo/orbit';
import { Telescope } from '@apollo/space-kit/illustrations/Telescope';
import React from 'react';

import Config from 'src/lib/config';

export const BuildInfoPage = () => (
  <div className="h-full bg-secondary p-16">
    <Card className="m-auto w-full max-w-xl">
      <CardHeader>
        <CardTitle>Build details</CardTitle>
        <CardDescription>Git commit: {Config.gitCommitHash}</CardDescription>
      </CardHeader>
      <CardBody>
        <p>
          <Link to="/__stats">Bundle stats</Link>
        </p>
        <p>
          <Link
            to={`https://github.com/mdg-private/studio-ui/compare/${Config.gitCommitHash}...main`}
          >
            What's new on main
          </Link>
        </p>

        <div className="p-8 text-center">
          <Telescope />
        </div>
      </CardBody>
    </Card>
  </div>
);
