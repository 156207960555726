// copied from https://github.com/graphql/graphiql/blob/b963fcac8a885c6ecbcad02a1e6dd781546f422e/packages/graphql-language-service/src/schemaLoader.ts
import {
  BuildSchemaOptions,
  IntrospectionOptions,
  IntrospectionQuery,
  buildASTSchema,
  buildClientSchema,
  getIntrospectionQuery,
  parse,
  printSchema,
  validateSchema,
} from 'graphql';

import { headersWithContentType } from 'src/app/graph/explorerPage/helpers/headersWithContentType';

import {
  performPostMessageIntrospectionRequest,
  performPostMessageSubgraphAndSchemaIntrospectionRequest,
} from '../../performPostMessageIntrospectionQuery';

export type SchemaConfig = {
  uri: string;
  requestOpts?: Omit<RequestInit, 'headers'> & {
    headers: Record<string, string> | undefined;
  };
  introspectionOptions?: IntrospectionOptions;
  buildSchemaOptions?: BuildSchemaOptions;
  shouldPostMessageRequests?: boolean;
};

export type SchemaResponse = {
  data?: IntrospectionQuery | {}; // there are some endpoints where data is returned as an empty object. Ex: https://api.blockchain.getaurox.com/v1/ethereum/graphql
  errors?: { message: string }[];
};

export type SubgraphSdlResponse = {
  data?: {
    _service: {
      sdl: string;
    };
  };
  errors?: { message: string }[];
};

export type SubgraphSdlAndSchemaResponse = [
  SchemaResponse,
  SubgraphSdlResponse,
];

export type SchemaLoader = (config: SchemaConfig) => Promise<SchemaResponse>;

export const defaultSchemaLoader: SchemaLoader = async (
  schemaConfig: SchemaConfig,
) => {
  const { requestOpts, uri, introspectionOptions } = schemaConfig;
  const introspectionQueryString = getIntrospectionQuery(introspectionOptions);
  if (schemaConfig.shouldPostMessageRequests) {
    const introspectionResponse = await performPostMessageIntrospectionRequest({
      processedHeaders: headersWithContentType(requestOpts?.headers ?? {}),
      sandboxEndpointUrl: uri,
      introspectionQueryString,
      includeCookies: requestOpts?.credentials === 'include',
    });
    return introspectionResponse;
  } else {
    const fetchResult = await fetch(uri, {
      method: requestOpts?.method ?? 'post',
      body: JSON.stringify({
        query: introspectionQueryString,
        operationName: 'IntrospectionQuery',
      }),
      credentials: 'omit',
      ...requestOpts,
      headers: headersWithContentType(requestOpts?.headers ?? {}),
    });

    const introspectionResponse: {
      data?: IntrospectionQuery;
      errors?: { message: string }[];
    } = await fetchResult.json();
    return introspectionResponse;
  }
};

export const defaultSubgraphSdlAndSchemaLoader = async (
  schemaConfig: SchemaConfig,
): Promise<SubgraphSdlAndSchemaResponse> => {
  const { requestOpts, introspectionOptions, uri } = schemaConfig;
  const processedHeaders = headersWithContentType(requestOpts?.headers ?? {});
  const introspectionQueryString = getIntrospectionQuery(introspectionOptions);
  // add the _service field query at the end of the query string nested in with the __schema query
  const introspectionQueryStringWithSubgraphQuery =
    introspectionQueryString.replace(/__schema/, `_service { sdl } __schema`);

  if (schemaConfig.shouldPostMessageRequests) {
    const [introspectionResponse, subgraphSdlIntrospectionResponse] =
      await performPostMessageSubgraphAndSchemaIntrospectionRequest({
        processedHeaders,
        sandboxEndpointUrl: uri,
        introspectionQueryStringWithSubgraphQuery,
        includeCookies: requestOpts?.credentials === 'include',
      });
    return [introspectionResponse, subgraphSdlIntrospectionResponse];
  } else {
    const fetchResult = await fetch(uri, {
      method: requestOpts?.method ?? 'post',
      body: JSON.stringify({
        query: introspectionQueryStringWithSubgraphQuery,
        operationName: 'IntrospectionQuery',
      }),
      credentials: 'omit',
      ...requestOpts,
      headers: processedHeaders,
    });
    const fetchJSON = await fetchResult.json();
    const introspectionResponse: {
      data?: IntrospectionQuery;
      errors?: { message: string }[];
    } = {
      data: {
        __schema: fetchJSON?.data?.__schema,
      },
      ...(fetchJSON.errors ? { errors: fetchJSON.errors } : {}),
    };
    const subgraphSdlIntrospectionResponse: SubgraphSdlResponse = {
      data: {
        _service: fetchJSON?.data?._service,
      },
      ...(fetchJSON.errors ? { errors: fetchJSON.errors } : {}),
    };
    return [introspectionResponse, subgraphSdlIntrospectionResponse];
  }
};

/**
 *
 * @param response {IntrospectionQuery} response from retrieving schema
 */
export function defaultSchemaBuilder(introspection: IntrospectionQuery) {
  const schemaFromIntrospection = buildClientSchema(introspection);
  const validationErrors = validateSchema(schemaFromIntrospection);
  if (validationErrors.length) {
    throw new Error(`Invalid schema from introspection: ${validationErrors}`);
  }
  // Various parts of Studio (like the schema page) assume that our GraphQLSchema
  // has AST nodes on it, and buildClientSchema doesn't set them up, so we round-trip
  // through the parser.
  return buildASTSchema(parse(printSchema(schemaFromIntrospection)));
}
