import { defineStyleConfig } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';
export const FormLabelStyles = defineStyleConfig({
  baseStyle: {
    fontFamily: 'heading',
    fontSize: 'base',
    fontStyle: 'normal',
    fontWeight: 'medium',
    lineHeight: 'base',
    color: customColors.text.heading,
    m: 0,
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
});
