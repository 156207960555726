import * as Yup from 'yup';

import { UserSegment } from 'src/lib/graphqlTypes/types';

import { welcome } from '../../routes/routes';

export const typeToUserSegment = (
  type: Parameters<typeof welcome.path>[0]['type'],
) => {
  switch (type) {
    case 'sandbox':
      return UserSegment.SANDBOX;
    case 'sandbox_operation_collections':
      return UserSegment.SANDBOX_OPERATION_COLLECTIONS;
    case 'sandbox_preflight_scripts':
      return UserSegment.SANDBOX_PREFLIGHT_SCRIPTS;
    case 'dev':
      return UserSegment.LOCAL_DEVELOPMENT;
    case 'invite':
      return UserSegment.JOIN_MY_TEAM;
    case 'prod':
      return UserSegment.PRODUCTION_GRAPHS;
    default:
      return UserSegment.NOT_SPECIFIED;
  }
};

type RequiredArgs = {
  acceptedTerms?: boolean;
  password?: boolean;
};

export const getSignupValidationSchema = (requiredFields?: RequiredArgs) => {
  return Yup.object().shape({
    acceptedTerms: requiredFields?.acceptedTerms
      ? Yup.bool()
          .oneOf(
            [true],
            'Please agree to the Terms of Service and Privacy Policy.',
          )
          .required()
      : Yup.bool().oneOf(
          [true],
          'Please agree to the Terms of Service and Privacy Policy.',
        ),
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    password: requiredFields?.password
      ? Yup.string().min(8).required()
      : Yup.string(),
    signupUser: Yup.string(),
    createOrg: Yup.string(),
  });
};
