import { Link } from '@apollo/orbit';
import React from 'react';

const termsOfServiceUrl =
  'https://www.apollographql.com/Apollo-Terms-of-Service.pdf';

export const TermsOfServiceLink = ({
  className,
  href = termsOfServiceUrl,
}: {
  className?: string;
  href?: string;
}) => (
  <Link isExternal className={className} to={href}>
    Terms of Service
  </Link>
);
