import {
  As,
  PropsOf,
  SystemStyleObject,
  chakra,
  forwardRef,
  useStyleConfig,
} from '@chakra-ui/react';
import React from 'react';

type HeadingThemeProps =
  | {
      /**
       * The rendered style of the text.
       *
       * @default heading
       */
      variant?: 'heading';

      /**
       * The size of the text.
       *
       * @default lg
       */
      size?: '3xl' | '2xl' | 'xl' | 'lg';
    }
  | {
      /**
       * The rendered style of the text.
       *
       * @default heading
       */
      variant: 'title';
      size?: never;
    }
  | {
      /**
       * The rendered style of the text.
       *
       * @default heading
       */
      variant: 'subtitle';
      size?: never;
    };

export type HeadingProps<Component extends As = 'h2'> = PropsOf<Component> &
  HeadingThemeProps & {
    /**
     * Additional style config needed for component that wraps `Heading`. This
     * should only be used internally by other Orbit components. Please use
     * `className` for all styling.
     *
     * @internal
     */
    __orbitStyleConfig?: SystemStyleObject;
  };

export const Heading = forwardRef<HeadingProps, 'h2'>(
  ({ variant = 'heading', size = 'lg', __orbitStyleConfig, ...props }, ref) => {
    const defaultRole =
      !props.as || (typeof props.as === 'string' && props.as.match(/^h[1-6]$/))
        ? undefined
        : 'heading';
    const styles = useStyleConfig('Heading', { variant, size });

    return (
      <chakra.h2
        role={defaultRole}
        {...props}
        ref={ref}
        __css={{ ...styles, ...__orbitStyleConfig }}
      />
    );
  },
);
