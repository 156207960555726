import {
  TableColumnHeaderProps as ChakraTableColumnHeaderProps,
  Th as ChakraTh,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export interface TableColumnHeaderProps
  extends OmitChakraProps<ChakraTableColumnHeaderProps> {
  /**
   * Set this `true` if using a form element (ie. Checkbox, Switch) to
   * align the cell content in the center.
   *
   * @default false
   */
  isFormElement?: boolean;

  /**
   * Set this `true` if using an actions menu to align the cell content to the
   * right.
   *
   * @default false
   */
  isActionsMenu?: boolean;
}

export const Th = forwardRef<TableColumnHeaderProps, 'th'>(
  ({ isFormElement, isActionsMenu, ...props }, ref) => {
    return (
      <ChakraTh
        ref={ref}
        data-is-form-element={isFormElement}
        data-is-actions-menu={isActionsMenu}
        {...props}
      />
    );
  },
);
