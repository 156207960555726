import SoundInvincibility from './invincible.wav';

/**
 * The official Konami Code sequence:
 * up up down down left right left right b a
 *   37: arrow left
 *   38: arrow up
 *   39: arrow right
 *   40: arrow down
 *   65: a
 *   66: b
 */
const konamiCode = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];

// a variable to remember the 'position' the user has reached so far.
let codePos = 0;

export const listenForKonami = (event: KeyboardEvent) => {
  const nextKey = konamiCode[codePos];
  if (event.keyCode === nextKey) {
    codePos++;

    // if the last key is reached, activate cheats
    if (codePos === konamiCode.length) {
      activateCheats();
      codePos = 0;
    }
  } else {
    codePos = 0;
  }
};

async function activateCheats() {
  const audio = new Audio(SoundInvincibility);
  await audio.load();
  audio.play();

  let beat = 0;
  const floor = Math.floor(Math.random() * 360);
  const int = setInterval(() => {
    document.body.style.filter = `hue-rotate(${
      floor + beat * 110
    }deg) saturate(30)`;
    if (beat === 14) {
      document.body.style.filter = '';
      clearInterval(int);
    }
    beat++;
  }, 780);
}
