import IconMoon from '@apollo/icons/default/IconMoon.svg';
import { Button, IconButton } from '@apollo/orbit';
import React from 'react';

import { Tooltip } from 'src/components/common/tooltip/Tooltip';
import { ThemeName } from 'src/components/themeProvider/ThemeProvider';
import { useLocalStorage } from 'src/hooks/useLocalStorage';

export const InternalGlobalThemeToggle = () => {
  const [theme, setTheme] = useLocalStorage('temporaryInternalGlobalThemeName');
  const isDarkTheme = theme === ThemeName.DARK;
  const sharedProps = {
    'data-testid': 'theme-toggle',
    'aria-label': 'toggle dark/light theme',
    onClick: () => {
      setTheme(!isDarkTheme ? ThemeName.DARK : ThemeName.LIGHT);
    },
    size: 'sm' as const,
  };
  return (
    <Tooltip
      label={`🚧 Internal WIP: toggle ${
        isDarkTheme ? 'light' : 'dark'
      } mode for whole app 🚧`}
    >
      {isDarkTheme ? (
        <Button
          {...sharedProps}
          variant="primary"
          leftIcon={<IconMoon className="size-4" />}
        >
          Global Dark: ON
        </Button>
      ) : (
        <IconButton {...sharedProps} variant="hidden">
          <IconMoon className="size-4" />
        </IconButton>
      )}
    </Tooltip>
  );
};
