import { gql } from '@apollo/client';
import * as Sentry from '@sentry/react';
import React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useDebouncedQuery } from 'src/hooks/useDebouncedQuery';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { isMinLengthArray } from 'src/lib/isMinLengthArray';
import { OrgIDValidator } from 'src/lib/validators';

import { validateAccountId } from '../../utils';

const accountIdAvailableQuery = gql`
  query AccountIdsAvailableQuery(
    $accountId1: ID!
    $accountId2: ID!
    $accountId3: ID!
    $accountId4: ID!
  ) {
    accountId1: accountIDAvailable(id: $accountId1)
    accountId2: accountIDAvailable(id: $accountId2)
    accountId3: accountIDAvailable(id: $accountId3)
    accountId4: accountIDAvailable(id: $accountId4)
  }
`;

const ACCOUNT_ID_KEYS = [
  'accountId1',
  'accountId2',
  'accountId3',
  'accountId4',
] as const;

export const makeName = (fullName: string, suffixNumber: number) => {
  const suffix = suffixNumber > 1 && fullName ? ` ${suffixNumber}` : '';
  return `${fullName || '...'}'${
    fullName.toLowerCase().endsWith('s') ? '' : 's'
  } Team${suffix}`;
};
// This should extract details from OrgIDValidator.makeFromName(makeName(...))
export const getSlugAndSuffixFromOrgId = (orgId: string) => {
  const idMatchResult = orgId.match(/(.*s-team)(?:-([0-9]+))?$/);
  if (idMatchResult && isMinLengthArray(2, idMatchResult)) {
    const orgSlug = idMatchResult[1];
    const orgSuffix = idMatchResult[2] ? parseInt(idMatchResult[2], 10) : 1;
    return { orgSlug, orgSuffix };
  }
  Sentry.captureMessage(
    `Found default org id with unexpected format: ${orgId}`,
  );
  return undefined;
};

// Defaults the values for account name and id if they are not provided.
export function useDefaultAccountDetails({
  skip = false,
  ...values
}: {
  fullName: string;
  accountName: string;
  accountId: string;
  skip?: boolean;
}) {
  const [lastAvailableDefaultAccountName, setLastAvailableDefaultAccountName] =
    React.useState('');

  const [shouldDefaultName, setShouldDefaultName] = React.useState(true);
  const [shouldDefaultId, setShouldDefaultId] = React.useState(true);
  React.useEffect(() => {
    if (values.accountName) {
      setShouldDefaultName(false);
    }
  }, [values.accountName, setShouldDefaultName]);
  React.useEffect(() => {
    if (values.accountId) {
      setShouldDefaultId(false);
    }
  }, [values.accountId, setShouldDefaultId]);

  const [availableSuffixesBySlug, setAvailableSuffixesBySlug] = React.useState<
    Record<string, { suffix: number; isAvailable: boolean }>
  >({});
  // When we store availability, we store by the org id we requested, match that
  // format here
  const slugKey = OrgIDValidator.makeFromName(
    makeName(values.fullName.toLowerCase(), 1),
  );
  const { suffix: currentSuffix, isAvailable: currentSuffixAvailable } =
    availableSuffixesBySlug[slugKey] ?? {
      suffix: 1,
      isAvailable: false,
    };

  // We should only be searching for an available name if neither the
  // account name or id has been set.
  const shouldDefault =
    !!values.fullName && shouldDefaultName && shouldDefaultId;

  // Default values for org name/id
  const defaultAccountName = makeName(values.fullName, currentSuffix);
  const defaultAccountId = OrgIDValidator.makeFromName(defaultAccountName);
  const isValidDefaultAccountId =
    Object.keys(validateAccountId(defaultAccountId)).length === 0;

  // Find an account name that is still available.
  const shouldRunQuery =
    !skip &&
    shouldDefault &&
    isValidDefaultAccountId &&
    !currentSuffixAvailable;

  const availabilityResult = useDebouncedQuery<
    GraphQLTypes.AccountIdsAvailableQuery,
    GraphQLTypes.AccountIdsAvailableQueryVariables
  >(
    accountIdAvailableQuery,
    {
      variables: Object.fromEntries(
        ACCOUNT_ID_KEYS.map((key, i) => [
          key,
          OrgIDValidator.makeFromName(
            makeName(values.fullName, currentSuffix + i),
          ),
        ]),
      ) as Record<(typeof ACCOUNT_ID_KEYS)[number], string>,
    },
    {
      delayMs: 500,
      suspend: !shouldRunQuery,
    },
  );

  useDeepCompareEffect(() => {
    if (shouldDefault && availabilityResult.data) {
      const availableIndex = ACCOUNT_ID_KEYS.findIndex(
        (resultKey) => availabilityResult.data?.[resultKey],
      );

      const slugAndSuffix =
        availabilityResult.variables?.accountId1 === undefined
          ? undefined
          : getSlugAndSuffixFromOrgId(availabilityResult.variables.accountId1);
      if (slugAndSuffix) {
        const currentSuffixIndex = slugAndSuffix.orgSuffix;
        setAvailableSuffixesBySlug((currentSuffixes) => ({
          ...currentSuffixes,
          [slugAndSuffix.orgSlug]: {
            suffix:
              currentSuffixIndex +
              (availableIndex !== -1 ? availableIndex : ACCOUNT_ID_KEYS.length),
            isAvailable: availableIndex !== -1,
          },
        }));
      }
    }
  }, [availabilityResult, shouldDefault, setAvailableSuffixesBySlug]);

  // Once we have an available account name, store it
  React.useEffect(() => {
    if (shouldDefault && currentSuffixAvailable) {
      setLastAvailableDefaultAccountName(defaultAccountName);
    }
  }, [
    shouldDefault,
    currentSuffixAvailable,
    defaultAccountName,
    setLastAvailableDefaultAccountName,
  ]);

  const accountName = shouldDefaultName
    ? lastAvailableDefaultAccountName
    : values.accountName;

  const accountId = shouldDefaultId
    ? OrgIDValidator.makeFromName(accountName)
    : values.accountId;

  return {
    accountName,
    accountId,
    loading: shouldRunQuery,
  };
}
