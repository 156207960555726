import React from 'react';
import { useHistory, useLocation } from 'react-router';

import { useQueryParam } from 'src/hooks/useQueryParams';
import {
  AnyQueryParamKey,
  ModalName,
  setOverlayClosedLocation,
} from 'src/lib/routing';

export type CloseModalFn = (
  _event?: Event,
  additionalSearchParametersToUnset?: AnyQueryParamKey[],
) => void;

export interface Props {
  /**
   * URL `overlay` value to match to show this modal
   *
   * This value must be included in `ModalName` from `lib/routing`
   */
  name: ModalName;

  /**
   * `children` is a render prop function that calls the function with an
   * `onClose` prop to change the URL to close the modal
   *
   * we should possibly also pass the URL we'll be navigating to so we can allow
   * the consumer to navigate on their own with links.
   */
  children: (props: {
    closeModal: CloseModalFn;
    isOpen: boolean;
  }) => React.ReactElement | null;
}

/**
 * Automatically render a modal when the `overlay` param in the URL matches
 * `name`.
 *
 * When the modal should not be displayed it is unmounted.
 * @deprecated use LazyUrlModal instead
 */
export const URLModal = ({ name, children }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const overlayParam = useQueryParam('overlay');

  const closeModal: CloseModalFn = React.useCallback(
    (_event, additionalSearchParametersToUnset?: AnyQueryParamKey[]) => {
      history.replace(
        setOverlayClosedLocation(location, additionalSearchParametersToUnset),
      );
    },
    [location, history],
  );

  if (overlayParam !== name) {
    // Return nothing if the overlay doesn't match _and_ we're *not* forcing it
    // to be open
    return null;
  }

  return children({ closeModal, isOpen: true });
};
