/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-console */
import { useRef } from 'react';

/**
 * Helpful hook used to warn consumers when a certain condition is not met
 *
 * @param location - The name of the location the warning is being used in
 * @param condition - Result of condition the warning should trigger for
 * @param message - Helpful error message pointing to issue and resolution
 */
export function useDevWarning(
  location: string,
  condition: boolean,
  message: string,
) {
  if (process.env.NODE_ENV === 'development') {
    const didWarn = useRef(false);

    if (!condition && !didWarn.current) {
      console.warn(`Orbit/${location}: ${message}`);
      didWarn.current = true;
    }
  }
}
