import {
  TableBodyProps as ChakraTableBodyProps,
  Tbody as ChakraTbody,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type TableBodyProps = OmitChakraProps<ChakraTableBodyProps>;

export const Tbody = forwardRef<TableBodyProps, 'tbody'>((props, ref) => {
  return <ChakraTbody {...props} ref={ref} />;
});
