import { Button, ButtonProps, Link } from '@apollo/orbit';
import React from 'react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';

import Config from 'src/lib/config';
import { setOverlayVisibleLocation } from 'src/lib/routing';

export const ContactSupportLink = ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  const location = useGetContactSupportOverlayLocation();
  return (
    <Link className={className} to={location}>
      {children}
    </Link>
  );
};

export const ContactSupportButton = ({
  className,
  variant,
  children,
  isDisabled,
  onClick,
}: {
  variant?: ButtonProps['variant'];
  className?: string;
  children?: React.ReactNode;
  isDisabled?: boolean;
  onClick?: ButtonProps['onClick'];
}) => {
  const location = useGetContactSupportOverlayLocation();
  return (
    <Button
      variant={variant}
      className={className}
      isDisabled={isDisabled}
      as={ReactRouterLink}
      to={location}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export const useGetContactSupportOverlayLocation = () => {
  const location = useLocation();

  return setOverlayVisibleLocation({
    location,
    overlayName: Config.modals.supportRequest,
  });
};
