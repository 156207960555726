import IconCopy from '@apollo/icons/default/IconCopy.svg';
import { IconButton } from '@apollo/orbit';
import copyToClipboard from 'copy-to-clipboard';
import moment, { Duration, Moment } from 'moment';
import React, { useContext } from 'react';

import { assertUnreachable } from 'src/lib/assertUnreachable';
import { isISOTimestamp, isTimeSecondsAgo } from 'src/lib/date/date';
import { Timestamp } from 'src/lib/graphqlTypes/customScalarTypes';

import { toAbsoluteMoment } from '../affectedOperations/affectedOperationsHelpers';
import { Tooltip, TooltipProps } from '../common/tooltip/Tooltip';

interface Props {
  className?: string;
  value: Date | Timestamp['output'] | Moment;
  withTooltip?: boolean;
  tooltipPlacement?: TooltipProps['placement'];
}

/**
 * Hopefully get the most preferred English locale of the
 * user.  E.g. if the user prefers 'en-US', use that, or if they prefer another
 * 'en-' locale like 'en-GB' it should choose that.
 */
function findPreferredEnglishLocale() {
  return navigator.languages.find((language) => language.startsWith('en'));
}

export function TimestampTooltip({
  date,
  locale,
}: {
  date: Moment;
  locale?: string;
}) {
  const preferredLocale = locale ?? findPreferredEnglishLocale();
  const utcIsoTime = date.utc().toISOString();
  const localTime = preferredLocale
    ? `${date.toDate().toLocaleDateString(preferredLocale, {
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })} ${date.toDate().toLocaleTimeString(preferredLocale, {
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short',
      })}`
    : date.format('D MMMM Y H:mm [UTC]Z');

  return (
    <>
      <p className="flex items-center">
        <span className="mr-2 text-xs font-semibold tracking-wide">LOCAL:</span>
        <span className="flex-1">{localTime}</span>
        <Tooltip label="Copy" clickedLabel="Copied" placement="right">
          <IconButton
            aria-label="Copy local timestamp"
            className="ml-2"
            size="sm"
            variant="hidden"
            onClick={(e) => {
              e.preventDefault();
              copyToClipboard(localTime);
            }}
          >
            <IconCopy />
          </IconButton>
        </Tooltip>
      </p>
      <p className="flex items-center">
        <span className="mr-2 text-xs font-semibold tracking-wide">UTC:</span>
        <span className="flex-1 tracking-wide">{utcIsoTime}</span>
        <Tooltip label="Copy" clickedLabel="Copied" placement="right">
          <IconButton
            aria-label="Copy UTC timestamp"
            className="ml-2"
            size="sm"
            variant="hidden"
            onClick={(e) => {
              e.preventDefault();
              copyToClipboard(utcIsoTime);
            }}
          >
            <IconCopy />
          </IconButton>
        </Tooltip>
      </p>
    </>
  );
}

export function coerceToMoment(value: Props['value']) {
  if (typeof value === 'string' || typeof value === 'number') {
    if (isISOTimestamp(value)) {
      return moment(value);
    }
    if (isTimeSecondsAgo(value)) {
      // handle "relative" timestamp strings like "-86400" as offset from now
      return toAbsoluteMoment(value, moment().toISOString());
    }
    throw new Error(`unable to coerce date ${JSON.stringify(value)}`);
  } else if (value instanceof Date) {
    return moment(value);
  } else if (moment.isMoment(value)) {
    if (!value.isValid()) {
      throw new Error('invalid timestamp');
    }
    return value;
  } else {
    assertUnreachable(value);
  }
}

interface RelativeProps extends Props {
  format?: 'fromnow' | 'calendar' | ((maxDuration: Duration) => String);
}

/**
 * Display a timestamp "relative" to now.
 * @param value a Date object or ISO8601 formatted time string.
 * @param format use either "moment.fromNow", "moment.calendar", 
 * or a callback method which accepts a "moment.Duration" input 
 * and applies custom formatting to the displayed string. 
 * Wraps the value in a tooltip which provides a copyable UTC timetamp.

 * @see https://momentjs.com/docs/#/displaying/fromnow/
 * @see https://momentjs.com/docs/#/displaying/calendar-time/
 */
export function RelativeTimestamp({
  className,
  value,
  format = 'fromnow',
  withTooltip = true,
  tooltipPlacement,
}: RelativeProps) {
  const mutableDate = coerceToMoment(value);
  return (
    <Tooltip
      disabled={!withTooltip}
      interactive={withTooltip}
      className="w-max min-w-80"
      placement={tooltipPlacement}
      label={<TimestampTooltip date={mutableDate} />}
    >
      <span className={className}>
        {format === 'fromnow'
          ? mutableDate.fromNow()
          : format === 'calendar'
            ? mutableDate.calendar()
            : format(moment.duration(mutableDate.diff(moment())))}
      </span>
    </Tooltip>
  );
}

interface LocalProps extends Props {
  format?: 'date-only' | 'time-only' | 'date-and-time';
  forceLocale?: string;
  forceTooltipVisibleForTesting?: boolean;
  children?: React.ReactNode;
}

/**
 * Display a timestamp using browser-native toLocaleDateString /
 * toLocaleTimeString.
 * @param value a Date object or ISO8601 formatted time string.
 * @param format Optionally only show date or time based on value of
 * "format".
 * @param forceLocale Optionally force a specific locale (mainly for testing,
 * as we should prefer to use the browser's default).
 */
export function LocalTimestamp({
  className,
  value,
  format = 'date-and-time',
  forceLocale,
  forceTooltipVisibleForTesting,
  withTooltip = true,
  tooltipPlacement,
  children,
}: LocalProps) {
  const preferredLocale = forceLocale ?? findPreferredEnglishLocale();
  const overrideLocalTimestampTimeZoneWithUTC = useContext(
    OverrideLocalTimestampTimeZoneWithUTCContext,
  );
  let mutableDate: moment.Moment;
  try {
    mutableDate = coerceToMoment(value);
  } catch {
    return (
      <Tooltip
        label={<>Timestamp value {value.toString()} is invalid</>}
        isOpen={forceTooltipVisibleForTesting}
        placement={tooltipPlacement}
      >
        <span className={className}>{children || <>invalid timestamp</>}</span>
      </Tooltip>
    );
  }

  function maybeUTC(m: Moment) {
    if (overrideLocalTimestampTimeZoneWithUTC) {
      return m.utc();
    }
    return m;
  }
  const datePart = preferredLocale
    ? mutableDate.toDate().toLocaleDateString(preferredLocale, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: overrideLocalTimestampTimeZoneWithUTC ? 'UTC' : undefined,
      })
    : maybeUTC(mutableDate).format('D MMM Y');
  const timePart = preferredLocale
    ? mutableDate.toDate().toLocaleTimeString(preferredLocale, {
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short',
        timeZone: overrideLocalTimestampTimeZoneWithUTC ? 'UTC' : undefined,
      })
    : maybeUTC(mutableDate).format('H:mm');
  return (
    <Tooltip
      disabled={!withTooltip}
      interactive
      className="w-80"
      isOpen={forceTooltipVisibleForTesting}
      placement={tooltipPlacement}
      label={<TimestampTooltip date={mutableDate} locale={preferredLocale} />}
    >
      <span className={className}>
        {children ||
          (format === 'date-and-time'
            ? `${datePart} at ${timePart}`
            : format === 'date-only'
              ? datePart
              : timePart)}
      </span>
    </Tooltip>
  );
}

interface MomentProps extends Props {
  format?: string;
}

/**
 * Display a timestamp using a custom moment.js formattter.  To maintain
 * consistency throughout the application, it's preferable to use
 * one of the above components but this exists as a fallback.
 * @param value a Date object or ISO8601 formatted time string.
 * @param format any formatting string consumable by moment.js
 * @see https://momentjs.com/docs/#/displaying/format/
 */
export function MomentTimestamp({
  className,
  value,
  format = 'D MMM LT',
  withTooltip = true,
  tooltipPlacement,
}: MomentProps) {
  const mutableDate = coerceToMoment(value);
  return (
    <Tooltip
      interactive
      disabled={!withTooltip}
      className="w-80"
      label={<TimestampTooltip date={mutableDate} />}
      placement={tooltipPlacement}
    >
      <span className={className}>{mutableDate.format(format)}</span>
    </Tooltip>
  );
}

export const OverrideLocalTimestampTimeZoneWithUTCContext =
  React.createContext<boolean>(false);
