import React, { ComponentProps, useCallback, useState } from 'react';

import { Tooltip } from './common/tooltip/Tooltip';

// A Tooltip component with content that can be set via a function call
// so that the callsite doesn't need to store that state
export const TooltipWithMutableContent = ({
  children,
  initialContent,
  shouldWrapChildren,
  placement,
}: {
  children: ({
    setContent,
    resetContent,
  }: {
    setContent: React.Dispatch<React.SetStateAction<string>>;
    resetContent: () => void;
  }) => ComponentProps<typeof Tooltip>['children'];
  initialContent: string;
  shouldWrapChildren?: boolean;
  placement?: React.ComponentProps<typeof Tooltip>['placement'];
}) => {
  const [content, setContent] = useState(initialContent);
  const resetContent = useCallback(
    () => setContent(initialContent),
    [initialContent],
  );

  return (
    <Tooltip
      label={content}
      shouldWrapChildren={shouldWrapChildren}
      placement={placement}
    >
      {children({
        setContent,
        resetContent,
      })}
    </Tooltip>
  );
};
