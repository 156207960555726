import { gql, useQuery } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { v4 as uuid } from 'uuid';

import { Loading } from 'src/components/common/loading/Loading';
import { Toasts } from 'src/components/toast/Toast';
import { useAppSettings } from 'src/hooks/useAppSettings';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { IsCloudEndpointInitiatingContextProvider } from 'src/hooks/useIsCloudEndpointInitiating';
import { usePerKeyLocalStorage } from 'src/hooks/usePerKeyLocalStorage';
import { useRouteParams } from 'src/hooks/useRouteParams';
import Config from 'src/lib/config';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { mergeQueryParams } from 'src/lib/routing';

import {
  ThemeName,
  ThemeProvider,
} from '../../components/themeProvider/ThemeProvider';
import { GlobalHeader } from '../appLayout/components/globalHeader/GlobalHeader';
import { constructEmbedLocalStorageKey } from '../embedHelpers/constructEmbedLocalStorageKey';
import { isSupportedInVersion } from '../embedHelpers/isSupportedInVersion';
import { useListenForAuthResponse } from '../embedHelpers/useListenForAuthResponse';
import { useListenForHandshakeResponseFromEmbed } from '../embedHelpers/useListenForHandshakeResponseFromEmbed';
import { usePassThroughExplorerQueryParamsFromParentPage } from '../embedHelpers/usePassThroughExplorerQueryParamsFromParentPage';
import { EMBEDDED_EXPLORER_FLAGS } from '../graph/explorerPage/explorerBehavioralFlags';
import { HeadersManagerContextProvider } from '../graph/explorerPage/hooks/useExplorerState/useHeadersManagerContext/useHeadersManagerContext';
import { safeParseHeadersState } from '../graph/explorerPage/hooks/useLoadExplorerStateFromURL';
import {
  clearTemporaryEmbeddablePerGraphIdentifierLocalStorage,
  useLocalStorageGraphIdentifier,
  usePerGraphIdentifierLocalStorage,
} from '../graph/explorerPage/hooks/usePerGraphIdentifierLocalStorage';
import { ReachabilityProvider } from '../graph/hooks/useEndpointReachability';
import {
  GraphRef,
  graphRefToString,
  useGraphRef,
} from '../graph/hooks/useGraphRef';
import { useIsPublicMemberOnly } from '../graph/hooks/useIsPublicMemberOnly';
import {
  PostMessageSchemaProvider,
  RegisteredSchemaProvider,
} from '../graph/hooks/useSchema';
import {
  embeddableExplorerRouteConfig,
  variantRouteConfig,
} from '../graph/routes';
import { EMBEDDED_EXPLORER_GRAPH_FLAGS } from '../graph/variantLayout/graphBehavioralFlags/graphBehavioralFlags';

import { AuthenticateLoginPage } from './AuthenticateLoginPage';
import { useEmbeddableExplorerConfig } from './useEmbeddableExplorerConfig';

const EmbeddableExplorerSchemaProvider = ({
  children,
  graphRef,
}: {
  graphRef: GraphRef | null;
  children: React.ReactElement;
}) => {
  return graphRef ? (
    <RegisteredSchemaProvider graphRef={graphRef}>
      {children}
    </RegisteredSchemaProvider>
  ) : (
    <PostMessageSchemaProvider>{children}</PostMessageSchemaProvider>
  );
};

const SharedSandboxAndGraphModals = React.lazy(async () => ({
  default: (
    await import(
      /* webpackChunkName: "VariantLayoutModals" */ '../graph/variantLayout/VariantLayoutModals'
    )
  ).SharedSandboxAndGraphModals,
}));

const ExplorerPage = React.lazy(async () => ({
  default: (
    await import(
      /* webpackChunkName: "ExplorerPage" */ '../graph/explorerPage/ExplorerPage'
    )
  ).ExplorerPage,
}));

const MIN_VERSION_WITH_DEFAULT_PREFIXED_QUERY_PARAMS = '0.5.3';
const MIN_VERSION_WITH_FILE_UPLOAD_SUPPORT = '3.3.0';

// Write local storage id to local storage on first render, remove it on unmount
// We need this to happen before anything else so we can appropriately store
// the rest of the config in local storage under this temporary id
const EmbeddableExplorerWithTemporaryLocalStorageId = () => {
  const { shouldPersistState, graphRef } = useRouteParams(
    embeddableExplorerRouteConfig,
  );
  // We only care about the value of shouldPersistState on the first render,
  // after the first render they are unreliable. They could have been wiped,
  // a query param of the same name might have been modified by another page etc.
  // We only want to update the local storage config values on the first render
  const [hasCompletedFirstRender, setFirstRenderComplete] =
    React.useState(false);

  const embedGeneratedLocalStorageId = useMemo(() => uuid(), []);
  // per key so if you have multiple graphs on a page they can each have their own sessions
  const [
    temporaryEmbedLocalStorageIdForGraphRef,
    setTemporaryEmbedLocalStorageIdForGraphRef,
  ] = usePerKeyLocalStorage({
    key: graphRef ?? 'manualSchema',
    initialValue: undefined,
    localStorageKey: 'temporaryEmbedLocalStorageId',
  });
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const temporaryLocalStorageCleanup = () => {
      setTemporaryEmbedLocalStorageIdForGraphRef(undefined);
      clearTemporaryEmbeddablePerGraphIdentifierLocalStorage();
    };
    // when the window is closed, remove the temporary local storage
    window.addEventListener('beforeunload', temporaryLocalStorageCleanup);
    // on unmount, remove the temporary local storage id
    return () => {
      window.removeEventListener('beforeunload', temporaryLocalStorageCleanup);
      temporaryLocalStorageCleanup();
    };
  }, [setTemporaryEmbedLocalStorageIdForGraphRef]);

  useEffect(() => {
    if (!hasCompletedFirstRender) {
      setFirstRenderComplete(true);
      if (
        shouldPersistState !== 'true' &&
        !temporaryEmbedLocalStorageIdForGraphRef
      ) {
        // clear local storage on load
        clearTemporaryEmbeddablePerGraphIdentifierLocalStorage();
        setTemporaryEmbedLocalStorageIdForGraphRef(
          embedGeneratedLocalStorageId,
        );
      }
    }
  }, [
    shouldPersistState,
    embedGeneratedLocalStorageId,
    history,
    location,
    setTemporaryEmbedLocalStorageIdForGraphRef,
    hasCompletedFirstRender,
    temporaryEmbedLocalStorageIdForGraphRef,
  ]);

  // wait until we populate the temporary embed local storage id if we need it before
  // rendering
  return !(
    shouldPersistState !== 'true' && !temporaryEmbedLocalStorageIdForGraphRef
  ) ? (
    <EmbeddableExplorer />
  ) : (
    <Loading />
  );
};

const EmbeddableExplorer = () => {
  useListenForHandshakeResponseFromEmbed();
  useListenForAuthResponse();
  usePassThroughExplorerQueryParamsFromParentPage();

  const {
    document: operation,
    headers,
    variables,
    defaultDocument,
    defaultHeaders,
    defaultVariables,
    defaultCollectionEntryId,
    defaultCollectionId,
  } = useRouteParams(embeddableExplorerRouteConfig);

  const history = useHistory();
  const location = useLocation();
  const graphIdentifier = useLocalStorageGraphIdentifier({
    shouldNamespaceSandboxByEndpoint: false,
    shouldOnlyStoreForRegisteredGraphs: false,
  });

  const config = useEmbeddableExplorerConfig();
  useEffect(() => {
    if (
      config.version &&
      !isSupportedInVersion(
        MIN_VERSION_WITH_DEFAULT_PREFIXED_QUERY_PARAMS,
        config.version,
      ) &&
      (operation || headers || variables)
    ) {
      history.replace({
        ...location,
        search: mergeQueryParams(location.search, {
          [Config.queryParameters.ExplorerDocument]: undefined,
          [Config.queryParameters.ExplorerHeaders]: undefined,
          [Config.queryParameters.ExplorerVariables]: undefined,
          defaultDocument: operation,
          defaultVariables: variables,
          defaultHeaders: headers,
        }),
      });
    }
  }, [headers, history, location, operation, variables, config.version]);

  const [embedDefaultTabState, setEmbedDefaultTabState] =
    usePerGraphIdentifierLocalStorage({
      graphIdentifier,
      key: 'embedDefaultTabState',
      stableInitialValue: undefined,
    });

  useEffect(() => {
    if (defaultCollectionEntryId && defaultCollectionId) {
      setEmbedDefaultTabState({
        collectionId: defaultCollectionId,
        collectionEntryId: defaultCollectionEntryId,
      });
    } else if (defaultDocument || defaultHeaders || defaultVariables) {
      setEmbedDefaultTabState({
        headers: defaultHeaders
          ? safeParseHeadersState(defaultHeaders)
          : undefined,
        operation: defaultDocument,
        variables: defaultVariables,
      });
    }
    history.replace({
      ...history.location,
      search: mergeQueryParams(history.location.search, {
        [Config.queryParameters.EmbeddableExplorerDefaultDocument]: undefined,
        [Config.queryParameters.EmbeddableExplorerDefaultHeaders]: undefined,
        [Config.queryParameters.EmbeddableExplorerDefaultVariables]: undefined,
        [Config.queryParameters.EmbeddableExplorerDefaultCollectionEntryId]:
          undefined,
        [Config.queryParameters.EmbeddableExplorerDefaultCollectionId]:
          undefined,
      }),
    });
  }, [
    defaultCollectionEntryId,
    defaultCollectionId,
    defaultDocument,
    defaultHeaders,
    defaultVariables,
    history,
    setEmbedDefaultTabState,
  ]);

  const { navCollapsed } = useAppSettings();
  const graphRef = useGraphRef();
  const [currentAccountId] = useCurrentAccountId();

  const { data, loading } = useQuery<
    GraphQLTypes.EmbeddedExplorerPermissionsQuery,
    GraphQLTypes.EmbeddedExplorerPermissionsQueryVariables
  >(
    gql`
      query EmbeddedExplorerPermissionsQuery($graphRef: ID!) {
        variant(ref: $graphRef) {
          ... on GraphVariant {
            id
            isPublic
            permissions {
              canQuerySchemas
            }
          }
        }
        me {
          id
          ... on User {
            apiKeys {
              id
            }
          }
        }
      }
    `,
    {
      variables: {
        graphRef: graphRefToString(graphRef) ?? '',
      },
      skip: !graphRef,
    },
  );

  const embeddedExplorerBehavioralFlags = useMemo(
    () =>
      EMBEDDED_EXPLORER_FLAGS({
        graphRef,
        showHeadersAndEnvironmentVariables: config.showHeadersAndEnvVars,
        subscriptionsSupported: config.parentSupportsSubscriptions,
        requiresConfiguredUrls: !!graphRef,
        defaultTabState: embedDefaultTabState,
        showFileUpload: isSupportedInVersion(
          MIN_VERSION_WITH_FILE_UPLOAD_SUPPORT,
          config.version,
        ),
      }),
    [
      config.parentSupportsSubscriptions,
      config.showHeadersAndEnvVars,
      config.version,
      embedDefaultTabState,
      graphRef,
    ],
  );

  const [embedAuthenticationDetails] = usePerKeyLocalStorage({
    initialValue: undefined,
    key: document.referrer,
    localStorageKey: 'embedAuthenticationDetails',
  });

  const embedLocalStorageKey = useMemo(() => {
    return embedAuthenticationDetails?.origin
      ? constructEmbedLocalStorageKey({
          origin: embedAuthenticationDetails.origin,
          graphRef: graphRefToString(graphRef) ?? undefined,
        })
      : undefined;
  }, [embedAuthenticationDetails, graphRef]);
  const [partialEmbedUserApiToken] = usePerKeyLocalStorage({
    localStorageKey: 'partialEmbedUserApiTokens',
    key: embedLocalStorageKey ?? '',
    initialValue: undefined,
  });

  const apiKeys = useMemo(
    () =>
      data?.me?.__typename === 'User'
        ? data.me.apiKeys.map((apiKey) => apiKey.id)
        : [],
    [data],
  );

  const isPublic =
    data?.variant?.__typename === 'GraphVariant' && data.variant.isPublic;
  const canQuerySchemas =
    data?.variant?.__typename === 'GraphVariant' &&
    data.variant.permissions?.canQuerySchemas;
  const isAuthenticated =
    canQuerySchemas &&
    partialEmbedUserApiToken &&
    partialEmbedUserApiToken.partialToken &&
    partialEmbedUserApiToken.id &&
    apiKeys.includes(partialEmbedUserApiToken.id);

  const doesNotNeedAuthentication = isPublic || !graphRef;
  const isPublicMemberOnly = useIsPublicMemberOnly({ graphRef });

  if (loading) {
    return <Loading />;
  }

  return (
    <IsCloudEndpointInitiatingContextProvider graphRef={graphRef}>
      <HeadersManagerContextProvider graphRef={graphRef}>
        <ReachabilityProvider>
          <ThemeProvider
            className="size-full"
            overrideThemeName={
              config.theme === 'light' ? ThemeName.LIGHT : ThemeName.DARK
            }
          >
            <EmbeddableExplorerSchemaProvider graphRef={graphRef}>
              <div className="flex h-full flex-col">
                {config.shouldShowGlobalHeader && (
                  <GlobalHeader
                    goHomeLink={
                      graphRef
                        ? variantRouteConfig.path({
                            ...graphRef,
                            graphVisibilityType: isPublicMemberOnly
                              ? 'public'
                              : 'graph',
                          })
                        : undefined
                    }
                    leftNavContext={navCollapsed ? 'collapsed' : 'expanded'}
                  />
                )}
                <div className="flex h-full flex-1 overflow-y-auto">
                  <Toasts />
                  <SharedSandboxAndGraphModals
                    currentAccountId={currentAccountId}
                    graphBehavioralFlags={EMBEDDED_EXPLORER_GRAPH_FLAGS}
                  />
                  <div className="relative flex flex-1 flex-col overflow-x-hidden overscroll-y-auto">
                    {loading ? (
                      <Loading />
                    ) : doesNotNeedAuthentication || isAuthenticated ? (
                      <ExplorerPage
                        userId={undefined}
                        explorerBehavioralFlags={
                          embeddedExplorerBehavioralFlags
                        }
                        graphBehavioralFlags={EMBEDDED_EXPLORER_GRAPH_FLAGS}
                      />
                    ) : (
                      <AuthenticateLoginPage embedSubdomain="explorer" />
                    )}
                  </div>
                </div>
              </div>
            </EmbeddableExplorerSchemaProvider>
          </ThemeProvider>
        </ReachabilityProvider>
      </HeadersManagerContextProvider>
    </IsCloudEndpointInitiatingContextProvider>
  );
};

export { EmbeddableExplorerWithTemporaryLocalStorageId as EmbeddableExplorer };
