import { gql, useMutation, useQuery } from '@apollo/client';
import { isNil, omitBy } from 'lodash';
import { useCallback } from 'react';

import { useIsLoggedIn } from 'src/app/graph/hooks/useIsLoggedIn';

import { GraphQLTypes } from '../lib/graphqlTypes';

import { useLocalStorage } from './useLocalStorage';

export const useAppSettings = () => {
  const { isLoggedIn } = useIsLoggedIn();

  const { data } = useQuery(
    gql<
      GraphQLTypes.UseAppSettingsQuery,
      GraphQLTypes.UseAppSettingsQueryVariables
    >`
      query UseAppSettingsQuery {
        studioSettings {
          id
          appNavCollapsed
        }
      }
    `,
    { skip: !isLoggedIn },
  );

  const studioSettings = data?.studioSettings;

  const [setAppSettingsMutation] = useMutation<
    GraphQLTypes.SetAppSettingsVariables,
    GraphQLTypes.SetAppSettingsVariablesVariables
  >(
    gql`
      mutation SetAppSettingsVariables($newSettings: UserSettingsInput) {
        setUserSettings(newSettings: $newSettings) {
          id
          appNavCollapsed
        }
      }
    `,
    {
      optimisticResponse: studioSettings
        ? (variables) => ({
            setUserSettings: {
              ...studioSettings,
              ...omitBy(variables, isNil),
            },
          })
        : undefined,
    },
  );
  const setAppSettings = useCallback(
    (newSettings: GraphQLTypes.UserSettingsInput) =>
      setAppSettingsMutation({ variables: { newSettings } }),
    [setAppSettingsMutation],
  );

  const navCollapsed = studioSettings?.appNavCollapsed ?? false;

  const setNavCollapsed = useCallback(
    (value: boolean | ((oldValue: boolean) => boolean)) =>
      setAppSettings({
        appNavCollapsed:
          typeof value === 'boolean' ? value : value(navCollapsed),
      }),
    [navCollapsed, setAppSettings],
  );

  const [locallyPersistedNavCollapsed, setLocallyPersistedNavCollapsed] =
    useLocalStorage('navCollapsed');

  if (!isLoggedIn) {
    return {
      navCollapsed: locallyPersistedNavCollapsed,
      setNavCollapsed: setLocallyPersistedNavCollapsed,
    };
  }

  return {
    navCollapsed,
    setNavCollapsed,
  };
};
