import classnames from 'classnames';
import React from 'react';

const isReactElement = (
  variableToCheck: React.ReactNode,
): variableToCheck is React.ReactElement =>
  (variableToCheck as React.ReactElement).type !== 'undefined';

export const Li = ({
  children,
  className,
}: {
  className: string;
  children: React.ReactNode[];
}) => {
  const firstChild = children[0];
  const isCheckList =
    firstChild && isReactElement(firstChild) && firstChild.type === 'input';
  return (
    <li className={classnames(className, isCheckList && 'list-none')}>
      {children}
    </li>
  );
};
