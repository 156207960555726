import { GraphRef } from '../hooks/useGraphRef';

import {
  CollectionEntryIdState,
  CollectionEntryState,
} from './hooks/useExplorerState/useEditorTabState/collectionEntryState';

export const STUDIO_UI_EXPLORER_FLAGS: ExplorerBehavioralFlags = {
  roundedCorners: false,
  fullHeightEditor: false,
  showLeftPaneToggle: true,
  showExplorerSettings: true,
  showOperationCollections: true,
  showRunHistory: true,
  shouldShowCopyLink: true,
  shouldAutoFocusEditor: true,
  showOnlyDocumentationTab: false,
  showSchemaVersion: true,
  showResultStatus: true,
  showResponseHeaders: true,
  showResponseActions: true,
  showHeadersAndEnvironmentVariables: true,
  shouldShowKeyboardShortcutIcon: true,
  shouldPostMessageOperations: false,
  environmentVariablesInSettings: true,
  cannotBeEmbedded: false,
  showConnectionSettings: true,
  showThemeToggle: true,
  subscriptionsSupported: true,
  requiresConfiguredUrls: true,
  canSeePreflightScripts: true,
  canSeePostflightScripts: true,
  initialRequestQueryPlan: false,
  showFileUpload: true,
  initialRequestConnectorsDebugging: false,
};

export const STORYBOOK_EXPLORER_FLAGS: ExplorerBehavioralFlags = {
  roundedCorners: false,
  fullHeightEditor: false,
  showLeftPaneToggle: true,
  showExplorerSettings: true,
  showOperationCollections: true,
  showRunHistory: true,
  shouldShowCopyLink: true,
  shouldAutoFocusEditor: true,
  showOnlyDocumentationTab: false,
  showSchemaVersion: true,
  showResultStatus: true,
  showResponseHeaders: true,
  showResponseActions: true,
  showHeadersAndEnvironmentVariables: false,
  shouldShowKeyboardShortcutIcon: true,
  shouldPostMessageOperations: false,
  environmentVariablesInSettings: true,
  cannotBeEmbedded: false,
  showConnectionSettings: true,
  showThemeToggle: true,
  subscriptionsSupported: true,
  requiresConfiguredUrls: true,
  canSeePreflightScripts: true,
  canSeePostflightScripts: true,
  initialRequestQueryPlan: false,
  initialRequestConnectorsDebugging: false,
  showFileUpload: true,
};

export const ATLAS_EXPLORER_FLAGS: ExplorerBehavioralFlags = {
  roundedCorners: true,
  fullHeightEditor: true,
  showLeftPaneToggle: false,
  showExplorerSettings: false,
  showOperationCollections: false,
  showRunHistory: false,
  shouldShowCopyLink: false,
  shouldAutoFocusEditor: true,
  showOnlyDocumentationTab: true,
  showSchemaVersion: false,
  showResultStatus: false,
  showResponseHeaders: false,
  showResponseActions: false,
  showHeadersAndEnvironmentVariables: false,
  shouldShowKeyboardShortcutIcon: false,
  shouldPostMessageOperations: false,
  environmentVariablesInSettings: false,
  cannotBeEmbedded: true,
  showConnectionSettings: false,
  showThemeToggle: true,
  subscriptionsSupported: true,
  requiresConfiguredUrls: true,
  canSeePreflightScripts: true,
  canSeePostflightScripts: true,
  initialRequestQueryPlan: false,
  initialRequestConnectorsDebugging: false,
  showFileUpload: true,
};

export const EMBEDDED_EXPLORER_FLAGS = ({
  graphRef,
  showHeadersAndEnvironmentVariables,
  subscriptionsSupported,
  requiresConfiguredUrls,
  defaultTabState,
  showFileUpload,
}: {
  graphRef: GraphRef | null;
  showHeadersAndEnvironmentVariables: boolean;
  subscriptionsSupported: boolean;
  requiresConfiguredUrls: boolean;
  defaultTabState?: CollectionEntryState | CollectionEntryIdState;
  showFileUpload: boolean;
}): ExplorerBehavioralFlags => ({
  roundedCorners: false,
  fullHeightEditor: false,
  showLeftPaneToggle: true,
  showExplorerSettings: true,
  showOperationCollections: !!graphRef,
  showRunHistory: true,
  shouldShowCopyLink: !!graphRef,
  shouldAutoFocusEditor: false,
  showOnlyDocumentationTab: false,
  showSchemaVersion: true,
  showResultStatus: true,
  showResponseHeaders: true,
  showResponseActions: true,
  showHeadersAndEnvironmentVariables,
  shouldShowKeyboardShortcutIcon: true,
  cannotBeEmbedded: true,
  environmentVariablesInSettings: true,
  shouldPostMessageOperations: true,
  showConnectionSettings: false,
  showThemeToggle: false,
  subscriptionsSupported,
  requiresConfiguredUrls,
  canSeePreflightScripts: false,
  canSeePostflightScripts: false,
  overrideDefaultTabState: defaultTabState,
  initialRequestQueryPlan: false,
  initialRequestConnectorsDebugging: false,
  showFileUpload,
});

export const EMBEDDED_SANDBOX_EXPLORER_FLAGS = ({
  defaultTabState,
  initialRequestQueryPlan,
  showFileUpload,
  initialRequestConnectorsDebugging,
}: {
  defaultTabState?: CollectionEntryState | CollectionEntryIdState;
  initialRequestQueryPlan: boolean;
  showFileUpload: boolean;
  initialRequestConnectorsDebugging: boolean;
}): ExplorerBehavioralFlags => ({
  roundedCorners: false,
  fullHeightEditor: false,
  showLeftPaneToggle: true,
  showExplorerSettings: true,
  showOperationCollections: true,
  showRunHistory: true,
  shouldShowCopyLink: true,
  shouldAutoFocusEditor: false,
  showOnlyDocumentationTab: false,
  showSchemaVersion: true,
  showResultStatus: true,
  showResponseHeaders: true,
  showResponseActions: true,
  showHeadersAndEnvironmentVariables: true,
  shouldShowKeyboardShortcutIcon: true,
  cannotBeEmbedded: true,
  environmentVariablesInSettings: true,
  shouldPostMessageOperations: true,
  showConnectionSettings: true,
  showThemeToggle: true,
  subscriptionsSupported: true,
  requiresConfiguredUrls: true,
  canSeePreflightScripts: false,
  canSeePostflightScripts: false,
  overrideDefaultTabState: defaultTabState,
  initialRequestQueryPlan,
  showFileUpload,
  initialRequestConnectorsDebugging,
});

export interface ExplorerBehavioralFlags {
  roundedCorners: boolean;
  fullHeightEditor: boolean;
  showOnlyDocumentationTab: boolean;
  showSchemaVersion: boolean;
  showRunHistory: boolean;
  showExplorerSettings: boolean;
  showOperationCollections: boolean;
  showLeftPaneToggle: boolean;
  shouldShowCopyLink: boolean;
  shouldAutoFocusEditor: boolean;
  showResultStatus: boolean;
  showResponseHeaders: boolean;
  showResponseActions: boolean;
  showHeadersAndEnvironmentVariables: boolean;
  showConnectionSettings: boolean;
  shouldShowKeyboardShortcutIcon: boolean;
  shouldPostMessageOperations: boolean;
  environmentVariablesInSettings: boolean;
  overrideDefaultTabState?: CollectionEntryState | CollectionEntryIdState;
  cannotBeEmbedded: boolean;
  showThemeToggle: boolean;
  subscriptionsSupported: boolean;
  // Allow Embedded Explorer from dev tools to send subscriptions without an endpoint/subscription url
  requiresConfiguredUrls: boolean;
  canSeePreflightScripts: boolean;
  canSeePostflightScripts: boolean;
  initialRequestQueryPlan: boolean;
  showFileUpload: boolean;
  initialRequestConnectorsDebugging: boolean;
}
