import {
  MenuButton as ChakraMenuButton,
  MenuButtonProps as ChakraMenuButtonProps,
  forwardRef,
  useMenuStyles,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type MenuButtonProps = OmitChakraProps<ChakraMenuButtonProps>;

export const MenuButton = forwardRef<MenuButtonProps, 'button'>(
  (props, ref) => {
    const { buttonOverride } = useMenuStyles();

    // check for `as` to know when to use the style overrides due to some internal handling by chakra
    return (
      <ChakraMenuButton
        {...props}
        ref={ref}
        sx={props.as ? buttonOverride : undefined}
      />
    );
  },
);
