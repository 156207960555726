import { useCurrentPlan } from './currentPlanV2Migration';
import { useCheckPlanTypes } from './useCheckPlanTypes';
import { useCurrentAccountId } from './useCurrentAccountId';

export const useShouldSeeSupergraphAccountView = () => {
  const [currentAccountId] = useCurrentAccountId();
  const currentPlanResult = useCurrentPlan({
    skip: !currentAccountId,
    accountId: currentAccountId as string,
  });
  const { isUsageBasedPlan, isDedicatedPlan } = useCheckPlanTypes({
    tier: currentPlanResult?.currentPlan?.tier,
    kind: currentPlanResult?.currentPlan?.kind,
  });

  return {
    shouldSeeSupergraphAccountView: isUsageBasedPlan || isDedicatedPlan,
    loading: currentPlanResult?.loading,
  };
};
