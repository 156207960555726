/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';

import { isEmbeddableExplorerRoute } from 'src/app/embeddableExplorer/isEmbeddableExplorerRoute';
import { login as loginRoute } from 'src/app/onboarding/routes/routes';
import { useIdentity } from 'src/hooks/useIdentity';
import { useIsCheckedRoute } from 'src/hooks/useIsCheckedRoute';
import { shouldOptOutOfAmplitude } from 'src/lib/analytics/amplitude/helpers/shouldOptOutOfAmplitude';
import { ampli } from 'src/lib/analytics/amplitude/vendor';
import { Config } from 'src/lib/config/config';
import { useLDFlag } from 'src/lib/launchDarkly';

type amplitudeFlagConfig = {
  trackingEnabled: boolean;
  trackEmbeddedUsers: boolean;
};

type ReactChildren = {
  children: React.ReactElement;
};

const useAmplitudeConfig = (): amplitudeFlagConfig => {
  const trackingEnabled = useLDFlag('studio-amplitude-tracking-enabled');

  const trackEmbeddedUsers = useLDFlag('betelgeuse-amplitude-in-embedded');

  return {
    trackingEnabled,
    trackEmbeddedUsers,
  };
};

export const AmplitudeProvider = ({ children }: ReactChildren) => {
  const { me, isUser, myAccount } = useIdentity();
  const amplitudeConfiguration = useAmplitudeConfig();
  const excludeRouteFromTracking =
    useIsCheckedRoute(loginRoute.definition) || isEmbeddableExplorerRoute();

  useEffect(() => {
    const shouldOptOut = shouldOptOutOfAmplitude({
      excludeRouteFromTracking,
      amplitudeConfiguration,
    });
    ampli.client.setOptOut(shouldOptOut);
  }, [excludeRouteFromTracking, amplitudeConfiguration]);

  useEffect(() => {
    // Call ampli.identify when `me` or `isUser` is updated
    if (me && isUser && ampli.isLoaded) {
      ampli.identify(
        me.id,
        {
          'Active Org Id': myAccount?.internalID,
          'Email Verified': me.emailVerified,
          Email: me?.email || undefined,
          'Full Name': me.fullName,
        },
        {
          app_version: Config.gitCommitHash,
          user_id: me.id,
        },
      );
    }
  }, [me, isUser, myAccount?.internalID]);

  return children;
};
