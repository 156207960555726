import { colors } from '@apollo/brand';
import confetti from 'canvas-confetti';

export const CONFETTI_CONFIG = {
  startVelocity: 45,
  colors: [
    colors.tokens.code.a.base,
    colors.tokens.code.b.base,
    colors.tokens.code.c.base,
    colors.tokens.code.d.base,
    colors.tokens.code.e.base,
    colors.tokens.code.f.base,
    colors.tokens.code.g.base,
  ],
};

export const triggerFullPageConfetti = () =>
  Promise.all(
    [0, 0.25, 0.5, 0.75, 1].map((xOrigin) =>
      confetti({
        ...CONFETTI_CONFIG,
        spread: 180,
        particleCount: 400,
        gravity: 1.7,
        disableForReducedMotion: true,
        origin: {
          x: xOrigin,
          y: 0,
        },
        ticks: 300,
      }),
    ),
  );
