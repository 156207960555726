import { ComponentStyleConfig } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';
import { field, sizes } from '../Input/Input.style';

export const SelectStyles: ComponentStyleConfig = {
  parts: ['field', 'icon'],
  baseStyle: {
    field,
    icon: {
      color: customColors.text.primary,
      marginLeft: `0.75rem`,
      zIndex: 10,
      _disabled: {
        color: customColors.text.disabled,
      },
    },
  },
  sizes: {
    /**
     * Unsetting `height` to help center the text vertically in the Select
     */
    xs: {
      ...sizes.xs,
      field: {
        ...sizes.xs.field,
        height: '24px',
      },
    },
    sm: {
      ...sizes.sm,
      field: {
        ...sizes.sm.field,
        height: '32px',
      },
    },
    md: {
      ...sizes.md,
      field: {
        ...sizes.md.field,
        height: '40px',
      },
    },
    lg: {
      ...sizes.xs,
      field: {
        ...sizes.lg.field,
        height: '48px',
      },
    },
  },
  variants: {
    outline: {
      field: {
        ...field,
        background: customColors.bg.input,
      },
    },
    hidden: {
      field: {
        ...field,
        borderColor: 'transparent',
        background: 'transparent',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
};
