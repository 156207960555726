import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const { border, text } = customColors;

const baseStyle = definePartsStyle({
  tabpanel: {
    padding: false,
  },
});

const variantLine = definePartsStyle(({ orientation }) => {
  const isVertical = orientation === 'vertical';
  const borderProp = isVertical ? 'borderStart' : 'borderBottom';
  const marginProp = isVertical ? 'marginStart' : 'marginBottom';

  return {
    tablist: {
      [borderProp]: '1px solid',
      borderColor: border.primary,
      gap: isVertical ? 2 : 6,
    },
    tab: {
      color: text.secondary,
      bg: false,
      [borderProp]: '4px solid',
      [marginProp]: '-1px',
      _selected: {
        color: text.primary,
        borderColor: border.tab,
        fontWeight: 'semibold',
      },
      _hover: {
        textDecoration: isVertical ? 'underline' : false,
      },
      _focus: {
        fontWeight: 'semibold',
        outlineColor: border.focused,
        outlineWidth: '2px',
        outlineOffset: '0',
        rounded: 'sm',
      },
    },
  };
});

const sizes = {
  md: definePartsStyle(({ orientation }) => {
    const isVertical = orientation === 'vertical';

    return {
      tab: {
        py: isVertical ? 1 : 2,
        px: isVertical ? 4 : 0,
      },
    };
  }),
};

export const TabsStyles = defineMultiStyleConfig({
  baseStyle,
  variants: { line: variantLine },
  sizes,
});
