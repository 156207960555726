import { MutationTuple, gql, useMutation } from '@apollo/client';

import { GraphQLTypes } from 'src/lib/graphqlTypes';

const updateBillingContactEmailMutation = gql<
  GraphQLTypes.UpdateBillingContactEmail,
  GraphQLTypes.UpdateBillingContactEmailVariables
>`
  mutation UpdateBillingContactEmail($accountId: ID!, $email: String!) {
    account(id: $accountId) {
      updateEmail(email: $email)
    }
  }
`;

interface Props {
  errorHandler?: (errorMsg: string) => void;
}

type Results = MutationTuple<
  GraphQLTypes.UpdateBillingContactEmail,
  GraphQLTypes.UpdateBillingContactEmailVariables
>;

export const useUpdateBillingContactEmailMutation = ({
  errorHandler,
}: Props): Results => {
  const [updateBillingContactEmail, { error, ...rest }] = useMutation<
    GraphQLTypes.UpdateBillingContactEmail,
    GraphQLTypes.UpdateBillingContactEmailVariables
  >(updateBillingContactEmailMutation, {
    onError() {
      if (error?.message && errorHandler) {
        errorHandler(error.message);
      }
    },
  });

  return [updateBillingContactEmail, { error, ...rest }];
};
