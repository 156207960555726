import { useLocation } from 'react-router';

import Config from 'src/lib/config';
import { setOverlayVisibleLocation } from 'src/lib/routing';

const {
  modals: { newOrganization },
} = Config;

export const useOrgCreationLocation = () => {
  const location = useLocation();

  return setOverlayVisibleLocation({
    location,
    overlayName: newOrganization,
  });
};
