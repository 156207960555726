/* eslint-disable tailwindcss/no-custom-classname */

import {
  BadgeProps as ChakraBadgeProps,
  chakra,
  forwardRef,
  omitThemingProps,
  useMultiStyleConfig,
} from '@chakra-ui/react'; // use absolute path to override webpack alias
import classnames from 'classnames';
import React, { ReactElement } from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export interface BadgeProps
  extends OmitChakraProps<ChakraBadgeProps, 'variant' | 'children'> {
  /**
   * Badge style
   */
  variant?:
    | 'brandedDark'
    | 'brandedLight'
    | 'neutral'
    | 'neutralOutlined'
    | 'info'
    | 'success'
    | 'warn'
    | 'error'
    | 'beta'
    | 'branded'
    | 'yellow'
    | 'indigo'
    | 'green'
    | 'purple'
    | 'pink';
  /**
   * Icon will be shown on the left of the badge. Icon size should be 'small'.
   */
  icon?: React.ReactElement;
  /**
   * Text displayed on badge
   */
  children: React.ReactNode;
}

export const Badge = forwardRef<BadgeProps, 'span'>(
  ({ icon, ...props }, ref) => {
    const styles = useMultiStyleConfig('Badge', props);
    const { className, children, ...rest } = omitThemingProps(props);

    return (
      <chakra.span
        ref={ref}
        className={classnames('orbit-badge', className)}
        {...rest}
        sx={styles.container}
      >
        <BadgeIcon icon={icon} variant={props.variant} />
        {children}
      </chakra.span>
    );
  },
);

interface BadgeIconProps {
  icon?: ReactElement;
  variant: BadgeProps['variant'];
}

const BadgeIcon = ({ icon, variant }: BadgeIconProps) => {
  const styles = useMultiStyleConfig('Badge', {
    variant,
  });

  return icon ? <chakra.span __css={styles.icon}>{icon}</chakra.span> : null;
};
