import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';
import {
  $iconColor as $buttonIconColor,
  $iconSize as $buttonIconSize,
} from '../Button/Button.style';

// Parts: title, description, container, icon, spinner
const customKeys = ['content' as const, 'closeButton' as const];

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers([...alertAnatomy.keys, ...customKeys]);

// Handling custom status styles based on discussion
// https://github.com/chakra-ui/chakra-ui/issues/7366
const INFO_TARGET = '[data-status="info"]';
const ERROR_TARGET = '[data-status="error"]';
const SUCCESS_TARGET = '[data-status="success"]';
const WARNING_TARGET = '[data-status="warning"]';
const NEUTRAL_TARGET = '[data-status="neutral"]';
const BETA_TARGET = '[data-status="beta"]';

const closeButton = defineStyle({
  alignSelf: 'start',
  [$buttonIconColor.variable]: 'currentColor',
  [$buttonIconSize.variable]: '1rem',
  [`${INFO_TARGET} &`]: {
    color: customColors.icon.info,
  },
  [`${ERROR_TARGET} &`]: {
    color: customColors.icon.error,
  },
  [`${WARNING_TARGET} &`]: {
    color: customColors.icon.warning,
  },
  [`${BETA_TARGET} &`]: {
    color: customColors.icon.beta,
  },
  _hover: {
    [`${INFO_TARGET} &`]: {
      background: customColors.bg['info-hover'],
      color: customColors.icon.inverted,
    },
    [`${ERROR_TARGET} &`]: {
      background: customColors.bg['error-hover'],
      color: customColors.icon.inverted,
    },
    [`${WARNING_TARGET} &`]: {
      background: customColors.bg['warning-hover'],
      color: customColors.icon.inverted,
    },
    [`${BETA_TARGET} &`]: {
      background: customColors.bg['beta-hover'],
      color: customColors.icon.inverted,
    },
  },
});

const content = defineStyle({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'start',
  gap: '0.5rem',
  flex: 1,
});

const container = defineStyle({
  display: 'flex',
  py: '3',
  px: '4',
  alignItems: 'center',
  gap: '2',
  rounded: 'base',
  position: false,

  [`&${INFO_TARGET}`]: {
    backgroundColor: customColors.bg.info,
    borderColor: customColors.border.info,
  },
  [`&${ERROR_TARGET}`]: {
    backgroundColor: customColors.bg.error,
    borderColor: customColors.border.error,
  },
  [`&${SUCCESS_TARGET}`]: {
    backgroundColor: customColors.bg.success,
    borderColor: customColors.border.success,
  },
  [`&${WARNING_TARGET}`]: {
    backgroundColor: customColors.bg.warning,
    borderColor: customColors.border.warning,
  },
  [`&${NEUTRAL_TARGET}`]: {
    backgroundColor: customColors.bg.neutral,
    borderColor: customColors.border.neutral,
  },
  [`&${BETA_TARGET}`]: {
    backgroundColor: customColors.bg.beta,
    borderColor: customColors.border.beta,
  },
});

const icon = defineStyle({
  fill: 'currentColor',
  height: '1.5rem',
  width: '1.5rem',
  marginInlineEnd: 'unset',
  [`${INFO_TARGET} &`]: {
    color: customColors.icon.info,
  },
  [`${ERROR_TARGET} &`]: {
    color: customColors.icon.error,
  },
  [`${SUCCESS_TARGET} &`]: {
    color: customColors.icon.success,
  },
  [`${WARNING_TARGET} &`]: {
    color: customColors.icon.warning,
  },
  [`${NEUTRAL_TARGET} &`]: {
    color: customColors.icon.primary,
  },
  [`${BETA_TARGET} &`]: {
    color: customColors.icon.beta,
  },
});

const description = defineStyle({
  fontFamily: 'var(--font-body)',
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.5rem',
  alignSelf: 'stretch',
  [`${INFO_TARGET} &`]: {
    color: customColors.text.info,
  },
  [`${ERROR_TARGET} &`]: {
    color: customColors.text.error,
  },
  [`${SUCCESS_TARGET} &`]: {
    color: customColors.text.success,
  },
  [`${WARNING_TARGET} &`]: {
    color: customColors.text.warning,
  },
  [`${NEUTRAL_TARGET} &`]: {
    color: customColors.text.primary,
  },
  [`${BETA_TARGET} &`]: {
    color: customColors.text.beta,
  },
});

const baseStyle = definePartsStyle({
  container,
  content,
  description,
  icon,
  closeButton,
});

const tile = definePartsStyle({
  container: {
    borderLeft: '4px solid',
  },
});

const banner = definePartsStyle({
  container: {
    justifyContent: 'space-between',
    rounded: 'none',
  },
  content,
});

const accordion = tile;

export const AlertStyles = defineMultiStyleConfig({
  baseStyle,
  variants: {
    tile,
    banner,
    accordion,
  },
  defaultProps: {
    variant: 'tile',
  },
});
