import {
  CardBody as ChakraCardBody,
  CardBodyProps as ChakraCardBodyProps,
  SystemStyleObject,
  forwardRef,
  useCardStyles,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type CardBodyProps = OmitChakraProps<ChakraCardBodyProps> & {
  __orbitStyleConfig?: SystemStyleObject;
};

export const CardBody = forwardRef<CardBodyProps, 'div'>((props, ref) => {
  const styles = useCardStyles();
  return (
    <ChakraCardBody
      {...props}
      ref={ref}
      __css={{ ...styles.body, ...props.__orbitStyleConfig }}
    />
  );
});
