import { ApolloError, gql, useQuery } from '@apollo/client';
import moment from 'moment';

import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

const usageQuery = gql<
  GraphQLTypes.UsageQuery,
  GraphQLTypes.UsageQueryVariables
>`
  query UsageQuery($accountId: ID!) {
    account(id: $accountId) {
      id
      internalID
      total: operationUsage {
        selfHosted {
          stats {
            value
          }
          planThreshold
        }
        serverless {
          stats {
            value
          }
          planThreshold
        }
      }
      currentSubscription {
        id: uuid
        state
        currentPeriodStartedAt
        currentPeriodEndsAt
        currentDraftInvoice {
          billingPeriodEndsAt
          billingPeriodStartsAt
          expectedInvoiceAt
          lineItems {
            groupKey
            groupValue
            name
            totalInCents
            quantity
          }
          subtotalInCents
          totalInCents
        }
      }
    }
  }
`;

interface Args extends GraphQLTypes.UsageQueryVariables {
  skip?: boolean;
}

interface Results
  extends GraphQLTypes.UsageQuery_account_currentSubscription_currentDraftInvoice {
  currentMonth: string;
  loading: boolean;
  error: ApolloError | undefined;
  internalID: string | undefined;
  total: GraphQLTypes.UsageQuery_account_total | undefined;
  hasActiveSubscription: boolean;
  hasCurrentSubscriptionStarted: boolean;
}

export const useMonthlyOperationDetails = ({
  accountId,
  skip = false,
}: Args): Results => {
  const {
    data = {} as GraphQLTypes.UsageQuery,
    loading,
    error,
  } = useQuery<GraphQLTypes.UsageQuery, GraphQLTypes.UsageQueryVariables>(
    usageQuery,
    {
      variables: {
        accountId,
      },
      skip,
      errorPolicy: 'all',
      context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
    },
  );

  const { account = {} as GraphQLTypes.UsageQuery_account } = data;
  const {
    currentDraftInvoice = {
      __typename: 'DraftInvoice',
      billingPeriodEndsAt: '',
      billingPeriodStartsAt: '',
      expectedInvoiceAt: '',
      lineItems: [],
      subtotalInCents: 0,
      totalInCents: 0,
    } as GraphQLTypes.UsageQuery_account_currentSubscription_currentDraftInvoice,
    state,
    currentPeriodStartedAt,
    currentPeriodEndsAt,
  } = account?.currentSubscription || {};
  const today = new Date();
  const hasCurrentSubscriptionStarted = !!(
    currentPeriodStartedAt &&
    new Date(new Date(today.toDateString()).toUTCString()) >=
      new Date(currentPeriodStartedAt)
  );

  // Default to the draft invoice start date if set otherwise default to current subscription start
  const billingPeriodStartsAt =
    (hasCurrentSubscriptionStarted
      ? currentPeriodStartedAt
      : currentDraftInvoice?.billingPeriodStartsAt) || '';

  const billingPeriodEndsAt =
    (hasCurrentSubscriptionStarted
      ? currentPeriodEndsAt
      : currentDraftInvoice?.billingPeriodEndsAt) || '';
  return {
    currentMonth: today.toLocaleString('default', { month: 'long' }),
    loading,
    error,
    internalID: account?.internalID ?? '',
    total: account?.total,
    ...(currentDraftInvoice as GraphQLTypes.UsageQuery_account_currentSubscription_currentDraftInvoice),
    totalInCents: currentDraftInvoice?.totalInCents || 0,
    // billingPeriodEndsAt is not inclusive, so adjusting it by 1 day to capture only included date range
    billingPeriodEndsAt: billingPeriodEndsAt
      ? moment(billingPeriodEndsAt).subtract(1, 'day').toISOString()
      : '',
    hasActiveSubscription: state === GraphQLTypes.SubscriptionState.ACTIVE,
    billingPeriodStartsAt,
    hasCurrentSubscriptionStarted,
  };
};
