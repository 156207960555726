import IconLink from '@apollo/icons/default/IconLink.svg';
import { Input } from '@apollo/orbit';
import React, { ComponentProps, useCallback } from 'react';

import { isLocalUrl } from 'src/lib/isLocalUrl';

const PROTOCOLS = {
  standard: {
    insecure: 'http',
    secure: 'https',
  },
  websocket: {
    insecure: 'ws',
    secure: 'wss',
  },
} as const;
const PROTOCOL_DELIMITER = '://';

export const URLField = ({
  isSocket,
  value,
  size,
  inputRightElement,
  /**
   * Do not pass an inline function for onChange into `URLField`.
   * It will freeze the app. Instead, you should pass a memoized handler.
   */
  onChange: parentOnChange,
  ...props
}: Omit<ComponentProps<typeof Input>, 'onChange' | 'type'> & {
  value?: string;
  onChange: (url: string) => void;
  isSocket?: boolean;
  inputRightElement?: React.ReactNode;
}) => {
  const defaultProtocols = PROTOCOLS[isSocket ? 'websocket' : 'standard'];
  const [isTrackingDefaultProtocol, setIsTrackingDefaultProtocol] =
    React.useState(true);

  // Keep the current value fully up to date using a ref
  const currentValueRef = React.useRef(value);
  const originalValueRef = React.useRef(value);
  const onChange = React.useCallback(
    (newValue: string) => {
      currentValueRef.current = newValue;
      parentOnChange(newValue);
    },
    [parentOnChange],
  );

  const handleChange = useCallback(
    (newValue: string) => {
      const previousValue = currentValueRef.current;

      const defaultProtocol =
        defaultProtocols[isLocalUrl(newValue) ? 'insecure' : 'secure'];
      const oldProtocol = previousValue?.includes(PROTOCOL_DELIMITER)
        ? previousValue.split(PROTOCOL_DELIMITER)[0]
        : undefined;
      const newProtocol = newValue.includes(PROTOCOL_DELIMITER)
        ? newValue.split(PROTOCOL_DELIMITER)[0]
        : undefined;
      const hasProtocolChanged = newProtocol !== oldProtocol;

      // If the user just pasted something with a protocol in,
      // never don't try to edit their protocol
      if (originalValueRef.current === previousValue && !!newProtocol) {
        onChange(newValue);
        setIsTrackingDefaultProtocol(false);
        return;
      }

      // Either
      // - Protocol already matches default
      // - Protocol is currently being typed
      //
      // No changes to make here
      if (
        newProtocol === defaultProtocol ||
        [defaultProtocols.insecure, defaultProtocols.secure].some((protocol) =>
          `${protocol}${PROTOCOL_DELIMITER}`.startsWith(newValue),
        )
      ) {
        onChange(newValue);
        setIsTrackingDefaultProtocol(true);
        return;
      }

      // Protocol does not match default, but it was currently unset, add the
      // protocol in the url and start tracking default
      if (newProtocol === undefined) {
        onChange(`${defaultProtocol}${PROTOCOL_DELIMITER}${newValue}`);
        setIsTrackingDefaultProtocol(true);
        return;
      }

      if (!isTrackingDefaultProtocol) {
        onChange(newValue);
        return;
      }

      // Protocol does not match default, but it was not changed, update the
      // protocol in the url
      if (!hasProtocolChanged || newProtocol === undefined) {
        onChange(newValue.replace(newProtocol, defaultProtocol));
        return;
      }

      // Protocol does not match default and was changed, stop tracking default
      onChange(newValue);
      setIsTrackingDefaultProtocol(false);
    },
    [onChange, defaultProtocols, isTrackingDefaultProtocol],
  );

  return (
    <Input
      {...props}
      value={value}
      type="url"
      onChange={(event) => handleChange(event.target.value)}
      leftElement={<IconLink />}
      rightElement={inputRightElement}
    />
  );
};
