import { gql, useQuery } from '@apollo/client';
import IconAstronaut3 from '@apollo/icons/default/IconAstronaut3.svg';
import IconBook from '@apollo/icons/default/IconBook.svg';
import IconDocument from '@apollo/icons/default/IconDocument.svg';
import IconFilter from '@apollo/icons/default/IconFilter.svg';
import IconHeadset from '@apollo/icons/default/IconHeadset.svg';
import IconHelp from '@apollo/icons/default/IconHelp.svg';
import IconLock from '@apollo/icons/default/IconLock.svg';
import IconLogout from '@apollo/icons/default/IconLogout.svg';
import IconMegaphone from '@apollo/icons/default/IconMegaphone.svg';
import IconMetrics from '@apollo/icons/default/IconMetrics.svg';
import IconPlanet4 from '@apollo/icons/default/IconPlanet4.svg';
import IconSandbox from '@apollo/icons/default/IconSandbox.svg';
import IconSupport from '@apollo/icons/default/IconSupport.svg';
import {
  Avatar,
  Badge,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuContent,
  MenuItem,
  MenuList,
  Spinner,
} from '@apollo/orbit';
import { ApolloIcon } from '@apollo/space-kit/icons/ApolloIcon';
import { ApolloLogo } from '@apollo/space-kit/icons/ApolloLogo';
import classnames from 'classnames';
import React from 'react';
import { Link, matchPath, useLocation, useRouteMatch } from 'react-router-dom';

import * as orgRoutes from 'src/app/account/routes';
import { InternalGlobalThemeToggle } from 'src/app/appLayout/components/globalHeader/internalGlobalThemeToggle/internalGlobalThemeToggle';
import { isEmbeddableExplorerRoute } from 'src/app/embeddableExplorer/isEmbeddableExplorerRoute';
import { isEmbeddableSandboxRoute } from 'src/app/embeddableSandbox/isEmbeddableSandboxRoute';
import { MaybeEmbeddedLoginLink } from 'src/app/embedHelpers/MaybeEmbedLoginLink';
import { OutlinkForEmbed } from 'src/app/embedHelpers/OutlinkForEmbed';
import { graphRefToString, useGraphRef } from 'src/app/graph/hooks/useGraphRef';
import { useIsLoggedIn } from 'src/app/graph/hooks/useIsLoggedIn';
import {
  atlasExplorerRouteConfig,
  sandboxExplorerRouteConfig,
  sandboxGraphRouteConfig,
} from 'src/app/graph/routes';
import {
  embedLogoutRouteConfig,
  logoutRouteConfig,
} from 'src/app/onboarding/routes';
import { Tooltip } from 'src/components/common/tooltip/Tooltip';
import { useGetContactSupportOverlayLocation } from 'src/components/ContactSupportCTAs';
import {
  ThemeName,
  ThemeProvider,
} from 'src/components/themeProvider/ThemeProvider';
import { useAccountHasSupergraphs } from 'src/hooks/useAccountHasSupergraphs';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { useIsAppOfflineContext } from 'src/hooks/useIsAppOffline';
import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import Config from 'src/lib/config';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { useLDFlag } from 'src/lib/launchDarkly';
import { notProduction } from 'src/lib/notProduction';
import { DocsRouter } from 'src/lib/routers';
import { mergeQueryParams } from 'src/lib/routing';
import { useNewWorkerVersionAvailable } from 'src/serviceWorkerSetup/serviceWorkerReactiveVariables';
import { triggerWorkerUpdateAndReload } from 'src/serviceWorkerSetup/serviceWorkerRegistration';

import { ChangelogModalTrigger } from '../changelogModalTrigger/ChangelogModalTrigger';

import { OwnersContactMenu } from './OwnersContactMenu';
import { SudoToggle } from './sudoToggle/SudoToggle';

const globalHeaderQuery = gql<
  GraphQLTypes.GlobalHeaderQuery,
  GraphQLTypes.GlobalHeaderQueryVariables
>`
  query GlobalHeaderQuery {
    me {
      id
      ... on User {
        avatarUrl
        fullName
        internalAdminRole
      }
    }
  }
`;

/**
 * Component that renders the user menu items
 */
const AvatarMenuContent = ({
  newVersionAvailable,
  isLoggedIn,
  isOnEmptySupergraphPage,
  isInSandbox,
}: {
  newVersionAvailable: boolean;
  isLoggedIn: boolean;
  isOnEmptySupergraphPage: boolean;
  isInSandbox: boolean;
}) => {
  const graphRef = useGraphRef();
  return (
    <MenuContent>
      <MenuList>
        {newVersionAvailable && (
          <MenuItem onClick={triggerWorkerUpdateAndReload}>
            Update available, click to relaunch
          </MenuItem>
        )}
        {notProduction && (
          <MenuItem
            as={OutlinkForEmbed}
            to={atlasExplorerRouteConfig.location({})}
            icon={<IconBook />}
            rightIcon={<Badge variant="beta">Preview</Badge>}
          >
            Graph Directory
          </MenuItem>
        )}
        <MenuItem
          as={OutlinkForEmbed}
          to={orgRoutes.userSettingsRouteConfig.location({})}
          icon={<IconAstronaut3 />}
        >
          Personal Settings
        </MenuItem>
        <MenuItem
          as={OutlinkForEmbed}
          to={orgRoutes.userSettingsRouteConfig.location({
            hash: '#preview-features',
          })}
          icon={<IconFilter />}
        >
          Preview features
        </MenuItem>
        {
          // if a user is not logged in, they should only see the log in CTA
          // if a user is on the embedded explorer, they shouldn't see sandbox
          isLoggedIn && !isEmbeddableExplorerRoute() && (
            <MenuItem
              {...(isOnEmptySupergraphPage && {
                'data-analytics-label':
                  'Go to Sandbox click on Empty Supergraph Page',
                'data-analytics-category': '6M2S: Onboarding',
              })}
              as={OutlinkForEmbed}
              to={isInSandbox ? '/' : sandboxExplorerRouteConfig.location({})}
              icon={<IconSandbox />}
              className="flex sm:hidden"
            >
              Sandbox
            </MenuItem>
          )
        }
        <MenuItem
          as={Link}
          to={
            isEmbeddableExplorerRoute() || isEmbeddableSandboxRoute()
              ? embedLogoutRouteConfig.location({
                  from: `${window.location.pathname}${window.location.search}`,
                  graphRef: graphRefToString(graphRef) ?? undefined,
                })
              : logoutRouteConfig.location({})
          }
          icon={<IconLogout />}
        >
          Logout
        </MenuItem>
      </MenuList>
    </MenuContent>
  );
};

/**
 * Component that renders the support menu items
 */
const SupportMenuContent = ({
  isAuthenticated,
  location,
}: {
  isAuthenticated: boolean;
  location: ReturnType<typeof useLocation>;
}) => {
  const contactSupportOverlayLocation = useGetContactSupportOverlayLocation();
  return (
    <MenuContent>
      <MenuList>
        <MenuItem
          icon={<IconSupport />}
          as="a"
          href="https://support.apollographql.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Help Center
        </MenuItem>

        {isAuthenticated && (
          <MenuItem
            icon={<IconHeadset />}
            as={Link}
            to={contactSupportOverlayLocation}
          >
            Contact Support
          </MenuItem>
        )}

        <MenuItem
          as="a"
          href="https://www.apollographql.com/trust/"
          rel="noopener noreferrer"
          target="_blank"
          icon={<IconLock />}
        >
          Apollo Trust and Security
        </MenuItem>

        <MenuItem
          as="a"
          href="https://community.apollographql.com"
          rel="noopener noreferrer"
          target="_blank"
          icon={<IconPlanet4 />}
        >
          Community Forum
        </MenuItem>

        <MenuItem
          as="a"
          href={DocsRouter.path('StudioHome')}
          rel="noopener noreferrer"
          target="_blank"
          icon={<IconDocument />}
        >
          Documentation
        </MenuItem>

        <MenuItem
          icon={<IconMetrics />}
          as="a"
          href="https://status.apollographql.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          Status Report
        </MenuItem>
        <MenuItem
          as={Link}
          className="p-0 sm:hidden"
          to={{
            ...location,
            search: mergeQueryParams(location.search, {
              [Config.queryParameters.Overlay]: Config.modals.appChangelog,
            }),
          }}
          aria-label="What's new in Studio"
          icon={<IconMegaphone />}
        >
          What's new in Studio
        </MenuItem>
      </MenuList>
    </MenuContent>
  );
};

export const GlobalHeader = ({
  children,
  goHomeLink,
  leftNavContext,
}: {
  children?: React.ReactNode;
  leftNavContext?: 'collapsed' | 'expanded' | null;
  goHomeLink?: string;
}) => {
  const isOffline = useIsAppOfflineContext();
  const location = useLocation();
  const allowDarkMode = useLDFlag('studio-dark-mode');

  // We don't capture `error` because there's nothing we can do about it if we
  // run into one
  const { data, loading } = useQuery(globalHeaderQuery, {
    context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
  });

  const { isLoggedIn } = useIsLoggedIn();
  const isInSandbox = matchPath(
    location.pathname,
    sandboxGraphRouteConfig.definition,
  );

  const [currentAccountId] = useCurrentAccountId();
  const { hasSupergraphs } = useAccountHasSupergraphs({
    accountId: currentAccountId ?? undefined,
  });

  const isOnEmptySupergraphPage =
    !!useRouteMatch(orgRoutes.graphs.definition) && !hasSupergraphs;

  const newWorkerVersionAvailable = useNewWorkerVersionAvailable();

  return (
    <ThemeProvider overrideThemeName={ThemeName.DARK}>
      <header
        className="z-50 flex h-12 items-center gap-2 border-b border-primary bg-secondary pr-2 text-secondary transition-background"
        aria-label="Global header"
      >
        <Tooltip placement="bottom" label="Go to graph index">
          <OutlinkForEmbed
            to={goHomeLink ?? '/'}
            className={classnames(
              'flex h-full flex-none items-center px-2 text-primary',
              !!leftNavContext && 'border-r border-primary',
            )}
          >
            {leftNavContext === 'collapsed' ? (
              <ApolloLogo className="mx-[5px] size-8 [&_path:last-of-type]:hover:origin-center [&_path:last-of-type]:hover:animate-custom-spin" />
            ) : leftNavContext === 'expanded' ? (
              <ApolloIcon className="mr-4 h-12 w-28 px-2" />
            ) : (
              <ApolloIcon className="h-12 w-28 px-2" />
            )}
          </OutlinkForEmbed>
        </Tooltip>
        {children}
        <div className="flex-1" />
        {data?.me?.__typename === 'User' &&
          data.me.internalAdminRole &&
          allowDarkMode && <InternalGlobalThemeToggle />}
        {data?.me?.__typename === 'User' && data.me.internalAdminRole && (
          <>
            <OwnersContactMenu />
            <SudoToggle adminRole={data.me.internalAdminRole} />
          </>
        )}
        {
          // if a user is not logged in, they should only see the log in CTA
          // if a user is on the embedded explorer, they shouldn't see sandbox
          isLoggedIn && !isEmbeddableExplorerRoute() && (
            <Tooltip label={isInSandbox ? 'Leave Sandbox' : 'Open Sandbox'}>
              <IconButton
                {...(isOnEmptySupergraphPage && {
                  'data-analytics-label':
                    'Go to Sandbox click on Empty Supergraph Page',
                  'data-analytics-category': '6M2S: Onboarding',
                })}
                className="hidden sm:inline-flex"
                variant={isInSandbox ? 'primary' : 'hidden'}
                size="sm"
                icon={<IconSandbox className="size-4" />}
                as={OutlinkForEmbed}
                to={
                  isInSandbox
                    ? '/'
                    : sandboxExplorerRouteConfig.location({}) || '/'
                }
                aria-label={isInSandbox ? 'Leave Sandbox' : 'Open Sandbox'}
              />
            </Tooltip>
          )
        }
        <ChangelogModalTrigger
          className="hidden sm:inline-flex"
          newVersionAvailable={newWorkerVersionAvailable}
        />
        <Menu placement="bottom-end" isLazy>
          <MenuButton
            as={IconButton}
            aria-label="support menu"
            variant="hidden"
            icon={<IconHelp />}
            size="sm"
          />
          <SupportMenuContent
            isAuthenticated={data?.me?.__typename === 'User' && !!data.me}
            location={location}
          />
        </Menu>
        {loading || (data?.me?.__typename === 'User' && data.me) ? (
          <Menu placement="bottom-end" isLazy>
            <MenuButton
              aria-label="User menu settings"
              as={IconButton}
              variant="hidden"
              className="p-0"
              size="sm"
            >
              {loading ? (
                <Spinner size="tiny" />
              ) : data?.me?.__typename === 'User' ? (
                <Avatar
                  src={data.me.avatarUrl ?? undefined}
                  name={data.me.fullName}
                  size="xs"
                />
              ) : (
                <Avatar size="xs" />
              )}
            </MenuButton>

            <AvatarMenuContent
              newVersionAvailable={newWorkerVersionAvailable}
              isLoggedIn={isLoggedIn}
              isInSandbox={!!isInSandbox}
              isOnEmptySupergraphPage={isOnEmptySupergraphPage}
            />
          </Menu>
        ) : isOffline ? (
          <Button
            variant="primary"
            className="pointer-events-none"
            aria-label="Offline"
            size="sm"
          >
            Offline
          </Button>
        ) : (
          <Button
            className="min-w-0"
            variant="primary"
            as={MaybeEmbeddedLoginLink}
            userSegmentType="sandbox"
            aria-label="Log in"
            size="sm"
          >
            Log in
          </Button>
        )}
      </header>
    </ThemeProvider>
  );
};
