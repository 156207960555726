import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  display: 'flex',
  width: 'full',
  flexWrap: 'nowrap',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
  overflow: 'hidden',
  whitespace: 'nowrap',
  p: 4,
});

export const PaginationStyles = defineStyleConfig({
  baseStyle,
  variants: {},
  sizes: {},
});
