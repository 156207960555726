import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ZXCVBNResult, ZXCVBNScore } from 'zxcvbn';

import { WeakPasswordRegex } from 'src/lib/graphqlTypes/serverErrors';
import { useLDFlag } from 'src/lib/launchDarkly';

import { assertUnreachable } from '../lib/assertUnreachable';

export type ZxcvbnSignature = (password: string) => ZXCVBNResult;

interface PasswordStatus {
  isWarning: boolean;
  isSuccess: boolean;
  errorMessage?: string;
  status: React.ReactNode;
}

const checkPasswordStatus = (passwordScore: ZXCVBNScore): PasswordStatus => {
  switch (passwordScore) {
    case 0:
    case 1:
      return {
        isWarning: true,
        isSuccess: false,
        errorMessage:
          'Your password is weak and easily guessable. Try adding more words.',
        status: (
          <>
            Your password is <span className="font-semibold">weak</span> and
            easily guessable. Try adding more words.
          </>
        ),
      };
    case 2:
      return {
        isWarning: true,
        isSuccess: false,
        errorMessage:
          'Your password is fair, but could be strengthened. Try adding another word.',
        status: (
          <>
            Your password is <span className="font-semibold">fair</span>, but
            could be strengthened. Try adding another word.
          </>
        ),
      };
    case 3:
      return {
        isWarning: false,
        isSuccess: true,
        status: (
          <>
            Your password is <span className="font-semibold">good</span>. Adding
            a few more characters could make it great.
          </>
        ),
      };
    case 4:
      return {
        isWarning: false,
        isSuccess: true,
        status: (
          <>
            Your password is <span className="font-semibold">great</span>! Time
            to move on.
          </>
        ),
      };
    default:
      return assertUnreachable(passwordScore);
  }
};

type PasswordChecker = (password: string) => PasswordStatus;

export function checkServerErrorForPasswordFailure(
  serverError?: string,
): PasswordStatus {
  const passwordError = serverError?.match(WeakPasswordRegex)?.groups?.errorMsg;

  return passwordError
    ? {
        isWarning: true,
        isSuccess: false,
        errorMessage: passwordError,
        status: <>{passwordError}</>,
      }
    : {
        isWarning: false,
        isSuccess: true,
        status: null,
      };
}

const getZxcvbnPasswordChecker = (zxcvbnSignature: ZxcvbnSignature | null) => {
  return (password: string) => {
    if (password.length < 8) {
      return {
        isWarning: true,
        isSuccess: false,
        errorMessage:
          'Your password is too short and must be at least 8 characters.',
        status: (
          <>Your password is too short and must be at least 8 characters.</>
        ),
      };
    }

    if (!zxcvbnSignature) {
      return {
        isWarning: false,
        isSuccess: false,
        status: <>Calculating password strength...</>,
      };
    }

    return checkPasswordStatus(zxcvbnSignature(password).score);
  };
};

const passwordLengthChecker: PasswordChecker = (password: string) => {
  if (password.length < 8) {
    return {
      isWarning: true,
      isSuccess: false,
      errorMessage:
        'Your password is too short and must be at least 8 characters.',
      status: (
        <>Your password is too short and must be at least 8 characters.</>
      ),
    };
  }

  return {
    isWarning: false,
    isSuccess: true,
    status: null,
  };
};

/**
 * This is a wrapper around `zxcvbn` and our shared password messages. This
 * takes in a password and returns a message to display to the user and whether
 * we should display a weak password warning.
 *
 * When this is first used it will asyncronously load `zxcvbn` (since it is
 * very large), and temporarily return a 'Calculating password strength...'
 * message. It will use the `checkPasswordStatus` messages once `zxcvbn` loads.
 */
export function usePasswordChecker(): PasswordChecker {
  const [zxcvbn, setZxcvbn] = useState<{ signature: ZxcvbnSignature | null }>({
    signature: null,
  });
  const [passwordChecker, setPasswordChecker] = useState<PasswordChecker>(
    () => passwordLengthChecker,
  );

  const shouldUseFrontendCheck = useLDFlag('astro-user-password-studio-check');
  const mountedRef = useRef(true);

  const loadZxcvbn = useCallback(async () => {
    if (!mountedRef.current) return;

    setZxcvbn({
      signature: (await import(/* webpackChunkName: "zxcvbn" */ 'zxcvbn'))
        .default,
    });
  }, []);

  useEffect(() => {
    if (shouldUseFrontendCheck) {
      setPasswordChecker(() => getZxcvbnPasswordChecker(zxcvbn.signature));
    } else {
      setPasswordChecker(() => passwordLengthChecker);
      return;
    }

    if (zxcvbn.signature) {
      return;
    }

    loadZxcvbn();
    return () => {
      mountedRef.current = false;
    };
  }, [loadZxcvbn, shouldUseFrontendCheck, zxcvbn]);

  return React.useMemo(() => passwordChecker, [passwordChecker]);
}
