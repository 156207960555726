import {
  Alert as ChakraAlert,
  AlertProps as ChakraAlertProps,
  useAlertStyles,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';
import { AlertStatus } from '../Alert/shared';
import { AlertContent } from '../AlertContent/AlertContent';
import { CloseButton } from '../CloseButton/CloseButton';

export type AlertBannerStatus = Extract<
  AlertStatus,
  'info' | 'error' | 'warning' | 'beta'
>;

export interface AlertBannerProps
  extends OmitChakraProps<ChakraAlertProps, 'status' | 'title' | 'variant'> {
  children?: React.ReactNode;
  isDismissible?: boolean;
  isOpen?: boolean;
  status?: AlertBannerStatus;
  onDismiss?: AlertCloseButtonProps['onClose'];
}

/**
 * Used for full-page alerts
 */
export const AlertBanner = ({
  status = 'info',
  isDismissible = false,
  isOpen = true,
  onDismiss,
  children,
  ...rest
}: AlertBannerProps) => {
  return isOpen ? (
    <ChakraAlert
      variant="banner"
      data-status={status}
      status={undefined} // We cannot use the `status` prop since we have custom status types
      {...rest}
    >
      <AlertContent status={status}>{children}</AlertContent>
      {isDismissible && <AlertCloseButton onClose={onDismiss} />}
    </ChakraAlert>
  ) : null;
};

interface AlertCloseButtonProps {
  onClose?: () => void;
}

const AlertCloseButton = ({ onClose }: AlertCloseButtonProps) => {
  const styles = useAlertStyles();

  return (
    <CloseButton
      onClick={onClose}
      size="xs"
      __orbitStyleConfig={styles.closeButton}
    />
  );
};
