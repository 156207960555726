import { SystemStyleObject } from '@chakra-ui/react';

import { createContext } from '../../utils/contextUtils';

export const [ToastStylesProvider, useToastStyles] = createContext<
  Record<string, SystemStyleObject>
>({
  name: 'ToastStylesContext',
  hookName: 'useToastStyles',
  providerName: '<Toast />',
});
