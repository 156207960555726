import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const baseStyle = defineStyle({
  borderColor: customColors.border.primary,
  opacity: false,
});

export const DividerStyles = defineStyleConfig({ baseStyle });
