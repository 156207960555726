import {
  MenuItem as ChakraMenuItem,
  MenuItemProps as ChakraMenuItemProps,
  chakra,
  forwardRef,
  useMenuStyles,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export interface MenuItemProps extends OmitChakraProps<ChakraMenuItemProps> {
  /**
   * A short description below the item that gives further context.
   */
  description?: string;
  /**
   * Icon placed at the end of the menu item
   */
  rightIcon?: React.ReactNode;
}

/**
 * An item in the menu.
 *
 * Use `MenuItemOption` if you need a checkbox or radio type menu item.
 */
export const MenuItem = forwardRef<MenuItemProps, 'button'>(
  ({ icon, description, rightIcon, children, ...rest }, ref) => {
    const styles = useMenuStyles();

    return (
      <ChakraMenuItem ref={ref} {...rest}>
        <chakra.span __css={styles.itemContainer}>
          <chakra.span __css={styles.itemContent}>
            {!!icon && (
              <chakra.span __css={styles.itemIcon}>{icon}</chakra.span>
            )}
            <span>{children}</span>
            {!!rightIcon && (
              <chakra.span __css={styles.itemRightIcon}>
                {rightIcon}
              </chakra.span>
            )}
          </chakra.span>
          {!!description && (
            <chakra.span
              data-testid="item-description"
              __css={{
                ...styles.itemDescription,
                // overrides description's margin to 0 when icon is present
                marginLeft: icon ? '1.75rem' : 0,
              }}
            >
              {description}
            </chakra.span>
          )}
        </chakra.span>
      </ChakraMenuItem>
    );
  },
);
