import {
  MenuList as ChakraMenuList,
  MenuListProps as ChakraMenuListProps,
  Portal,
  forwardRef,
} from '@chakra-ui/react';
import classnames from 'classnames';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type MenuContentProps = OmitChakraProps<ChakraMenuListProps>;

/**
 * The wrapper component for any menu content.  Must be used with `Menu`
 */
export const MenuContent = forwardRef<MenuContentProps, 'div'>((props, ref) => {
  return (
    <Portal>
      <ChakraMenuList
        {...props}
        ref={ref}
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={classnames('orbit-menu__content', props.className)}
      />
    </Portal>
  );
});
