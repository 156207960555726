import React, { useEffect, useMemo } from 'react';

import { constructEmbedLocalStorageKey } from 'src/app/embedHelpers/constructEmbedLocalStorageKey';
import { useListenForLogoutSuccess } from 'src/app/embedHelpers/useListenForLogoutSuccess';
import {
  TRIGGER_LOGOUT_IN_PARENT,
  sendPostMessageFromEmbedToParent,
} from 'src/app/graph/explorerPage/helpers/postMessageHelpers';
import { graphRefToString, useGraphRef } from 'src/app/graph/hooks/useGraphRef';
import { Loading } from 'src/components/common/loading/Loading';
import { usePerKeyLocalStorage } from 'src/hooks/usePerKeyLocalStorage';

export const EmbedLogoutPage = () => {
  const graphRef = useGraphRef();
  const [embedAuthenticationDetails] = usePerKeyLocalStorage({
    initialValue: undefined,
    key: document.referrer,
    localStorageKey: 'embedAuthenticationDetails',
  });

  const embedLocalStorageKey = useMemo(() => {
    return embedAuthenticationDetails?.origin
      ? constructEmbedLocalStorageKey({
          origin: embedAuthenticationDetails.origin,
          graphRef: graphRefToString(graphRef) ?? undefined,
        })
      : undefined;
  }, [embedAuthenticationDetails, graphRef]);

  useListenForLogoutSuccess();

  useEffect(() => {
    if (embedLocalStorageKey) {
      sendPostMessageFromEmbedToParent({
        name: TRIGGER_LOGOUT_IN_PARENT,
        localStorageKey: embedLocalStorageKey,
      });
    }
  }, [embedAuthenticationDetails, embedLocalStorageKey]);

  return <Loading />;
};
