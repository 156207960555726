/* eslint-disable tailwindcss/no-custom-classname */

import { PropsOf, chakra, forwardRef } from '@chakra-ui/react';
import classnames from 'classnames';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';
import { useToastStyles } from '../Toast/Toast.context';

export type ToastBodyProps = OmitChakraProps<PropsOf<'div'>>;

export const ToastBody = forwardRef<ToastBodyProps, 'div'>(
  ({ className, ...props }, ref) => {
    const styles = useToastStyles();

    return (
      <chakra.div
        {...props}
        ref={ref}
        className={classnames('toast-body', className)}
        __css={styles.body}
      />
    );
  },
);
