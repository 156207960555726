import { useState } from 'react';

import { BackendRouter } from '../lib/routers';

interface LoginResult {
  loggingIn: boolean;
  logInSuccessful: boolean | null;
  error?: Error;
}

// Hook to log in, action returns a Promise of whether log in was successful
export function useLogin(): [
  LoginResult,
  (
    email: string,
    password: string,
    recaptchaToken: string,
  ) => Promise<LoginResult>,
] {
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [logInSuccessful, setLogInSuccessful] = useState<boolean | null>(null);
  const [error, setError] = useState<Error>();

  const login = async (
    email: string,
    password: string,
    recaptchaToken: string,
  ) => {
    setLoggingIn(true);
    let fetchError;
    try {
      const response = await fetch(BackendRouter.path('LoginFirstParty'), {
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
          'sec-fetch-mode': 'cors',
        },
        body: JSON.stringify({ email, password, recaptchaToken }),
        method: 'POST',
        mode: 'cors',
      });
      if (!response.ok) {
        const responseText = await response.text();
        fetchError = new Error(responseText);
      }
    } catch (e) {
      console.error('error logging in', e); // eslint-disable-line no-console
      fetchError = e as Error;
    }
    setError(fetchError);
    setLogInSuccessful(!fetchError);
    setLoggingIn(false);
    return {
      loggingIn: false,
      logInSuccessful: !fetchError,
      error: fetchError,
    };
  };

  return [{ loggingIn, logInSuccessful, error }, login];
}
