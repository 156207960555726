import moment from 'moment';

import { Timestamp } from 'src/lib/graphqlTypes/customScalarTypes';

// Note that for annual accounts, the server sends us timestamps rounded
// to the beginning of the UTC day. We want to display that day, not the
// previous day if we're west of England, so we use moment.utc here.
// (All request-based accounts should be annual these days so we won't
// stress too much about the effect of this on monthly accounts.)
export const formatDay = (d: Timestamp['output']): string => {
  if (!d) return '';

  return moment.utc(d).format('MMMM Do, Y');
};
