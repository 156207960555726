import { anatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';
import { WizardStepStyles } from '../WizardStep/WizardStep.style';

const { bg } = customColors;

const wizardAnatomy = anatomy('wizard').parts(
  'step',
  'container',
  'stepContainer',
  'stepperContainer',
  'step',
);

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(wizardAnatomy.keys);

const step = WizardStepStyles;
const baseStyle = definePartsStyle({
  container: { display: 'flex', flex: 1, height: '100%' },
  step,
  stepContent: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  stepContainer: {
    flexShrink: 0,
    flex: 1,
    p: 6,
  },
  stepperContainer: {
    bg: bg.secondary,
    rounded: 'lg',
    position: 'sticky',
    alignSelf: 'flex-start',
    flexShrink: 0,
    top: 0,
  },
});

export const WizardStyle = defineMultiStyleConfig({
  baseStyle,
});
