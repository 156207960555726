import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
} from '@apollo/orbit';
import { useFormikContext } from 'formik';
import React from 'react';

import { formatDay } from '../../orgSettingsPage/components/planCard/utils/formatDay';
import { FormValues } from '../UpgradeModal';

export const Payment = ({ children }: { children: React.ReactNode }) => {
  const { handleChange, handleBlur, values, errors, touched } =
    useFormikContext<FormValues>();
  const date = new Date();
  const currentMonth = date.toLocaleString('default', { month: 'long' });
  const nextBillingDate = formatDay(
    new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString(),
  );

  return (
    <ModalBody className="min-h-80 overflow-x-hidden">
      <h1 className="font-heading text-lg font-medium text-heading">
        Payment details
      </h1>
      <p className="text-base font-normal">
        Your credit card will not be immediately charged. You will be billed for{' '}
        {currentMonth} usage on {nextBillingDate}.
      </p>
      <div className="flex flex-row gap-4">
        <FormControl
          isRequired
          isInvalid={!!touched.firstName && !!errors.firstName}
        >
          <FormLabel>First Name</FormLabel>
          <Input
            onChange={handleChange}
            onBlur={handleBlur}
            name="firstName"
            value={values.firstName}
          />
          <FormErrorMessage>{errors.firstName}</FormErrorMessage>
        </FormControl>

        <FormControl
          isRequired
          isInvalid={!!touched.lastName && !!errors.lastName}
        >
          <FormLabel>Last Name</FormLabel>
          <Input
            onChange={handleChange}
            onBlur={handleBlur}
            name="lastName"
            value={values.lastName}
          />
          <FormErrorMessage>{errors.lastName}</FormErrorMessage>
        </FormControl>
      </div>
      {children}
    </ModalBody>
  );
};
