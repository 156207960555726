import { TypographyDefinition } from "./TypographyDefinition";
import type { Property } from "csstype";

/**
 * Sans-serif font
 *
 * @see https://zpl.io/V4ep7Jy
 */
const baseFont: Property.FontFamily = "'Source Sans Pro', sans-serif";

export const xxxlarge: TypographyDefinition = {
  fontFamily: baseFont,
  fontSize: 30,
  lineHeight: "36px",
};

export const xxlarge: TypographyDefinition = {
  fontFamily: baseFont,
  fontSize: 24,
  lineHeight: "32px",
};

export const xlarge: TypographyDefinition = {
  fontFamily: baseFont,
  fontSize: 20,
  lineHeight: "28px",
};

export const large: TypographyDefinition = {
  fontFamily: baseFont,
  fontSize: 18,
  lineHeight: "28px",
};

export const base: TypographyDefinition = {
  fontFamily: baseFont,
  fontSize: 16,
  lineHeight: "24px",
};

export const small: TypographyDefinition = {
  fontFamily: baseFont,
  fontSize: 14,
  lineHeight: "20px",
};

export const xsmall: TypographyDefinition = {
  fontFamily: baseFont,
  fontSize: 12,
  lineHeight: "16px",
};
