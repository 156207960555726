import { KeyArgsFunction } from '@apollo/client/cache/inmemory/policies';
import { print } from 'graphql/language/printer';
import { visit } from 'graphql/language/visitor';

import { removeTypenameFields } from './removeTypenameFields';

/**
 * Generate a unique key for fields based on stats, meaning descendants of
 * `StatsWindow`, AccountStatsWindow`, and `ServiceStatsWindow`. We need to use
 * the arguments for the fields, the selection set for the query that lives in
 * the field we're keying, and the variables that are used in that selection
 * set. This is the only way to identify a unique entity for the stats in the
 * cache.
 *
 * As a design decision I (Justin) chose not to simply use the `groupBy` fields
 * to uniquely key the fields because then we have to worry about merging data.
 * The shape of the data make sit non-trivial and potentially computationally
 * expensive to merge; so we'll just use the entire selection set and keep data
 * that _could_ have been merged separate. This costs memory and saves CPU and
 * development overhead.
 *
 * Check out the tests for the output these will produce.
 */
export const statsFieldKeyArgs: KeyArgsFunction = (args, context) => {
  /**
   * String represention of `args`
   *
   * We don't feed `args` to `JSON.stringify` beacuse it's nullable and
   * we don't want to have "null" as a result.
   */
  const argsString: string = args ? `(${JSON.stringify(args)})` : '';

  /**
   * String representation of the `selectionSet`
   *
   * We could instead strip this down to only include fields that
   * include a `groupBy` and then include that `groupBy`. If we do that
   * then we'll have a truly unique key that will makes data sets
   * mergeable, but then we have to actually concern ourselves with
   * merging datasets that are identiifed by the contents of `groupBy`,
   * which will be computationally expensive. It will be more memory
   * intensive to store them seperately, but not subject to performance
   * issues with merging potentially tens of thousands of rows.
   */
  const selectionSetString = context.field?.selectionSet
    ? ` ${print(removeTypenameFields(context.field.selectionSet)).replace(
        /\s+/g,
        ' ',
      )}`.trimRight()
    : null;

  const descendentVariables: Record<string, unknown> = {};
  if (context.field?.selectionSet) {
    visit(context.field.selectionSet, {
      Variable: (variable) => {
        descendentVariables[variable.name.value] =
          context.variables?.[variable.name.value];
      },
    });
  }

  // This needs to take the variable name and get the values
  const variablesString =
    Object.keys(descendentVariables).length > 0
      ? ` ${JSON.stringify(descendentVariables)} `
      : '';

  return `${context.fieldName}${argsString}${variablesString}${selectionSetString}`;
};
