import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { usePerKeyLocalStorage } from 'src/hooks/usePerKeyLocalStorage';
import Config from 'src/lib/config';
import { mergeQueryParams } from 'src/lib/routing';

const passableExplorerQueryParams = [
  Config.queryParameters.Referrer,
  Config.queryParameters.ExplorerDocument,
  Config.queryParameters.ExplorerVariables,
  Config.queryParameters.ExplorerHeaders,
  Config.queryParameters.ExplorerURLState,
  Config.queryParameters.CollectionId,
  Config.queryParameters.FocusCollectionId,
  Config.queryParameters.SavedCollectionEntryId,
  Config.queryParameters.SavedCollectionId,
  Config.queryParameters.Overlay,
];

/**
 * Why do we have this capability?
 *
 * We want folks to be able to copy a parent page link to an operation
 * from an embedded Sandbox / Explorer. To do this, we put the params on the parent
 * page & pass them through to the embedded Sandbox / Explorer.
 *
 * However, there is a security issue here: an attack can construct URLs against
 * somebody's hosted copy of embedded Sandbox that actually send data to the
 * attacker's chosen target URL.
 *
 * For this reason, we exclude the `endpoint` url from passable query params, the
 * user receiving the link will have to populate the endpoint themselves
 */
export const usePassThroughExplorerQueryParamsFromParentPage = () => {
  const [embedParentHref] = usePerKeyLocalStorage({
    initialValue: undefined,
    key: document.referrer,
    localStorageKey: 'embedParentHref',
  });

  const history = useHistory();
  useEffect(() => {
    if (embedParentHref) {
      const parentUrl = new URL(embedParentHref);
      const paramsToPush: Record<string, string> = {};
      parentUrl.searchParams.forEach((value, key) => {
        if ((passableExplorerQueryParams as string[]).indexOf(key) > -1) {
          paramsToPush[key] = value;
        }
      });
      if (paramsToPush) {
        history.replace({
          ...history.location,
          search: mergeQueryParams(history.location.search, paramsToPush),
        });
      }
    }
  }, [embedParentHref, history]);
};
