export function generateUniqueString() {
  return Math.random().toString(36).substring(7);
}

export function generateLongUniqueString(length = 7) {
  let uniqueString = '';
  while (uniqueString.length < length) {
    // Each Math.random().toString(36) generates a random string of length 11-13
    // substring(2) is used to take out the first 2 chars (0.), so the remaining
    // string will be length 9-11. To generate strings larger than length 11,
    // keep appending randomly generated strings until it is large enough.
    uniqueString += Math.random().toString(36).substring(2);
  }
  return uniqueString.substring(0, length);
}
