/**
 * replace shortcodes like :smile: with there unicode emoji equivalant for rendering.
 */
export const populateEmoji = (
  content: string,
  emojiData: Record<string, string>,
) => {
  // the value isn't returned so it is safe to say it is a keyof the emoji data so we can use it to look up, if it's not a key it will return undefined.
  type RegexGroups = Record<'lookupKey', keyof typeof emojiData>;
  return content.replace(
    /(?<delimit_start>:)(?<lookupKey>[\w|\-|\+]+)(?<delimit_end>:)/g,
    (fullMatch, ...matches: (string | number | RegexGroups)[]) => {
      // last arg is always group lookup
      const groups = matches[matches.length - 1] as RegexGroups;
      const lookedUp = emojiData[groups.lookupKey];
      return lookedUp || fullMatch;
    },
  );
};
