import { gql, useMutation } from '@apollo/client';
import IconInfo from '@apollo/icons/default/IconInfo.svg';
import IconLock from '@apollo/icons/default/IconLock.svg';
import {
  Alert,
  Badge,
  Button,
  Code,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@apollo/orbit';
import { ApolloIcon } from '@apollo/space-kit/icons/ApolloIcon';
import { FormikErrors, useFormik } from 'formik';
import { debounce } from 'lodash';
import React, { useEffect, useMemo } from 'react';

import { Form } from 'src/components/common/form/Form';
import { Loading } from 'src/components/common/loading/Loading';
import { Tooltip } from 'src/components/common/tooltip/Tooltip';
import { useRouteParams } from 'src/hooks/useRouteParams';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { Yup } from 'src/lib/yup';

import { ssoConfiguration } from '../../routes';

const SUBMIT_OIDC_CONFIGURATION_MUTATION = gql<
  GraphQLTypes.UpdateOidcConfiguration,
  GraphQLTypes.UpdateOidcConfigurationVariables
>`
  mutation UpdateOidcConfiguration(
    $key: String!
    $config: OidcConfigurationInput!
  ) {
    addOidcConfigurationToBaseConnection(
      configurationKey: $key
      config: $config
    ) {
      id
    }
  }
`;

const CHECK_CONFIGURATION_KEY = gql<
  GraphQLTypes.CheckOidcConfigurationKey,
  GraphQLTypes.CheckOidcConfigurationKeyVariables
>`
  mutation CheckOidcConfigurationKey($key: String!) {
    checkSsoConfigurationKey(key: $key)
  }
`;

export const SSOConfigurationPage = () => {
  const { key } = useRouteParams(ssoConfiguration);
  const [
    checkConfigurationKey,
    { data: validator, loading, error: checkError },
  ] = useMutation(CHECK_CONFIGURATION_KEY, { errorPolicy: 'all' });

  const {
    submitError,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
    isValid,
    isSubmitting,
    submission,
  } = useSsoConfiguration(key);

  useEffect(() => {
    if (key) checkConfigurationKey({ variables: { key } });
  }, [key, checkConfigurationKey]);

  if (loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  if (!loading && (!validator?.checkSsoConfigurationKey || checkError)) {
    return (
      <Container>
        <h1 className="pb-4 pt-20 text-center font-semibold">
          Please contact an Apollo admin with the following key for help:
        </h1>
        <Code className="bg-primary">{key}</Code>
      </Container>
    );
  }

  if (submission && !submitError) {
    return (
      <Container>
        <h1 className=" pb-4 pt-20 text-center font-semibold">
          Submission Successful! &nbsp; You can close this page and inform your
          Apollo admin.
        </h1>
      </Container>
    );
  }

  return (
    <Container>
      {submitError && (
        <Alert status="error">
          We encountered a submission failure. Please contact an Apollo admin
          with the following key for help: <Badge variant="error">{key}</Badge>
        </Alert>
      )}
      <h1 className="py-10 text-center font-semibold">
        SSO OIDC Configuration
      </h1>
      <Form className="flex flex-col gap-6 text-base" onSubmit={handleSubmit}>
        <FormControl isRequired>
          <FormLabel>Client Id</FormLabel>
          <Input
            type="text"
            value={values.clientId}
            placeholder="Client Id"
            name="clientId"
            onChange={handleChange}
          />
          {touched.clientId && errors.clientId && (
            <FormErrorMessage>{errors.clientId}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Client Secret</FormLabel>
          <Input
            type="password"
            value={values.clientSecret}
            placeholder="Client Secret"
            name="clientSecret"
            onChange={handleChange}
            onBlur={handleBlur}
            leftElement={<IconLock />}
          />

          {touched.clientSecret && errors.clientSecret && (
            <FormErrorMessage>{errors.clientSecret}</FormErrorMessage>
          )}
          <p className="text-secondary">
            Apollo will <strong>encrypt</strong> this value and store it
            securely.
          </p>
        </FormControl>
        <FormControl isRequired isInvalid={touched.issuer && !!errors.issuer}>
          <FormLabel>Issuer</FormLabel>
          <Input
            type="text"
            value={values.issuer}
            placeholder="https://auth.example.com"
            name="issuer"
            onChange={handleChange}
          />
          {errors.issuer && (
            <FormErrorMessage>{errors.issuer}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl>
          <FormLabel>
            Discovery URL
            <Tooltip
              placement="top"
              className="w-auto"
              label="If your discovery URL differs from the standard '.../.well-known/openid-configuration' path, specify the unique path using this field."
            >
              <IconInfo />
            </Tooltip>
          </FormLabel>
          <Input
            type="text"
            value={values.discoveryURI}
            placeholder="Optional"
            name="discoveryURI"
            onChange={handleChange}
          />
        </FormControl>
        {errors.issuer && <Alert status="error">{errors.issuer}</Alert>}
        <Button
          type="submit"
          size="lg"
          className="mt-6 w-full"
          variant="brandDark"
          isLoading={isSubmitting}
          loadingText="Submitting"
          isDisabled={isSubmitting || !isValid}
        >
          Submit
        </Button>
      </Form>
    </Container>
  );
};

const validationSchema = Yup.object().shape({
  clientId: Yup.string().required(),
  clientSecret: Yup.string().required(),
  issuer: Yup.string().url().required(),
  discoveryURI: Yup.string().url().notRequired(),
});

interface Values {
  clientId: string;
  clientSecret: string;
  issuer: string;
  discoveryURI?: string;
}

const initialValues = {
  clientId: '',
  clientSecret: '',
  issuer: '',
  discoveryURI: '',
};

const useSsoConfiguration = (key: string) => {
  const {
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    touched,
    setFieldError,
    validateForm,
  } = useFormik<Yup.InferType<typeof validationSchema>>({
    initialValues,
    initialTouched: {},
    initialErrors: {
      clientId: '',
      clientSecret: '',
      issuer: '',
      discoveryURI: '',
    },
    validate: async (formValues) => {
      const newErrors: FormikErrors<Values> = {};

      if (!formValues.clientId) {
        newErrors.clientId = 'Please provide a client Id.';
      }

      if (!formValues.clientSecret) {
        newErrors.clientSecret = 'Please provide a client secret.';
      }

      if (!formValues.issuer) {
        newErrors.issuer = 'Please provide the issuer for your IdP.';
      }

      return newErrors;
    },
    validationSchema,
    validateOnChange: false,
    validateOnMount: false,
    enableReinitialize: true,
    async onSubmit(_values, _formikHelpers) {
      await submitConfig({
        variables: { key, config: _values },
      });
    },
  });

  const [
    submitConfig,
    { loading: submitting, error: submitError, data: submission },
  ] = useMutation(SUBMIT_OIDC_CONFIGURATION_MUTATION, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  const debouncedValidate = useMemo(
    () => debounce(validateForm, 500),
    [validateForm],
  );

  useEffect(() => {
    debouncedValidate(values);
  }, [values, debouncedValidate]);

  return {
    submission,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    touched,
    errors,
    submitConfig,
    submitting,
    submitError,
    setFieldError,
  };
};

type ContainerProps = { children: React.ReactNode };
const Container = ({ children }: ContainerProps) => (
  <div className="mx-auto size-full max-w-xl">
    <div className="mb-8 flex flex-none items-center justify-center pt-14">
      <ApolloIcon className="h-20 w-52 text-primary" />
    </div>
    <div className="flex flex-1 flex-col items-center pb-8">{children}</div>
  </div>
);
