/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
interface Props extends Omit<React.SVGProps<SVGSVGElement>, "css"> {
  weight?: "thin" | "normal";
}
export const IconWarningSolid = React.forwardRef<SVGSVGElement, Props>(
  (
    { fill = "none", stroke = "currentColor", weight = "normal", ...props },
    ref,
  ) => (
    <svg
      viewBox="0 0 24 24"
      ref={ref}
      css={css`
        * {
          vector-effect: non-scaling-stroke;
        }
        overflow: visible;
        height: 24px;
      `}
      {...props}
    >
      <path
        fill="currentColor"
        d="M23.119 20L13.772 2.15a2 2 0 0 0-3.543 0L.881 20a2 2 0 0 0 1.772 2.928h18.694A2 2 0 0 0 23.119 20zM11 8.423a1 1 0 0 1 2 0v6a1 1 0 0 1-2 0v-6zm1.05 11.51h-.028a1.528 1.528 0 0 1-1.522-1.47 1.475 1.475 0 0 1 1.421-1.529l.027-.001h.028A1.527 1.527 0 0 1 13.5 18.4c.032.814-.602 1.5-1.416 1.532l-.034.001z"
      />
    </svg>
  ),
);
