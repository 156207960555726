export enum NewUpgradeConfigStep {
  Overview = 'Overview',
  OrgDetails = 'Org Details',
  Payment = 'Payment Details',
  Billing = 'Billing Address',
}

export enum NewUpgradeWorkflow {
  upgrade = 'upgrade',
}

export const NewUserUpgradeConfigSteps = [
  NewUpgradeConfigStep.Overview,
  NewUpgradeConfigStep.OrgDetails,
  NewUpgradeConfigStep.Billing,
  NewUpgradeConfigStep.Payment,
];

export const getUpgradeWizardConfigWorkflowSteps =
  (): Array<NewUpgradeConfigStep> => {
    return NewUserUpgradeConfigSteps;
  };
