import { gql } from '@apollo/client';

import {
  GetOrgDetailsQuery,
  GetOrgDetailsQueryVariables,
  StartUsageBasedPlanMutation,
  StartUsageBasedPlanMutationVariables,
  TrackTermsAcceptedMutation,
  TrackTermsAcceptedMutationVariables,
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables,
} from 'src/lib/graphqlTypes/types';

export const trackTermsAcceptedMutation = gql<
  TrackTermsAcceptedMutation,
  TrackTermsAcceptedMutationVariables
>`
  mutation TrackTermsAcceptedMutation($accountId: ID!, $at: Timestamp!) {
    account(id: $accountId) {
      trackTermsAccepted(at: $at)
    }
  }
`;

export const startUsageBasedPlanMutation = gql<
  StartUsageBasedPlanMutation,
  StartUsageBasedPlanMutationVariables
>`
  mutation StartUsageBasedPlanMutation(
    $internalAccountId: ID!
    $paymentMethodId: String!
  ) {
    billing {
      startUsageBasedPlan(
        internalAccountId: $internalAccountId
        paymentMethodId: $paymentMethodId
      ) {
        __typename
        ... on StartUsageBasedPlanSuccess {
          customerPlanId
        }
        ... on Account {
          id
          currentPlan {
            id
            kind
          }
        }
      }
    }
  }
`;

export const getOrgDetailsQuery = gql<
  GetOrgDetailsQuery,
  GetOrgDetailsQueryVariables
>`
  query GetOrgDetailsQuery($accountId: ID!) {
    me {
      id
      ... on User {
        email
        __typename
      }
    }
    account(id: $accountId) {
      id
      billingContactEmail
    }
    organization(id: $accountId) {
      id
      currentPlan {
        id
        tier
        kind
      }
      internalID
      name
      companyUrl
      billingInfo {
        address {
          address1
          address2
          city
          state
          country
          zip
        }
        name
        vatNumber
      }
    }
  }
`;

export const updatePaymentMethodMutation = gql<
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables
>`
  mutation UpdatePaymentMethodMutation(
    $internalAccountId: ID!
    $paymentMethodId: String!
  ) {
    billing {
      updatePaymentMethod(
        internalAccountId: $internalAccountId
        paymentMethodId: $paymentMethodId
      ) {
        __typename
        ... on UpdatePaymentMethodSuccess {
          paymentMethodId
        }
        ... on Account {
          id
          billingInfo {
            vatNumber
            name
            month
            year
            lastFour
            address {
              address1
              address2
              city
              state
              country
              zip
            }
          }
        }
      }
    }
  }
`;
