import {
  PopoverBody as ChakraPopoverBody,
  PopoverBodyProps as ChakraPopoverBodyProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type PopoverBodyProps = OmitChakraProps<ChakraPopoverBodyProps>;

export const PopoverBody = forwardRef<PopoverBodyProps, 'div'>((props, ref) => {
  return <ChakraPopoverBody {...props} ref={ref} />;
});
