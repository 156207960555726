import {
  ModalBody as ChakraModalBody,
  ModalBodyProps as ChakraModalBodyProps,
} from '@chakra-ui/react';
import React, { forwardRef, useCallback, useState } from 'react';

import { useScrollLocation } from '../../hooks/useScrollLocation';
import { OmitChakraProps } from '../../utils/typeUtils';

export interface ModalBodyProps extends OmitChakraProps<ChakraModalBodyProps> {
  isScrollable?: boolean;
}

export const ModalBody = forwardRef<HTMLDivElement, ModalBodyProps>(
  ({ isScrollable = true, ...props }, ref) => {
    const [modalBodyRef, setRef] = useState<HTMLDivElement>();
    const scrollLocation = useScrollLocation(modalBodyRef);

    const updateRef = useCallback((element: HTMLDivElement) => {
      setRef(element);

      if (!ref) {
        return;
      }

      if (typeof ref === 'function') {
        ref(element);
      } else {
        // eslint-disable-next-line no-param-reassign
        ref.current = element;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <ChakraModalBody
        data-scroll-location={scrollLocation}
        ref={isScrollable ? updateRef : ref}
        overflowY={isScrollable ? 'auto' : undefined}
        {...props}
      />
    );
  },
);
