import * as Yup from 'yup';

import { isLocalUrl } from 'src/lib/isLocalUrl';
import { isValidUrl } from 'src/lib/isValidUrl';

import {
  API_NAME_MAX_CHAR,
  GRAPH_ID_MAX_CHAR,
  GRAPH_ID_MIN_CHAR,
  GRAPH_NAME_MAX_CHAR,
  GRAPH_NAME_MIN_CHAR,
} from '../graphOnboarding/constants';

// Email List
const splitEmails = (emailsString: string | undefined) =>
  emailsString?.trim().replace(/\s+/g, '').split(',').filter(Boolean);

function emailList(this: Yup.StringSchema, message: string) {
  return this.test({
    name: 'emailList',
    message,
    test: (value: string) => {
      const emails = splitEmails(value);
      const schema = Yup.string().email();
      return !!emails?.every((email: string) => schema.isValidSync(email));
    },
  });
}

Yup.addMethod(Yup.string, 'emailList', emailList);

// Subgraph Name

const SUBGRAPH_NAME_SCHEMA = Yup.string()
  .required('Please provide a name.')
  .min(
    GRAPH_NAME_MIN_CHAR,
    `Must be at least ${GRAPH_NAME_MIN_CHAR} characters.`,
  )
  .max(API_NAME_MAX_CHAR, `Must be at most ${API_NAME_MAX_CHAR} characters.`)
  .matches(/^[a-zA-Z]/, 'Must start with a letter.')
  .matches(
    /^[a-zA-Z0-9_-]*$/,
    'May only contain letters, numbers, and dashes.',
  );

const SUPERGRAPH_NAME_SCHEMA = Yup.string()
  .required('Please provide a name.')
  .min(
    GRAPH_NAME_MIN_CHAR,
    `Must be at least ${GRAPH_NAME_MIN_CHAR} characters.`,
  )
  .max(
    GRAPH_NAME_MAX_CHAR,
    `Must be at most ${GRAPH_NAME_MAX_CHAR} characters.`,
  );

const SUPERGRAPH_ID_SCHEMA = Yup.string()
  .required('Please provide an id.')
  .min(GRAPH_ID_MIN_CHAR, `Must be at least ${GRAPH_ID_MIN_CHAR} characters.`)
  .max(GRAPH_ID_MAX_CHAR, `Must be at most ${GRAPH_ID_MAX_CHAR} characters.`)
  .matches(/^[a-zA-Z]/, 'Must start with a letter.')
  .matches(/^[a-zA-Z0-9-]*$/, 'May only contain letters, numbers, and dashes.')
  .matches(
    /^(?!.*\-\-)[a-zA-Z0-9-]*$/,
    'May not contain two consecutive dashes.',
  );

const PUBLIC_API_ENDPOINT_URL_SCHEMA = Yup.string()
  .required('Please enter an endpoint URL.')
  .test('valid-http-url', 'Please enter a valid URL.', (value: string) => {
    return !value || isValidUrl(value);
  })
  .test(
    'non-local-url',
    'Endpoints need to be available on the public internet. Please deploy your API before continuing.',
    (value: string) => {
      return !isLocalUrl(value);
    },
  )
  .url('Please enter a valid URL.');
export {
  Yup,
  SUBGRAPH_NAME_SCHEMA,
  SUPERGRAPH_NAME_SCHEMA,
  SUPERGRAPH_ID_SCHEMA,
  PUBLIC_API_ENDPOINT_URL_SCHEMA,
};
