import { formErrorAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  ...formErrorAnatomy.keys,
  'iconContainer' as const,
  'textContainer' as const,
]);

export const FormErrorMessageStyles = defineMultiStyleConfig({
  baseStyle: {
    text: {
      color: customColors.text.secondary,
      margin: '0px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
      fontStyle: 'normal',
      fontFamily: 'var(--font-body)',
    },
    icon: {
      color: customColors.icon.error,
      marginRight: '0.5rem',
      height: '1rem',
      width: '1rem',
      lineHeight: '1rem',
    },
    iconContainer: {
      paddingTop: '0.125rem',
      paddingBottom: '0.125rem',
    },
    textContainer: {
      width: '100%',
    },
  },
});
