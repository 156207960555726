import React from 'react';

export type Reachability =
  // Everything working fine with the endpoint
  | 'reachable'
  // Unknown whether the endpoint is reachable. This could be a registered
  // graph where no query has been run yet, or an introspection endpoint where
  // introspection has been paused
  | 'unknown'
  // No endpoint has been set
  | 'noEndpoint'
  // Endpoint is unreachable
  | 'unreachable'
  // Endpoint is unreachable due to any 'failed to execute' errors
  | 'possibleCorsError';

export const ReachabilityValueContext =
  React.createContext<Reachability | null>(null);
const ReachabilitySetterContext = React.createContext<React.Dispatch<
  React.SetStateAction<Reachability>
> | null>(null);

export const ReachabilityProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [endpointReachability, setEndpointReachability] =
    React.useState<Reachability>('unknown');

  return (
    <ReachabilityValueContext.Provider value={endpointReachability}>
      <ReachabilitySetterContext.Provider value={setEndpointReachability}>
        {children}
      </ReachabilitySetterContext.Provider>
    </ReachabilityValueContext.Provider>
  );
};

export const useEndpointReachability = () => {
  const context = React.useContext(ReachabilityValueContext);
  if (context === null) {
    throw new Error(
      'useEndpointReachability must be used within a ReachabilityValueContext',
    );
  }

  return context;
};

export const useSetEndpointReachability = () => {
  const context = React.useContext(ReachabilitySetterContext);
  if (context === null) {
    throw new Error(
      'useSetEndpointReachability must be used within a ReachabilitySetterContext',
    );
  }

  return context;
};
