import {
  TableHeadProps as ChakraTableHeadProps,
  Thead as ChakraThead,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type TableHeadProps = OmitChakraProps<ChakraTableHeadProps>;

export const Thead = forwardRef<TableHeadProps, 'thead'>((props, ref) => {
  return <ChakraThead {...props} ref={ref} />;
});
