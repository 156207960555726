import { buildSubscriptionUrlFromEndpoint } from 'src/lib/buildSubscriptionUrlFromEndpoint';

import { SandboxConnectionSettings } from '../../explorerPage/connectionSettingsModal/sandboxConnectionSettingsModal/SandboxConnectionSettingsModal';

export const STUDIO_UI_GRAPH_FLAGS: GraphBehavioralFlags = {
  sandboxConnectionSettingsInitialValue: (endpointUrl) => ({
    subscriptionUrl: buildSubscriptionUrlFromEndpoint(endpointUrl),
    sendCookies: false,
    shouldAutoUpdateSchema: true,
  }),
  sandboxEndpointIsEditable: true,
  showSandboxConnectionSettingsCookieToggle: true,
  canEditSharedHeaders: true,
};

export const STORYBOOK_GRAPH_FLAGS: GraphBehavioralFlags = {
  sandboxConnectionSettingsInitialValue: (endpointUrl) => ({
    subscriptionUrl: buildSubscriptionUrlFromEndpoint(endpointUrl),
    sendCookies: false,
    shouldAutoUpdateSchema: true,
  }),
  sandboxEndpointIsEditable: true,
  showSandboxConnectionSettingsCookieToggle: true,
  canEditSharedHeaders: true,
};

export const ATLAS_GRAPH_FLAGS: GraphBehavioralFlags = {
  sandboxConnectionSettingsInitialValue: (endpointUrl) => ({
    subscriptionUrl: buildSubscriptionUrlFromEndpoint(endpointUrl),
    sendCookies: false,
    shouldAutoUpdateSchema: true,
  }),
  sandboxEndpointIsEditable: true,
  showSandboxConnectionSettingsCookieToggle: true,
  canEditSharedHeaders: true,
};

export const DOCS_EMBEDDED_GRAPH_FLAGS: GraphBehavioralFlags = {
  sandboxConnectionSettingsInitialValue: (endpointUrl) => ({
    subscriptionUrl: buildSubscriptionUrlFromEndpoint(endpointUrl),
    sendCookies: false,
    shouldAutoUpdateSchema: true,
  }),
  sandboxEndpointIsEditable: true,
  showSandboxConnectionSettingsCookieToggle: true,
  canEditSharedHeaders: true,
};

export const EMBEDDED_SANDBOX_GRAPH_FLAGS = ({
  shouldAutoUpdateSchemaInitialValue,
  endpointIsEditable,
  sendCookiesInitialValue,
  showCookieToggle,
  canEditSharedHeaders,
  initialSubscriptionUrl,
}: {
  shouldAutoUpdateSchemaInitialValue: boolean;
  endpointIsEditable: boolean;
  sendCookiesInitialValue: boolean;
  showCookieToggle: boolean;
  canEditSharedHeaders: boolean;
  initialSubscriptionUrl: string | undefined;
}): GraphBehavioralFlags => ({
  sandboxConnectionSettingsInitialValue: (endpointUrl) => ({
    subscriptionUrl:
      initialSubscriptionUrl ?? buildSubscriptionUrlFromEndpoint(endpointUrl),
    sendCookies: sendCookiesInitialValue,
    shouldAutoUpdateSchema: shouldAutoUpdateSchemaInitialValue,
  }),
  sandboxEndpointIsEditable: endpointIsEditable,
  showSandboxConnectionSettingsCookieToggle: showCookieToggle,
  canEditSharedHeaders,
});

export const EMBEDDED_EXPLORER_GRAPH_FLAGS: GraphBehavioralFlags = {
  // neither of these should have an effect on the embedded explorer
  sandboxConnectionSettingsInitialValue: (endpointUrl) => ({
    subscriptionUrl: buildSubscriptionUrlFromEndpoint(endpointUrl),
    sendCookies: false,
    shouldAutoUpdateSchema: true,
  }),
  sandboxEndpointIsEditable: true,
  showSandboxConnectionSettingsCookieToggle: false,
  canEditSharedHeaders: true,
};

export interface GraphBehavioralFlags {
  sandboxConnectionSettingsInitialValue: (
    endpointUrl: string,
  ) => SandboxConnectionSettings;
  sandboxEndpointIsEditable: boolean;
  showSandboxConnectionSettingsCookieToggle: boolean;
  canEditSharedHeaders: boolean;
}
