export const isMinLengthArray = <Length extends number, Data>(
  length: Length,
  array: ReadonlyArray<Data> | null | undefined,
): array is MinLengthArray<Length, Data> => {
  if (!array) return false;
  for (let i = 0; i < length; i += 1) {
    if (!(i in array)) return false;
  }
  return true;
};
