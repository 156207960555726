export const enterpriseFeatureList = [
  'Custom self-hosted operation limit',
  'Protected schema variants',
  'Contract schema variants',
  'Advanced user roles and permissions',
  'Single sign-on (SSO)',
  '18-month usage data retention',
  'Audit logs',
  'Production SLA',
  'Dedicated success team',
];
