import {
  TableCellProps as ChakraTableCellProps,
  Td as ChakraTd,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export interface TableCellProps extends OmitChakraProps<ChakraTableCellProps> {
  /**
   * Set this `true` if using a form element (ie. Checkbox, Switch) to
   * align the cell content in the center. This is not needed in most cases as
   * buttons and checkboxes are already centered by default.
   *
   * @default false
   */
  isFormElement?: boolean;

  /**
   * Set this `true` if using an actions menu to align the cell content to the
   * right.
   *
   * @default false
   */
  isActionsMenu?: boolean;
}

export const Td = forwardRef<TableCellProps, 'td'>(
  ({ isFormElement, isActionsMenu, ...props }, ref) => {
    return (
      <ChakraTd
        ref={ref}
        data-is-form-element={isFormElement}
        data-is-actions-menu={isActionsMenu}
        {...props}
      />
    );
  },
);
