import { Link, Text } from '@apollo/orbit';
import classnames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { PermissionWarning } from 'src/components/permissionGuards/permissionWarning/PermissionWarning';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import Config from 'src/lib/config';
import { setOverlayVisibleLocation } from 'src/lib/routing';

const overlayName = Config.modals.requestHigherLimit;

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const HigherLimitContactLink = ({ className, children }: Props) => {
  const location = useLocation();
  const accountId = useCurrentAccountId()[0] || '';

  return (
    <PermissionWarning
      accountId={accountId}
      accountPermissions="canUpdateBillingInfo"
      childRenderer={(hasPermission) =>
        hasPermission ? (
          <Link
            className={className}
            to={setOverlayVisibleLocation({
              location,
              overlayName,
            })}
          >
            {children}
          </Link>
        ) : (
          <Text
            as="span"
            className={classnames(className, 'cursor-not-allowed')}
          >
            {children}
          </Text>
        )
      }
    />
  );
};
