import {
  ModalHeader as ChakraModalHeader,
  ModalHeaderProps as ChakraModalHeaderProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type ModalHeaderProps = OmitChakraProps<ChakraModalHeaderProps>;

export const ModalHeader = forwardRef<ModalHeaderProps, 'header'>(
  (props, ref) => {
    return <ChakraModalHeader {...props} ref={ref} />;
  },
);
