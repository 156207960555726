export type HeaderEntry = {
  headerName: string;
  value: string;
  key: number;
  checked: boolean;
};

export const INITIAL_HEADERS = [
  {
    key: 0,
    headerName: '',
    checked: true,
    value: '',
  },
];
