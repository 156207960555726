import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { clearLocalStorage } from 'src/hooks/useLocalStorage';
import { useRouteParams } from 'src/hooks/useRouteParams';

import {
  IncomingEmbedMessageEvent,
  PARENT_LOGOUT_SUCCESS,
} from '../graph/explorerPage/helpers/postMessageHelpers';
import { embedLogoutRouteConfig } from '../onboarding/routes';

export const useListenForLogoutSuccess = () => {
  const [_, setCurrentAccountId] = useCurrentAccountId();
  const history = useHistory();
  const { from } = useRouteParams(embedLogoutRouteConfig);

  useEffect(() => {
    const handleLogoutMessage = (event: IncomingEmbedMessageEvent) => {
      if (event.data.name === PARENT_LOGOUT_SUCCESS) {
        setCurrentAccountId(null);
        clearLocalStorage();

        history.push(from);
      }
    };
    window.addEventListener('message', handleLogoutMessage);

    return () => {
      window.removeEventListener('message', handleLogoutMessage);
    };
  }, [from, history, setCurrentAccountId]);
};
