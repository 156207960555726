import {
  ModalOverlay as ChakraModalOverlay,
  ModalOverlayProps as ChakraModalOverlayProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type ModalOverlayProps = OmitChakraProps<
  ChakraModalOverlayProps,
  'motionProps' | 'children'
>;

export const ModalOverlay = forwardRef<ModalOverlayProps, 'div'>(
  (props, ref) => {
    return <ChakraModalOverlay {...props} ref={ref} />;
  },
);
