import { gql } from '@apollo/client';

import { GraphQLTypes } from 'src/lib/graphqlTypes';

export const convertAccountMutation = gql<
  GraphQLTypes.ConvertAccount,
  GraphQLTypes.ConvertAccountVariables
>`
  mutation ConvertAccount($internalAccountId: ID!) {
    billing {
      startFreeUsageBasedPlan(internalAccountId: $internalAccountId) {
        ... on StartUsageBasedPlanSuccess {
          customerPlanId
        }
        ... on Account {
          id
          currentPlan {
            tier
            kind
            id
          }
        }
        ... on Error {
          message
        }
      }
    }
  }
`;
