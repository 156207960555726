// This code is used to register a service worker.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// This registration script includes several lifecycle events that help control
// the service worker.
//
// Events we listen for:
//   - waiting: There is a new service worker that is waiting to control the page
//   - message: The worker sends two messages
//     - `POLL_FOR_UPDATES` triggers a check for a new worker version. This is
//       called on an interval
//     - `FORCE_UPDATE` triggers a blocking update prompt. This is called when
//       the worker invalidation version changes
//
// Events we send:
//   - update (`wb.update`): Check for newer available versions of the worker
//   - skip-waiting (`wb.messageSkipWaiting`): The message sent to the service
//     worker to tell the worker to take control the webpage
//   - message (`messageSW`): We send a few messages to the worker
//     - `GET_ACTIVATION_TIME` returns when this worker was initially activated
//     - `GET_NUMBER_OF_CLIENTS` returns how many studio tabs are open
//     - `GET_NEEDS_FORCE_UPDATE` returns whether the worker invalidation
//       version has changed
//

// There are a few ways the worker will update
// - When a worker is first registered, if the current worker is > 12 hours old
//   and there is only one tab the worker will update and the page will reload
// - When a new worker version is received
//   - If the worker is > 24 hours old a modal will be displayed asking the user
//     to update the worker and refresh
//   - If the worker is > 24 hours old a dismissable toast will be displayed
//     asking the user to update the worker and refresh
// - At any point where there is a new worker version available, the user can
//   manually update by going to any of:
//   - The changelog
//   - The global header help dropdown
//   - The error page
// - If the polled worker invalidation version is changed, a modal will be
//   displayed asking the user to update the worker and refresh

// The following is a flow chart explaining the lifecycle of our service worker configuration
// Old Worker | ---- In Control ----------------------x
//            |
//            |
// New Worker | - Waiting --------------------- Skip Waiting ----------
//            |      \                                 /    \
//            |    sw-prompt-user-reload    sw-user-confirm  \ Controlling
//            |        \                             /        \
// Web App    |         ----------- Toast -----------          - Window Reload

import * as Sentry from '@sentry/react';
import moment from 'moment';
import { Workbox, messageSW as rawMessageSW } from 'workbox-window';

import { internalTrackCustomEvent } from 'src/hooks/useTrackCustomEvent';
import { localStorageWithMemoryFallback } from 'src/lib/localStorageWithMemoryFallback';

import type {
  WorkerMessageEventResponse,
  WorkerMessageEventType,
} from './serviceWorkerMessages';
import {
  triggerBlockingReloadPrompt,
  triggerDismissableReloadPrompt,
  triggerNewWorkerVersionAvailable,
} from './serviceWorkerReactiveVariables';

const DISMISSABLE_RELOAD_PROMPT_AFTER_HOURS = 12;
const BLOCKING_RELOAD_PROMPT_AFTER_HOURS = 24;
const AUTOUPDATE_BUNDLE_AFTER_HOURS = 12;

/**
 * This is just to add typing to messageSW
 */
function messageSW<MessageType extends WorkerMessageEventType>(
  worker: ServiceWorker,
  message: { type: MessageType },
): Promise<WorkerMessageEventResponse<MessageType>> {
  return rawMessageSW(worker, message);
}

/**
 * This is the entry point for our service worker's registration. This controls
 * registering service workers and updating the user's bundle if changes to
 * files have been made.
 */
export function setupServiceWorker() {
  // Don't register for non production, or if workers not avaliable
  if (
    process.env.NODE_ENV !== 'production' ||
    !('serviceWorker' in navigator)
  ) {
    return;
  }

  // The URL constructor is available in all browsers that support SW.
  const publicUrl = new URL(window.location.hostname, window.location.href);
  if (publicUrl.origin !== window.location.origin) {
    // Our service worker won't work if PUBLIC_URL is on a different origin
    // from what our page is served on. This might happen if a CDN is used to
    // serve assets; see https://github.com/facebook/create-react-app/issues/2374
    return;
  }

  // We do not want to register workers on deploy previews unless explicitly enabled via query params
  const isDeployPreview = window.location.hostname.match(
    /deploy-preview-[0-9]+--.*\.netlify\.app/,
  );

  const isEmbedded = /\.embed\.apollographql\.com/.test(
    window.location.hostname,
  );

  if (window.location.search.includes('__registerServiceWorker__')) {
    register();
  } else if (!isEmbedded) {
    // We only need service workers / offline mode for embedded explorer & embedded sandbox
    // Unregister any existing service workers
    navigator.serviceWorker.getRegistration().then((registration) => {
      registration?.unregister();
    });
  } else if (!isDeployPreview) {
    register();
  } else {
    // If a worker is already registered on this deploy preview, continue to register
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration) {
        register();
      }
    });
  }
}

/**
 * Trigger any waiting service worker to take control and reload the page (or
 * just reload if no worker available)
 */
export function triggerWorkerUpdateAndReload() {
  window.dispatchEvent(new window.Event('sw-update-and-skip-waiting'));
}

function register() {
  const wb = new Workbox('/service-worker.js');

  // Whether or not a worker has registered as 'waiting'
  let hasWaitingServiceWorker = false;

  // This gets set when we trigger a reload. When a worker registers as
  // waiting, if this is set the reload will get triggered then (in case no
  // worker was waiting at the initial reload time).
  let shouldImmediatelySkipWaiting = false;

  // NOTE: Since we use Continuous Deployment and perform a prod release after
  // each PR is merged and CI passes, we frequently release multiple times
  // per day, or even per hour. So to better control this, we throttle update
  // prompts so they only show up when the existing service worker is more than
  // DISMISSABLE_RELOAD_PROMPT_AFTER_HOURS old AND a new service worker is
  // detected
  const getHoursSinceWorkerActivationTime = async () => {
    const activationTime = moment(
      (
        await messageSW(await wb.getSW(), {
          type: 'GET_ACTIVATION_TIME',
        })
      ).activationTime,
    );

    const currentTime = moment();

    const hoursSinceWorkerActivationTime = moment
      .duration(currentTime.diff(activationTime))
      .asHours();

    return hoursSinceWorkerActivationTime;
  };

  // This will return true if there is a service worker waiting, or wait for
  // the service worker to reach waiting if there is a new service worker
  // available but not yet installed.
  const waitForNextWaitingWorker = async (
    registration: ServiceWorkerRegistration | undefined,
  ): Promise<boolean> => {
    if (registration?.waiting) {
      return true;
    }
    await wb.update();
    const installing = registration?.installing;
    if (installing) {
      return new Promise<boolean>((resolve) => {
        installing.addEventListener('statechange', () =>
          resolve(installing.state === 'installed'),
        );
      });
    }
    if (registration?.waiting) {
      return true;
    }
    return false;
  };

  // This gets set when we trigger the prompt. When a worker registers as
  // waiting, if this is set the prompt will get triggered then (in case no
  // worker was waiting at the initial trigger time).
  let shouldShowDismissableReloadPrompt = false;

  // Prompts the user to reload in a dismissable way
  const updateAndTriggerDismissableReloadPrompt = () => {
    shouldShowDismissableReloadPrompt = true;
    wb.update();
    if (hasWaitingServiceWorker) {
      triggerDismissableReloadPrompt();
    }
  };

  // This gets set when we trigger the prompt. When a worker registers as
  // waiting, if this is set the prompt will get triggered then (in case no
  // worker was waiting at the initial trigger time).
  let shouldShowBlockingReloadPrompt = false;

  // Prompts the user to reload in a blocking way
  const updateAndTriggerBlockingReloadPrompt = () => {
    shouldShowBlockingReloadPrompt = true;
    wb.update();
    if (hasWaitingServiceWorker) {
      triggerBlockingReloadPrompt();
    }
  };

  wb.register().then(async (registration) => {
    // `wb.messageSW` will message the waiting service worker, here we want
    // to message the controlling worker so we can get the number of active
    // tabs
    const numClientsPromise = wb.controlling.then((controlling) =>
      messageSW(controlling, {
        type: 'GET_NUMBER_OF_CLIENTS',
      }),
    );

    const trackingOrgId =
      (JSON.parse(
        localStorageWithMemoryFallback.getItem('engine:currentAccountId') ||
          'null',
      ) as string | null | undefined) || 'Unspecified';

    const hoursSinceWorkerActivationTimePromise =
      getHoursSinceWorkerActivationTime();

    // Track the age of the activated worker
    hoursSinceWorkerActivationTimePromise.then(
      (hoursSinceWorkerActivationTime) => {
        internalTrackCustomEvent({
          event: {
            category: 'Service Workers',
            action: 'loaded_bundle_of_hours_old',
            value: hoursSinceWorkerActivationTime,
            orgId: trackingOrgId,
          },
          offline: false,
        });
      },
    );

    // Check if we already have a force update waiting, if we do, send the
    // prompt
    wb.controlling
      .then((controlling) =>
        messageSW(controlling, {
          type: 'GET_NEEDS_FORCE_UPDATE',
        }),
      )
      .then(({ needsForceUpdate }) => {
        if (needsForceUpdate) {
          updateAndTriggerBlockingReloadPrompt();
        }
      });

    // If after registering there is a new worker available, let the new
    // worker take control immediately if there is only one tab (client)
    if (await waitForNextWaitingWorker(registration)) {
      const hoursSinceWorkerActivationTime =
        await hoursSinceWorkerActivationTimePromise;
      const { numClients } = await numClientsPromise;

      if (
        hoursSinceWorkerActivationTime > AUTOUPDATE_BUNDLE_AFTER_HOURS &&
        numClients < 2
      ) {
        // Track the autoupdate event
        internalTrackCustomEvent({
          event: {
            category: 'Service Workers',
            action: 'autoupdate_bundle',
            orgId: trackingOrgId,
          },
          offline: false,
        });
        wb.messageSkipWaiting();
      }
    }
  });

  // When the user triggers an update, we should check for a new bundle,
  // skipWaiting on new service worker, and reload the page if there was no
  // service worker
  window.addEventListener('sw-update-and-skip-waiting', async () => {
    shouldImmediatelySkipWaiting = true;

    await wb.update();
    wb.messageSkipWaiting();

    // We want to give enough time for the 'waiting' event listener to fire
    // here, but we have no way to tell if the update returned no new worker.
    // After 5 seconds if there was no action, assume no new worker and reload
    // this tab.
    setTimeout(() => {
      // This is to debug an issue where users click 'reload now' but
      // `skipWaiting` does not trigger a reload
      Sentry.captureMessage('Skip waiting did not trigger reload', {
        level: 'warning',
      });
      window.location.reload();
    }, 5000);
  });

  wb.addEventListener('waiting', async () => {
    hasWaitingServiceWorker = true;

    if (shouldImmediatelySkipWaiting) {
      wb.messageSkipWaiting();
      return;
    }

    triggerNewWorkerVersionAvailable();

    if (shouldShowBlockingReloadPrompt) {
      // Tell studio ui that there is a waiting service worker
      updateAndTriggerBlockingReloadPrompt();
      return;
    }

    if (shouldShowDismissableReloadPrompt) {
      // Tell studio ui that there is a waiting service worker
      updateAndTriggerDismissableReloadPrompt();
      // Dont prompt user again until throttled timeout
      shouldShowDismissableReloadPrompt = false;
      return;
    }

    // Tell studio ui that there is a waiting service worker
    const hoursSinceWorkerActivationTime =
      await getHoursSinceWorkerActivationTime();
    if (hoursSinceWorkerActivationTime > BLOCKING_RELOAD_PROMPT_AFTER_HOURS) {
      updateAndTriggerBlockingReloadPrompt();
      return;
    }
    if (
      hoursSinceWorkerActivationTime > DISMISSABLE_RELOAD_PROMPT_AFTER_HOURS
    ) {
      updateAndTriggerDismissableReloadPrompt();
    }
  });

  wb.addEventListener('message', (event) => {
    // The worker will set an interval to check for updates. We set the
    // interval over on the worker side since `setInterval` doesn't always
    // work as expected in background tabs
    if (event.data.type === 'POLL_FOR_UPDATES') {
      wb.update();
    }

    // #ServiceWorkerInvalidation When the service worker sees a new
    // invalidation version it will send this message. Update and prompt the
    // user to refresh in a non dismissable way
    if (event.data.type === 'FORCE_UPDATE') {
      updateAndTriggerBlockingReloadPrompt();
    }
  });
}
