// This is the preferred wrapper for `markdown-to-jsx`
import { Table, Tbody, Td, Th, Thead, Tr } from '@apollo/orbit';
import { MarkdownToJSX, compiler as compileMarkdown } from 'markdown-to-jsx'; // eslint-disable-line no-restricted-imports
import React from 'react';

import { EventCategory } from '../analytics';

import { A } from './A';
import { Code } from './Code';
import { Input } from './Input';
import { Li } from './Li';

export function safeCompileMarkdown(
  markdown: string,
  {
    includeDefaultOverrides,
    analyticsLabelPrefix,
    analyticsCategory,
    ...options
  }: Omit<MarkdownToJSX.Options, 'disableParsingRawHTML'> & {
    includeDefaultOverrides?: boolean;
    analyticsLabelPrefix?: string;
    analyticsCategory?: EventCategory;
  } = {},
) {
  return compileMarkdown(markdown, {
    ...options,
    overrides: !includeDefaultOverrides
      ? options?.overrides
      : {
          a: {
            component: A,
            props: {
              analyticsLabelPrefix,
              analyticsCategory,
            },
          },
          blockquote: {
            props: {
              className:
                'text-secondary border-solid border-l-2 border-primary pl-4 my-6 py-1 text-base',
            },
          },
          code: {
            component: Code,
            props: { analyticsLabelPrefix, analyticsCategory },
          },
          em: {
            props: {
              className: 'text-base italic font-normal',
            },
          },
          h1: {
            props: {
              className: 'text-heading-3xl border-b border-primary mb-4 py-2',
            },
          },
          h2: {
            props: {
              className: 'text-heading-2xl border-b border-primary mb-2 py-2',
            },
          },
          h3: {
            props: {
              className: 'text-heading-xl border-b border-primary mb-1 py-2',
            },
          },
          h4: {
            props: {
              className: 'text-heading-lg py-2',
            },
          },
          h5: {
            props: {
              className: 'text-title py-2',
            },
          },
          h6: {
            props: {
              className: 'text-subtitle py-2',
            },
          },
          input: {
            component: Input,
          },
          li: {
            component: Li,
          },
          ol: {
            props: {
              className: 'list-decimal list-outside pl-0 ml-8 mb-6',
            },
          },
          pre: {
            component: ({ children }) =>
              React.cloneElement(children, { inline: false }),
          },
          ul: {
            props: {
              className: 'list-disc list-outside pl-0 ml-8 mb-6',
            },
          },
          hr: {
            props: {
              className: 'border-primary my-6',
            },
          },
          table: {
            component: Table,
          },
          thead: {
            component: Thead,
          },
          tbody: {
            component: Tbody,
          },
          th: {
            component: Th,
          },
          td: {
            component: Td,
          },
          tr: {
            component: Tr,
          },
          ...options?.overrides,
        },
    disableParsingRawHTML: true,
  });
}
