import {
  Spinner as ChakraSpinner,
  SpinnerProps as ChakraSpinnerProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type SpinnerProps = OmitChakraProps<
  ChakraSpinnerProps,
  'speed' | 'orientation' | 'styleConfig' | 'colorScheme' | 'variant' | 'size'
> & {
  variant?: 'neutral' | 'default';
  size?: 'lg' | 'md' | 'sm' | 'xs' | 'tiny';
};

export const Spinner = forwardRef<SpinnerProps, 'div'>((props, ref) => (
  <ChakraSpinner {...props} ref={ref} speed="0.6s" />
));
