import { ApolloLink, InMemoryCache, gql, makeVar } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { generatePersistedQueryIdsFromManifest } from '@apollo/persisted-query-lists';
import * as Sentry from '@sentry/react';
import _ from 'lodash';

import { readFromLocalStorage } from 'src/hooks/useLocalStorage';

import { trackUserIsLoggedIn } from '../analytics';
import Config from '../config';
import { StudioConfigEnv, runtimeConfig } from '../config/runtime';
import { GraphQLTypes } from '../graphqlTypes';
import { notProduction } from '../notProduction';
import { BackendRouter } from '../routers';

import {
  CatchErrorsContext,
  catchErrors,
  isExpiredSessionError,
  isInvalidApiKeyError,
} from './catchErrors';
import { sha256 } from './hash';
import {
  SentryReportingContext,
  sentryErrorReporting,
} from './sentryErrorReporting';

// Alias this for now, but the idea is to grow the feature set of things
// we store in the context here.
type AppLinkContext = CatchErrorsContext & SentryReportingContext;

// Convenience method / syntax sugar for bootstrapping an AppLinkContext
export const appLinkContext = (...contexts: AppLinkContext[]): AppLinkContext =>
  Object.assign({}, ...contexts);

const checkLogin = new ApolloLink((operation, forward) =>
  forward(operation).map((result) => {
    const { response, cache } = operation.getContext() as {
      response: Response;
      cache: InMemoryCache;
    };
    const isLoggedIn = response.headers.get('logged-in');
    trackUserIsLoggedIn(isLoggedIn === 'true');
    if (isLoggedIn && (isLoggedIn === 'true' || isLoggedIn === 'false')) {
      cache.writeQuery<GraphQLTypes.IsLoggedInInitialCacheSeedQuery>({
        query: gql`
          query IsLoggedInInitialCacheSeedQuery {
            isLoggedIn @client
          }
        `,
        data: {
          isLoggedIn: isLoggedIn === 'true',
        },
      });
    }

    return result;
  }),
);

const alreadyHasExpiredSession = makeVar(false);

const checkSessionExpirationLink = onError(({ graphQLErrors }) => {
  // If the backend says that the user's session is expired, we redirect
  // them to '/logout'. The backend then deletes the expired api key and
  // handles redirecting the user through PingOne's logout flow.
  //
  // The simplest way to do this redirection is, unfortunately,
  // setting window.location.href. There is already precedent for this in
  // AuthenticatedRoutes.tsx.
  //
  // We tried to get this redirection to work a number of different ways, but
  // none were as consistent as this approach. In studio, we do not have a standard
  // way of handling authn errors; not all routes within studio require a user to be
  // logged in to view them and some will hide features/capabilities based on
  // if the 'isLoggedIn' flag is set (the above 'checkLogin' link is what sets
  // this 'isLoggedIn' flag). If the user has an expired session, we do *not*
  // want to treat them as if they are logged out, that is, we still want to
  // redirect them to '/logout' instead of allowing them to view these special pages.
  // So, doing this is much simpler than trying to find all of the non-standard
  // approaches to authn and making bespoke changes to them.
  //
  // We will be working on authn betterment in studio as part of our 'centralizing
  // auth' project, which should hopefully remove this and other hacks. See ATRO-3370.
  const sessionIsNowExpired =
    graphQLErrors &&
    graphQLErrors.some(
      (gqlError) =>
        isExpiredSessionError(gqlError) || isInvalidApiKeyError(gqlError),
    );

  // We only want to set the location to '/logout' once, so we use (misuse?) apollo-client's
  // reactive vars for checking the state
  if (sessionIsNowExpired && !alreadyHasExpiredSession()) {
    alreadyHasExpiredSession(true);
    BackendRouter.go('Logout', { callbackUrl: Config.absoluteUrl });
  }
});

const makeOperationFrequencyMonitor = () => {
  const operationsThreshold = 150;
  const intervalMs = 60 * 1000;
  const ignoreFrequencyFilter = new Set([
    'GraphIDAvailableQuery',
    'SupergraphsVariantsTableQuery', // this query is once per variant,
    // graphs may have many graphs w many variants
    'UsePermissionsServiceQuery', // this query is in components
    // that are rendered as lists, like variant items in the supergraph list,
    // so for orgs with many variants it may fire 100+ times in a single page load
    'GraphsListVariantItemDruidStats',
  ]);
  let operationsInPeriod: string[] = [];
  const monitorIntervalId = setInterval(() => {
    if (operationsInPeriod.length > operationsThreshold) {
      Sentry.captureMessage(
        `Excessive operations occurred in ${intervalMs}ms. This is probably unexpected.`,
        {
          level: 'warning',
          extra: {
            operationCount: operationsInPeriod.length,
            operations: _.countBy(operationsInPeriod),
          },
        },
      );
      // stop the monitor once triggered; it'll likely be triggered every minute if it's triggered once
      clearInterval(monitorIntervalId);
    }
    operationsInPeriod = [];
  }, intervalMs);

  return new ApolloLink((operation, forward) => {
    if (!ignoreFrequencyFilter.has(operation.operationName) && !notProduction) {
      operationsInPeriod.push(operation.operationName);
    }
    return forward(operation);
  });
};

const sudoLink = setContext(() => ({
  headers: { 'apollo-sudo': readFromLocalStorage('sudo') },
}));

export const chain: ApolloLink[] = [
  sudoLink,
  sentryErrorReporting,
  catchErrors,
  checkLogin,
  checkSessionExpirationLink,
  makeOperationFrequencyMonitor(),
];

async function loadManifest() {
  // Don't prefetch, because we only need this in staging!
  return import(
    /* webpackChunkName: "persistedQueryManifest" */ './persistedQueryManifest.json'
  );
}

// If we're running a proper build on staging (but not, say, `npm start
// staging`, then send only persisted queries (not freeform GraphQL)!
// The manifests are published when we deploy.
if (
  window.location.hostname === 'studio-staging.apollographql.com' &&
  runtimeConfig.env === StudioConfigEnv.Staging
) {
  chain.push(
    createPersistedQueryLink({
      ...generatePersistedQueryIdsFromManifest({ loadManifest }),
      useGETForHashedQueries:
        // XXX: `useGETForHashedQueries: true` results in 413 errors for
        // services that have large numbers of clients (ie, we need to avoid
        // using GETs when variables are large).
        false,
    }),
  );
} else if (Config.settings.enablePersistedQueries && !!crypto?.subtle) {
  // Otherwise enable automatic persisted queries on test, staging and
  // production.
  chain.push(
    createPersistedQueryLink({
      sha256,
      useGETForHashedQueries:
        // XXX: `useGETForHashedQueries: true` results in 413 errors
        // for services that have large numbers of clients.
        false,
    }),
  );
}

export const link = ApolloLink.from(chain);
