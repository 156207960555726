import { gql, useQuery } from '@apollo/client';
import { GraphQLSchema, extendSchema } from 'graphql';
import React, { useState } from 'react';

import { GraphRef } from 'src/app/graph/hooks/useGraphRef';
import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import { buildSchemaLeniently } from 'src/lib/buildSchemaLeniently';
import { BuildGraphQLSchemaError } from 'src/lib/errors/buildSchemaError/BuildSchemaError';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { Timestamp } from 'src/lib/graphqlTypes/customScalarTypes';
import { GraphType } from 'src/lib/graphqlTypes/types';

/**
 * This is copied from the experimental release of graphql-js
 * It can be removed when we update graphql-js
 *
 * We're injecting this because we know for sure that cloud supergraphs
 * support defer
 */
const deferSDL = gql`
  """
  Directs the executor to defer this fragment when the \`if\` argument is true or undefined.
  """
  directive @defer(
    """
    Deferred when true or undefined.
    """
    if: Boolean
    """
    Unique name.
    """
    label: String
  ) on FRAGMENT_SPREAD | INLINE_FRAGMENT
`;

const studioIntrospectionQuery = gql`
  query Studio__IntrospectionQuery($serviceId: ID!, $schemaTag: String!) {
    service(id: $serviceId) {
      id
      graphType
      schema(tag: $schemaTag) {
        document
        createdAt
        hash
      }
      variant(name: $schemaTag) {
        id
        activeSchemaPublish {
          id
          compositionResult {
            graphCompositionID
            supergraphSdl
          }
        }
      }
    }
  }
`;

/**
 * Get the GraphQLSchema result from the registry service.
 *
 * The return type here should try to mimic `QueryResult`, but it doesn't
 * because some of the extra stuff returned from `useQuery` is typed, so using
 * `QueryResult<IntrospectionQuery>` causes type mismatches.
 */
export function useSchemaFromRegistry({
  graphRef,
  skip,
}: {
  graphRef: GraphRef | null;
  skip: boolean;
}): {
  loading: boolean;
  error: Error | undefined;
  schema: GraphQLSchema | undefined;
  supergraphSchema: GraphQLSchema | undefined;
  supergraphSchemaDocument: string | undefined;
  schemaDocument: string | undefined;
  createdAt: Timestamp['output'] | undefined;
  hash: string | undefined;
} {
  const { loading, error, data } = useQuery<
    GraphQLTypes.Studio__IntrospectionQuery,
    GraphQLTypes.Studio__IntrospectionQueryVariables
  >(studioIntrospectionQuery, {
    skip,
    variables: {
      serviceId: graphRef?.graphId ?? '',
      schemaTag: graphRef?.graphVariant ?? '',
    },
    context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
  });

  const isCloudSupergraph =
    data?.service?.graphType === GraphType.CLOUD_SUPERGRAPH;

  const [buildSchemaError, setBuildSchemaError] =
    useState<BuildGraphQLSchemaError>();

  const supergraphSchemaDocument =
    data?.service?.variant?.activeSchemaPublish?.compositionResult
      ?.supergraphSdl ?? undefined;

  const schemaDocument = data?.service?.schema?.document;

  const schema = React.useMemo(() => {
    if (!schemaDocument) {
      return undefined;
    }

    try {
      const _schema = buildSchemaLeniently(schemaDocument).schema;
      if (isCloudSupergraph) return extendSchema(_schema, deferSDL);
      return _schema;
    } catch (e) {
      if (e instanceof BuildGraphQLSchemaError) {
        setBuildSchemaError(e);
      } else {
        // rethrow unexpected errors
        throw e;
      }
    }
  }, [schemaDocument, isCloudSupergraph]);

  const supergraphSchema = React.useMemo(() => {
    if (!supergraphSchemaDocument) {
      return undefined;
    }

    try {
      const _schema = buildSchemaLeniently(supergraphSchemaDocument).schema;
      if (isCloudSupergraph) return extendSchema(_schema, deferSDL);
      return _schema;
    } catch (e) {
      if (e instanceof BuildGraphQLSchemaError) {
        setBuildSchemaError(e);
      } else {
        // rethrow unexpected errors
        throw e;
      }
    }
  }, [supergraphSchemaDocument, isCloudSupergraph]);

  return {
    schema,
    supergraphSchema,
    supergraphSchemaDocument,
    schemaDocument,
    createdAt: data?.service?.schema?.createdAt,
    hash: data?.service?.schema?.hash,
    loading,
    error: error || buildSchemaError,
  };
}
