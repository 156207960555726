import { ModalBody } from '@apollo/orbit';
import React from 'react';

import { Loading } from 'src/components/common/loading/Loading';
import { MultiColorProgressBar } from 'src/components/common/multiColorProgressBar/MultiColorProgressBar';
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage';
import { useCurrentPlan } from 'src/hooks/currentPlanV2Migration';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { useMonthlyOperationDetails } from 'src/hooks/useMonthlyOperationDetails';

import { formatDay } from '../../orgSettingsPage/components/planCard/utils/formatDay';

const FREE_OPERATIONS_COUNT = 10_000_000;
const OPERATION_BUNDLE = 1_000_000;
const PRICE_PER_OPERATION_BUNDLE_IN_USD_CENTS = 1500;

export const Overview = () => {
  const accountId = useCurrentAccountId()[0] || '';
  const currentPlanResults = useCurrentPlan({
    skip: !accountId,
    accountId,
  });
  const { loading, error, total } = useMonthlyOperationDetails({
    accountId,
    skip: !accountId,
  });
  const planName = currentPlanResults.currentPlan?.name;

  const date = new Date();
  const firstDateOfMonth = formatDay(
    new Date(date.getFullYear(), date.getMonth(), 1).toISOString(),
  );
  const lastDateOfMonth = formatDay(
    new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString(),
  );

  if (loading)
    return (
      <div className="p-20">
        <Loading />
      </div>
    );
  if (error) return <ErrorMessage>{error.message}</ErrorMessage>;
  const totalServerlessMonthlyOps = total?.serverless?.stats[0]?.value || 0;
  const overage = totalServerlessMonthlyOps - FREE_OPERATIONS_COUNT;
  const overFreeThreshold = overage > 0;
  const overageCost = overFreeThreshold
    ? Math.ceil(overage / OPERATION_BUNDLE) *
      (PRICE_PER_OPERATION_BUNDLE_IN_USD_CENTS / 100)
    : 0;
  return (
    <ModalBody>
      <h1 className="font-heading text-lg font-medium text-heading">
        Upgrade to Serverless
      </h1>
      <p className="text-base font-normal ">
        You are on the <span className="font-semibold">{planName}</span> plan,
        which is limited to {FREE_OPERATIONS_COUNT.toLocaleString()} serverless
        operations/month.{' '}
        {overFreeThreshold ? (
          <>
            You have used{' '}
            <span className="font-semibold text-error">
              {totalServerlessMonthlyOps.toLocaleString()} operations
            </span>{' '}
            so far this month and will need to upgrade to{' '}
            <span className="font-semibold">Serverless</span> to continue using
            Studio.
          </>
        ) : (
          <>
            Upgrade to the <span className="font-semibold">Serverless</span>{' '}
            plan to get access to 90 days of metrics data retention and
            operation traces.
          </>
        )}
      </p>
      <p className="italic text-secondary">
        *Below is an example of what your monthly bill may look like when you
        upgrade to the{' '}
        <span className="font-semibold not-italic">Serverless</span> plan. You
        will not be billed for any usage accrued while on{' '}
        <span className="font-semibold not-italic">{planName}</span> and your
        usage will be reset once you've upgraded.
      </p>
      <div className="flex space-x-4">
        <div className="w-1/2 rounded-md border border-primary bg-secondary p-4">
          {/* Section 1 */}
          <div className="space-y-2">
            <h6 className=" text-lg font-semibold">Bill preview</h6>
            <div className="text-base">
              <div className="flex justify-between">
                <p>First {FREE_OPERATIONS_COUNT.toLocaleString()} operations</p>
                <p className="font-semibold">Free</p>
              </div>
              {overFreeThreshold ? (
                <div className="flex justify-between">
                  <p>{overage.toLocaleString()} operations</p>
                  <p className="font-semibold">
                    ${overageCost.toLocaleString()}
                  </p>
                </div>
              ) : null}
              <div className="mt-1 flex justify-between border-t border-primary pt-1">
                <p className="font-semibold text-success">Total</p>
                <p className="font-semibold text-success">
                  ${overageCost.toLocaleString()}
                </p>
              </div>
            </div>
            <div className="flex justify-between">
              <p>
                Additional operations over the free limit will be billed at a
                rate of ${PRICE_PER_OPERATION_BUNDLE_IN_USD_CENTS / 100} per{' '}
                {(OPERATION_BUNDLE / 1_000_000).toLocaleString()}M operations on{' '}
                <span className="font-semibold">Serverless</span>.
              </p>
            </div>
          </div>
        </div>
        <div className="w-1/2 space-y-2">
          {/* Section 2 */}
          <h2 className="text-base font-semibold">
            Serverless usage for {firstDateOfMonth} - {lastDateOfMonth}
          </h2>
          <MultiColorProgressBar
            max={
              overFreeThreshold
                ? totalServerlessMonthlyOps
                : FREE_OPERATIONS_COUNT
            }
            sections={
              overFreeThreshold
                ? [
                    {
                      label: 'Free operations',
                      value: FREE_OPERATIONS_COUNT,
                      color: 'blue',
                    },
                    {
                      label: 'Pay-as-you-go operations',
                      value: overage,
                      color: 'lightGreen',
                    },
                  ]
                : [
                    {
                      label: 'Free operations',
                      value: totalServerlessMonthlyOps,
                      color: 'blue',
                    },
                  ]
            }
          />
          <p className="text-secondary">
            You’ve used{' '}
            <span className="font-semibold">
              {totalServerlessMonthlyOps.toLocaleString()} operations
            </span>{' '}
            so far.
          </p>
          <p className="text-secondary">
            We will only start counting operations towards the pay-as-you-go
            plan after you upgrade, and your final bill will depend on how much
            you use.
          </p>
        </div>
      </div>
    </ModalBody>
  );
};
