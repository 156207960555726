import { InMemoryCacheConfig, Reference } from '@apollo/client/cache';
import { TypePolicy } from '@apollo/client/cache/inmemory/policies';
import { relayStylePagination } from '@apollo/client/utilities';
import { TRelayEdge } from '@apollo/client/utilities/policies/pagination';
import _ from 'lodash';
import naturalCompare from 'natural-compare-lite';

import { statsFieldKeyArgs } from './statsFieldKeyArgs/statsFieldKeyArgs';

// For all stats types (`*StatsWindow`) we're defining the stats field behaviors
// here. We'll use `merge: true` on the `**StatsWindow` because all children
// will be uniquely keyed so they can be shallowly merged safely. Each field
// (listed below in the `statsFieldFields` will have a custom keyArgs function
// to generate a key based on the query and will have `merge: false` set
// indicating all new data will overwrite all old data.
const statsFieldFields: TypePolicy['fields'] = {
  edgeServerInfos: {
    keyArgs: statsFieldKeyArgs,
    merge: false,
  },
  errorStats: {
    keyArgs: statsFieldKeyArgs,
    merge: false,
  },
  fieldExecutions: {
    keyArgs: statsFieldKeyArgs,
    merge: false,
  },
  fieldLatencies: {
    keyArgs: statsFieldKeyArgs,
    merge: false,
  },
  fieldUsage: {
    keyArgs: statsFieldKeyArgs,
    merge: false,
  },
  operationCheckStats: {
    keyArgs: statsFieldKeyArgs,
    merge: false,
  },
  queryStats: {
    keyArgs: statsFieldKeyArgs,
    merge: false,
  },
  tracePathErrorsRefs: {
    keyArgs: statsFieldKeyArgs,
    merge: false,
  },
  traceRefs: {
    keyArgs: statsFieldKeyArgs,
    merge: false,
  },
};

export const typePolicies: InMemoryCacheConfig['typePolicies'] = {
  Account: {
    fields: {
      // `invitations` is a list of all the pending invitations, whenever we
      // fetch them, assume that we are getting them all, so remove the previous
      // values
      invitations: { merge: false },
      // `AccountRoles` is known to be constant given an `Account`
      roles: {
        merge: true,
      },
      services: {
        merge: false,
      },
      stats: {
        merge: true,
      },
      statsWindow: {
        merge: true,
      },
      billingInfo: {
        merge: true,
      },
      graphsConnection: {
        ...relayStylePagination(['filterBy']),
        // Custom read function that handles sorting graphs by title for consistent UX across
        // application/functionality and filtering duplicate graphs from the edge list
        // that may be introduced by other features, such as adding a new graph.  This allows
        // us to write to the field freely at any time/place in the app without having
        // to worry about the list being out of order or accidentally storing reference twice
        // if the graph is loaded later via pagination
        read(
          existing,
          { canRead, readField, toReference, isReference, ...rest },
        ) {
          const graphsConnection = relayStylePagination(['filterBy'])?.read?.(
            existing,
            { canRead, readField, toReference, isReference, ...rest },
          );

          /**
           * Use the node reference to attempt to read the `title` property from store.
           * If  no title found, attempt to read id.
           * Default to empty string.
           * Convert all read properties to string to be compared.
           *
           * @param nodeRef TRelayEdge<Reference>
           * @returns string
           */
          const getCompareValue = (nodeRef: TRelayEdge<Reference>): string => {
            // If we can read the title of the node, return the title/value as string
            if (canRead(readField('title', nodeRef))) {
              return (readField('title', nodeRef) || '').toString();
            }

            // For all other cases, attempt to read the ID of the node
            return (
              (canRead(readField('id', nodeRef))
                ? readField('id', nodeRef)?.toString()
                : '') || ''
            );
          };

          const edges = graphsConnection?.edges
            ? (
                _.uniqBy(graphsConnection?.edges, (edge) => {
                  if (canRead(readField('node', edge))) {
                    const node = readField('node', edge);
                    return isReference(node) ? readField('id', node) : node;
                  }
                  return edge;
                }) || []
              ).sort((a, b) => {
                const aValue = getCompareValue(a);
                const bValue = getCompareValue(b);

                return naturalCompare(aValue, bValue);
              })
            : graphsConnection?.edges;

          return graphsConnection
            ? { ...graphsConnection, edges }
            : graphsConnection;
        },
      },
    },
  },
  AccountStatsWindow: {
    fields: statsFieldFields,
  },
  RegistryStatsWindow: {
    merge: true,
  },
  AffectedQuery: {
    fields: {
      changes: {
        merge: false,
      },
    },
  },
  BillingPlan: {
    merge: true,
  },
  BillingSubscription: {
    keyFields: ['uuid'],
  },
  CheckWorkflow: {
    fields: {
      gitContext: {
        merge: true,
      },
    },
  },
  CompositionResult: { keyFields: ['graphCompositionID'] },
  CompositionPublishResult: {
    keyFields: ['graphCompositionID'],
  },
  CompositionValidationResult: {
    keyFields: ['graphCompositionID'],
  },
  EmailPreferences: {
    keyFields: ['email'],
    fields: {
      subscriptions: {
        // This is an array; the newest value is always right.
        merge: false,
      },
    },
  },
  FieldInsights: {
    keyFields: false,
  },
  GitContext: {
    keyFields: false,
  },
  GraphVariant: {
    fields: {
      permissions: { merge: true },
      fieldInsightsList: relayStylePagination([
        'from',
        'to',
        'filter',
        'orderBy',
      ]),
    },
  },
  GraphVariantPermissions: {
    keyFields: false,
  },
  GraphVariantMutation: {
    keyFields: false,
    merge: true,
  },
  OperationCollectionEntry: {
    fields: { currentOperationRevision: { merge: true } },
  },
  OperationCollection: {
    fields: {
      permissions: { merge: true },
      operation: {
        read(existing, { args, toReference }) {
          return (
            existing ??
            toReference({
              __typename: 'OperationCollectionEntry',
              id: args?.id,
            })
          );
        },
      },
    },
  },
  OperationCollectionPermissions: {
    keyFields: false,
  },
  OrganizationInviteLink: {
    keyFields: ['joinToken'],
  },
  PersistedQuery: {
    keyFields: ['id', 'clientName'],
  },
  Query: {
    fields: {
      stats: { merge: true },
      operationCollection: {
        read(existing, { args, toReference }) {
          return (
            existing ??
            toReference({
              __typename: 'OperationCollection',
              id: args?.id,
            })
          );
        },
      },
    },
  },
  RoleOverride: {
    keyFields: ['role', 'user', ['id']],
  },
  StatsWindow: {
    fields: statsFieldFields,
  },
  ServiceQueryStatsDimensions: {
    keyFields: false,
  },
  Schema: {
    // XXX really we should be able to use hash alone;
    // in the case of a schema check, (SchemaTag.schema) these can actually
    // resolve differently depending on `createdAt`.  Fix me when
    // we no longer use the Schema type to have multiple meanings.
    keyFields: ['hash', 'createdAt'],
  },
  SchemaDiff: {
    keyFields: false,
    merge: true,
  },
  Secret: {
    keyFields: ['hash'],
  },
  Service: {
    fields: {
      // `apiKeys` is an array that will always be fetched in it's entirety;
      // always overwrite cache with new data.
      apiKeys: { merge: false },
      // thus far, all channels are always fetched together
      channels: { merge: false },
      queryTriggers: { merge: false },
      // `ServiceRoles` is known to be constant given a `Service`
      roles: {
        merge: true,
      },
      services: {
        merge: false,
      },
      stats: {
        merge: true,
      },
      statsWindow: {
        merge: true,
      },
      roleOverrides: {
        merge: false,
      },
      operationCheckRequestsByVariant: {
        merge: false,
      },
    },
  },
  ServiceMutation: {
    keyFields: false,
    merge: true,
  },
  ServiceStatsWindow: {
    fields: statsFieldFields,
  },
  TraceNode: {
    // The TraceNode id field is unfortunately only unique for a given Trace.
    keyFields: false,
  },
  AccountMembership: {
    keyFields: ['user', ['id'], `account`, ['id']],
  },
  UserMembership: {
    keyFields: ['user', ['id'], 'account', ['id']],
  },

  // Keeping this UserMutation in type policies to prevent an Apollo client local cache warning
  UserMutation: {
    keyFields: false,
    merge: true,
  },
  Proposal: {
    fields: {
      activities: relayStylePagination(),
      revisionHistory: {
        keyArgs: ['orderBy'],
        merge(existing, incoming, { args }) {
          const merged = existing?.revisions.slice() ?? [];
          merged.splice(
            args?.offset ?? 0,
            incoming?.revisions.length ?? 0,
            ...(incoming?.revisions ?? []),
          );

          return {
            ...existing,
            ...incoming,
            revisions: merged,
          };
        },
      },
    },
  },
  CoreSchema: { keyFields: ['coreHash'] },
};
