import { useEffect } from 'react';

import { isEmbeddableSandboxRoute } from 'src/app/embeddableSandbox/isEmbeddableSandboxRoute';
import { ampli } from 'src/lib/analytics/amplitude/vendor';
import Config from 'src/lib/config';
import { useLDFlag } from 'src/lib/launchDarkly';

const { studioUrl } = Config.settings;

export const getAmplitudeDeviceId = async () => {
  return new Promise((resolve, _) => {
    setTimeout(() => {
      const deviceId = ampli.client.getDeviceId();
      resolve(deviceId);
    }, 1000);
  });
};

const syncViaIframe = (url: string) => {
  const iframe = document.createElement('iframe');
  iframe.src = url;
  iframe.style.display = 'none';
  iframe.style.border = 'none';
  iframe.style.height = '0';
  iframe.style.width = '0';
  document.body.appendChild(iframe);
};

const sendSyncSignal = () => {
  getAmplitudeDeviceId().then((deviceId) => {
    // Be nice and avoid hogging a socket for the first 10 seconds.
    setTimeout(() => {
      const url = `${studioUrl}/iframeIdentitySync?did=${deviceId}`;
      syncViaIframe(url);
    }, 10 /* seconds */ * 1000 /* milliseconds */);
  });
};

export const useSyncAmplitudeForEmbedded = () => {
  const trackEmbeddedUsers = useLDFlag('betelgeuse-amplitude-in-embedded');

  useEffect(() => {
    if (isEmbeddableSandboxRoute() && trackEmbeddedUsers) {
      sendSyncSignal();
    }
  }, [trackEmbeddedUsers]);
};
