import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

import type { TagColorScheme } from './shared';

const { bg, icon, text } = customColors;
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const $bg = cssVar('tag-bg');
const $color = cssVar('tag-color');
const $shadow = cssVar('tag-shadow');

interface ColorSchemeProperties {
  base: string;
  hover: string;
  text: string;
  icon: string;
}

const COLORS_SCHEMES = {
  blue: {
    base: bg.info,
    hover: bg['blue-tag-hover'],
    text: text.info,
    icon: icon.info,
  },
  gray: {
    base: bg.neutral,
    hover: bg['gray-tag-hover'],
    text: text.neutral,
    icon: icon.secondary,
  },
} satisfies Record<TagColorScheme, ColorSchemeProperties>;

const baseStyle = definePartsStyle(({ colorScheme }) => {
  const colors = COLORS_SCHEMES[colorScheme as TagColorScheme];

  return {
    container: {
      [$bg.variable]: colors.base,
      [$color.variable]: colors.text,
      [$shadow.variable]: false,
      py: 0,
      pl: 2,
      pr: 0,
      fontSize: 'sm',
      fontFamily: 'body',
      fontWeight: 'semibold',
      lineHeight: 'sm',
      boxShadow: false,
      rounded: 'base',
      transitionProperty: 'colors',
      transitionDuration: 'fast',
      transitionTimingFunction: 'ease-out',
      gap: 2,
      minH: false,
      minW: false,

      '.tag-icon': {
        display: 'inline-block',
        color: colors.icon,
        w: 4,
        h: 4,
      },
    },
    closeButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      w: 6,
      h: 6,
      marginStart: false,
      marginEnd: false,
      color: colors.icon,
      fontSize: false,
      opacity: false,
      borderLeftRadius: 'none',
      borderRadius: 'base',
      _hover: {
        opacity: false,
        borderLeftRadius: 'none',
        bg: colors.hover,
      },
    },
  };
});

export const TagStyles = defineMultiStyleConfig({ baseStyle });
