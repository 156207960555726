import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  Resolvers,
  createHttpLink,
} from '@apollo/client';
import React, { PropsWithChildren, useMemo } from 'react';

import { isEmbeddableExplorerRoute } from 'src/app/embeddableExplorer/isEmbeddableExplorerRoute';
import { isEmbeddableSandboxRoute } from 'src/app/embeddableSandbox/isEmbeddableSandboxRoute';
import { useEmbedUserApiKey } from 'src/app/embedHelpers/useEmbedUserApiKey';

import Config from '../config';
import { runtimeConfig } from '../config/runtime';
import { generateUniqueString } from '../generateUniqueString';
import { BackendRouter } from '../routers';

import { resolvers, typeDefs } from './client-schema';
import { chain } from './link';

import { createCache } from '.';

export const makeClient = ({
  headers,
  customClientResolvers,
}: {
  headers?: Record<string, string>;
  customClientResolvers?: Resolvers;
} = {}) => {
  const graphqlEndpoint = BackendRouter.path('GraphQL');
  const http = createHttpLink({
    uri: (operation) => {
      if (runtimeConfig.shouldAppendOperationNameToGraphqlNetworkRequests) {
        return `${graphqlEndpoint}?operationName=${encodeURIComponent(
          operation.operationName,
        )}`;
      } else {
        return graphqlEndpoint;
      }
    },
    credentials:
      isEmbeddableExplorerRoute() || isEmbeddableSandboxRoute()
        ? 'omit'
        : 'include',
    fetch,
    headers: headers ?? {},
  });

  return new ApolloClient({
    cache: createCache(),
    assumeImmutableResults: true,
    connectToDevTools: true,
    link: ApolloLink.from(chain.concat([http])),
    resolvers: customClientResolvers ?? resolvers,
    typeDefs,
    name: Config.settings.apolloClientClientName,
    version: Config.gitCommitHash,
  });
};

export const ApolloClientProvider = ({ children }: PropsWithChildren<{}>) => {
  const embedUserApiKey = useEmbedUserApiKey();

  const client = useMemo(() => {
    return makeClient({
      headers: {
        // for debugging traffic from api log
        'x-instance-id': generateUniqueString(),
        ...(embedUserApiKey && { 'x-api-key': embedUserApiKey }),
      },
    });
  }, [embedUserApiKey]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
