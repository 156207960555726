import IconCheck2 from '@apollo/icons/small/IconCheck2.svg';
import IconIntermediate from '@apollo/icons/small/IconIntermediate.svg';
import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  SystemStyleObject,
  ThemingProps,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type CheckboxProps = OmitChakraProps<
  ChakraCheckboxProps,
  keyof ThemingProps
> & {
  /**
   * Additional style config needed for component that wraps `Checkbox`. This
   * should only be used internally by other Orbit components. Please use
   * `className` for all styling.
   *
   * @internal
   */
  __orbitStyleConfig?: SystemStyleObject;
};

function CheckBoxIcon({
  isChecked,
  isIndeterminate,
}: {
  isChecked?: boolean;
  isIndeterminate?: boolean;
}) {
  if (isIndeterminate) {
    return <IconIntermediate />;
  } else if (isChecked) {
    return <IconCheck2 />;
  } else {
    return null;
  }
}

export const Checkbox = ({ __orbitStyleConfig, ...props }: CheckboxProps) => {
  const [input, setInput] = useState<HTMLInputElement | null>(null);
  useEffect(() => {
    // Chakra doesn't seem to set `aria-checked` to mixed anymore, I can't figure
    // out if this is required or not, but we have tests that check that it's set, so lets set it!
    const value = props.isIndeterminate ? 'mixed' : props.isChecked?.toString();
    if (value !== undefined) input?.setAttribute('aria-checked', value);
  }, [input, props.isChecked, props.isIndeterminate, props.children]);

  return (
    <ChakraCheckbox
      ref={setInput}
      icon={<CheckBoxIcon />}
      {...props}
      sx={__orbitStyleConfig}
    />
  );
};
