import { ApolloError } from '@apollo/client';
import { utmGrabber } from '@apollo/utm-grabber';
import React, { useEffect } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { Outlet } from 'react-router-dom-v5-compat';

import { Loading } from 'src/components/common/loading/Loading';
import { NoBillingEmailBanner } from 'src/components/noBillingEmailBanner/NoBillingEmailBanner';
import { NoVerifiedEmailBanner } from 'src/components/noVerifiedEmailBanner/NoVerifiedEmailBanner';
import { PermissionGuard } from 'src/components/permissionGuards/permissionGuard/PermissionGuard';
import { PublicVariantPreviewBanner } from 'src/components/publicVariantPreviewBanner/PublicVariantPreviewBanner';
import { SsoMigrationBanner } from 'src/components/ssoMigrationBanner/SsoMigrationBanner';
import { useCurrentPlan } from 'src/hooks/currentPlanV2Migration';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { isUserIdentity, useIdentity } from 'src/hooks/useIdentity';
import { DocsRouter } from 'src/lib/routers';

import { invite } from '../account/routes';
import { AppModals } from '../appModals/AppModals';
import * as routes from '../onboarding/routes';

import { ScheduledMaintenances } from './components/scheduledMaintenances/ScheduledMaintenances';
import { TrialAlertBanner } from './components/trialAlertBanner/TrialAlertBanner';
import { UsageLimitAlerts } from './components/usageLimitAlerts/UsageLimitAlerts';

export const AppLayout = () => {
  const { me, meLoading, error: useIdentityError } = useIdentity();
  const [currentAccountId] = useCurrentAccountId();
  const currentPlanResult = useCurrentPlan({
    skip: !currentAccountId,
    accountId: currentAccountId || '',
  });

  useEffect(() => {
    // https://github.com/apollographql/dxe/tree/main/packages/utm-grabber
    utmGrabber();
  }, []);

  const isInviteRoute = !!useRouteMatch(invite.definition);
  const isWelcomeRoute = !!useRouteMatch(routes.welcome.definition);

  if (useIdentityError) {
    if (
      // try to figure out if this is a network error
      !(
        useIdentityError instanceof ApolloError &&
        useIdentityError.networkError?.message &&
        // different browsers have different messages for network errors
        [
          'Failed to fetch',
          'NetworkError when attempting to fetch resource.',
        ].includes(useIdentityError.networkError.message)
      )
    ) {
      // was not a network error / an unforeseen error has occurred
      throw useIdentityError;
    }
    // the user is offline, carry on and don't make a fuss
  }

  if (meLoading) return <Loading />;

  if (
    !isInviteRoute &&
    !isWelcomeRoute &&
    me &&
    isUserIdentity(me) &&
    ((!me.email && me.canUpdateEmail) ||
      (!me.fullName && me.canUpdateFullName) ||
      !me.acceptedPrivacyPolicyAt)
  ) {
    return <Redirect to={routes.welcome.location({})} />;
  }

  return (
    <div className="flex h-full flex-col">
      <div className="flex h-full flex-col">
        <AppModals />
        {currentAccountId && !currentPlanResult?.data?.account?.isLocked && (
          <PermissionGuard
            accountId={currentAccountId}
            accountPermissions="canQueryBillingInfo"
            fallback={null}
          >
            <NoBillingEmailBanner accountId={currentAccountId} />
            {currentPlanResult?.currentPlan?.isTrial && (
              <TrialAlertBanner
                accountId={currentAccountId}
                learnMoreHref={DocsRouter.path('EnterpriseTrialOrgPlan')}
              />
            )}
          </PermissionGuard>
        )}
        {me && <NoVerifiedEmailBanner />}
        {currentAccountId && !currentPlanResult?.data?.account?.isLocked && (
          <SsoMigrationBanner accountId={currentAccountId} />
        )}
        <PublicVariantPreviewBanner />
        <ScheduledMaintenances />
        <UsageLimitAlerts />
        <Outlet />
      </div>
    </div>
  );
};
