import {
  Table as ChakraTable,
  TableProps as ChakraTableProps,
  ThemingProps,
} from '@chakra-ui/react';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';

import { useEvent } from '../../hooks/useEvent';
import { SortOrder } from '../../types';
import { OmitChakraProps } from '../../utils/typeUtils';

import { TableContext, TableProvider } from './Table.context';

interface SortProperties<TSortBy extends string | number> {
  sortOrder: SortOrder;
  sortBy: TSortBy;
}

export interface TableProps<TSortBy extends string | number = string | number>
  extends Omit<OmitChakraProps<ChakraTableProps, keyof ThemingProps>, 'as'> {
  isInteractive?: boolean;
  variant?: 'plain' | 'striped';
  size?: 'condensed' | 'default';
  sortOrder?: SortOrder;
  sortBy?: TSortBy;
  defaultSortBy?: TSortBy;
  defaultSortOrder?: SortOrder;
  onRequestSort?: (
    sortProperties: SortProperties<TSortBy>,
    event: React.MouseEvent,
  ) => void;
}

function TableInner<TSortBy extends string | number>(
  {
    isInteractive: interactive,
    sortOrder,
    sortBy,
    defaultSortOrder = 'asc',
    defaultSortBy,
    onRequestSort = () => {},
    ...props
  }: TableProps<TSortBy>,
  ref: React.ForwardedRef<HTMLTableElement>,
) {
  const handleSort = useEvent(onRequestSort);
  const [sortOrderState, setSortOrderState] = useState(defaultSortOrder);
  const [sortByState, setSortByState] = useState(defaultSortBy);

  const setSort = useCallback(
    (
      attribute: string | number,
      nextSortOrder: SortOrder,
      event: React.MouseEvent,
    ) => {
      if (sortOrder === undefined) {
        setSortOrderState(nextSortOrder);
      }

      if (sortBy === undefined) {
        setSortByState(attribute as TSortBy);
      }

      handleSort(
        { sortOrder: nextSortOrder, sortBy: attribute as TSortBy },
        event,
      );
    },
    [sortOrder, sortBy, handleSort],
  );

  const context = useMemo<TableContext>(
    () => ({
      sortOrder: sortOrder === undefined ? sortOrderState : sortOrder,
      sortBy: sortBy === undefined ? sortByState : sortBy,
      setSort,
    }),
    [sortOrder, sortOrderState, sortBy, sortByState, setSort],
  );

  return (
    <TableProvider value={context}>
      <ChakraTable ref={ref} data-interactive={interactive} {...props} />
    </TableProvider>
  );
}

export const Table = forwardRef<HTMLTableElement, TableProps>(TableInner) as <
  TSortBy extends string | number = string | number,
>(
  props: TableProps<TSortBy> & { ref?: React.ForwardedRef<HTMLTableElement> },
) => JSX.Element;
