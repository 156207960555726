import {
  TableRowProps as ChakraTableRowProps,
  Tr as ChakraTr,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type TableRowProps = OmitChakraProps<ChakraTableRowProps>;

export const Tr = forwardRef<TableRowProps, 'tr'>((props, ref) => {
  return <ChakraTr {...props} ref={ref} />;
});
