import React from 'react';

import { usePerKeyLocalStorage } from 'src/hooks/usePerKeyLocalStorage';

import {
  EXPLORER_LISTENING_FOR_HANDSHAKE,
  HANDSHAKE_RESPONSE,
  IncomingEmbedMessageEvent,
  sendPostMessageFromEmbedToParent,
} from '../graph/explorerPage/helpers/postMessageHelpers';

export const useListenForHandshakeResponseFromEmbed = () => {
  const [_, setEmbedAuthenticationDetails] = usePerKeyLocalStorage({
    initialValue: undefined,
    key: document.referrer,
    localStorageKey: 'embedAuthenticationDetails',
  });
  const [__, setEmbedParentHref] = usePerKeyLocalStorage({
    initialValue: undefined,
    key: document.referrer,
    localStorageKey: 'embedParentHref',
  });
  const handleHandshakeMessage = React.useCallback(
    (event: IncomingEmbedMessageEvent) => {
      if (event.data.name === HANDSHAKE_RESPONSE) {
        setEmbedAuthenticationDetails({
          origin: event.origin,
          inviteToken: event.data.inviteToken,
          accountId: event.data.accountId,
        });
        setEmbedParentHref(event.data.parentHref);

        window.removeEventListener('message', handleHandshakeMessage);
      }
    },
    [setEmbedAuthenticationDetails, setEmbedParentHref],
  );

  React.useEffect(() => {
    window.addEventListener('message', handleHandshakeMessage);

    // We need to send the parent page a message when the embedded explorer is listening
    // for a handshake so that the parent can send us their origin via post message.
    // This is more reliable than the iframe onload function
    sendPostMessageFromEmbedToParent({
      name: EXPLORER_LISTENING_FOR_HANDSHAKE,
    });
    return () => {
      window.removeEventListener('message', handleHandshakeMessage);
    };
  }, [handleHandshakeMessage]);
};
