import { generateLongUniqueString } from 'src/lib/generateUniqueString';

export const splitEmbedToken = (token: string) => {
  const textEncoder = new TextEncoder();
  // Create random string/one-time pad with same length as API key to be used as
  // a partial key on the embed. This string will be XORed with another partial
  // key to combine into the full user API key
  const partialEmbedToken = generateLongUniqueString(token.length);

  // Encode the strings to bytes so that they can be XORed
  const encodedEmbedToken = textEncoder.encode(token);
  const partialEncodedEmbedToken = textEncoder.encode(partialEmbedToken);

  // XOR each byte of the API key with each byte of the partial embed token.
  // Result of this can be XORed with the partial embed token to restore API key
  const partialEncodedParentToken = new Uint8Array(token.length);
  for (let i = 0; i < encodedEmbedToken.length; i++) {
    partialEncodedParentToken[i] =
      // eslint-disable-next-line no-bitwise
      (encodedEmbedToken[i] ?? 0) ^ (partialEncodedEmbedToken[i] ?? 0);
  }
  return {
    partialEmbedToken,
    // Decoding encoded bytes so it can be stored as a string in local storage
    partialParentToken: new TextDecoder().decode(partialEncodedParentToken),
  };
};

export const combinePartialEmbedTokens = (
  partialEmbedToken: string,
  partialParentToken: string,
) => {
  const textEncoder = new TextEncoder();
  const combinedEncodedToken = new Uint8Array(partialEmbedToken.length);
  // Encode the strings to bytes so that they can be XORed
  const partialEncodedEmbedToken = textEncoder.encode(partialEmbedToken);
  const partialEncodedParentToken = textEncoder.encode(partialParentToken);

  // XOR each byte of the partial embed token with each byte of the partial
  // parent token. The result of this will be the original user API key
  for (let i = 0; i < combinedEncodedToken.length; i++) {
    combinedEncodedToken[i] =
      // eslint-disable-next-line no-bitwise
      (partialEncodedEmbedToken[i] ?? 0) ^ (partialEncodedParentToken[i] ?? 0);
  }
  // Decoding encoded bytes so the user API key string can be restored
  return new TextDecoder().decode(combinedEncodedToken);
};
