import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';

import Config from '../config';

import { resolvers, typeDefs } from './client-schema';
import { link } from './link';
import possibleTypes from './possibleTypes.json';
import { typePolicies } from './typePolicies/typePolicies';

export const createCache = () =>
  new InMemoryCache({
    possibleTypes: possibleTypes.possibleTypes,
    typePolicies,
  });

export const client = new ApolloClient({
  cache: createCache(),
  assumeImmutableResults: true,
  connectToDevTools: true,
  link: ApolloLink.from([link]),
  resolvers,
  typeDefs,
  name: window.Cypress
    ? `web-cypress-${Config.settings.env}`
    : `web-${Config.settings.env}`,
  version: Config.gitCommitHash,
});
