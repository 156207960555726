import { useEffect, useMemo, useState } from 'react';

import { usePerKeyLocalStorage } from 'src/hooks/usePerKeyLocalStorage';

import {
  EXPLORER_LISTENING_FOR_PARTIAL_TOKEN,
  IncomingEmbedMessageEvent,
  PARTIAL_AUTHENTICATION_TOKEN_RESPONSE,
  sendPostMessageFromEmbedToParent,
} from '../graph/explorerPage/helpers/postMessageHelpers';
import { graphRefToString, useGraphRef } from '../graph/hooks/useGraphRef';

import { constructEmbedLocalStorageKey } from './constructEmbedLocalStorageKey';
import { combinePartialEmbedTokens } from './embedApiTokenHelpers';

export const useEmbedUserApiKey = () => {
  const graphRef = useGraphRef();
  const graphRefString = graphRefToString(graphRef) ?? undefined;
  const [embedAuthenticationDetails] = usePerKeyLocalStorage({
    initialValue: undefined,
    key: document.referrer,
    localStorageKey: 'embedAuthenticationDetails',
  });

  const embedLocalStorageKey = useMemo(() => {
    return embedAuthenticationDetails?.origin
      ? constructEmbedLocalStorageKey({
          origin: embedAuthenticationDetails.origin,
          graphRef: graphRefString,
        })
      : undefined;
  }, [embedAuthenticationDetails, graphRefString]);
  const [partialEmbedUserApiToken] = usePerKeyLocalStorage({
    localStorageKey: 'partialEmbedUserApiTokens',
    key: embedLocalStorageKey ?? '',
    initialValue: undefined,
  });
  const [embedUserApiKey, setEmbedUserApiKey] = useState<string | undefined>();

  useEffect(() => {
    const handleTokenMessage = (event: IncomingEmbedMessageEvent) => {
      if (
        event.data.name === PARTIAL_AUTHENTICATION_TOKEN_RESPONSE &&
        partialEmbedUserApiToken?.partialToken
      ) {
        setEmbedUserApiKey(
          combinePartialEmbedTokens(
            partialEmbedUserApiToken.partialToken,
            event.data.partialToken,
          ),
        );
      }
    };
    window.addEventListener('message', handleTokenMessage);

    sendPostMessageFromEmbedToParent({
      name: EXPLORER_LISTENING_FOR_PARTIAL_TOKEN,
      localStorageKey: embedLocalStorageKey,
    });

    // keep the local state api key returned here in line with local storage
    // if the partial embed user token is removed
    if (!partialEmbedUserApiToken) {
      setEmbedUserApiKey(undefined);
    }

    return () => {
      window.removeEventListener('message', handleTokenMessage);
    };
  }, [embedLocalStorageKey, partialEmbedUserApiToken]);

  return embedUserApiKey;
};
