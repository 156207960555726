import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const { brand, text } = customColors;

const baseStyle = defineStyle(({ colorScheme }) => ({
  color: colorScheme
    ? brand[colorScheme as keyof typeof customColors.brand]
    : text.heading,
}));

export const ApolloLogoStyles = defineStyleConfig({
  baseStyle,
  variants: {},
  sizes: {},
});
