import {
  CardHeader as ChakraCardHeader,
  CardHeaderProps as ChakraCardHeaderProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type CardHeaderProps = OmitChakraProps<ChakraCardHeaderProps>;

export const CardHeader = forwardRef<CardHeaderProps, 'div'>(
  ({ children, ...props }, ref) => {
    return (
      <ChakraCardHeader ref={ref} {...props}>
        {children}
      </ChakraCardHeader>
    );
  },
);
