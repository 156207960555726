import { forwardRef, useCardStyles } from '@chakra-ui/react';
import React from 'react';

import { Heading, HeadingProps } from '../Heading/Heading';

export type CardTitleProps = Omit<
  HeadingProps<'h2'>,
  'size' | 'variant' | '__orbitStyleConfig'
>;

export const CardTitle = forwardRef<CardTitleProps, 'h2'>((props, ref) => {
  const styles = useCardStyles();

  return (
    <Heading {...props} ref={ref} size="xl" __orbitStyleConfig={styles.title} />
  );
});
