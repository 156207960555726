import {
  ModalContent as ChakraModalContent,
  ModalContentProps as ChakraModalContentProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type ModalContentProps = OmitChakraProps<ChakraModalContentProps>;

export const ModalContent = forwardRef<ModalContentProps, 'section'>(
  (props, ref) => {
    return <ChakraModalContent {...props} ref={ref} />;
  },
);
