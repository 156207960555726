import { FormControl, FormLabel, Radio, RadioGroup } from '@apollo/orbit';
// TODO: Remove use of `Stack` component
// eslint-disable-next-line no-restricted-imports
import { Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

interface Props {
  handleConsentSelection: (consents: boolean) => void;
}

export const UserResearchOptIn = ({ handleConsentSelection }: Props) => {
  const [value, setValue] = useState<'' | 'opt-in' | 'opt-out'>('');

  useEffect(() => {
    if (value) {
      handleConsentSelection(value === 'opt-in');
    }
  });

  return (
    <FormControl className="flex flex-col gap-6 text-primary">
      <h2 className="text-heading-2xl text-center">
        Optional: Join Apollo’s research community!
      </h2>
      <>
        <p className="mb-4">
          Share your GraphOS experiences with us and have a direct influence on
          the future of GraphQL. All levels of experience are welcome to share
          feedback 🚀
        </p>
        <p>
          By choosing to opt-in, you give Apollo permission to contact you at
          the email you’ve provided for future research opportunities.
        </p>
      </>
      <fieldset>
        <FormLabel className="sr-only">
          Do you consent to share your email with Apollo?
        </FormLabel>
        <RadioGroup
          onChange={(selectedValue) => {
            if (selectedValue === 'opt-in' || selectedValue === 'opt-out') {
              setValue(selectedValue);
            } else {
              setValue('');
            }
          }}
          value={value}
          className="text-body"
        >
          <Stack className="gap-2 space-y-2">
            <Radio value="opt-in">
              I consent to sharing my email with Apollo and being contacted for
              future opportunities
            </Radio>
            <Radio value="opt-out">No thanks!</Radio>
          </Stack>
        </RadioGroup>
      </fieldset>
    </FormControl>
  );
};
