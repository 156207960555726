import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  SystemStyleObject,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';
import { Spinner } from '../Spinner/Spinner';

export interface ButtonProps
  extends OmitChakraProps<
    ChakraButtonProps,
    | 'iconSpacing'
    | 'spinner'
    | 'spinnerPlacement'
    | 'styleConfig'
    | 'orientation'
    | 'colorScheme'
    | 'variant'
    | 'size'
    | 'disabled'
    | 'loading'
  > {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  variant?:
    | 'buttonLink'
    | 'primary'
    | 'secondary'
    | 'hidden'
    | 'destructive'
    | 'brandDark'
    | 'brandLight';

  /**
   * Use `isDisabled` instead
   */
  disabled?: never;

  /**
   * Use `isLoading` instead
   */
  loading?: never;

  /**
   * Additional style config needed for the button. This should only be used
   * internally by other Orbit components. Please use `className` for all
   * styling.
   * @internal
   */
  __orbitStyleConfig?: SystemStyleObject;
}

export const Button = forwardRef<ButtonProps, 'button'>(
  ({ __orbitStyleConfig, ...props }, ref) => {
    // We need to conditionally add the `sx` prop like this, otherwise the
    // presence of the `sx` key in props changes the hash of the
    // class and conflicts with the buttonOverride style for menu buttons.
    //
    // This can and should be removed if Orbit migrates to use Tailwind classes
    // at some point in the future.
    const spreadProps: ChakraButtonProps = { ...props };
    if (__orbitStyleConfig) {
      spreadProps.sx = __orbitStyleConfig;
    }

    return (
      <ChakraButton
        {...spreadProps}
        as={props.isDisabled ? undefined : props.as}
        ref={ref}
        iconSpacing={0}
        spinner={
          <Spinner variant="neutral" size={getSpinnerSize(props.size)} />
        }
      />
    );
  },
);

function getSpinnerSize(size: ButtonProps['size']) {
  switch (size) {
    case 'lg':
      return 'sm';
    case 'xs':
      return 'tiny';
    case 'md':
    case 'sm':
    default:
      return 'xs';
  }
}
