/* eslint-disable func-names */
import { captureException } from '@sentry/react';
import type History from 'history';
import _ from 'lodash';
import pathToRegExp from 'path-to-regexp';
import { ParsedQuery, parse, stringify } from 'query-string';
import { ExtractRouteParams } from 'react-router';
import { matchPath } from 'react-router-dom';

import { assertIfNotProd } from '../assertUnreachable';
import Config from '../config';
import { Team } from '../teamOwnership';

import { addFaultToleranceToParse } from './addFaultToleranceToParse';
import { locationToPath } from './locationToPath';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyRouteConfig = RouteConfig<any, any, any, any, any>;

export const __registeredConfigs: AnyRouteConfig[] = [];
// TODO: These are not handled in the react-router v6 conversion functions below.
// Before these routes an be used in react-router v6 we will need to fix them.
// The problem is that these use partial matches with eh `.` separator within 1 segment
const notImplemented = [
  '/insights/schema/:typeName.:fieldName',
  '/:graphVisibilityType(graph|public)/:graphId/variant/:graphVariant/insights/schema/:typeName.:fieldName',
];

/**
 * Generic taking two objects and returning the parameters that are required to
 * complete `To` when spread all `From` values.
 */
type Needed<From, To> = {
  [Key in Exclude<RequiredKeys<To>, RequiredKeys<From>> & {
    [key: string]: never;
  }]: To[Key];
};

export type RouteConfigParams<Config> =
  Config extends RouteConfig<
    infer MatchParams,
    infer SearchParams,
    infer State,
    infer Hash,
    infer _Definition
  >
    ? MatchParams & SearchParams & State & Hash
    : never;

interface LocationOptions {
  /**
   * Return an absolute URL
   *
   * @default `false`
   */
  absolute?: boolean;
}

export type AbsoluteOrEmptyPath = `/${string}` | '';

/**
 * This interface exists solely to allow `RouteConfig` constructor arguments and
 * the class itself to conform to the same interface with the same jsdoc
 * comments.
 *
 * Note that the _types_ of the class parameters can be inferred as of
 * TypeScript 4; but the jsdoc comments will not.
 */
interface RouteConfigConstructorParams<
  MatchParams extends Record<string, unknown>,
  SearchParams extends Record<string, unknown>,
  State,
  Hash,
  Definition extends AbsoluteOrEmptyPath,
> {
  /**
   * Absolute definition of the route string. This will be used in `<Route path={...} />`
   *
   * If this includes an optional segment (`/:foo?`), use `config.splatDefinition` instead of config.definition
   *
   * If this includes an enum segment (`/:foo(bar|baz)`), use `config.patchV5DefinitionsForV6()` instead of config.definition and map the definitions to multiple routes
   */
  definition: Definition;

  /**
   * Function to parse an unknown object of match params into the expected match
   * params format. The type of `MatchParams` will be inferred from the return
   * of this function.
   *
   * @default `() => ({})`
   */
  parseMatchParams?: (
    params: undefined | ExtractRouteParams<Definition, string>,
  ) => MatchParams;

  /**
   * Function to parse an unknown object of query string params into the
   * expected shape. The type of `SearchParams` will be inferred from the
   * return of this function.
   *
   * @default `() => ({})`
   */
  parseSearchParams?: (queryStringParams: ParsedQuery) => SearchParams;

  /**
   * Function to parse an unknown location state into the expected match shape.
   * The type of `State` will be inferred from the return of this
   * function.
   *
   * @default `() => ({})`
   */
  parseState?: (state: unknown) => State;

  /**
   * Function to parse an string into the expected hash shape.
   * The type of `Hash` will be inferred from the return of this
   * function. RouteConfig instance needs to write their own one of these
   *
   * Since hash strings don't have name/value pairs that can be parsed,
   * consumer of RouteConfig using hash will need to write a custom `parseHash`
   * to parse the hash string to the expected hash params shape -> there is no way
   * to parse a hash string into an object generally
   *
   * @default `() => ({})`
   */
  parseHash?: (hash: string) => Hash;

  /**
   * Accept parameters of type `MatchParams` and convert that into a value to be
   * used in `History.Location`'s `pathname`.
   */
  locationPathname?: (
    this: RouteConfig<MatchParams, SearchParams, State, Hash, Definition>,
    params: MatchParams,
  ) => History.Location<State>['pathname'];

  /**
   * Accept parameters of type `SearchParams` and convert that into a value
   * to be used in `History.Location`'s `search`.
   */
  locationSearch?: (
    this: RouteConfig<MatchParams, SearchParams, State, Hash, Definition>,
    params: SearchParams,
  ) => History.Location<State>['search'];

  /**
   * Accept parameters of type `State` and convert that into a value to be
   * used in `History.Location`'s `state`.
   */
  locationState?: (
    this: RouteConfig<MatchParams, SearchParams, State, Hash, Definition>,
    params: State,
  ) => History.Location<State>['state'];

  /**
   * Accept parameters of type `Hash` and convert that into a value to be
   * used in `History.Location`'s `hash`.
   * By default, this function doesn't return anything,
   * you need to pass your own `locationHash` function based on the structure of
   * your hash string to get the string from the params object
   */
  locationHash?: (
    this: RouteConfig<MatchParams, SearchParams, State, Hash, Definition>,
    params: Hash,
  ) => History.Location<State>['hash'];
}

type OwnerInput<
  MatchParams extends Record<string, unknown>,
  SearchParams extends Record<string, unknown>,
  State,
  Hash,
> =
  | ((
      matchParams: MatchParams,
      SearchParams: SearchParams,
      state: State,
      hash: Hash,
    ) => [Team, ...Team[]])
  | [Team, ...Team[]];

function patchExactDefinitionForV6<Definition extends string>(
  definition: Definition,
): `${Definition}/*` {
  if (definition.endsWith('?')) {
    assertIfNotProd(
      'definitions with optional segments must use config.patchV5DefinitionsForV6',
    );
  }

  if (definition === '') {
    assertIfNotProd('empty definition');
    return definition as `${Definition}/*`;
  } else {
    return definition.replace(/(\/\*?)?$/, '/*') as `${Definition}/*`;
  }
}

/**
 * Abstraction to unify definition of and access of routes, intended to
 * encapsulate all the logic for generating routes and encoding/decoding the
 * data stored in the route.
 *
 * Each `RouteConfig` has functions to parse match parameters, search strings,
 * and state. Along with those functions, each `RouteConfig` has functions to
 * translate those parameters into values for a
 * `History.Location` (`pathname`, `search`, and `state`). All
 * of the parse functions and location functions have defaults, so none are
 * required.
 */
export class RouteConfig<
  MatchParams extends Record<string, unknown>,
  SearchParams extends Record<string, unknown>,
  State,
  Hash,
  Definition extends AbsoluteOrEmptyPath,
> implements
    RouteConfigConstructorParams<
      MatchParams,
      SearchParams,
      State,
      Hash,
      Definition
    >
{
  public static DELETE = Symbol('delete');

  public readonly definition;

  public readonly locationPathname;
  public readonly locationSearch;
  public readonly locationState;
  public readonly locationHash;

  // These have types because they self-reference in the constructor and,
  // therefore, the types can't be inferred.
  public readonly parseMatchParams: (
    params: undefined | ExtractRouteParams<Definition, string>,
  ) => MatchParams;
  public readonly parseSearchParams: (
    queryStringParams: ParsedQuery,
  ) => SearchParams;
  public readonly parseState: (state: unknown) => State;
  public readonly parseHash: (hash: string) => Hash;

  protected compiledPathToRegExp: pathToRegExp.PathFunction;

  private readonly owners:
    | OwnerInput<MatchParams, SearchParams, State, Hash>
    | undefined;

  private parents: AnyRouteConfig[] = [];
  private extendedFrom: AnyRouteConfig | null = null;
  private cachedSplitOnMatch: string[];

  constructor({
    definition,
    parseMatchParams = () => ({}) as MatchParams,
    parseSearchParams = () => ({}) as SearchParams,
    parseState = () => ({}) as State,
    parseHash = () => ({}) as Hash,
    locationPathname = function (params) {
      return this.compiledPathToRegExp(params);
    },
    locationSearch = function (params) {
      return stringify(
        this.parseSearchParams(params as ParsedQuery<string>) as never,
      );
    },
    locationState = parseState,
    locationHash = () => '',
    owners,
  }: RouteConfigConstructorParams<
    MatchParams,
    SearchParams,
    State,
    Hash,
    Definition
  > &
    (Definition extends ''
      ? { owners?: undefined }
      : {
          /**
           * Use owners to configure internal team contact information per route.
           *
           * Each route will require atleast 1 team owner, but can have multiple
           *
           * Options to configure
           * Array - will be treated as a list of teams owning this route
           * Function - will be called with the parsed params and should return a list of teams owning this route
           *
           * We have kept this as explicit teams per route without any parent/child inheritence.
           * If there is a route that shouldn't render, and currently doesn't have an explicit owner,
           * we can use the `Unowned` team until a team is assigned
           */
          owners: OwnerInput<MatchParams, SearchParams, State, Hash>;
        })) {
    this.owners = owners;
    this.definition = definition;
    this.locationPathname = locationPathname;
    this.locationSearch = locationSearch;
    this.locationState = locationState;
    this.locationHash = locationHash;
    this.parseMatchParams = addFaultToleranceToParse(parseMatchParams);
    this.parseSearchParams = addFaultToleranceToParse(parseSearchParams);
    this.parseState = addFaultToleranceToParse(parseState);
    this.compiledPathToRegExp = pathToRegExp.compile(definition);
    this.parseHash = parseHash;

    if (definition !== '') {
      __registeredConfigs.push(this);
    }

    if (notImplemented.includes(this.definition)) {
      // there are urls that include multiple variables in a single segment. This
      // is to complicated to migrate to be worth it, so we skip splitting it to avoid throwing for the
      // know urls in dev. We will need to fix these urls before we can use them in react-router v6.
      this.cachedSplitOnMatch = [];
    } else {
      this.cachedSplitOnMatch = this.splitOnMatch();
    }
  }

  /**
   * This is the definition configured with `/*` appended to make it match nested urls in react-router v6
   */
  public get splatDefinition() {
    return patchExactDefinitionForV6(this.definition);
  }

  public static getActiveOwners(location: History.Location<unknown>): Team[] {
    return _.uniq(
      __registeredConfigs
        .filter((c) => !c.extendedFrom && c.isMatchingLocation(location))
        .flatMap((c) => c.getOwners(location)),
    ).sort();
  }

  /**
   * react-router v6 removed support for pattern matching in definitions and only
   * supports literal and variable matches `/literal/:variable`.
   *
   * We currently use enums `/var(val1|val2)` and optional segments `/var?` in our definitions.
   * To use these definitions in react-router v6 while we migrate, we need to split them into all possible definitions.
   *
   * This helper will give back an array of definitions that will match the patterns defined in the original definition.
   * Then you can map them and make a Route for each definition. `config.patchV5DefinitionsForV6(false).map(d => <Route key={d} path={d} />)`
   */
  public patchV5DefinitionsForV6 = (exact: boolean) => {
    if (this.definition === '') {
      assertIfNotProd('empty definition');
      return [this.definition];
    } else if (notImplemented.includes(this.definition)) {
      assertIfNotProd(`not implemented: ${this.definition}`);
      return [this.definition];
    } else {
      const longestUrl = this.cachedSplitOnMatch.reduce(
        (max, b) => Math.max(max, b.split('/').length),
        0,
      );

      return this.cachedSplitOnMatch.map((url) =>
        // Only add /* to the longest URl because /:opt? will have `/` and `/:opt` so we don't want to create `/*`
        url.split('/').length === longestUrl && !exact
          ? patchExactDefinitionForV6(url)
          : url,
      );
    }
  };

  public location = (
    params: MatchParams & SearchParams & State & Hash,
    locationOptions: LocationOptions = { absolute: false },
  ): History.Location<State> => {
    return {
      pathname:
        (locationOptions.absolute
          ? Config.absoluteUrl.replace(/\/$/, '')
          : '') + this.locationPathname(params),
      search: this.locationSearch(params),
      state: this.locationState(params),
      hash: this.locationHash(params),
    };
  };

  /**
   * Create a new `Location` that routes from one route config
   * to the same route config with modified parameters or to another route
   * config, also with patched parameters.
   *
   * Type TypeScript types require you to:
   *
   * 1. Require all params in the route config you're navigating to not
   *    satisfied in route config you're navigating from, and
   * 2. Optionally patch to params or delete non-required params for the route
   *    config you're navigating to (which can be the same route config you're
   *    navigating from, in which case, there are no new required params)
   *
   * This should then be used in a `Link` or to navigate with `history.push` or
   * `history.replace`.
   *
   * This function does not perform any navigation, it generates a new
   * `Location` that can be used for navigation.
   *
   * Also, all generics are intended to be inferred; do not pass them.
   */
  public locationFrom = <
    FromMatchParams extends Record<string, unknown>,
    FromSearchParams extends Record<string, unknown>,
    FromState,
    FromHash,
  >(
    {
      location,
      patch,
      fromRouteConfig,
    }: {
      location: History.Location<unknown>;
      /**
       * Parameters to change. To delete a key/value pair, pass the key with
       * value `RouteConfig.DELETE`.
       *
       * This TypeScript definition will require that all keys exist in
       * `MatchParams & SearchParams & State`. Also, if the key is required,
       * then this will disallow you from setting it to `RouteConfig.DELETE`
       */
      patch?: undefined extends typeof fromRouteConfig
        ? Patch<
            RouteConfigParams<
              RouteConfig<MatchParams, SearchParams, State, Hash, Definition>
            >,
            typeof RouteConfig.DELETE
          >
        : Needed<
            RouteConfigParams<typeof fromRouteConfig>,
            RouteConfigParams<
              RouteConfig<MatchParams, SearchParams, State, Hash, Definition>
            >
          > &
            Patch<
              RouteConfigParams<
                RouteConfig<MatchParams, SearchParams, State, Hash, Definition>
              >,
              typeof RouteConfig.DELETE
            >;

      fromRouteConfig?: RouteConfig<
        FromMatchParams,
        FromSearchParams,
        FromState,
        FromHash,
        any // eslint-disable-line @typescript-eslint/no-explicit-any
      >;
    },
    locationOptions?: LocationOptions,
  ): History.Location<State> => {
    const params = (fromRouteConfig || this).parseParams(location);

    // The output must be `RouteConfig` with these params. We need the previous
    // params `&` new params to satisfy the constraints.

    if (patch) {
      Object.entries(patch).forEach(([key, value]) => {
        if (value === RouteConfig.DELETE) {
          delete params[key];
        } else if (typeof value !== 'undefined') {
          // @ts-expect-error TODO: improve types
          params[key] = value;
        }
      });
    }
    return this.location(
      params as MatchParams & SearchParams & State & Hash,
      locationOptions,
    );
  };

  /**
   * Create a string that routes from one route config to the same route config
   * with modified parameters or to another route config, also with patched
   * parameters.
   *
   * Type TypeScript types require you to:
   *
   * 1. Require all params in the route config you're navigating to not
   *    satisfied in route config you're navigating from, and
   * 2. Optionally patch to params or delete non-required params for the route
   *    config you're navigating to (which can be the same route config you're
   *    navigating from, in which case, there are no new required params)
   *
   * This should then be used in a `Link` or to navigate with `history.push` or
   * `history.replace`.
   *
   * This function does not perform any navigation, it generates a string that
   * can be used for navigation.
   *
   * Also, all generics are intended to be inferred; do not pass them.
   */
  public pathFrom = <
    FromMatchParams extends Record<string, unknown>,
    FromSearchParams extends Record<string, unknown>,
    FromState,
    FromHash,
    FromDefinition extends AbsoluteOrEmptyPath,
  >(
    {
      location,
      patch,
      fromRouteConfig,
    }: {
      location: History.Location<unknown>;
      /**
       * Parameters to change. To delete a key/value pair, pass the key with
       * value `RouteConfig.DELETE`.
       *
       * This TypeScript definition will require that all keys exist in
       * `MatchParams & SearchParams & State`. Also, if the key is required,
       * then this will disallow you from setting it to `RouteConfig.DELETE`
       */
      patch?: undefined extends typeof fromRouteConfig
        ? Patch<
            RouteConfigParams<
              RouteConfig<MatchParams, SearchParams, State, Hash, Definition>
            >,
            typeof RouteConfig.DELETE
          >
        : Needed<
            RouteConfigParams<typeof fromRouteConfig>,
            RouteConfigParams<
              RouteConfig<MatchParams, SearchParams, State, Hash, Definition>
            >
          > &
            Patch<
              RouteConfigParams<
                RouteConfig<MatchParams, SearchParams, State, Hash, Definition>
              >,
              typeof RouteConfig.DELETE
            >;

      fromRouteConfig?: RouteConfig<
        FromMatchParams,
        FromSearchParams,
        FromState,
        FromHash,
        FromDefinition
      >;
    },
    locationOptions?: LocationOptions,
  ): string => {
    return locationToPath(
      this.locationFrom({ fromRouteConfig, location, patch }, locationOptions),
    );
  };

  public parseParams = (location: History.Location<unknown>) => {
    return {
      ...this.parseMatchParams(
        matchPath(location.pathname, { path: this.definition })?.params as
          | ExtractRouteParams<Definition, string>
          | undefined,
      ),
      ...this.parseSearchParams(parse(location.search)),
      ...this.parseState(location.state || {}),
      // location.hash includes the `#`, parse it out before sending to functions
      ...this.parseHash(location.hash.substring(1)),
    };
  };

  /**
   * Function to generate a `string` given route match parameters and query
   * string values.
   *
   * This should _only_ be used when we need a string, and `cy.visit`:
   *
   * ```ts
   * cy.visit(...)
   * ```
   *
   * ```ts
   * cy.visit(fieldsRouteConfig.location({ graphId: 'engine' }))
   *   .url()
   *   .should(endWith(fieldsRouteConfig.path({ graphId: 'engine' })));
   * ```
   *
   * Things like `initialEntries` and `<Link to={...} />` should use `location`
   */
  public path = (
    /**
     * Combination of match parameters, query string parameters, and location
     * state
     */
    parameters: MatchParams & SearchParams & State & Hash,
    locationOptions?: LocationOptions,
  ): string => {
    return locationToPath(this.location(parameters, locationOptions));
  };

  /**
   * Extend an existing RouteConfig. Accepts a new RouteConfig as it's only
   * argument and will decorate the existing config with the new one.
   *
   * All behavior is additive; nothing can be taken away with an extension. We
   * might want to change this in the future to allow for "migration"
   * extensions.
   */
  public extend = <
    ExtendedMatchParams extends Record<string, unknown>,
    ExtendedSearchParams extends Record<string, unknown>,
    ExtendedState,
    ExtendedHash,
    ExtendedDefinition extends AbsoluteOrEmptyPath,
  >(
    config: RouteConfig<
      ExtendedMatchParams,
      ExtendedSearchParams,
      ExtendedState,
      ExtendedHash,
      ExtendedDefinition
    >,
  ) => {
    const parent = this;
    config.parents.push(parent);

    const extendedDefinition: `${Definition}${ExtendedDefinition}` = `${parent.definition}${config.definition}`;

    const extendedOwners = config.owners ?? parent.owners;
    if (!extendedOwners && extendedDefinition !== '') {
      assertIfNotProd(`RouteConfig ${extendedDefinition} has no owner`);
    } else if (extendedOwners && extendedDefinition === '') {
      assertIfNotProd(
        `RouteConfig ${extendedDefinition} has no definition so it shouldn't have owners`,
      );
    }
    const extendedConfig = new RouteConfig<
      ExtendedMatchParams & MatchParams,
      ExtendedSearchParams & SearchParams,
      ExtendedState & State,
      ExtendedHash,
      `${Definition}${ExtendedDefinition}`
    >({
      parent,
      owners: extendedOwners as [Team, ...Team[]],
      definition: extendedDefinition,
      // match params are merged
      parseMatchParams: (params) => {
        return {
          ...parent.parseMatchParams(
            params as ExtractRouteParams<Definition, string> | undefined,
          ),
          ...config.parseMatchParams(
            params as
              | ExtractRouteParams<ExtendedDefinition, string>
              | undefined,
          ),
        };
      },
      // merge parent and extended query string params
      parseSearchParams: (params) => ({
        ...parent.parseSearchParams(params),
        ...config.parseSearchParams(params),
      }),
      // Return the merged child and parent state
      parseState: (params) => ({
        ...parent.parseState(params),
        ...config.parseState(params),
      }),
      // Return the merged child only
      parseHash: (params) => {
        return config.parseHash(params);
      },
      locationPathname(params) {
        return `${parent.locationPathname(params)}${config.locationPathname(
          params,
        )}`;
      },
      locationSearch(params) {
        return stringify({
          ...parse(parent.locationSearch(params)),
          ...parse(config.locationSearch(params)),
        });
      },
      locationState(params) {
        return {
          ...parent.locationState(params),
          ...config.locationState(params),
        } as State & ExtendedState;
      },
      // Return child hash string only
      locationHash(params) {
        return config.locationHash(params);
      },
    });
    extendedConfig.parents = [parent];
    extendedConfig.extendedFrom = config;
    return extendedConfig;
  };

  private getOwners = (location: History.Location<unknown> | null): Team[] => {
    if (this.owners === undefined) {
      captureException(
        new Error(
          `RouteConfig.getOwners shouldn't be called on "fragment" RouteConfigs`,
        ),
      );
      return this.parents.flatMap((p) => p.getOwners(location));
    } else if (typeof this.owners === 'function') {
      if (location === null) {
        return ['Unowned'];
      } else {
        try {
          return this.owners(
            this.parseMatchParams(
              matchPath(location.pathname, { path: this.definition })
                ?.params as ExtractRouteParams<Definition, string> | undefined,
            ),
            this.parseSearchParams(parse(location.search)),
            this.parseState(location.state || {}),
            // location.hash includes the `#`, parse it out before sending to functions
            this.parseHash(location.hash.substring(1)),
          );
        } catch (err) {
          return [];
        }
      }
    } else {
      return this.owners;
    }
  };
  private isMatchingLocation = (location: History.Location<unknown>) => {
    const definitions =
      this.parents.length > 0 && !this.extendedFrom
        ? this.parents.map((p) => `${p.definition}${this.definition}`)
        : [this.definition];
    return definitions.some(
      (path) => matchPath(location.pathname, { path })?.isExact,
    );
  };

  private splitOnMatch = () => {
    return pathToRegExp
      .parse(this.definition)
      .map((v) => {
        if (typeof v === 'string') {
          return v;
        } else if (v.asterisk) {
          assertIfNotProd('wildcards are not supported in definitions');
          return `${v.prefix || v.delimiter}*`;
        } else if (v.partial) {
          assertIfNotProd(`Partial is not implemented`);
          return `${v.prefix}:${v.name}`;
        } else if (v.repeat) {
          assertIfNotProd(`repeat is not supported`);
          return `${v.prefix}:${v.name}*`;
        } else if (v.pattern === '[^\\/]+?') {
          const segment = `${v.prefix}:${v.name}`;
          // This is a regular variable `/:var` with or without a trailing `?`
          if (v.optional) {
            // TODO: this is no longer supported by react-router v6
            return [segment, null];
          } else {
            return segment;
          }
        } else if (v.pattern.match(/[a-z\-]+(\|[a-z\-]+)/)) {
          // TODO: this is no longer supported by react-router v6
          // These are enums `/:var(foo|bar|baz)` with or without a trailing `?`
          const options = v.pattern.split('|');
          return [
            ...options.map((option) => `${v.prefix}${option}`),
            ...(v.optional ? [null] : []),
          ];
        } else {
          assertIfNotProd(`only | is supported in patterns, got ${v.pattern}`);
          return `${v.prefix}:${v.name}(${v.pattern})`;
        }
      })
      .reduce(
        (urls: string[][], segment, i, parsed) => {
          if (typeof segment === 'string') {
            return urls.map((url) => [...url, segment]);
          }

          // validate optional only after optional
          const next = parsed[i + 1];
          if (
            segment.includes(null) &&
            next &&
            !(Array.isArray(next) && next.includes(null))
          ) {
            assertIfNotProd(
              `all segments must be optional after an optional segment`,
            );
          }

          return urls.flatMap((url) => {
            // to handle `/:opt1?/:opt2?`, after 1 optional segment, we only want to add the new segment to the full previous path
            if (url.length === i) {
              return segment.map((s) => (s ? [...url, s] : url));
            } else {
              return [url];
            }
          });
        },
        [[]],
      )
      .map((url) => (url.length === 0 ? '/' : url.join('')));
  };
}
