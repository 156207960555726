import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@apollo/orbit';
import React from 'react';

import { ContactSupportLink } from 'src/components/ContactSupportCTAs';
import { PricingLink } from 'src/components/pricingLink/PricingLink';
interface Props {
  onClose: () => void;
}
export const Success = ({ onClose }: Props) => {
  const date = new Date();
  const currentMonth = date.toLocaleString('default', { month: 'long' });
  const nextMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    1,
  ).toLocaleString('default', { month: 'long' });

  return (
    <>
      <ModalHeader>
        <ModalTitle>You’re all set! 🎉</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <p>
          You are now subscribed to the <b>Serverless</b> plan and can continue
          using Studio as usual! Bills will arrive on the 1st of the month and
          cover usage for the previous month. You may cancel your plan at any
          time on your Account Settings page. You may also delete your graphs
          and delete your account at any time.
        </p>
        <p className="font-semibold">
          You’re projected to receive your first bill, which will cover{' '}
          {currentMonth} usage, on {nextMonth} 1st, 2022.{' '}
        </p>
        <p>
          If you have any questions along the way or about our billing policy,{' '}
          <ContactSupportLink>let us know.</ContactSupportLink> We’re here to
          help.
        </p>
      </ModalBody>
      <ModalFooter>
        <PricingLink>View pricing details</PricingLink>
        <Button variant="primary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
};
