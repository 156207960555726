import {
  PopoverContent as ChakraPopoverContent,
  PopoverContentProps as ChakraPopoverContentProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type PopoverContentProps = OmitChakraProps<
  ChakraPopoverContentProps,
  'motionProps'
>;

export const PopoverContent = forwardRef<PopoverContentProps, 'section'>(
  (props, ref) => {
    return <ChakraPopoverContent {...props} ref={ref} />;
  },
);
