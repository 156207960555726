import { formAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';
import {
  FormHelperTextStyles as helperText,
  FormHelperTextContentStyles as helperTextContent,
  FormHelperTextIconStyles as helperTextIcon,
} from '../FormHelperText/FormHelperText.style';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  ...formAnatomy.keys,
  'helperTextIcon' as const,
  'helperTextContent' as const,
]);

const container = defineStyle({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const requiredIndicator = defineStyle({
  color: customColors.icon.error,
});

export const FormControlStyles = defineMultiStyleConfig({
  baseStyle: {
    container,
    helperText,
    helperTextContent,
    helperTextIcon,
    requiredIndicator,
  },
});
