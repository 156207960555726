import { useApolloClient } from '@apollo/client';
import React, { createContext, useContext } from 'react';

const IsAppOfflineContext = createContext<ReturnType<() => boolean> | null>(
  null,
);

export const IsAppOfflineContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOffline, setIsOffline] = React.useState<boolean>(
    !window.navigator.onLine,
  );
  const client = useApolloClient();

  React.useEffect(() => {
    if (isOffline) {
      const intervalId = setInterval(() => {
        fetch('https://studio-ui-deployments.apollographql.com', {
          method: 'HEAD',
        }).then(
          (response) => {
            if (response.status === 200) {
              // back online!
              clearInterval(intervalId);
              setIsOffline(false);
              client.refetchQueries({
                include: ['GlobalHeaderQuery'],
              });
            }
          },
          () => {
            // do nothing on error, we are still offline
          },
        );
      }, 3000);
    }
  }, [isOffline, client]);

  React.useEffect(() => {
    const handleAppIsOffline = () => {
      setIsOffline(true);
    };

    window.addEventListener('offline', handleAppIsOffline);
    return () => {
      window.removeEventListener('offline', handleAppIsOffline);
    };
  }, []);

  return (
    <IsAppOfflineContext.Provider value={isOffline}>
      {children}
    </IsAppOfflineContext.Provider>
  );
};

export const useIsAppOfflineContext = () => {
  const appIsOffline = useContext(IsAppOfflineContext);
  // The context provider is used in `RoutedApp`, so this default is safe
  return appIsOffline ?? false;
};
