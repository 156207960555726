import React from 'react';

interface Props {
  children: React.ReactElement;
  on: boolean;
  element: React.ReactElement;
}

/**
 * Component conditionally rendering `children` as a child of `element`
 * depending on `on` prop
 *
 * Useful for elements that sometimes need to be wrapped and you don't want to
 * have an intermediate variable storing the wrapped content to keep it DRY.
 */
export const WrapMaybe = React.forwardRef<HTMLElement, Props>(
  ({ children, element, on }: Props, ref) => {
    return on
      ? React.cloneElement(element, { ref }, children)
      : React.cloneElement(children, { ref });
  },
);
