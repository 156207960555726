import { anatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const badgeAnatomy = anatomy('Badge').parts('container', 'icon');

const { border, icon, text, brand, bg } = customColors;
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(badgeAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 1,
    color: text.primary,
    fontFamily: 'body',
    fontSize: 'xs',
    fontWeight: 'bold',
    lineHeight: 'xs',
    fontStyle: 'normal',
    rounded: 'sm',
    border: '1px solid',
    borderColor: 'transparent',
    textTransform: 'uppercase',
    textWrap: 'nowrap',
    w: 'fit-content',
    h: 5,
    px: 1,
    py: 0.5,
  },
  icon: {
    color: 'currentColor',
    height: 3,
    width: 3,
    '> svg': {
      display: 'block',
      width: 3,
      height: 3,
    },
  },
});

const variants = {
  brandedDark: definePartsStyle({
    container: {
      backgroundColor: brand.primary,
      color: text.white,
    },
    icon: {
      color: icon.white,
    },
  }),
  brandedLight: definePartsStyle({
    container: {
      backgroundColor: brand.secondary,
      color: text.white,
    },
    icon: {
      color: icon.white,
    },
  }),
  neutral: definePartsStyle({
    container: {
      backgroundColor: bg.neutral,
      color: text.neutral,
    },
    icon: {
      color: 'inherit',
    },
  }),
  neutralOutlined: definePartsStyle({
    container: {
      backgroundColor: bg.primary,
      borderColor: border.primary,
      color: text.primary,
    },
    icon: {
      color: icon.primary,
    },
  }),
  info: definePartsStyle({
    container: {
      backgroundColor: bg.info,
      color: text.info,
    },
    icon: {
      color: icon.info,
    },
  }),
  success: definePartsStyle({
    container: {
      backgroundColor: bg.success,
      color: text.success,
    },
    icon: {
      color: icon.success,
    },
  }),
  warn: definePartsStyle({
    container: {
      backgroundColor: bg.warning,
      color: text.warning,
    },
    icon: {
      color: icon.warning,
    },
  }),
  error: definePartsStyle({
    container: {
      backgroundColor: bg.error,
      color: text.error,
    },
    icon: {
      color: icon.error,
    },
  }),
  beta: definePartsStyle({
    container: {
      backgroundColor: bg.beta,
      color: text.beta,
    },
    icon: {
      color: icon.beta,
    },
  }),
  branded: definePartsStyle({
    container: {
      backgroundColor: 'transparent',
      borderColor: brand.accent,
      color: text.primary,
    },
    icon: {
      color: icon.primary,
    },
  }),
  yellow: definePartsStyle({
    container: {
      backgroundColor: '#f4d03f',
      borderColor: '#E8C63C',
      color: text.black,
    },
    icon: {
      color: 'inherit',
    },
  }),
  indigo: definePartsStyle({
    container: {
      backgroundColor: '#ad9bf6',
      borderColor: '#A493EA',
      color: text.black,
    },
    icon: {
      color: 'inherit',
    },
  }),
  green: definePartsStyle({
    container: {
      backgroundColor: '#7ed9a4',
      borderColor: '#78CD9C',
      color: text.black,
    },
    icon: {
      color: 'inherit',
    },
  }),
  purple: definePartsStyle({
    container: {
      backgroundColor: '#e8ccff',
      borderColor: '#DCC2F2',
      color: text.black,
    },
    icon: {
      color: 'inherit',
    },
  }),
  pink: definePartsStyle({
    container: {
      backgroundColor: '#ffa3e0',
      borderColor: '#F29BD5',
      color: text.black,
    },
    icon: {
      color: 'inherit',
    },
  }),
};

export const BadgeStyles = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'neutralOutlined',
  },
});
