import {
  ButtonGroup as ChakraButtonGroup,
  ButtonGroupProps as ChakraButtonGroupProps,
  SystemStyleObject,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';
import { $splitBorderColor } from '../Button/Button.style';

export type ButtonGroupProps = OmitChakraProps<
  ChakraButtonGroupProps,
  'colorScheme' | 'orientation' | 'styleConfig' | 'disabled' | 'spacing'
> & {
  spacing?: string | number;
};

const gapStyles = {
  horizontal: (spacing: string | number): SystemStyleObject => ({
    '& > *:not(style) ~ *:not(style)': { marginStart: spacing },
  }),
  vertical: (spacing: string | number): SystemStyleObject => ({
    '& > *:not(style) ~ *:not(style)': { marginTop: spacing },
  }),
};

// We need to override chakra's styles so we target any child element vs targeting first & last of types
// so that the styles continue to work no matter the child tag
const attachedStyles: Record<string, SystemStyleObject> = {
  horizontal: {
    '> *:first-of-type:not(:last-of-type)': { borderEndRadius: false },
    '> *:not(:first-of-type):not(:last-of-type)': { borderRadius: false },
    '> *:not(:first-of-type):last-of-type': { borderStartRadius: false },
    '> *:first-child:not(:last-child)': {
      borderEndRadius: 0,
      borderRight: '1px solid',
      borderRightColor: [$splitBorderColor.reference],
    },
    '> *:not(:first-child):not(:last-child)': {
      borderRadius: 0,
      borderRight: '1px solid',
      borderRightColor: [$splitBorderColor.reference],
    },
    '> *:not(:first-child):last-child': {
      borderStartRadius: 0,
      borderLeftWidth: 0,
    },
  },
  vertical: {
    '> *:first-of-type:not(:last-of-type)': { borderBottomRadius: false },
    '> *:not(:first-of-type):not(:last-of-type)': { borderRadius: false },
    '> *:not(:first-of-type):last-of-type': { borderTopRadius: false },
    '> *:first-child:not(:last-child)': {
      borderBottomRadius: 0,
      borderRight: '1px solid',
      borderRightColor: [$splitBorderColor.reference],
    },
    '> *:not(:first-child):not(:last-child)': {
      borderRadius: 0,
      borderRight: '1px solid',
      borderRightColor: [$splitBorderColor.reference],
    },
    '> *:not(:first-child):last-child': {
      borderTopRadius: 0,
      borderTopWidth: 0,
    },
  },
};

export const ButtonGroup = forwardRef<ButtonGroupProps, 'div'>(
  ({ spacing = '3', ...props }, ref) => {
    const orientation = 'horizontal';
    const groupStyles: SystemStyleObject = {
      display: 'inline-flex',
      alignItems: 'center',
      ...(props.isAttached
        ? attachedStyles[orientation]
        : gapStyles[orientation](spacing)),
    };
    return (
      <ChakraButtonGroup
        {...props}
        spacing={spacing}
        orientation={orientation}
        ref={ref}
        __css={groupStyles}
      />
    );
  },
);
