import IconClose from '@apollo/icons/default/IconClose.svg';
import { SystemStyleObject, forwardRef } from '@chakra-ui/react';
import React, { ComponentPropsWithoutRef } from 'react';

import { IconButton } from '../IconButton/IconButton';

type IconButtonProps = ComponentPropsWithoutRef<typeof IconButton>;

export interface CloseButtonProps
  extends Omit<IconButtonProps, 'icon' | 'aria-label'> {
  'aria-label'?: IconButtonProps['aria-label'];
  /** @internal Do not use outside of Orbit */
  __orbitStyleConfig?: SystemStyleObject;
}

export const CloseButton = forwardRef<CloseButtonProps, 'button'>(
  ({ __orbitStyleConfig, ...props }, ref) => {
    return (
      <IconButton
        variant="hidden"
        {...props}
        ref={ref}
        aria-label={props['aria-label'] || 'Close'}
        icon={<IconClose />}
        sx={__orbitStyleConfig}
      />
    );
  },
);
