import {
  Card as ChakraCard,
  CardProps as ChakraCardProps,
  ThemingProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export interface CardProps
  extends OmitChakraProps<ChakraCardProps, keyof ThemingProps> {
  isDivided?: boolean;
  variant?: 'outline' | 'unstyled' | 'filled' | 'control';
}

export const Card = forwardRef<CardProps, 'div'>(
  ({ isDivided, ...props }, ref) => {
    return <ChakraCard data-is-divided={isDivided} ref={ref} {...props} />;
  },
);
