import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const invalidStyles = defineStyle({
  border: '2px solid',
  borderColor: customColors.border.error,
  boxShadow: 'unset',
});

const focusStyles = defineStyle({
  border: '2px solid',
  borderColor: customColors.border.focused,
  boxShadow: 'unset',
});

const disabledStyles = defineStyle({
  opacity: 1,
  bg: customColors.bg.disabled,
  borderColor: customColors.border.disabled,
  color: customColors.text.disabled,
  _placeholder: {
    color: customColors.text.disabled,
  },
});

const hoverStyles = defineStyle({
  border: '1px solid',
  borderColor: customColors.border.hover,
});

const baseStyle = defineStyle({
  p: 3,
  borderRadius: '8',
  color: customColors.text.primary,
  _disabled: {
    ...disabledStyles,
    _hover: disabledStyles,
  },
  _hover: {
    ...hoverStyles,
    _focus: focusStyles,
    // cancels hover styles when hovering over invalid inputs
    _invalid: invalidStyles,
  },
  _focus: {
    ...focusStyles,
    _hover: focusStyles,
  },
  _invalid: {
    ...invalidStyles,
    _focus: invalidStyles,
  },
});

export const outline = defineStyle({
  border: '1px solid',
  borderColor: customColors.border.primary,
  isolation: 'isolate',
  bg: customColors.bg.input,
  _placeholder: {
    color: customColors.text.placeholder,
  },
  _disabled: {
    ...disabledStyles,
    _hover: disabledStyles,
  },
  _focus: {
    ...focusStyles,
    _hover: focusStyles,
  },
  _hover: {
    ...hoverStyles,
    _focus: focusStyles,
    // cancels hover styles when hovering over invalid inputs
    _invalid: invalidStyles,
  },
  _invalid: {
    ...invalidStyles,
    _focus: invalidStyles,
  },
});

const md = defineStyle({
  height: '72px',
});

const lg = defineStyle({
  height: '160px',
});

const hiddenField = defineStyle({
  background: 'transparent',
  color: 'inherit',
  borderColor: 'transparent',
  border: '1px solid transparent',
});

const hidden = defineStyle({
  ...outline,
  ...hiddenField,
  _disabled: {
    ...disabledStyles,
    _hover: disabledStyles,
  },
  _focus: {
    ...hiddenField,
    _hover: hiddenField,
  },
  _hover: {
    ...hiddenField,
    _focus: hiddenField,
    // cancels hover styles when hovering over invalid inputs
    _invalid: hiddenField,
  },
  _invalid: {
    ...hiddenField,
    _focus: hiddenField,
  },
});

export const TextareaStyles = defineStyleConfig({
  baseStyle,
  variants: {
    hidden,
    outline,
  },
  sizes: { md, lg },
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
});
