import { gql, useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router';

import { useLocalStorageGraphIdentifier } from 'src/app/graph/explorerPage/hooks/usePerGraphIdentifierLocalStorage';
import { graphRouteConfig } from 'src/app/graph/routes';
import { usePerKeyLocalStorage } from 'src/hooks/usePerKeyLocalStorage';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

import Config from '../lib/config';

import { useRouteParams } from './useRouteParams';

// If there is no variant present in the url, return either the last
// accessed or default variant. Otherwise return null.
export const useDefaultVariant = (): {
  defaultVariant: string | null;
  loadingDefaultVariant: boolean;
} => {
  const { graphId } = useRouteParams(graphRouteConfig);
  const routeMatch = useRouteMatch<{ graphVariant?: string }>();

  const localStorageGraphIdentifier = useLocalStorageGraphIdentifier({
    shouldNamespaceSandboxByEndpoint: false,
    shouldOnlyStoreForRegisteredGraphs: false,
  });
  const [lastAccessedVariant, setLastAccessedVariant] = usePerKeyLocalStorage({
    localStorageKey: 'lastAccessedVariant',
    key: localStorageGraphIdentifier.domain,
    initialValue: null,
  });

  const { data, loading } = useQuery(
    gql<
      GraphQLTypes.UseSelectedVariantQuery,
      GraphQLTypes.UseSelectedVariantQueryVariables
    >`
      query UseSelectedVariantQuery($graphId: ID!) {
        service(id: $graphId) {
          id
          variants {
            id
            name
            isFavoriteOfCurrentUser
          }
        }
      }
    `,
    {
      variables: { graphId },
    },
  );

  const variants = useMemo(
    () => data?.service?.variants || [],
    [data?.service?.variants],
  ); // default to and empty list of variants

  useEffect(() => {
    const existingVariant = variants.find(
      (v) => v.name === routeMatch.params.graphVariant,
    );
    if (existingVariant) {
      setLastAccessedVariant(existingVariant.name);
    }
  }, [routeMatch, setLastAccessedVariant, variants]);

  if (loading) {
    return {
      defaultVariant: null,
      loadingDefaultVariant: true,
    };
  }

  // If we have a last accessed variant, return that
  if (
    lastAccessedVariant &&
    data?.service?.variants.find(
      (variant) => variant.name === lastAccessedVariant,
    )
  ) {
    return {
      defaultVariant: lastAccessedVariant,
      loadingDefaultVariant: false,
    };
  }

  // If we have a default variant, return that
  if (
    data?.service?.variants.find(
      (variant) => variant.name === Config.service.defaultVariant,
    )
  ) {
    return {
      defaultVariant: Config.service.defaultVariant,
      loadingDefaultVariant: false,
    };
  }

  // If we do not have a default or last accessed variant, use the first
  // favorited variant, the first variant, or null (shouldn't get there hopefully)
  const defaultVariant = orderBy(data?.service?.variants ?? [], [
    (variant) => (variant.isFavoriteOfCurrentUser ? -1 : 1), // otherwise it will be the alphabetical first of favorites
    (variant) => variant.name, // (or of all variants if they have no favorites)
  ])[0]?.name;

  return {
    defaultVariant: defaultVariant ?? null,
    loadingDefaultVariant: false,
  };
};
