import moment, { MomentInput } from 'moment';

export const printDateRange = (
  fromDate: MomentInput,
  toDate: MomentInput,
): string => {
  const formattedFromDate = moment(fromDate).format('MMM D, YYYY [at] h:mm A');
  let formattedToDate: string;

  if (moment(fromDate).isAfter(toDate)) {
    throw new Error('FROM cannot come after TO');
  }

  if (moment(fromDate).year() !== moment(toDate).year()) {
    formattedToDate = moment(toDate).format('MMM D, YYYY [at] h:mm A');
  } else if (moment(fromDate).dayOfYear() !== moment(toDate).dayOfYear()) {
    formattedToDate = moment(toDate).format('MMM D [at] h:mm A');
  } else {
    formattedToDate = moment(toDate).format('h:mm A');
  }
  return `${formattedFromDate} - ${formattedToDate}`;
};
