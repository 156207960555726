import { gql, useQuery } from '@apollo/client';
import IconWarningSolid from '@apollo/icons/large/IconWarningSolid.svg';
import { Button } from '@apollo/orbit';
import React from 'react';
import { Link } from 'react-router-dom';

import * as orgRoutes from 'src/app/account/routes';
import { useCurrentPlan } from 'src/hooks/currentPlanV2Migration';
import { useCheckPlanTypes } from 'src/hooks/useCheckPlanTypes';
import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

import { PermissionGuard } from '../permissionGuards/permissionGuard/PermissionGuard';

// Exported for storybook
const accountBillingEmailQuery = gql`
  query UI__accountBillingEmailQuery($accountId: ID!) {
    account(id: $accountId) {
      id
      name
      billingContactEmail
      isOnTrial
    }
  }
`;

interface Props {
  accountId: string;
}

export const NoBillingEmailBanner = ({ accountId }: Props) => {
  const { data, loading } = useQuery<
    GraphQLTypes.UI__accountBillingEmailQuery,
    GraphQLTypes.UI__accountBillingEmailQueryVariables
  >(accountBillingEmailQuery, {
    variables: {
      accountId,
    },
    errorPolicy: 'all',
    context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
  });
  const currentPlan = useCurrentPlan({
    accountId,
  });

  const { isFreePlan } = useCheckPlanTypes({
    tier: currentPlan.currentPlan?.tier,
    kind: currentPlan.currentPlan?.kind,
  });

  // Hide if we are loading, our account has an email, or org is on free or trial plan
  if (
    isFreePlan ||
    loading ||
    currentPlan.loading ||
    !data?.account ||
    data.account.billingContactEmail ||
    data.account.isOnTrial
  ) {
    return null;
  }

  // putting this inside the JSX below crashes eslint-plugin-jsx-a11y
  // see https://github.com/evcohen/eslint-plugin-jsx-a11y/issues/399
  const resetBillingEmailLocation = orgRoutes.settings.location({
    orgId: accountId,
    overlay: 'update-billing-email',
  });

  return (
    <div className="flex items-center justify-center bg-red p-3 text-base text-inverted">
      <IconWarningSolid className="mr-1" />
      <div>
        <b>{data.account.name}</b>&nbsp;does not have a billing contact
        designated. Please{' '}
        <PermissionGuard
          accountId={data.account.id}
          accountPermissions="canUpdateMetadata"
          fallback={<>ask your account admin to add a billing contact.</>}
        >
          add a billing contact now.
        </PermissionGuard>
      </div>
      <PermissionGuard
        accountId={data.account.id}
        accountPermissions="canUpdateMetadata"
        fallback={null}
        childRenderer={(hasPermission) => {
          return (
            <Button
              className="ml-3"
              size="sm"
              variant="secondary"
              isDisabled={!hasPermission}
              as={Link}
              to={resetBillingEmailLocation}
            >
              Add a billing contact
            </Button>
          );
        }}
      />
    </div>
  );
};
