import {
  PopoverHeader as ChakraPopoverHeader,
  PopoverHeaderProps as ChakraPopoverHeaderProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type PopoverHeaderProps = OmitChakraProps<ChakraPopoverHeaderProps>;

export const PopoverHeader = forwardRef<PopoverHeaderProps, 'div'>(
  (props, ref) => {
    return <ChakraPopoverHeader {...props} ref={ref} />;
  },
);
