import { Checkbox } from '@apollo/orbit';
import React, { ComponentPropsWithoutRef } from 'react';

export function Input(props: ComponentPropsWithoutRef<'input'>) {
  if (props.type === 'checkbox') {
    const { checked, readOnly, 'aria-invalid': ariaInvalid } = props;

    return (
      <Checkbox
        className="mb-0.5 mr-1 align-middle"
        aria-invalid={ariaInvalid === 'true' ? true : undefined}
        isChecked={checked}
        isReadOnly={readOnly}
      />
    );
  }

  return null;
}
