import IconPerson from '@apollo/icons/default/IconPerson.svg';
import {
  Avatar as ChakraAvatar,
  AvatarProps as ChakraAvatarProps,
  chakra,
  useAvatarStyles,
} from '@chakra-ui/react';
import { randomColor } from '@chakra-ui/theme-tools';
import React from 'react';

import { customColors } from '../../theme/customColors';
const { code, text, bg } = customColors;

const colors = Object.values(code);

type AvatarVariants = 'org' | 'user' | 'graph';

export interface AvatarProps
  extends Omit<ChakraAvatarProps, 'borderRadius' | 'variant' | 'src'> {
  src?: string | null;
  /**
   * use variant="org" instead to control border radius
   */
  variant?: AvatarVariants;
}

export const Avatar = ({
  name,
  size,
  variant = 'user',
  src,
  ...props
}: AvatarProps) => {
  const showInitials = name && !src;
  return (
    <ChakraAvatar
      {...props}
      icon={<AvatarIcon />}
      variant={variant}
      src={src ?? undefined}
      bgColor={showInitials ? randomColor({ colors, string: name }) : bg.white}
      color={showInitials ? text.inverted : text.black}
      name={name?.replace(/[^a-zA-Z0-9\s]/, '')} // make sure characters like `(` don't get used
      size={size}
      showBorder
    />
  );
};

const ChakraIconPerson = chakra(IconPerson);

const AvatarIcon = () => {
  const styles = useAvatarStyles();
  return <ChakraIconPerson __css={styles.icon} aria-label="Avatar icon" />;
};
