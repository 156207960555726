import { useApolloClient } from '@apollo/client';

/**
 * Hook to clear `me` from the Apollo cache.
 *
 * Returns a function that can be called to clear the cache when you're ready.
 */
export function useClearMeFromApolloCache() {
  const client = useApolloClient();

  return ({ userId }: { userId: string }) => {
    client.cache.evict({
      id: client.cache.identify({
        __typename: 'User',
        id: userId,
      }),
    });

    client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'me',
    });

    // Remove all dangling references to force re-fetches of all this data.
    client.cache.gc();
  };
}
