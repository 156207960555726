import { clone } from 'lodash';

function isObject(input: unknown): input is Record<string, unknown> {
  return typeof input === 'object' && input !== null;
}

/**
 * Decorate a `parse`. When run, attempt to delete any invalid data from
 * a failed parse attempt
 *
 * @param parseFunction `parse` function decorate
 */
export function addFaultToleranceToParse<Input, ParsedParams>(
  parseFunction: (input: Input) => ParsedParams,
): (input: Input) => ParsedParams {
  return (input, ...otherArgs) => {
    try {
      return parseFunction(input, ...otherArgs);
    } catch (e) {
      // If `input` isn't an object, then we can't recover from any errors.
      if (!isObject(input)) {
        throw e;
      }

      const error = e as Error;

      if (error.name !== 'MyZodError') {
        throw error;
      }

      const pathRegex = /error parsing object at path: "(.+?)"/i;
      const enumError =
        /error parsing object at path: ".+?" - No union satisfied/i;
      const literalError =
        /error parsing object at path: ".+?" - expected value to be literal ".+?" but got ".+?"/i;

      if (enumError.test(error.message) || literalError.test(error.message)) {
        // Try to handle enum failures
        const parsed = error.message.match(pathRegex);
        if (parsed && typeof parsed[1] === 'string') {
          const field = parsed[1];

          /**
           * Shallow clone of `input` to modify
           */
          const newInput = clone(input);
          delete newInput[field];

          return parseFunction(newInput, ...otherArgs);
        }
      }

      throw error;
    }
  };
}
