import React from 'react';

/**
 * Layout component that wraps content that should take up the whole screen, be
 * horizontally centered, be at 5% from the top of the viewport, and
 * automatically scroll when the viewport is too short.
 */
export const FixedScrollingContentLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <div className="relative w-full bg-primary">
    <div className="relative mx-auto min-h-screen max-w-xl px-8">
      <div className="sticky py-8" style={{ top: 'calc(5% - 2rem)' }}>
        {children}
      </div>
    </div>
  </div>
);
