import * as myzod from 'myzod';

import { assertUnreachableOrReturnDefault } from 'src/lib/assertUnreachable';
import { Config } from 'src/lib/config/config';
import { CloudProvider, CloudTier } from 'src/lib/graphqlTypes/types';
import { RouteConfig } from 'src/lib/routeConfig/RouteConfig';

/**
 * Org
 */
export const org = new RouteConfig({
  owners: ['Unowned'],
  definition: '/org/:orgId',
  parseMatchParams: (params) =>
    myzod
      .object({
        orgId: myzod.string(),
      })
      .allowUnknownKeys()
      .parse(params),
});

export const legacyAccount = new RouteConfig({
  owners: ['Unowned'],
  definition: '/account',
});

export const invite = org.extend(
  new RouteConfig({
    owners: ['growth'],
    definition: '/invite/:joinToken',
    parseMatchParams: (params) =>
      myzod
        .object({
          joinToken: myzod.string(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

export const usage = org.extend(
  new RouteConfig({
    owners: ['astro'],
    definition: '/usage',
  }),
);

export const dashboard = org.extend(
  new RouteConfig({
    owners: ['astro'],
    definition: '/dashboard',
  }),
);

export const members = org.extend(
  new RouteConfig({
    owners: ['astro'],
    definition: '/members',
    parseSearchParams: (queryStringParams) =>
      myzod
        .object({
          overlay: myzod.literals('invite-member').optional(),
        })
        .allowUnknownKeys()
        .parse(queryStringParams),
  }),
);

const orgSettingsSlugSchema = myzod.literals('general', 'billing', 'security');
export type OrgSettingsSlug = myzod.Infer<typeof orgSettingsSlugSchema>;

export const settings = org.extend(
  new RouteConfig({
    owners: ({ tab }: { tab?: OrgSettingsSlug }) => {
      switch (tab) {
        case 'general':
        case 'security':
        case 'billing':
        case undefined: // tab defaults to general
          return ['astro'];
        default:
          return assertUnreachableOrReturnDefault(tab, ['Unowned']);
      }
    },
    definition: '/settings/:tab?',
    parseMatchParams: (params) =>
      myzod
        .object({
          tab: orgSettingsSlugSchema.optional(),
        })
        .allowUnknownKeys()
        .parse(params),
    parseSearchParams: (queryStringParams) =>
      myzod
        .object({
          overlay: myzod
            .literals(
              'invite-member',
              'update-billing-email',
              'update-default-user-role',
              'update-display-name',
              'update-id',
            )
            .optional(),
          outcome: myzod.string().optional(),
          error: myzod.string().optional(),
          message: myzod.string().optional(),
        })
        .allowUnknownKeys()
        .parse(queryStringParams),
  }),
);

// old settings route
export const legacyBilling = org.extend(
  new RouteConfig({ owners: ['astro'], definition: '/billing' }),
);

/**
 * Graphs [Extends Org]
 */
const HighlightSupergraphFragment = new RouteConfig({
  definition: '',
  parseState: (params) =>
    myzod
      .object({
        highlightSupergraphState: myzod
          .object({
            graphId: myzod.string(),
          })
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

const InitiatingSupergraphFragment = new RouteConfig({
  definition: '',
  parseState: (params) =>
    myzod
      .object({
        initiatingSupergraphState: myzod
          .object({
            graphId: myzod.string(),
            graphVariant: myzod.string(),
          })
          .optional(),
      })
      .allowUnknownKeys()
      .parse(params),
});

const graphsOverlaySchema = myzod
  .literals('publish-your-schema', 'create-graph', 'upgrade')
  .optional();
export function isGraphsOverlay(
  value: string | undefined,
): value is myzod.Infer<typeof graphsOverlaySchema> {
  try {
    graphsOverlaySchema.parse(value);
    return true;
  } catch (e) {
    return false;
  }
}
export const graphs = org
  .extend(
    new RouteConfig({
      owners: ['nebula'],
      definition: '/graphs',
      parseSearchParams: (queryStringParams) =>
        myzod
          // TODO: There should be a way to require `serviceId` to be included if
          // `overlay` is set, but I couldn't figure it out.
          .object({
            overlay: graphsOverlaySchema,
            graphId: myzod.string().optional(),
            [Config.queryParameters.SelectedVariant]: myzod.string().optional(),
            publishArch: myzod.literals('monolith', 'federation').optional(),
          })
          .allowUnknownKeys()
          .parse(queryStringParams),
    }),
  )
  .extend(HighlightSupergraphFragment)
  .extend(InitiatingSupergraphFragment);

// old graphs list route
export const legacyServices = org.extend(
  new RouteConfig({ owners: ['nebula'], definition: '/services' }),
);
// old supergraphs list route
export const legacySupergraphs = org.extend(
  new RouteConfig({ owners: ['nebula'], definition: '/supergraphs' }),
);

export const supergraphOnboarding = graphs.extend(
  new RouteConfig({
    owners: ['growth'],
    definition: '/new',
    parseSearchParams: (queryStringParams) =>
      myzod
        .object({
          prefillGraphRef: myzod.string().optional(),
          includeVariantOnboardingStep: myzod
            .literals('true', 'false')
            .optional(),
        })
        .allowUnknownKeys()
        .parse(queryStringParams),
    parseState: (params) =>
      myzod
        .object({
          formValues: myzod
            .object({
              tab: myzod.literals('unregistered', 'registered'),
              sdl: myzod.string().optional(),
              registeredGraphRef: myzod.string().optional(),
              registeredVariantSubgraphName: myzod.string().optional(),
              registeredEndpointUrl: myzod.string().optional(),
              unregisteredEndpointUrl: myzod.string().optional(),
              unregisteredSubgraphName: myzod.string().optional(),
              supergraphName: myzod.string().optional(),
              supergraphID: myzod.string().optional(),
              stepIndex: myzod.number().optional(),
              cloudProvider: myzod.enum(CloudProvider).optional(),
              cloudRegion: myzod.string().optional(),
              cloudTier: myzod.enum(CloudTier).optional(),
              arn: myzod.string().optional(),
              variantName: myzod.string().optional(),
              graphId: myzod.string().optional(),
            })
            .optional(),
        })
        .allowUnknownKeys()
        .parse(params),
  }),
);

/**
 * User Settings
 */
export const userSettingsRouteConfig = new RouteConfig({
  owners: ['astro'],
  definition: '/user-settings/:tab?',
  parseMatchParams: (params) =>
    myzod
      .object({
        tab: myzod.literals('general', 'api-keys').optional(),
      })
      .allowUnknownKeys()
      .parse(params),
  parseHash: (hash) => (hash.length ? { hash } : {}),
});

const userSettingsSlugSchema = myzod.literals('general', 'api-keys');
export type UserSettingsSlug = myzod.Infer<typeof userSettingsSlugSchema>;
