import {
  AbsoluteCenter,
  Divider as ChakraDivider,
  DividerProps as ChakraDividerProps,
  ThemingProps,
  chakra,
  forwardRef,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import { customColors } from '../../theme/customColors';
import { OmitChakraProps } from '../../utils/typeUtils';

type ForwardedProps = OmitChakraProps<
  ChakraDividerProps,
  Exclude<keyof ThemingProps, 'orientation'>
>;

export interface DividerProps extends ForwardedProps {
  label?: ReactNode;
  labelBg?: keyof typeof customColors.bg;
}

export const Divider = forwardRef<DividerProps, 'hr'>(
  (
    { label, labelBg = 'primary', orientation = 'horizontal', ...props },
    ref,
  ) => {
    if (orientation === 'horizontal' && label) {
      return (
        <chakra.div __css={{ position: 'relative' }} {...props}>
          <ChakraDivider orientation={orientation} ref={ref} />
          <AbsoluteCenter
            sx={{
              px: 2.5,
              bg: customColors.bg[labelBg],
              color: customColors.text.secondary,
              fontWeight: 'semibold',
              fontSize: 'sm',
              lineHeight: 'sm',
            }}
          >
            {label}
          </AbsoluteCenter>
        </chakra.div>
      );
    }

    return <ChakraDivider {...props} orientation={orientation} ref={ref} />;
  },
);
