import { Spinner, SpinnerProps } from '@apollo/orbit';
import React from 'react';

export const Loading = ({ size }: { size?: SpinnerProps['size'] }) => {
  return (
    <div className="flex size-full items-center justify-center">
      <Spinner size={size || 'lg'} data-testid="loading-spinner" />
    </div>
  );
};
