/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { Button, Link } from '@apollo/orbit';
import { colors } from '@apollo/space-kit/colors';
import { Rocket2 } from '@apollo/space-kit/illustrations/Rocket2';
import { jsx } from '@emotion/react';
import React from 'react';

import * as onboardingRoutes from 'src/app/onboarding/routes';
import { Loading } from 'src/components/common/loading/Loading';
import { usePerKeyLocalStorage } from 'src/hooks/usePerKeyLocalStorage';
import { openStudioLinkFromEmbedInNewTab } from 'src/lib/routers/embedded';

import { graphRefToString, useGraphRef } from '../graph/hooks/useGraphRef';

export const AuthenticateLoginPage = ({
  embedSubdomain,
}: {
  embedSubdomain: 'sandbox' | 'explorer';
}) => {
  const graphRef = useGraphRef();
  const graphRefString = graphRefToString(graphRef) ?? undefined;
  // we store the embedAuthenticationDetails by document.referrer
  const [embedAuthenticationDetails] = usePerKeyLocalStorage({
    initialValue: undefined,
    key: document.referrer,
    localStorageKey: 'embedAuthenticationDetails',
  });

  const origin =
    embedAuthenticationDetails && embedAuthenticationDetails.origin;
  if (!origin) {
    return <Loading />;
  }

  return (
    <>
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden text-primary">
        <div className="flex h-full flex-1 overflow-y-auto">
          <div className="flex size-full items-center justify-center">
            <div className="max-w-md text-center">
              <Rocket2
                className="mx-auto mb-6 block w-40 text-transparent"
                css={{
                  '[fill="#F18686"]': {
                    fill: 'transparent',
                  },
                  '[fill="#74B0F4"]': {
                    fill: 'transparent',
                  },
                  '[stroke="#464646"]': {
                    stroke: colors.blilet.light,
                  },
                  'g.stars, g[fill-rule="evenodd"] > g': {
                    'path[fill="#CAD0D8"]': {
                      fill: 'transparent',
                      stroke: colors.blilet.base,
                    },
                    'circle[fill="#CAD0D8"]': {
                      fill: colors.blilet.base,
                    },
                  },
                }}
              />
              <div className="mb-4 text-2xl font-semibold">
                Log in to continue
              </div>
              <div>
                To continue, authorize{' '}
                <Link isExternal to={origin}>
                  {origin}
                </Link>{' '}
                to use your Studio account{' '}
                {graphRefString && (
                  <>
                    {' '}
                    for graph <b>{graphRefString}</b>
                  </>
                )}
                .
              </div>
              <Button
                className="m-auto mt-6"
                onClick={() => {
                  openStudioLinkFromEmbedInNewTab(
                    onboardingRoutes.embedAuthenticationRouteConfig.path({
                      origin,
                      embedSubdomain,
                      graphRef: graphRefString,
                      type: embedAuthenticationDetails.inviteToken
                        ? 'invite'
                        : 'prod',
                      inviteToken: embedAuthenticationDetails.inviteToken,
                      accountId: embedAuthenticationDetails.accountId,
                    }),
                  );
                }}
              >
                Authorize
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
