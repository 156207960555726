import React from 'react';
import { Location, Navigate, Route } from 'react-router-dom-v5-compat';

import { legacyAccount } from 'src/app/account/routes';
import {
  changelogSingleChangeRouteConfig,
  checkChangesRouteConfig,
  checkRouteConfig,
  checksRouteConfig,
  graphSettingsRouteConfig,
  insightsRouteConfig,
  legacyChecksConfigRouteConfig,
  legacyChecksRouteConfig,
  legacyGraphsSettingsRouteConfig,
  legacyHistoryRouteConfig,
  legacyImplementingServicesRouteConfig,
  legacyInsightsRouteConfig,
  legacyIntegrationsRouteConfig,
  legacySchemaChangelogRouteConfig,
  legacySchemaSchemaTagChangelogRouteConfig,
  legacySchemaSingleChangelogRouteConfig,
  legacyServiceRouteConfig,
  legacyVariantUpgradeRouteConfig,
  proposalCheckChangesRouteConfig,
  proposalCheckRouteConfig,
  recentChecksRouteConfig,
  sandboxExplorerRouteConfig,
  sandboxGraphRouteConfig,
  sdlRouteConfig,
  variantRouteConfig,
} from 'src/app/graph/routes';
import { upgradeRoute } from 'src/app/onboarding/components';
import { sandboxLanding } from 'src/app/onboarding/routes';
import { Config } from 'src/lib/config/config';
import { mergeQueryParams } from 'src/lib/routing';

import { legacyRootUpgradeRoute, legacySupportRoute } from '../routes';

import { ChecksRedirect } from './ChecksRedirect';
import { LegacyChangelogRedirect } from './LegacyChangelogRedirect';
import { LegacyChecksRedirect } from './LegacyChecksConfigRedirect';
import { VariantDefaultRedirectWithLegacySupport } from './VariantDefaultRedirectWithLegacySupport';

/**
 * These are redirects primarily for legacy routes, that are just restructuring the data in the URL.
 *
 * For anything that needs to fetch data, we likely want those to be nested in the logged in route,
 * so we have access to the users data before redirecting
 */
export function redirects(location: Location) {
  return (
    <>
      {variantRouteConfig.patchV5DefinitionsForV6(true).map((path) => (
        <Route
          key={path}
          path={path}
          Component={VariantDefaultRedirectWithLegacySupport}
        />
      ))}
      <Route
        path={sandboxLanding.definition}
        element={
          <Navigate
            replace={true}
            to={{
              ...sandboxExplorerRouteConfig.location({}),
              search: location.search,
            }}
          />
        }
      />
      <Route
        path={sandboxGraphRouteConfig.definition}
        element={
          <Navigate
            replace={true}
            to={{
              ...location,
              pathname: location.pathname.replace(
                sandboxGraphRouteConfig.definition,
                sandboxExplorerRouteConfig.definition,
              ),
            }}
          />
        }
      />
      {legacyInsightsRouteConfig.patchV5DefinitionsForV6(false).map((path) => (
        <Route
          key={path}
          path={path}
          Component={() => (
            <Navigate
              replace={true}
              to={insightsRouteConfig.locationFrom({
                location,
                fromRouteConfig: legacyInsightsRouteConfig,
              })}
            />
          )}
        />
      ))}
      {legacyIntegrationsRouteConfig
        .patchV5DefinitionsForV6(false)
        .map((path) => (
          <Route
            key={path}
            path={path}
            Component={() => (
              <Navigate
                replace={true}
                to={graphSettingsRouteConfig.locationFrom({
                  location,
                  fromRouteConfig: legacyIntegrationsRouteConfig,
                  patch: { tab: 'reporting' },
                })}
              />
            )}
          />
        ))}
      {legacyGraphsSettingsRouteConfig
        .patchV5DefinitionsForV6(false)
        .map((path) => (
          <Route
            key={path}
            path={path}
            Component={() => (
              <Navigate
                replace={true}
                to={graphSettingsRouteConfig.locationFrom({
                  location,
                  fromRouteConfig: legacyGraphsSettingsRouteConfig,
                })}
              />
            )}
          />
        ))}
      {legacyHistoryRouteConfig.patchV5DefinitionsForV6(false).map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <LegacyChangelogRedirect
              fromRouteConfig={legacyHistoryRouteConfig}
            />
          }
        />
      ))}
      {legacySchemaChangelogRouteConfig
        .patchV5DefinitionsForV6(true)
        .map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <LegacyChangelogRedirect
                fromRouteConfig={legacySchemaChangelogRouteConfig}
              />
            }
          />
        ))}
      {legacySchemaSingleChangelogRouteConfig
        .patchV5DefinitionsForV6(false)
        .map((path) => (
          <Route
            key={path}
            path={path}
            Component={() => (
              <Navigate
                replace={true}
                to={changelogSingleChangeRouteConfig.locationFrom({
                  location,
                  fromRouteConfig: legacySchemaSingleChangelogRouteConfig,
                })}
              />
            )}
          />
        ))}
      {legacySchemaSchemaTagChangelogRouteConfig
        .patchV5DefinitionsForV6(false)
        .map((path) => (
          <Route
            key={path}
            path={path}
            Component={() => (
              <Navigate
                replace={true}
                to={changelogSingleChangeRouteConfig.locationFrom({
                  location,
                  fromRouteConfig: legacySchemaSchemaTagChangelogRouteConfig,
                })}
              />
            )}
          />
        ))}
      {legacyImplementingServicesRouteConfig
        .patchV5DefinitionsForV6(false)
        .map((path) => (
          <Route
            key={path}
            path={path}
            Component={() => {
              return (
                <Navigate
                  replace={true}
                  to={sdlRouteConfig.locationFrom({
                    fromRouteConfig: legacyImplementingServicesRouteConfig,
                    location,
                  })}
                />
              );
            }}
          />
        ))}
      {legacyChecksConfigRouteConfig
        .patchV5DefinitionsForV6(false)
        .map((path) => (
          <Route key={path} path={path} Component={LegacyChecksRedirect} />
        ))}
      {checksRouteConfig.patchV5DefinitionsForV6(true).map((path) => (
        <Route
          key={path}
          path={path}
          Component={() => (
            <Navigate
              replace={true}
              to={recentChecksRouteConfig.locationFrom({
                location,
                fromRouteConfig: checksRouteConfig,
              })}
            />
          )}
        />
      ))}
      {checkRouteConfig.patchV5DefinitionsForV6(true).map((path) => (
        <Route
          key={path}
          path={path}
          Component={() => (
            <Navigate
              replace={true}
              to={checkChangesRouteConfig.locationFrom({
                location,
                fromRouteConfig: checkRouteConfig,
              })}
            />
          )}
        />
      ))}
      {proposalCheckRouteConfig.patchV5DefinitionsForV6(true).map((path) => (
        <Route
          key={path}
          path={path}
          Component={() => (
            <Navigate
              replace={true}
              to={proposalCheckChangesRouteConfig.locationFrom({
                location,
                fromRouteConfig: proposalCheckRouteConfig,
              })}
            />
          )}
        />
      ))}
      {legacyChecksRouteConfig.patchV5DefinitionsForV6(true).map((path) => (
        <Route key={path} path={path} Component={ChecksRedirect} />
      ))}
      <Route
        path={legacyAccount.splatDefinition}
        element={
          <Navigate
            replace={true}
            to={{
              ...location,
              pathname: location.pathname.replace(/\/account\//, '/org/'),
            }}
          />
        }
      />
      <Route
        path={legacyServiceRouteConfig.splatDefinition}
        element={
          <Navigate
            replace={true}
            to={{
              ...location,
              pathname: location.pathname.replace(/\/service\//, '/graph/'),
            }}
          />
        }
      />
      {[
        ...legacyVariantUpgradeRouteConfig.patchV5DefinitionsForV6(false),
        legacyRootUpgradeRoute.splatDefinition,
      ].map((path) => (
        <Route
          key={path}
          path={path}
          element={<Navigate replace={true} to={upgradeRoute(location)} />}
        />
      ))}
      <Route
        path={legacySupportRoute.splatDefinition}
        element={
          <Navigate
            replace={true}
            to={{
              pathname: '/',
              search: mergeQueryParams(location.search, {
                [Config.queryParameters.Overlay]: Config.modals.supportRequest,
              }),
            }}
          />
        }
      />
    </>
  );
}
