import { captureEvent } from '@sentry/react';
import { useEffect, useState } from 'react';

export function useIncidentStatus() {
  const [errorStatus, setErrorStatus] = useState<string>();

  useEffect(() => {
    fetch('https://status.apollographql.com/api/v2/status.json')
      .then(async (result) => {
        // Shape from https://status.apollographql.com/api#status
        const resultJson = JSON.parse(await result.text());
        const status =
          typeof resultJson === 'object' &&
          resultJson &&
          'status' in resultJson &&
          typeof resultJson.status === 'object' &&
          resultJson.status;
        if (status && 'indicator' in status && status?.indicator !== 'none') {
          setErrorStatus(status?.description?.toString());
        }
      })
      .catch(() => captureEvent(new Error('Failed to fetch incident status')));
  }, []);

  return errorStatus;
}
