/* eslint-disable tailwindcss/no-custom-classname */
import IconOutlink from '@apollo/icons/default/IconOutlink.svg';
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  Icon,
  ThemingProps,
} from '@chakra-ui/react';
import React, { ComponentProps, forwardRef } from 'react';
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom';
import { Link as ReactRouterLinkCompat } from 'react-router-dom-v5-compat';

import { OmitChakraProps } from '../../utils/typeUtils';
// import { ExternalLinkIcon } from '../ExternalLinkIcon/ExternalLinkIcon';

type ReactRouterLinkPropsCompat = ComponentProps<typeof ReactRouterLinkCompat>;

export type LinkProps = OmitChakraProps<
  Omit<ChakraLinkProps, 'as' | 'href'>,
  keyof ThemingProps
> &
  (
    | {
        isExternal: true;
        to: string;
        compat?: never;
      }
    | (ReactRouterLinkPropsCompat & {
        isExternal?: false;
        compat?: true;
      })
    | (ReactRouterLinkProps & {
        isExternal?: false;
        compat: false;
      })
  );

/**
 * Orbit Links are meant to be used for branded links.
 *
 * They should not be passed to other Orbit components via the `as` prop.
 * Instead, use React Router's `Link` component or an anchor tag.
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ isExternal, to, children, compat = false, ...rest }, ref) => {
    return isExternal ? (
      <ExternalLink href={to} {...rest} ref={ref}>
        {children}
        <Icon as={IconOutlink} className="orbit-external-link-icon" />
      </ExternalLink>
    ) : compat ? (
      <InternalLinkCompat
        to={to as ReactRouterLinkPropsCompat['to']}
        {...rest}
        ref={ref}
      >
        {children}
      </InternalLinkCompat>
    ) : (
      <InternalLink to={to} {...rest} ref={ref}>
        {children}
      </InternalLink>
    );
  },
);

type ExternalLinkProps = Omit<ChakraLinkProps, 'isExternal'>;

const ExternalLink = forwardRef<HTMLAnchorElement, ExternalLinkProps>(
  (props, ref) => {
    return <ChakraLink isExternal {...props} ref={ref} />;
  },
);

const InternalLinkCompat = forwardRef<
  HTMLAnchorElement,
  ReactRouterLinkPropsCompat
>((props, ref) => {
  return <ChakraLink as={ReactRouterLinkCompat} {...props} ref={ref} />;
});

const InternalLink = forwardRef<HTMLAnchorElement, ReactRouterLinkProps>(
  (props, ref) => {
    return <ChakraLink as={ReactRouterLink} {...props} ref={ref} />;
  },
);
