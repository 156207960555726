import { ASTNode } from 'graphql';
import { visit } from 'graphql/language/visitor';

/** Remove all `__typename` fields from a given AST */
export function removeTypenameFields<T extends ASTNode>(node: T): T {
  return visit(node, {
    Field(fieldNode) {
      if (fieldNode.name.value === '__typename') {
        return null;
      }
    },
  });
}
