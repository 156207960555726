import {
  IconButtonProps as ChakraIconButtonProps,
  forwardRef,
} from '@chakra-ui/react';
import React, { cloneElement, isValidElement } from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';
import { Button, ButtonProps } from '../Button/Button';

export interface IconButtonProps
  extends OmitChakraProps<
      ChakraIconButtonProps,
      | 'iconSpacing'
      | 'spinner'
      | 'spinnerPlacement'
      | 'styleConfig'
      | 'orientation'
      | 'colorScheme'
      | 'variant'
      | 'size'
      | 'disabled'
      | 'loading'
    >,
    Pick<ButtonProps, 'size' | 'variant' | '__orbitStyleConfig'> {
  /**
   * Use `isDisabled` instead
   */
  disabled?: never;

  /**
   * Use `isLoading` instead
   */
  loading?: never;
}

export const IconButton = forwardRef<IconButtonProps, 'button'>(
  (props, ref) => {
    const { icon, children, isRound, 'aria-label': ariaLabel, ...rest } = props;
    /**
     * Passing the icon as prop or children should work
     */
    const element = icon || children;
    const _children = isValidElement(element)
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cloneElement(element as any, {
          'aria-hidden': true,
          focusable: false,
        })
      : null;

    return (
      <Button
        padding="0"
        borderRadius={isRound ? 'full' : undefined}
        ref={ref}
        aria-label={ariaLabel}
        {...rest}
      >
        {_children}
      </Button>
    );
  },
);
