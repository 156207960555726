import IconTeam from '@apollo/icons/default/IconTeam.svg';
import {
  Divider,
  IconButton,
  Link as OrbitLink,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@apollo/orbit';
// eslint-disable-next-line no-restricted-imports
import { useDisclosure } from '@chakra-ui/react';
import { Global, css } from '@emotion/react';
import { captureException } from '@sentry/react';
import _ from 'lodash';
import React, { Fragment, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { raw as ImageSlack } from 'src/app/graph/graphSettingsPage/reportingPage/helpers/slack.svg';
import { Tooltip } from 'src/components/common/tooltip/Tooltip';
import { assertUnreachableOrReturnDefault } from 'src/lib/assertUnreachable';
import { RouteConfig } from 'src/lib/routeConfig/RouteConfig';
import { Team, isTeam, teamContactInfo } from 'src/lib/teamOwnership';

function startsWith<SearchString extends string>(
  targetString: string,
  searchString: SearchString,
): targetString is `${SearchString}${string}` {
  return targetString.startsWith(searchString);
}

export function OwnersContactMenu() {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <Tooltip
        label="Show internal team ownership of this page"
        shouldWrapChildren={true}
      >
        <PopoverTrigger>
          <IconButton
            aria-label="internal owners"
            variant="hidden"
            size="sm"
            className={isOpen ? 'bg-btn-primary' : ''}
            icon={<IconTeam />}
            onClick={onToggle}
          />
        </PopoverTrigger>
      </Tooltip>

      <div className="z-50">
        <PopoverContent>
          <PopoverHeader>
            This page is owned internally by
            <PopoverCloseButton />
          </PopoverHeader>
          <OwnersBody isOpen={isOpen} />
        </PopoverContent>
      </div>
    </Popover>
  );
}
function OwnerContact({
  owner,
  color,
  isFirst,
}: {
  owner: Team;
  color: (typeof colors)[number] | undefined;
  isFirst: boolean;
}) {
  const contactInfo = teamContactInfo[owner];
  const link = contactInfo.contactTeam;
  return (
    <Fragment key={owner}>
      {!isFirst && <Divider className="my-2" />}
      <div>
        <div className="flex items-center">
          <div
            style={{ background: color }}
            className="mr-1 size-3 rounded-full"
          />
          Team name: {'teamName' in contactInfo ? contactInfo.teamName : owner}
          <br />
        </div>
        <OrbitLink isExternal={true} to={contactInfo.contactTeam} className="">
          {startsWith(link, 'slack://') ? (
            <img
              src={ImageSlack}
              alt="slack"
              className="mr-2 size-4 align-middle"
            />
          ) : (
            assertUnreachableOrReturnDefault(link, null)
          )}
          Contact team
        </OrbitLink>
      </div>
    </Fragment>
  );
}
const colors = [
  'blue',
  'green',
  'red',
  'yellow',
  'purple',
  'pink',
  'maroon',
  'fuchsia',
  'lime',
  'navy',
  'teal',
  'aqua',
] as const;
let hasReportedMoreTeamsThenColours = false;
function OwnersBody({ isOpen }: { isOpen: boolean }) {
  const location = useLocation();
  const [domOwners, setDomOwners] = React.useState<Team[]>([]);
  useEffect(() => {
    if (isOpen) {
      const updateDomOwners = () => {
        setDomOwners((old) => {
          const nextDomOwners = _.orderBy(
            _.uniq(
              [...document.querySelectorAll('[data-team]')].map((el) => {
                const owner =
                  el instanceof HTMLElement ? el.dataset.team : null;
                if (!isTeam(owner)) {
                  throw new Error(`Unknown team: ${owner}`);
                }
                return owner;
              }),
            ),
            (n) => {
              const index = old.indexOf(n);
              return index === -1 ? Infinity : index;
            },
            'asc',
          );
          if (!_.isEqual(old, nextDomOwners)) {
            if (
              !hasReportedMoreTeamsThenColours &&
              nextDomOwners.length > colors.length
            ) {
              hasReportedMoreTeamsThenColours = true;
              captureException(new Error('More teams then colors'));
            }
            return nextDomOwners;
          } else {
            return old;
          }
        });
      };
      const intervalId = setInterval(updateDomOwners, 2_000);
      updateDomOwners();
      return () => clearInterval(intervalId);
    }
  }, [isOpen]);

  const routeOwners = useMemo(
    () => RouteConfig.getActiveOwners(location),
    [location],
  );

  const domOwnersThatDontOwnTheRoute = domOwners.filter(
    (o) => !routeOwners.includes(o),
  );
  return (
    <>
      <PopoverBody>
        {isOpen ? (
          <Global
            styles={domOwners.map((team, i) => {
              return css`
                [data-team='${team}']:after {
                  content: '';
                  display: block;
                  border: 4px solid ${colors[i % colors.length]};
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  border-radius: 4px;
                  z-index: 1;
                }
              `;
            })}
          />
        ) : null}
        {routeOwners.length > 0
          ? routeOwners.map((owner, i) => {
              return (
                <OwnerContact
                  key={owner}
                  owner={owner}
                  color={colors[domOwners.indexOf(owner) % colors.length]}
                  isFirst={i === 0}
                />
              );
            })
          : 'no team identified, contact #inbox-studio'}
      </PopoverBody>
      {domOwnersThatDontOwnTheRoute.length > 0 ? (
        <>
          <PopoverHeader>Additional component owners</PopoverHeader>
          <PopoverBody>
            {domOwnersThatDontOwnTheRoute.map((owner, i) => {
              return (
                <OwnerContact
                  key={owner}
                  owner={owner}
                  color={colors[domOwners.indexOf(owner) % colors.length]}
                  isFirst={i === 0}
                />
              );
            })}
          </PopoverBody>
        </>
      ) : null}
    </>
  );
}
