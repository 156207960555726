import { useCallback, useLayoutEffect, useRef } from 'react';

export function useEvent<TCallback extends Function>(
  callback: TCallback,
): TCallback {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    ((...args: unknown[]) =>
      callbackRef.current(...args)) as unknown as TCallback,
    [],
  );
}
