import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const { bg, border, text } = customColors;

const baseStyle = defineStyle({
  rounded: 'base',
  border: '1px solid',
  borderColor: border.primary,
  background: bg.secondary,
  py: 0.5,
  px: 2,
  color: text.primary,
  fontFamily: 'mono',
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: 'sm',
});

export const KbdStyles = defineStyleConfig({ baseStyle, variants: {} });
