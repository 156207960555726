import {
  useEnterpriseTrialForAll,
  useGraphOSTrialEnabled,
} from 'src/lib/featureFlags';

export const useDefaultPlanName = () => {
  const isGraphOSTrialEnabled = useGraphOSTrialEnabled();
  const isEnterpriseTrialForAllEnabled = useEnterpriseTrialForAll();

  if (isGraphOSTrialEnabled) return 'GraphOS Trial';
  if (isEnterpriseTrialForAllEnabled) return 'Enterprise Trial';

  return 'Apollo Serverless (Free)';
};
