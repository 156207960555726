import { ExternalRouter } from 'src/lib/external-router';

import Config from '../config';

const { pricingUrl } = Config;

export const PricingRouter = new ExternalRouter(pricingUrl, {
  Home: '',
  FAQ: '#faq',
  CompareFeatures: '#features',
});
