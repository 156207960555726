import {
  ModalFooter as ChakraModalFooter,
  ModalFooterProps as ChakraModalFooterProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export type ModalFooterProps = OmitChakraProps<ChakraModalFooterProps>;

export const ModalFooter = forwardRef<ModalFooterProps, 'footer'>(
  (props, ref) => {
    return <ChakraModalFooter {...props} ref={ref} />;
  },
);
