import React from 'react';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';

import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { chooseDefaultAccountId, useIdentity } from 'src/hooks/useIdentity';
import { useLDFlag } from 'src/lib/launchDarkly';

import { org, userSettingsRouteConfig } from '../../account/routes';
import { OrgProvisioner } from '../../appLayout/components/orgProvisioner/OrgProvisioner';
import { useIsLoggedIn } from '../../graph/hooks/useIsLoggedIn';
import { UnifiedOnboardingSurveyModal } from '../../onboarding/views/unifiedOnboardingSurvey/UnifiedOnboardingSurvey';

export function RootRedirect() {
  const location = useLocation();
  const [currentAccountId] = useCurrentAccountId();
  const { me, meLoading } = useIdentity();
  const { isLoggedIn } = useIsLoggedIn();

  const unifiedOnboardingFlagEnabled = useLDFlag(
    'astro-growth-unified-onboarding-survey',
  );

  // Redirect from '/' to the last org the user looked at (if they're still in
  // the org!) or to an org that they are in.
  const defaultAccount =
    me?.__typename !== 'User' ||
    me?.memberships.some(({ account }) => account.id === currentAccountId)
      ? currentAccountId
      : chooseDefaultAccountId(me);
  // TODO: Refactor to use route config locations directly
  const rootRedirect = {
    ...location,
    pathname: defaultAccount
      ? org.location({ orgId: defaultAccount }).pathname
      : userSettingsRouteConfig.location({}).pathname,
  };

  if (meLoading) return null;

  // We used to not make orgs for folks who signed up
  // through sandbox. The OrgProvisioner will automatically backfill
  // orgs for folks without them, so that they can use features that
  // require you to have an org
  if (isLoggedIn && me?.__typename === 'User' && !me?.memberships.length) {
    return unifiedOnboardingFlagEnabled ? (
      <UnifiedOnboardingSurveyModal />
    ) : (
      <OrgProvisioner />
    );
  }
  return <Redirect to={rootRedirect} />;
}
