import { ApolloClient, InMemoryCache, gql } from '@apollo/client';

import * as ApolloTypes from '../graphqlTypes/types';
import { localStorageWithMemoryFallback } from '../localStorageWithMemoryFallback';

interface Context {
  client: ApolloClient<InMemoryCache>;
}

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean
    currentAccountId: ID
  }

  extend type Mutation {
    # Save the current org id. Pass null to delete the saved value.
    setCurrentAccountId(currentAccountId: ID): ID
  }
`;

export const resolvers = {
  Query: {
    currentAccountId() {
      return JSON.parse(
        localStorageWithMemoryFallback.getItem('engine:currentAccountId') ||
          'null',
      );
    },
  },
  Mutation: {
    setCurrentAccountId: (
      _root: unknown,
      {
        currentAccountId = null,
      }: ApolloTypes.SetCurrentAccountIdMutationVariables,
      context: Context,
    ) => {
      context.client.writeQuery<ApolloTypes.ClientSchemaSetCurrentAccountIdCacheWriteQuery>(
        {
          query: gql`
            query ClientSchemaSetCurrentAccountIdCacheWriteQuery {
              currentAccountId @client
            }
          `,
          data: { currentAccountId },
        },
      );

      if (currentAccountId) {
        localStorageWithMemoryFallback.setItem(
          'engine:currentAccountId',
          JSON.stringify(currentAccountId),
        );
      } else {
        localStorageWithMemoryFallback.removeItem('engine:currentAccountId');
      }

      return currentAccountId;
    },
  },
};
