import {
  useEnterpriseTrialForAll,
  useGraphOSTrialEnabled,
} from 'src/lib/featureFlags';

export const PLAN_ID_ENTERPRISE_TRIAL = 'sub-engine-ent-trial';
export const PLAN_ID_GRAPHOS_TRIAL = 'graphos-trial-v1';

/**
 * Returns the default plan ID for a new user.
 *
 * Logic:
 * - Prioritizes GraphOS trial plan if feature flag enabled.
 * - Falls back to the Enterprise trial plan if GraphOS is not enabled but Enterprise trial for all is.
 * - Returns undefined if neither trial is active.
 *
 * Used when generating a new organization or when no org is found for the user.
 *
 * NOTE: The approach to setting a default plan could be optimized via a plan helper
 * in order to have more control in providing a default plan, rather than
 * deferring to a planId prop. A ticket to track this implementation is in the frontend backlog
 * here: https://apollographql.atlassian.net/browse/DEBT-182.
 * See prior work on generating a default by feature flag here https://github.com/mdg-private/studio-ui/pull/10744.
 */
export const useDefaultPlan = () => {
  const isGraphOSTrialEnabled = useGraphOSTrialEnabled();
  const isEnterpriseTrialEnabled = useEnterpriseTrialForAll();

  if (isGraphOSTrialEnabled) return PLAN_ID_GRAPHOS_TRIAL;

  if (isEnterpriseTrialEnabled) return PLAN_ID_ENTERPRISE_TRIAL;

  return undefined;
};
