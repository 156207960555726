import IconErrorSolid from '@apollo/icons/default/IconErrorSolid.svg';
import {
  FormErrorIcon as ChakraFormErrorIcon,
  FormErrorMessage as ChakraFormErrorMessage,
  chakra,
  forwardRef,
  useFormErrorStyles,
} from '@chakra-ui/react';
import React from 'react';

export interface FormErrorMessageProps {
  /**
   * Contains the error message.
   */
  children: React.ReactNode;
}

/**
 * This component is intended to be used within FormControl (see component's story for examples).
 * If you need to display info that is NOT an error, please use FormHelperText
 */
export const FormErrorMessage = forwardRef<FormErrorMessageProps, 'div'>(
  ({ children }, ref) => {
    return (
      <ChakraFormErrorMessage ref={ref}>
        <FormErrorContent>{children}</FormErrorContent>
      </ChakraFormErrorMessage>
    );
  },
);

const FormErrorContent = ({
  children,
}: {
  children: FormErrorMessageProps['children'];
}) => {
  const styles = useFormErrorStyles();

  return (
    <>
      <chakra.div __css={styles.iconContainer}>
        <ChakraFormErrorIcon as={IconErrorSolid} />
      </chakra.div>
      <chakra.div __css={styles.textContainer}>{children}</chakra.div>
    </>
  );
};
