import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const { text } = customColors;

const baseStyle = defineStyle({
  color: text.heading,
  fontFamily: 'heading',
});

const variants = {
  heading: defineStyle({
    fontWeight: 'medium',
  }),
  title: defineStyle({
    fontSize: 'base',
    fontWeight: 'medium',
    lineHeight: 'base',
  }),
};

const sizes = {
  lg: defineStyle({
    fontSize: 'lg',
    lineHeight: 'lg',
  }),
  xl: defineStyle({
    fontSize: 'xl',
    lineHeight: 'xl',
  }),
  '2xl': defineStyle({
    fontSize: '2xl',
    lineHeight: '2xl',
  }),
  '3xl': defineStyle({
    fontSize: '3xl',
    lineHeight: '3xl',
  }),
};

export const HeadingStyles = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: { size: 'lg', variant: 'heading' },
});
