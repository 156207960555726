import classnames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';
import { useWizard } from 'react-use-wizard';

import { WrapMaybe } from '../wrapMaybe/WrapMaybe';

export function WizardHeader({
  wizardSteps,
  className,
  animated = true,
}: {
  wizardSteps: string[];
  className?: string;
  animated?: boolean;
}) {
  const { activeStep } = useWizard();
  return (
    // this motion.div is here for the Supergraph onboarding flow
    // to animate the right side drawer in and out
    <WrapMaybe element={<motion.div layout />} on={animated}>
      <div className={classnames('flex h-16 min-h-16 items-center', className)}>
        {wizardSteps.map((step, idx) => (
          <React.Fragment key={step}>
            {idx !== 0 && (
              <div
                className={classnames('mx-3 h-0 flex-1 border', {
                  'border-blue': idx <= activeStep,
                  'border-dashed border-grey-light': idx > activeStep,
                })}
              />
            )}
            <div className="flex truncate text-sm font-semibold">
              <div
                className={classnames(
                  'flex size-6 min-w-6 items-center justify-center rounded-full border-[1.5px]',
                  {
                    'border-blue bg-blue text-inverted': idx < activeStep,
                    'border-blue text-link': idx === activeStep,
                    'border-grey-light text-placeholder': idx > activeStep,
                  },
                )}
              >
                {idx + 1}
              </div>
              <div
                className={classnames('ml-2 truncate text-base font-semibold', {
                  'text-secondary': idx < activeStep,
                  'text-link': idx === activeStep,
                  'text-placeholder': idx > activeStep,
                })}
              >
                {step}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </WrapMaybe>
  );
}
