import { useRouteParams } from 'src/hooks/useRouteParams';
import { catchAllRouteConfig } from 'src/lib/routeConfig/catchAllRoute';

import { embeddableExplorerRouteConfig, graphRouteConfig } from '../routes';

import { parseGraphRef } from './useGraphRef';
import { useIsPublicMemberOnly } from './useIsPublicMemberOnly';

export function useGraphVisibility() {
  const { graphVisibilityType } = useRouteParams(
    graphRouteConfig,
    catchAllRouteConfig,
  );

  // In the embeddable route:
  //  The graphRef lives in a query param in the form `graphId@variant`
  //  This graph can be public or private, and we don't know from the url.
  //  We have to fetch to figure out if this graph should be showing the public
  //  view
  const graphRefFromEmbeddableRoute = parseGraphRef(
    useRouteParams(embeddableExplorerRouteConfig, catchAllRouteConfig).graphRef,
  );

  const isPublicEmbedMemberOnly = useIsPublicMemberOnly({
    graphRef: graphRefFromEmbeddableRoute,
  });

  return graphVisibilityType ?? (!isPublicEmbedMemberOnly ? 'graph' : 'public');
}
