/* eslint-disable local/no-conditional-class-names */

import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  forwardRef,
} from '@chakra-ui/react';
import classnames from 'classnames';
import React from 'react';

import { OmitChakraProps } from '../../utils/typeUtils';

export interface InputProps
  extends OmitChakraProps<
    ChakraInputProps,
    'disabled' | 'required' | 'isRequired'
  > {
  /**
   * Class name to apply to the input element
   */
  inputClassName?: string;
  /**
   * Elements meant to be displayed internally on the left side of the input
   */
  leftElement?: React.ReactNode;
  /**
   * Elements meant to be displayed internally on the right side of the input
   */
  rightElement?: React.ReactNode;
}

export const Input = forwardRef<InputProps, 'input'>(
  ({ leftElement, rightElement, className, inputClassName, ...props }, ref) => {
    return (
      <InputGroup className={classnames('isolate', className)}>
        {!!leftElement && (
          <InputLeftElement
            className={classnames(props.isDisabled ? 'text-icon-disabled' : '')}
          >
            {leftElement}
          </InputLeftElement>
        )}
        <ChakraInput {...props} className={inputClassName} ref={ref} />
        {!!rightElement && (
          <InputRightElement
            className={classnames(props.isDisabled ? 'text-icon-disabled' : '')}
          >
            {rightElement}
          </InputRightElement>
        )}
      </InputGroup>
    );
  },
);
