/* eslint-disable @typescript-eslint/ban-types */
import IconErrorSolid from '@apollo/icons/large/IconErrorSolid.svg';
import IconInfoSolid from '@apollo/icons/large/IconInfoSolid.svg';
import IconSparklesSolid from '@apollo/icons/large/IconSparklesSolid.svg';
import IconStarSolid from '@apollo/icons/large/IconStarSolid.svg';
import IconSuccessSolid from '@apollo/icons/large/IconSuccessSolid.svg';
import IconWarningSolid from '@apollo/icons/large/IconWarningSolid.svg';
import { AlertIcon as ChakraAlertIcon } from '@chakra-ui/react';
import React from 'react';

import { AlertStatus } from '../Alert/shared';

const getAlertIcon = (status: AlertStatus) => {
  switch (status) {
    case 'info':
      return IconInfoSolid;
    case 'error':
      return IconErrorSolid;
    case 'success':
      return IconSuccessSolid;
    case 'warning':
      return IconWarningSolid;
    case 'beta':
      return IconSparklesSolid;
    case 'neutral':
    default:
      return IconStarSolid;
  }
};

export interface AlertIconProps {
  status: AlertStatus;
  icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>> | undefined;
}

export const AlertIcon = ({ status, icon }: AlertIconProps) => (
  <ChakraAlertIcon as={icon ?? getAlertIcon(status)} />
);
