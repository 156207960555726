import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const baseStyle = defineStyle({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 2,
  color: customColors.text.secondary,
  fontFamily: 'mono',
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: 'sm',
});

export const KbdComboStyles = defineStyleConfig({
  baseStyle,
  sizes: {},
  variants: {},
});
