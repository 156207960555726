import { castArray } from 'lodash';
import React from 'react';

import { UserPermission } from 'src/lib/graphqlTypes/types';

import {
  AccountPermission,
  GraphPermission,
  GraphVariantPermission,
  usePermissions,
} from './usePermissions';

type BlockData = ReturnType<typeof usePermissions>['data'] & {
  loading?: boolean;
};
type ChildrenOrChildRenderer =
  | {
      childRenderer: (hasPermission: boolean) => React.ReactNode;
      children?: never;
    }
  | {
      childRenderer?: never;
      children?: React.ReactNode;
    };

type AllOrNothing<T> = T | { [key in keyof T]?: never };

interface AccountGuard {
  accountId: string | undefined;
  accountPermissions: keyof AccountPermission | (keyof AccountPermission)[];
}

interface GraphGuard {
  graphId: string | undefined;
  graphPermissions: keyof GraphPermission | (keyof GraphPermission)[];
}

interface GraphVariantGuard {
  graphId: string | undefined;
  graphVariant: string | undefined;
  graphVariantPermissions:
    | keyof GraphVariantPermission
    | (keyof GraphVariantPermission)[];
}

type RequiredProps = AllOrNothing<AccountGuard> &
  AllOrNothing<
    { graphId: string | undefined } & AllOrNothing<
      Omit<GraphGuard, 'graphId'>
    > &
      AllOrNothing<Omit<GraphVariantGuard, 'graphId'>>
  > &
  (AccountGuard | GraphVariantGuard | GraphGuard);

export function permissionWrapperFactory<T>(
  PermissionWrapper: (
    props: T &
      RequiredProps &
      BlockData & { isGraphBlock?: boolean; isProtectedVariantBlock?: boolean },
  ) => React.ReactElement,
): (props: T & RequiredProps & ChildrenOrChildRenderer) => React.ReactElement {
  return (props) => {
    const {
      accountId,
      graphId,
      accountPermissions,
      graphPermissions,
      graphVariantPermissions,
      children,
      childRenderer,
      graphVariant,
    } = props;
    const permissions = usePermissions(accountId, graphId, graphVariant);

    if (
      castArray(accountPermissions ?? []).some(
        (permission) => !permissions.data.accountPermissions?.[permission],
      )
    ) {
      return (
        <PermissionWrapper
          loading={permissions.loading}
          {...props}
          {...permissions.data}
        >
          {childRenderer ? childRenderer(false) : children}
        </PermissionWrapper>
      );
    }

    if (
      castArray(graphPermissions ?? []).some(
        (permission) => !permissions.data.graphPermissions?.[permission],
      )
    ) {
      return (
        <PermissionWrapper
          isGraphBlock
          loading={permissions.loading}
          {...props}
          {...permissions.data}
        >
          {childRenderer ? childRenderer(false) : children}
        </PermissionWrapper>
      );
    }

    if (
      castArray(graphVariantPermissions ?? []).some(
        (permission) => !permissions.data.graphVariantPermissions?.[permission],
      )
    ) {
      return (
        <PermissionWrapper
          isProtectedVariantBlock={
            permissions.data.graphRole &&
            permissions.data.graphVariantIsProtected &&
            permissions.data.graphRole === UserPermission.CONTRIBUTOR
          }
          isGraphBlock
          loading={permissions.loading}
          {...props}
          {...permissions.data}
        >
          {childRenderer ? childRenderer(false) : children}
        </PermissionWrapper>
      );
    }

    return <>{childRenderer ? childRenderer(true) : children}</>;
  };
}
