import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';
import { $groupSpacingX, $groupSpacingY } from '../Group/Group.style';

const { bg, border, icon, text } = customColors;
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const header = defineStyle({
  [$groupSpacingX.variable]: 'space.2',
  [$groupSpacingY.variable]: 'space.1',
  cursor: 'pointer',
  py: 3,
  px: 4,
  gap: 6,
  border: '1px solid',
  borderColor: border.primary,
  borderRadius: 'base',
  bg: bg.primary,
  color: text.heading,
  '& > :last-child': {
    ml: 'auto',
  },
  '&:has(.orbit-accordion__description)': {
    alignItems: 'start',
    '& .chakra-accordion__icon': {
      mt: 1,
    },
  },
  _expanded: {
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
  },
  _focusVisible: {
    boxShadow: 'unset',
    outlineColor: border.focused,
  },
  _hover: {
    bg: bg.secondary,
  },
});

const baseStyle = definePartsStyle({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  container: {
    border: 'none',
  },
  // The <AccordionHeader /> uses <AccordionButton />, so we target its styles
  // using the `button` key.
  button: header,
  panel: {
    p: 4,
    px: false,
    py: false,
    alignItems: 'flex-start',
    gap: 4,
    alignSelf: 'stretch',
    borderTopRadius: 0,
    borderBottomRadius: 'base',
    borderRight: '1px solid',
    borderLeft: '1px solid',
    borderBottom: '1px solid',
    bg: bg.primary,
    color: text.primary,
    borderColor: border.primary,
    fontFamily: 'body',
    fontSize: 'base',
    lineHeight: 'base',
    '&:has(.chakra-table__container)': {
      p: 0,
      overflow: 'hidden',
      '.chakra-table__container tr:last-child td': {
        border: 'none',
      },
    },
  },
  icon: {
    w: 4,
    h: 4,
    color: icon.primary,
  },
});

export const AccordionStyles = defineMultiStyleConfig({ baseStyle });
