import * as baseFont from "./base";
import { TypographyDefinition } from "./TypographyDefinition";
import type { Property } from "csstype";

/**
 * Monospace font
 *
 * @see https://zpl.io/V4ep7Jy
 */
const monoFontFamily: Property.FontFamily = "'Source Code Pro', monospace";

export const large: TypographyDefinition = {
  ...baseFont.large,
  fontFamily: monoFontFamily,
};

export const base: TypographyDefinition = {
  ...baseFont.base,
  fontFamily: monoFontFamily,
};

export const small: TypographyDefinition = {
  ...baseFont.small,
  fontFamily: monoFontFamily,
};

export const xsmall: TypographyDefinition = {
  ...baseFont.xsmall,
  fontFamily: monoFontFamily,
};
