import {
  As,
  PropsOf,
  SystemStyleObject,
  chakra,
  forwardRef,
  useStyleConfig,
} from '@chakra-ui/react';
import React from 'react';

type TextThemeProps =
  | {
      variant?: never;

      /**
       * The size of the text.
       *
       * @default base
       */
      size?: 'base' | 'sm';
    }
  | {
      /**
       * The rendered style of the text.
       *
       * @default body
       */
      variant: 'body' | 'bodySb' | 'codeSb';

      /**
       * The size of the text.
       *
       * @default base
       */
      size?: 'base' | 'sm';
    }
  | {
      /**
       * The rendered style of the text.
       *
       * @default body
       */
      variant: 'code';

      /**
       * The size of the text.
       *
       * @default base
       */
      size?: 'base' | 'sm' | '2xl';
    }
  | {
      /**
       * The rendered style of the text.
       *
       * @default body
       */
      variant: 'label';
      size?: never;
    }
  | {
      /**
       * The rendered style of the text.
       *
       * @default body
       */
      variant: 'subtitle';
      size?: never;
    };

export type TextProps<Component extends As = 'span'> = PropsOf<Component> &
  TextThemeProps & {
    /**
     * Additional style config needed for component that wraps `Text`. This
     * should only be used internally by other Orbit components. Please use
     * `className` for all styling.
     *
     * @internal
     */
    __orbitStyleConfig?: SystemStyleObject;
  };

export const Text = forwardRef<TextProps, 'span'>(
  ({ variant = 'body', size = 'base', __orbitStyleConfig, ...props }, ref) => {
    const styles = useStyleConfig('Text', { variant, size });

    return (
      <chakra.p
        {...props}
        ref={ref}
        __css={{ ...styles, ...__orbitStyleConfig }}
      />
    );
  },
);
