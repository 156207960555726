import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  companyName: Yup.string().required(
    'You must provide a company name for billing invoices.',
  ),
  companyDomain: Yup.string()
    .url('You must provide a valid url.')
    .required('You must provide a company domain.'),
  billingContactEmail: Yup.string()
    .email('You must provide a valid email.')
    .required('You must provide a billing contact email.'),
  firstName: Yup.string().required('You must provide a first name.'),
  lastName: Yup.string().required('You must provide a last name.'),
  streetAddress: Yup.string().required('You must provide an address.'),
  aptSuiteBldg: Yup.string(),
  city: Yup.string().required('You must provide a city.'),
  stateProvReg: Yup.string().required(
    'Please enter a state / province / region.',
  ),
  zip: Yup.string().required('You must provide a zip / postal code.'),
  country: Yup.string().required('You must provide a country.'),
  vat: Yup.string(),
  acceptsTerms: Yup.boolean()
    .required(`You must accept Apollo's Terms of Service.`)
    .oneOf([true], `You must accept Apollo's Terms of Service.`),
});

export const updateCardValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('You must provide a first name.'),
  lastName: Yup.string().required('You must provide a last name.'),
  streetAddress: Yup.string().required('You must provide an address.'),
  aptSuiteBldg: Yup.string(),
  city: Yup.string().required('You must provide a city.'),
  stateProvReg: Yup.string().required(
    'You must provide a state / province / region.',
  ),
  zip: Yup.string().required('You must provide a zip / postal code.'),
  country: Yup.string().required('You must provide a country.'),
  vat: Yup.string(),
});
