import { ExternalRouter } from 'src/lib/external-router';

import Config from '../config';

const { odysseyUrl } = Config;

export const OdysseyRouter = new ExternalRouter(odysseyUrl, {
  Home: '',
  Certifications: '#certifications',
  About: 'about',
  AssociateCertification: 'certifications/apollo-graph-associate',
  ProfessionalCertification: 'certifications/apollo-graph-professional',
  AssociateCertificationExam: 'exams/apollo-graph-associate',
});
