import { useLDFlag } from './launchDarkly';

export const useShouldEnableFed2Onboarding = () =>
  useLDFlag('pulsar_fed2Onboarding');

export const usePulsarShowFieldsL1Metrics = () =>
  useLDFlag('pulsar_displayListAnalyticsLargeSchemas');

export const usePulsarCommercialEntitlementsBanners = () =>
  useLDFlag('operations-l1-commercial-router-entitlements');

export const useOrionFedAlpha = () => useLDFlag('orion_fed_alpha');

export const useShowCompositionRules = () =>
  useLDFlag('orion-show-composition-rules');

export const useUnifiedGraphOnboarding = () =>
  useLDFlag('betelgeuse-unified-onboarding');

export const useEnterpriseTrialForAll = () =>
  useLDFlag('betelgeuse-enterprise-trial-for-all');

export const useGraphOSTrialEnabled = () =>
  useLDFlag('growth-graphos-trial-v1-onboarding-ui');

export const useIsFedNextEnabled = () => useLDFlag('fed_next_enabled');

export const useShowConnectorsOnboarding = () => {
  const isFedNextEnabled = useIsFedNextEnabled();
  const isConnectorsOnboardingEnabled = useLDFlag(
    'growth-show-connectors-onboarding',
  );
  return isFedNextEnabled && isConnectorsOnboardingEnabled;
};

export const useShowUserProfileSurvey = () =>
  useLDFlag('growth-show-user-profile-survey');
