import { cssVar, defineStyle, defineStyleConfig } from '@chakra-ui/react';

export const $groupSpacing = cssVar('group-spacing', '0');
export const $groupSpacingX = cssVar(
  'group-spacing-x',
  $groupSpacing.reference,
);
export const $groupSpacingY = cssVar(
  'group-spacing-y',
  $groupSpacing.reference,
);
export const $groupAlign = cssVar('group-align', 'center');
export const $groupJustify = cssVar('group-justify');

const baseStyle = defineStyle({
  display: 'flex',
  '&[data-direction="vertical"]': {
    gap: $groupSpacingY.reference,
    flexDirection: 'column',
    justifyContent: $groupAlign.reference,
    alignItems: $groupJustify.reference,

    // We don't use the CSS variable when passing alignment and justification to
    // the component to avoid cascading the value to other groups contained
    // within this one.
    '&[data-justify="start"]': {
      alignItems: 'start',
    },
    '&[data-justify="center"]': {
      alignItems: 'center',
    },
    '&[data-justify="end"]': {
      alignItems: 'end',
    },
    '&[data-align="start"]': {
      justifyContent: 'start',
    },
    '&[data-align="center"]': {
      justifyContent: 'center',
    },
    '&[data-align="end"]': {
      justifyContent: 'end',
    },
    '&[data-align="between"]': {
      justifyContent: 'space-between',
    },
  },
  '&[data-direction="horizontal"]': {
    gap: $groupSpacingX.reference,
    justifyContent: $groupJustify.reference,
    alignItems: $groupAlign.reference,

    '&[data-align="start"] > svg': {
      mt: 1,
    },

    // We don't use the CSS variable when passing alignment and justification to
    // the component to avoid cascading the value to other groups contained
    // within this one.
    '&[data-justify="start"]': {
      justifyContent: 'start',
    },
    '&[data-justify="center"]': {
      justifyContent: 'center',
    },
    '&[data-justify="end"]': {
      justifyContent: 'end',
    },
    '&[data-justify="between"]': {
      justifyContent: 'space-between',
    },
    '&[data-align="start"]': {
      alignItems: 'start',
    },
    '&[data-align="center"]': {
      alignItems: 'center',
    },
    '&[data-align="end"]': {
      alignItems: 'end',
    },
  },
});

const fill = defineStyle({ flex: 1 });

export const GroupStyles = defineStyleConfig({
  baseStyle,
  sizes: { fill },
  defaultProps: {
    size: 'fill',
  },
});
