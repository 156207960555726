import { Button, ButtonGroup, ModalFooter } from '@apollo/orbit';
import React from 'react';

import { PricingLink } from 'src/components/pricingLink/PricingLink';
interface Props {
  onClose: () => void;
  handleButtonClick: () => void;
  loading: boolean;
  planName: string;
}

export const ConvertAccountModalFooter = ({
  loading,
  handleButtonClick,
  onClose,
  planName,
}: Props) => {
  return (
    <ModalFooter>
      <PricingLink>Learn more about our plans</PricingLink>
      <ButtonGroup>
        <Button
          variant="secondary"
          data-analytics-category="Upgrade Account"
          data-analytics-label={`Canceled conversion to ${planName}`}
          onClick={onClose}
          isDisabled={loading}
        >
          Cancel
        </Button>
        <Button
          data-analytics-category="Upgrade Account"
          data-analytics-label={`Upgraded account to ${planName}`}
          isLoading={loading}
          loadingText="Upgrading"
          onClick={handleButtonClick}
        >
          Yes, convert my account
        </Button>
      </ButtonGroup>
    </ModalFooter>
  );
};
