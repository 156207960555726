import type {
  EmbedDefaultRouteFragmentType,
  EmbeddedExplorerRouteFragmentType,
} from '../graph/routes';

type ExplorerSpecificConfigOptions = Omit<
  ReturnType<EmbeddedExplorerRouteFragmentType['parseSearchParams']>,
  | 'shouldPersistState'
  | 'showHeadersAndEnvVars'
  | 'shouldShowGlobalHeader'
  | 'parentSupportsSubscriptions'
> & {
  // convert string 'true' or 'false' into booleans
  showHeadersAndEnvVars: boolean;
  shouldShowGlobalHeader: boolean;
  parentSupportsSubscriptions: boolean;
};

type SharedEmbedConfigOptions = Omit<
  ReturnType<EmbedDefaultRouteFragmentType['parseSearchParams']>,
  'runTelemetry'
> & { runTelemetry: boolean };

// Require all the booleans to be not undefined
export type EmbeddableExplorerConfig = Required<
  Omit<
    SharedEmbedConfigOptions & ExplorerSpecificConfigOptions,
    'version' | 'graphRef' | 'docsPanelState' | 'theme'
  >
> &
  Pick<
    SharedEmbedConfigOptions & ExplorerSpecificConfigOptions,
    'version' | 'docsPanelState' | 'theme'
  >;

export const STABLE_DEFAULT_EMBEDDABLE_EXPLORER_CONFIG: EmbeddableExplorerConfig =
  {
    docsPanelState: undefined,
    theme: undefined,
    parentSupportsSubscriptions: false,
    showHeadersAndEnvVars: true,
    version: undefined,
    runTelemetry: false,
    // default to false b/c dev tools might pass undefined bc its versioned &
    // we need to not show the header there
    shouldShowGlobalHeader: false,
  };
