import { gql } from '@apollo/client';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
} from '@apollo/orbit';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { Form } from 'src/components/common/form/Form';
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage';
import { useUpdateBillingContactEmailMutation } from 'src/hooks/useUpdateBillingContactEmailMutation';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

/**
 * Schema for the form
 */
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please provide a valid email address.')
    .required('Please provide an email address.'),
});

/**
 * Types inferred from `validationSchema`
 */
type FormValues = Yup.InferType<typeof validationSchema>;

export const BillingContactEmailModalAccountFragment = gql`
  fragment BillingContactEmailModalAccountFragment on Account {
    id
    billingContactEmail
  }
`;

interface Props {
  isOpen: boolean;
  account: GraphQLTypes.BillingContactEmailModalAccountFragment;
  onClose: () => void;
}

export const BillingContactEmailModal = ({
  isOpen,
  account,
  onClose,
}: Props) => {
  const [validationError, setValidationError] = useState<string | null>(null);
  // We don't use `loading` from the mutation because we're letting `formik`
  // handle the loading state
  const [updateBillingContactEmail, { error }] =
    useUpdateBillingContactEmailMutation({
      errorHandler: setValidationError,
    });

  const initialEmailValue = account.billingContactEmail || '';
  const {
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = useFormik<FormValues>({
    initialValues: {
      email: initialEmailValue,
    },
    async onSubmit(formValues) {
      await updateBillingContactEmail({
        variables: {
          accountId: account.id,
          email: formValues.email,
        },
        update(store) {
          store.writeFragment({
            id: `Account:${account.id}`,
            fragment: BillingContactEmailModalAccountFragment,
            fragmentName: 'BillingContactEmailModalAccountFragment',
            data: {
              __typename: 'Account',
              id: account.id,
              billingContactEmail: formValues.email,
            },
          });
        },
      });

      // Close the modal after the mutation finishes
      onClose();
    },
    validationSchema,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          if (errors.email) {
            setValidationError(errors.email);
            return;
          }
          handleSubmit();
        }}
        noValidate
      >
        <ModalContent>
          <ModalHeader>
            <ModalTitle>Update Billing Contact Email</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <FormControl isRequired isInvalid={!!validationError}>
              <FormLabel>Update email</FormLabel>
              <Input
                autoFocus
                name="email"
                onBlur={handleBlur}
                onChange={(event) => {
                  event.preventDefault();
                  setValidationError(null);
                  handleChange(event);
                }}
                value={values.email}
                type="email"
                isDisabled={isSubmitting}
              />
              <FormErrorMessage>{validationError}</FormErrorMessage>
            </FormControl>
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                variant="secondary"
                isDisabled={isSubmitting}
                onClick={onClose}
                type="button"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                isLoading={isSubmitting}
                loadingText="Update email"
                type="submit"
                isDisabled={!values.email || values.email === initialEmailValue}
              >
                Update email
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Form>
    </Modal>
  );
};
