export enum QUESTION_KEY {
  UserSignupPersona = 'user_signup_persona',
  OrgCreateIntent = 'org_create_intent',
  UserInitialIntent = 'user_initial_intent',
}

export interface SurveyOption {
  label: string;
  value: string;
}

export interface SurveyQuestionType {
  label: string;
  name: QUESTION_KEY;
  options: SurveyOption[];
  version: number;
}

export interface Survey {
  name: string;
  dbName: string;
  version: number;
  questions: QUESTION_KEY[];
}

export const QUESTION_BANK: Record<QUESTION_KEY, SurveyQuestionType> = {
  [QUESTION_KEY.UserSignupPersona]: {
    label: 'How do you primarily interact with APIs?',
    name: QUESTION_KEY.UserSignupPersona,
    version: 1,
    options: [
      {
        label: 'I use APIs to build client applications',
        value: 'frontendDev',
      },
      {
        label: 'I build and implement APIs',
        value: 'backendDev',
      },
      {
        label: 'I work on API design strategy and architecture',
        value: 'platformLead',
      },
      {
        label: 'I focus on deploying and monitoring APIs',
        value: 'devops',
      },
      {
        label: 'Other',
        value: 'other',
      },
    ],
  },
  [QUESTION_KEY.OrgCreateIntent]: {
    label: 'Is this organization for work or something else?',
    name: QUESTION_KEY.OrgCreateIntent,
    version: 1,
    options: [
      {
        label: 'Work',
        value: 'work',
      },
      {
        label: 'Personal',
        value: 'personal',
      },
    ],
  },
  [QUESTION_KEY.UserInitialIntent]: {
    label: 'What is your primary goal today?',
    name: QUESTION_KEY.UserInitialIntent,
    version: 1,
    options: [
      {
        label: 'Manage and scale an existing GraphQL API',
        value: 'manageExisting',
      },
      {
        label: 'Build a new GraphQL API',
        value: 'buildNew',
      },
      {
        label: 'Contribute to an existing GraphQL API',
        value: 'contributeToExisting',
      },
      {
        label: "Use Apollo's advanced features for GraphQL",
        value: 'advancedFeatures',
      },
      {
        label: 'Learn more about GraphQL and Apollo',
        value: 'learnMore',
      },
    ],
  },
};

export const SURVEYS: Record<string, Survey> = {
  userProfileSurveyV1: {
    name: 'User Profile',
    dbName: 'user_profile_survey',
    version: 1,
    questions: [
      QUESTION_KEY.UserSignupPersona,
      QUESTION_KEY.OrgCreateIntent,
      QUESTION_KEY.UserInitialIntent,
    ],
  },
};
