import {
  Tooltip as OrbitTooltip,
  TooltipProps as OrbitTooltipProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@apollo/orbit';
import React, { ReactElement, cloneElement, useEffect, useState } from 'react';

export type TooltipProps = Omit<
  OrbitTooltipProps,
  'children' | 'isDisabled'
> & {
  disabled?: boolean;
  /**
   * Controls whether the tooltip stays open when contents are hovered over
   */
  interactive?: boolean;
  /**
   * Controls wether the tooltip messages changes when contents are clicked
   */
  clickedLabel?: React.ReactNode;
  children?: ReactElement;
};

type InternalTooltipProps = Omit<OrbitTooltipProps, 'children'> & TooltipProps;

/**
 * Render an interactive and themed Tooltip using Chakra.
 *
 * Chakra's tooltips are minimal and we need additional features for our uses
 * in Studio. This component extends the basic tooltip with Interactive and
 * Confirmation behavior.
 */
export const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(
  ({ disabled, interactive, label, clickedLabel, children, ...props }, ref) => {
    const [isClicked, setIsClicked] = useState(false);
    useEffect(() => {
      if (isClicked) {
        const timeout = setTimeout(() => {
          setIsClicked(false);
        }, 2000);
        return () => clearTimeout(timeout);
      }
    }, [isClicked]);

    const Component = interactive ? InteractiveTooltip : OrbitTooltip;

    return (
      <Component
        ref={ref}
        closeOnClick={!clickedLabel}
        isDisabled={disabled}
        label={isClicked ? clickedLabel : label}
        {...props}
      >
        {children ? (
          clickedLabel ? (
            cloneElement(children, {
              // we pass through args for onClick so that the child component
              // can handle onClick however it wants, despite the wrapper
              onClick: (...args: unknown[]) => {
                setIsClicked(true);
                if (children.props.onClick) children.props.onClick(...args);
              },
            })
          ) : (
            children
          )
        ) : (
          <></>
        )}
      </Component>
    );
  },
);

export const InteractiveTooltip = ({
  children,
  className,
  label,
  placement,
  isOpen: inputtedIsOpen,
  isDisabled = false,
}: InternalTooltipProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ isOpen: inputtedIsOpen });
  return (
    <Popover
      placement={placement}
      isOpen={isOpen}
      trigger="hover"
      gutter={0}
      variant="tooltip"
    >
      {children && (
        <PopoverTrigger>
          {cloneElement(children, {
            onMouseEnter: onOpen,
            onMouseLeave: onClose,
            onFocus: onOpen,
            onBlur: onClose,
            tabIndex: 0,
          })}
        </PopoverTrigger>
      )}
      {!isDisabled && (
        <PopoverContent
          className={className}
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          onFocus={onOpen}
          onBlur={onClose}
        >
          {label}
        </PopoverContent>
      )}
    </Popover>
  );
};
