import IconErrorSolid from '@apollo/icons/default/IconErrorSolid.svg';
import IconInfoSolid from '@apollo/icons/default/IconInfoSolid.svg';
import IconSuccessSolid from '@apollo/icons/default/IconSuccessSolid.svg';
import { chakra } from '@chakra-ui/react';
import React from 'react';

import { Spinner } from '../Spinner/Spinner';
import { ToastStatus } from '../Toast/shared';
import { useToastStyles } from '../Toast/Toast.context';

interface ToastIconProps {
  className?: string;
  status: ToastStatus;
}

const StyledIconErrorSolid = chakra(IconErrorSolid);
const StyledIconInfoSolid = chakra(IconInfoSolid);
const StyledIconSuccessSolid = chakra(IconSuccessSolid);

export const ToastIcon = ({ className, status }: ToastIconProps) => {
  const styles = useToastStyles();
  const props = { className, __css: styles.icon };

  switch (status) {
    case 'info':
      return <StyledIconInfoSolid {...props} />;
    case 'error':
      return <StyledIconErrorSolid {...props} />;
    case 'success':
      return <StyledIconSuccessSolid {...props} />;
    case 'loading':
      return <Spinner size="xs" />;
    default:
      throw new Error(`Unknown status '${status}'`);
  }
};
