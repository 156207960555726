import React from 'react';
import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom-v5-compat';

import {
  graphSettingsRouteConfig,
  legacyChecksConfigRouteConfig,
  variantSettingsRouteConfig,
} from 'src/app/graph/routes';
import { useRouteParams } from 'src/hooks/useRouteParams';

export function LegacyChecksRedirect() {
  const location = useLocation();
  const { view } = useRouteParams(legacyChecksConfigRouteConfig);

  if (view === 'graph') {
    return (
      <Navigate
        to={graphSettingsRouteConfig.locationFrom({
          fromRouteConfig: legacyChecksConfigRouteConfig,
          location,
        })}
      />
    );
  } else {
    return (
      <Navigate
        to={variantSettingsRouteConfig.locationFrom({
          fromRouteConfig: legacyChecksConfigRouteConfig,
          location,
        })}
      />
    );
  }
}
