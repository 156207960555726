import { ApolloError, gql, useMutation } from '@apollo/client';

import {
  readFromLocalStorage,
  removeFromLocalStorage,
  writeToLocalStorage,
} from 'src/hooks/useLocalStorage';
import { useLogin } from 'src/hooks/useLogin';
import { checkServerErrorForPasswordFailure } from 'src/hooks/usePasswordChecker';
import { internalTrackCustomEvent } from 'src/hooks/useTrackCustomEvent';
import {
  Analytics,
  LEAD_SOURCE_DETAIL,
  Lead,
  PROGRAM_ID_STUDIO_ENTERPRISE_TRIAL_SIGN_UP,
  getGaClientId,
  getTrackingCookie,
  getTrackingCookies,
  pushMarketingAnalytics,
} from 'src/lib/analytics';
import { appLinkContext } from 'src/lib/apollo/link';
import Config from 'src/lib/config';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { EmailAddressAlreadyInUseRegex } from 'src/lib/graphqlTypes/serverErrors';
import { getInvisibleRecaptchaToken } from 'src/lib/recaptcha';

export function initializeReferrer() {
  const { referrerCookie, sourceCookie, campaignCookie, mediumCookie } =
    getTrackingCookies();

  let referrer = referrerCookie;

  if (referrer) {
    internalTrackCustomEvent({
      event: {
        category: 'Unspecified',
        action: 'loaded_with_referrer',
        label: referrer,
      },
      offline: false,
    });
  } else {
    // no referrer cookie exists - let's use document.referrer
    // strip any query params because the marketo field we're pushing
    // this value into has a character limit of 255
    referrer = document.referrer.split('?')[0] || 'direct';
  }

  const utm = {
    referrer,
    utmSource: sourceCookie,
    utmCampaign: campaignCookie,
    utmMedium: mediumCookie,
  };

  if (Object.values(utm).some(Boolean)) {
    writeToLocalStorage('utm', utm);
  }
}

// For first party users we want to create a new user
export const signUpMutation = gql<
  GraphQLTypes.UI__SignUpMutation,
  GraphQLTypes.UI__SignUpMutationVariables
>`
  mutation UI__SignUpMutation(
    $email: String!
    $password: String!
    $fullName: String!
    $referrer: String
    $utmSource: String
    $utmMedium: String
    $utmCampaign: String
    $userSegment: UserSegment
    $trackingGoogleClientId: String
    $trackingMarketoClientId: String
  ) {
    signUp(
      email: $email
      password: $password
      fullName: $fullName
      referrer: $referrer
      utmSource: $utmSource
      utmMedium: $utmMedium
      utmCampaign: $utmCampaign
      userSegment: $userSegment
      trackingGoogleClientId: $trackingGoogleClientId
      trackingMarketoClientId: $trackingMarketoClientId
    ) {
      id
      email
      fullName
    }
  }
`;

// For github users we want to set the user email/name
const setUserDetailsMutation = gql`
  mutation UI__SetUserDetailsMutation(
    $userId: ID!
    $email: String!
    $fullName: String!
    $referrer: String
    $utmSource: String
    $utmMedium: String
    $utmCampaign: String
    $userSegment: UserSegment
    $trackingGoogleClientId: String
    $trackingMarketoClientId: String
  ) {
    # Hack for serialization. It's important that these mutations run in serial
    # and very likely that there will be a race condition if they don't, where
    # acceptedPrivacyPolicyAt will come back as null for new users.
    # We're basically tricking the gateway into running these serially by
    # adding an alias. @daniman + @azionts worked on this logic together.
    userAccept: user(id: $userId) {
      acceptPrivacyPolicy
    }
    user(id: $userId) {
      update(
        email: $email
        fullName: $fullName
        referrer: $referrer
        utmSource: $utmSource
        utmMedium: $utmMedium
        utmCampaign: $utmCampaign
        userSegment: $userSegment
        trackingGoogleClientId: $trackingGoogleClientId
        trackingMarketoClientId: $trackingMarketoClientId
      ) {
        id
        email
        fullName
        acceptedPrivacyPolicyAt
      }
    }
  }
`;

interface Success {
  userId: string;
  isSuccess: true;
}

interface Failure {
  errors: { signupUser?: string; password?: string };
  isSuccess?: false;
}

export function useCreateUserOrSetDetails(
  {
    lead,
  }: {
    lead: Lead;
  } = {
    lead: {
      programId: PROGRAM_ID_STUDIO_ENTERPRISE_TRIAL_SIGN_UP,
      source: 'Product',
      sourceDetail: LEAD_SOURCE_DETAIL,
    },
  },
) {
  const [setUserDetails, setUserDetailsResponse] = useMutation<
    GraphQLTypes.UI__SetUserDetailsMutation,
    GraphQLTypes.UI__SetUserDetailsMutationVariables
  >(setUserDetailsMutation);
  const [signUp, signUpResponse] = useMutation<
    GraphQLTypes.UI__SignUpMutation,
    GraphQLTypes.UI__SignUpMutationVariables
  >(signUpMutation, {
    context: appLinkContext({
      ignoreSentryReporting: [
        (graphqlError) =>
          EmailAddressAlreadyInUseRegex.test(graphqlError.message),
      ],
    }),
  });
  const [{ loggingIn }, login] = useLogin();

  const createUserOrSetDetails = async ({
    userId,
    email,
    password,
    fullName,
    utm = readFromLocalStorage('utm'),
    userSegment,
  }: {
    userId?: string | null;
    email: string;
    password: string;
    fullName: string;
    userSegment?: GraphQLTypes.UserSegment;
    /**
     * override localStorage utm
     */
    utm?: null | {
      utmSource?: string;
      utmMedium?: string;
      utmCampaign?: string;
      referrer?: string;
    };
  }): Promise<Success | Failure> => {
    // handle ga client id
    const trackingGoogleClientId = await getGaClientId();
    // handle marketo munchkin id
    const trackingMarketoClientId = getTrackingCookie(
      Config.cookies.MarketoMunchkin,
    );

    try {
      // First party user
      if (!userId) {
        const result = await signUp({
          variables: {
            email,
            password,
            fullName,
            userSegment,
            ...utm,
            trackingGoogleClientId,
            trackingMarketoClientId,
          },
        });
        if (result && result.data && result.data.signUp) {
          removeFromLocalStorage('utm');
          Analytics.track(result.data.signUp.id, 'emailSetForUser', { email });
          Analytics.track(result.data.signUp.id, 'User Created', {
            category: 'Onboarding',
            label: 'First Party',
          });
          // push user signup data to Marketo
          pushMarketingAnalytics({
            userId: result.data.signUp.id,
            fullName,
            email,
            utm,
            lead,
          });

          const recaptchaToken = await getInvisibleRecaptchaToken({
            action: 'createUserOrSetDetails',
          });

          const loginResult = await login(email, password, recaptchaToken);
          if (loginResult.logInSuccessful) {
            return { isSuccess: true, userId: result.data.signUp.id };
          }
          if (loginResult.error) {
            return {
              errors: {
                signupUser: `Error logging in: ${loginResult.error.message}`,
              },
            };
          }
        }

        const defaultError = {
          errors: {
            signupUser: 'Error logging in',
          },
        };

        return (
          result.errors?.reduce<Failure>((currentError, error) => {
            if (currentError !== defaultError) return currentError;

            const passwordCheckStatus = checkServerErrorForPasswordFailure(
              error.message,
            );
            return passwordCheckStatus.isSuccess
              ? currentError
              : { errors: { password: passwordCheckStatus.errorMessage } };
          }, defaultError) || defaultError
        );
      }
      const result = await setUserDetails({
        variables: {
          userId,
          email,
          fullName,
          userSegment,
          ...utm,
          trackingGoogleClientId,
          trackingMarketoClientId,
        },
      });
      if (!result.errors) {
        removeFromLocalStorage('utm');
        Analytics.track(userId, 'emailSetForUser', { email });
        Analytics.track(userId, 'User Created', {
          category: 'Onboarding',
          label: 'SSO',
        });
        // push user signup data to Marketo
        pushMarketingAnalytics({
          userId,
          fullName,
          email,
          utm,
          lead,
        });
        return { isSuccess: true, userId };
      }
      return {
        errors: {
          signupUser: result.errors.map((error) => error.message).join('; '),
        },
      };
    } catch (e) {
      const error = e as ApolloError;
      /* eslint-disable-next-line no-console  */
      console.warn('Unable to update user data.', error);
      const errorMessage = error.graphQLErrors
        ? error.graphQLErrors.map((gqlError) => gqlError.message).join('; ')
        : error.message;

      const defaultError = {
        errors: { signupUser: 'Error creating organization' },
      };
      const passwordCheckStatus =
        checkServerErrorForPasswordFailure(errorMessage);

      let userFacingError: Failure | undefined;
      if (!passwordCheckStatus.isSuccess) {
        userFacingError = {
          errors: { password: passwordCheckStatus.errorMessage },
        };
      } else if (EmailAddressAlreadyInUseRegex.test(errorMessage)) {
        userFacingError = {
          errors: {
            signupUser:
              'An organization with this email address already exists. Please use a different email address.',
          },
        };
      }

      return userFacingError || defaultError;
    }
  };

  return {
    createUserOrSetDetails,
    loading:
      setUserDetailsResponse.loading || signUpResponse.loading || loggingIn,
    error: setUserDetailsResponse.error || signUpResponse.error,
  };
}
