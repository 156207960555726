import { Button, IconButton } from '@apollo/orbit';
import { IconMarioFlower } from '@apollo/space-kit/icons/IconMarioFlower';
import React from 'react';

import { Tooltip } from 'src/components/common/tooltip/Tooltip';
import { useLocalStorage } from 'src/hooks/useLocalStorage';

import SoundSMBPipe from './smb_pipe.wav';
import SoundSMBPowerUp from './smb_powerup.wav';

interface Props {
  adminRole: string;
  keepCompact?: boolean;
}

export const SudoToggle = ({ adminRole, keepCompact = false }: Props) => {
  const [sudo, setSudo] = useLocalStorage('sudo');

  const sharedProps = {
    'aria-label': 'toggle sudo mode',
    onClick: () => {
      setSudo(!sudo);
      const sound = new Audio(sudo ? SoundSMBPipe : SoundSMBPowerUp);
      sound.volume = 0.02;
      sound.play();
      sound.onended = () => {
        /**
         * We opt for a window reload here instead of a cache.resetStore()
         * because there are cases, especially in the VariantLayout, where the
         * cache has a waterfall of stored queries, none of which are
         * refetchable when you toggle sudo off, which throws errors. What
         * we actually want to do in this case is re-render our React tree
         * and re-trigger the waterfall of queries, which will take
         * different forks depending on your permissions.
         *
         * This isn't really issue with the cache, and the cache does what
         * it's supposed to. This is just sort of how the system works and
         * a window reload is the best way to trigger the re-rendering of
         * the React tree at this time.
         */
        window.location.reload();
      };
    },
  };
  return (
    <Tooltip label={`Toggle sudo mode: ${adminRole}`}>
      {sudo && !keepCompact ? (
        <Button
          {...sharedProps}
          variant="primary"
          leftIcon={<IconMarioFlower />}
          size="sm"
        >
          Sudo: ON
        </Button>
      ) : (
        <IconButton
          {...sharedProps}
          variant={keepCompact ? 'primary' : 'hidden'}
          size="sm"
        >
          <IconMarioFlower />
        </IconButton>
      )}
    </Tooltip>
  );
};
