import * as Yup from 'yup';

import { SurveyQuestionInput } from 'src/lib/graphqlTypes/types';

import { QUESTION_BANK, QUESTION_KEY, SURVEYS } from './constants';

type FilterFunction = (questions: QUESTION_KEY[]) => QUESTION_KEY[];

/**
 * Filters a list of questions using specified filter functions.
 *
 * @param {QUESTION_KEY[]} questions - List of questions to filter.
 * @param {...FilterFunction[]} filters - Functions to apply as filters.
 * @returns {QUESTION_KEY[]} - Filtered list of questions.
 */
export const filterQuestions = (
  questions: QUESTION_KEY[],
  ...filters: FilterFunction[]
): QUESTION_KEY[] => {
  return filters.reduce(
    (filteredQuestions, filter) => filter(filteredQuestions),
    questions,
  );
};

// When a user is invited to an organization, we want to narrow the survey questions
// to only those relevant to a specific user setup journey.
// This ensures that questions align with the context of the user's onboarding experience.
export const joinTokenFilter = (hasJoinToken?: string): FilterFunction => {
  return (questions: QUESTION_KEY[]) =>
    hasJoinToken
      ? questions.filter(
          (question) => question === QUESTION_KEY.UserSignupPersona,
        )
      : questions;
};

/**
 * Initializes form values for each question with default values,
 * including "_details" fields.
 */
export const getInitialFormikValues = (
  questions: QUESTION_KEY[],
): Record<string, string | undefined> => {
  const initialValues: Record<string, string | undefined> = {};

  questions.forEach((questionKey) => {
    const question = QUESTION_BANK[questionKey];
    if (question) {
      // Create a flat key for answerKey
      initialValues[question.name] = undefined;
      // Always add a flat key for answerDetails
      initialValues[`${question.name}_details`] = undefined;
    }
  });

  return initialValues;
};

/**
 * Generates a Yup validation schema for each question in the provided list,
 * automatically adding validation for "_details" fields.
 */
export const getValidationSchema = (questions: QUESTION_KEY[]) => {
  const shape = questions.reduce(
    (acc, question) => {
      const questionData = QUESTION_BANK[question];
      if (!questionData) return acc; // Skip if question data not found

      const questionName = questionData.name;

      // Base validation for the main question field
      acc[questionName] = Yup.string().nullable().strict(true);

      // Always add validation for "_details" field
      acc[`${questionName}_details`] = Yup.string().nullable().strict(true);

      return acc;
    },
    {} as Record<string, Yup.StringSchema<string | undefined | null>>,
  );

  return Yup.object().shape(shape);
};

/**
 * Maps form values to the survey state format required by the updateSurvey mutation.
 *
 * @param {Record<QUESTION_KEY, SurveyInitialValues[QUESTION_KEY]>} formValues - The form values from Formik.
 * @returns {SurveyQuestionInput[]} - The formatted survey state array needed for userProfileSurvey, with:
 *   - `answerKey`: The selected answer.
 *   - `answerValue`: A legacy value from the database, always returned as an empty string.
 *   - `answerDetails`: Additional details provided by the user from an input field. Formatted as <[answerKey]>_details.
 *   - `questionKey`: A unique identifier for the question.
 *   - `wasSkipped`: A flag indicating if the question was skipped.
 */
export const mapFormValuesToSurveyState = (
  formValues: Record<string, string | undefined | null>,
): SurveyQuestionInput[] => {
  return Object.entries(formValues)
    .filter(([key]) => !key.endsWith('_details')) // Exclude any additional details from input fields, as they'll be added as `answerDetails`
    .flatMap(([questionKey, answerKey]) => {
      const question = QUESTION_BANK[questionKey as QUESTION_KEY];

      return {
        answerKey: answerKey ?? '',
        answerValue: '',
        answerDetails: formValues[`${questionKey}_details`] || '',
        questionKey: question.name,
        questionKeyVersion: question.version,
        wasSkipped: !answerKey,
      };
    });
};

/**
 * Generates the surveyState array when creating a new organization.
 */
export const generateSurveyState = (
  values: Record<string, string | undefined | null>,
): SurveyQuestionInput[] => {
  const questions = SURVEYS.userProfileSurveyV1?.questions || [];
  return questions.map((questionKey) => {
    const answerKey = values[questionKey] || '';
    return {
      answerKey,
      answerValue: '', // Legacy value, set to empty as per requirements
      questionKey,
      wasSkipped: !answerKey,
    };
  });
};
