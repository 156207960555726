import LogoSymbol from '@apollo/icons/logos/LogoSymbol.svg';
import LogoType from '@apollo/icons/logos/LogoType.svg';
import { PropsOf, chakra, useStyleConfig } from '@chakra-ui/react';
import React from 'react';

import { customColors } from '../../theme/customColors';
import { OmitChakraProps } from '../../utils/typeUtils';

export interface ApolloLogoProps extends OmitChakraProps<PropsOf<'svg'>> {
  logoType: 'symbol' | 'wordmark';
  colorScheme?: keyof typeof customColors.brand;
}

const StyledLogoSymbol = chakra(LogoSymbol);
const StyledLogoType = chakra(LogoType);

export const ApolloLogo = ({
  logoType,
  colorScheme,
  ...props
}: ApolloLogoProps) => {
  const styles = useStyleConfig('ApolloLogo', { colorScheme });

  return logoType === 'symbol' ? (
    <StyledLogoSymbol {...props} __css={styles} />
  ) : (
    <StyledLogoType {...props} __css={styles} />
  );
};
