import slugify from 'slugify';

export const OrgIDValidator = {
  minLength: 5,
  maxLength: 50,
  // returns null on success, reason string on failure
  validate(slug: string): string | null {
    if (!slug) {
      return 'is required';
    }
    if (slug.length < OrgIDValidator.minLength) {
      return `must be at least ${OrgIDValidator.minLength} characters`;
    }
    if (slug.length > OrgIDValidator.maxLength) {
      return `must be at most ${OrgIDValidator.maxLength} characters`;
    }
    if (!/^[a-zA-Z]/.test(slug)) {
      return 'must start with a letter';
    }
    if (/[^a-zA-Z0-9-]/.test(slug)) {
      return 'may only contain letters, numbers, and dashes';
    }
    return null;
  },
  makeFromName(name: string) {
    // 1. start with provided name, or provide a random number as an ID
    let slug = slugify(name.toLowerCase());
    // 2. ensure the slug starts with a letter
    if (slug.length && !/^[a-zA-Z]/.test(slug)) {
      slug = `org-${slug}`;
    }
    // 3. remove any special characters
    if (slug.match(/[^a-zA-Z0-9-]/g)) {
      slug = slug.replace(/[^a-zA-Z0-9-]/g, '');
    }
    // 4. ensure the slug is not too long
    if (slug.length > OrgIDValidator.maxLength) {
      slug = slug.substring(0, OrgIDValidator.maxLength);
    }
    return slug;
  },
} as const;
