import { ApolloIcon } from '@apollo/space-kit/icons/ApolloIcon';
import React from 'react';

import { OnboardingLayoutBackground } from 'src/app/onboarding/components/onboardingLayoutBackground/OnboardingLayoutBackground';

export const EmbedAuthenticatorLayoutBackground = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <OnboardingLayoutBackground>
    <div className="relative z-50 mx-auto flex size-full flex-col items-center justify-start">
      <div className="mb-12 flex flex-none items-center justify-center pt-14">
        <ApolloIcon className="h-12 w-36 text-indigo-dark" />
      </div>
      <div className="w-max pb-8">{children}</div>
    </div>
  </OnboardingLayoutBackground>
);
