import { gql } from '@apollo/client';

import {
  GetInternalOrgIdQuery,
  GetInternalOrgIdQueryVariables,
} from 'src/lib/graphqlTypes/types';

export const getInternalOrgIdQuery = gql<
  GetInternalOrgIdQuery,
  GetInternalOrgIdQueryVariables
>`
  query GetInternalOrgIdQuery($accountId: ID!) {
    organization(id: $accountId) {
      id
      internalID
    }
  }
`;
