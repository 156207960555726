import * as fullstory from '@fullstory/browser';
import { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { sandboxGraphRouteConfig } from 'src/app/graph/routes';

export const useFullstoryInitializer = () => {
  const [hasReceivedConsent, setHasReceivedConsent] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const isSandboxRoute = !!useRouteMatch(sandboxGraphRouteConfig.definition);
  useEffect(() => {
    // don't initialize fullstory on sandbox route, too many sessions
    if (isSandboxRoute) return;
    if (!hasReceivedConsent) return;
    if (isInitialized) return;
    fullstory.init({ orgId: '15NC4' });
    setIsInitialized(true);
  }, [hasReceivedConsent, isInitialized, isSandboxRoute]);

  return useMemo(
    () => ({
      consentToFullStory: () => setHasReceivedConsent(true),
    }),
    [],
  );
};
