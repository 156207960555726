import { SortOrder } from '../../types';
import { createContext } from '../../utils/contextUtils';

export interface TableContext {
  sortBy?: string | number;
  sortOrder: SortOrder;
  setSort: (
    sortBy: string | number,
    sortOrder: SortOrder,
    event: React.MouseEvent,
  ) => void;
}

export const [TableProvider, useTableContext] = createContext<TableContext>({
  name: 'TableContext',
  hookName: 'useTableContext',
  providerName: '<Table />',
});
