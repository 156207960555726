import Config from './config';

/**
 * Take a relative or absolute URL and return an absolute URL
 */
export function absoluteURL(href: string) {
  const url = new URL(href, Config.absoluteUrl);

  return `${Config.absoluteUrl.replace(/\/+$/, '')}${url.pathname}${
    url.search
  }`;
}
