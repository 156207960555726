import { AlertBanner } from '@apollo/orbit';
import React from 'react';

import { HigherLimitContactLink } from 'src/components/higherLimitRequest/HigherLimitContactLink';
import { useCurrentPlan } from 'src/hooks/currentPlanV2Migration';
import { useCheckPlanTypes } from 'src/hooks/useCheckPlanTypes';
import { useCurrentAccountId } from 'src/hooks/useCurrentAccountId';
import { useMonthlyOperationDetails } from 'src/hooks/useMonthlyOperationDetails';

export const UsageLimitBanner = ({
  metricName,
  used,
  threshold,
}: {
  metricName: string;
  used?: number;
  threshold?: number | null;
}) => {
  // Lack of usage data or threshold implies that the user is not on a usage
  // based plan
  if (!(used && threshold) || threshold === 0) return null;

  const percentageUsed = used / threshold;
  if (percentageUsed < 0.8) return null;
  const type: 'warning' | 'error' = percentageUsed < 1 ? 'warning' : 'error';

  return (
    <AlertBanner status={type}>
      <div className="flex flex-1 flex-col">
        <p>
          Your account has used <b>{used.toLocaleString()}</b> {metricName}{' '}
          requests this month and{' '}
          {type === 'warning' ? 'is approaching' : 'has exceeded'} the limit of{' '}
          <b>{threshold.toLocaleString()}</b> {metricName} requests{' '}
          <b>
            ({percentageUsed.toLocaleString(undefined, { style: 'percent' })})
          </b>
          . Please <HigherLimitContactLink>contact us</HigherLimitContactLink>{' '}
          to get access to a plan with higher {metricName} operations limit.
        </p>
      </div>
    </AlertBanner>
  );
};

export const UsageLimitAlerts = () => {
  const currentAccountId = useCurrentAccountId()[0] || '';
  const currentPlanResults = useCurrentPlan({
    accountId: currentAccountId,
    skip: !currentAccountId,
  });
  const tier = currentPlanResults?.currentPlan?.tier;
  const kind = currentPlanResults?.currentPlan?.kind;
  const { isUsageBasedPlan, isFreePlan } = useCheckPlanTypes({ tier, kind });
  const isFreeUsagePlan = isUsageBasedPlan && isFreePlan;
  const {
    loading,
    hasCurrentSubscriptionStarted,
    total: operationUsage,
  } = useMonthlyOperationDetails({
    accountId: currentAccountId,
    skip: !currentAccountId || !isUsageBasedPlan,
  });

  if (
    !isUsageBasedPlan ||
    loading ||
    !operationUsage ||
    currentPlanResults.loading ||
    !hasCurrentSubscriptionStarted
  ) {
    return null;
  }

  const { serverless, selfHosted } = operationUsage;
  const selfHostedUsage = selfHosted?.stats?.[0]?.value;
  const serverlessUsage = serverless?.stats?.[0]?.value;

  return (
    <div className="flex flex-col gap-3">
      <UsageLimitBanner
        metricName="self hosted"
        used={selfHostedUsage}
        threshold={selfHosted?.planThreshold}
      />
      {isFreeUsagePlan ? (
        <UsageLimitBanner
          metricName="serverless"
          used={serverlessUsage}
          threshold={serverless?.planThreshold}
        />
      ) : null}
    </div>
  );
};
