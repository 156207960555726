import { Badge } from '@apollo/orbit';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import {
  Link as LinkV5,
  Route as RouteV5,
  Switch as SwitchV5,
} from 'react-router-dom';
import { Navigate, Route, useLocation } from 'react-router-dom-v5-compat';

import { OrgSwitcher } from 'src/components/appNav/orgSwitcher/OrgSwitcher';
import { LoggedInRoute } from 'src/components/common/authenticatedRoutes/AuthenticatedRoutes';
import { ErrorBoundary } from 'src/components/common/errorBoundary/ErrorBoundary';
import { Loading } from 'src/components/common/loading/Loading';
import { NotFound } from 'src/components/common/notFound/NotFound';
import { Toasts } from 'src/components/toast/Toast';
import { useInitializeClickAnalytics } from 'src/hooks/useInitializeClickAnalytics';
import {
  SentryRoutes as Routes,
  useTelemetryInitializer,
} from 'src/hooks/useTelemetryInitializer';
import { useSyncAmplitudeForEmbedded } from 'src/lib/analytics/amplitude/hooks/useSyncAmplitudeForEmbedded';
import { isMinLengthArray } from 'src/lib/isMinLengthArray';
import { listenForKonami } from 'src/templates/index/konami';

import { AccountInvitationHandler } from '../account/accountInvitationHandler/AccountInvitationHandler';
import { graphOnboardingRoutes } from '../account/graphOnboarding/routes';
import { EmbedLogoutPage } from '../account/logoutPage/EmbedLogoutPage';
import { LogoutPage } from '../account/logoutPage/LogoutPage';
import { invite, org, userSettingsRouteConfig } from '../account/routes';
import { AppLayout } from '../appLayout/AppLayout';
import { GlobalHeader } from '../appLayout/components/globalHeader/GlobalHeader';
import { BuildInfoPage } from '../buildInfo/buildInfoPage/BuildInfoPage';
import { EmailPreferencesPage } from '../emailPreferences/emailPreferencesPage/EmailPreferencesPage';
import { EmbedAuthenticator } from '../embeddableExplorer/EmbedAuthenticator';
import { EmbeddableExplorer } from '../embeddableExplorer/EmbeddableExplorer';
import { isEmbeddableExplorerRoute } from '../embeddableExplorer/isEmbeddableExplorerRoute';
import { isEmbeddableSandboxRoute } from '../embeddableSandbox/isEmbeddableSandboxRoute';
import { PreflightScriptOAuthTokenFetcher } from '../graph/explorerPage/explorerSettings/PreflightScriptOAuthConfirmationModal';
import {
  atlasExplorerRouteConfig,
  graphProposalListRouteConfig,
  graphRouteConfig,
  persistedQueriesRouteConfig,
  proposalRouteConfig,
  sandboxGraphRouteConfig,
  variantRouteConfig,
} from '../graph/routes';
import {
  Onboarding,
  ownedRoutes as onboardingOwnedRoutes,
} from '../onboarding/Onboarding';
import * as routes from '../onboarding/routes';
import * as sharedRoutes from '../shared/routes';

import { RedirectFromGraphPathToVariantPath } from './loggedInRedirects/RedirectFromGraphPathToVariantPath';
import { RootRedirect } from './loggedInRedirects/RootRedirect';
import { LoginWithProvider } from './LoginWithProvider';
import { redirects } from './redirects/redirects';
import {
  BuildRoute,
  NotFoundRoute,
  RootRoute,
  userEmailPreferencesRoute,
} from './routes';
import { ServiceWorkerRefreshPrompts } from './ServiceWorkerRefreshPrompts';

const EmbeddableSandbox = React.lazy(async () => ({
  default: (
    await import(
      /* webpackChunkName: "EmbeddableSandbox" */ '../embeddableSandbox/EmbeddableSandbox'
    )
  ).EmbeddableSandbox,
}));
const UserProfileSurvey = React.lazy(async () => ({
  default: (
    await import(
      /* webpackChunkName: "UserProfileSurvey" */ '../onboarding/views/userProfileSurvey/UserProfileSurvey'
    )
  ).UserProfileSurvey,
}));
const AccountLayout = React.lazy(async () => ({
  default: (
    await import(
      /* webpackPrefetch: true, webpackChunkName: "AccountLayout" */ '../account/accountLayout/AccountLayout'
    )
  ).AccountLayout,
}));
const SettingsPageLayout = React.lazy(async () => ({
  default: (
    await import(
      /* webpackPrefetch: true, webpackChunkName: "SettingsPageLayout" */ '../user/settingsPage/SettingsPageLayout'
    )
  ).SettingsPageLayout,
}));
const AtlasLayout = React.lazy(async () => ({
  default: (
    await import(
      /* webpackPrefetch: true, webpackChunkName: "AtlasLayout" */ '../atlas/AtlasLayout'
    )
  ).AtlasLayout,
}));
const VariantLayout = React.lazy(async () => ({
  default: (
    await import(
      /* webpackPrefetch: true, webpackChunkName: "VariantLayout" */ '../graph/variantLayout/VariantLayout'
    )
  ).VariantLayout,
}));
const SandboxLayout = React.lazy(async () => ({
  default: (
    await import(
      /* webpackPrefetch: true, webpackChunkName: "SandboxLayout" */ '../graph/variantLayout/SandboxLayout'
    )
  ).SandboxLayout,
}));
const ProposalLayout = React.lazy(async () => ({
  default: (
    await import(
      /* webpackPrefetch: true, webpackChunkName: "ProposalLayout" */ '../graph/proposalLayout/ProposalLayout'
    )
  ).ProposalLayout,
}));
const GraphLayout = React.lazy(async () => ({
  default: (
    await import(
      /* webpackPrefetch: true, webpackChunkName: "GraphLayout" */ '../graph/graphLayout/GraphLayout'
    )
  ).GraphLayout,
}));

/*
 * Studio – the app!
 *
 * Authentication is enforced on LoggedInRoute's, Route's are public,
 * but will need to be placed above
 * <LoggedInRoute path={RootRoute.definition} component={AppLayout} />
 * since it matches everything
 *
 * If you visit a protect route while logged out, you'll be forwarded to /login.
 * If you visited /login while logged in, you'll be sent to the root url /.
 */
export const RoutedApp = Sentry.withProfiler(() => {
  useEffect(() => {
    document.addEventListener('keydown', listenForKonami);
    return () => document.removeEventListener('keydown', listenForKonami);
  }, []);

  useInitializeClickAnalytics();

  const isEmbeddableExplorer = isEmbeddableExplorerRoute();
  const isEmbeddableSandbox = isEmbeddableSandboxRoute();

  useSyncAmplitudeForEmbedded();
  useTelemetryInitializer(isEmbeddableExplorer || isEmbeddableSandbox);

  const location = useLocation();

  const graphRefMatch = location.pathname.match(
    /\/graph\/(([^@\/]+)@([^@\/]+))/,
  );
  if (isMinLengthArray(4, graphRefMatch)) {
    const [, graphRef, graphId, variant] = graphRefMatch;
    return (
      <Navigate
        replace={true}
        to={{
          ...location,
          pathname: location.pathname.replace(
            graphRef,
            `${graphId}/variant/${variant}`,
          ),
        }}
      />
    );
  }

  return (
    <ErrorBoundary>
      <ServiceWorkerRefreshPrompts />
      <React.Suspense fallback={<Loading />}>
        {isEmbeddableExplorer ? (
          <Routes>
            <Route
              path={routes.embedLogoutRouteConfig.splatDefinition}
              Component={EmbedLogoutPage}
            />
            <Route
              path={sharedRoutes.preflightOAuth2RedirectRoute.splatDefinition}
              Component={PreflightScriptOAuthTokenFetcher}
            />
            <Route
              path={RootRoute.splatDefinition}
              element={<EmbeddableExplorer />}
            />
          </Routes>
        ) : isEmbeddableSandbox ? (
          <Routes>
            <Route
              path={routes.embedLogoutRouteConfig.splatDefinition}
              Component={EmbedLogoutPage}
            />
            <Route
              path={sharedRoutes.preflightOAuth2RedirectRoute.splatDefinition}
              Component={PreflightScriptOAuthTokenFetcher}
            />
            <Route
              path={sandboxGraphRouteConfig.splatDefinition}
              Component={EmbeddableSandbox}
            />
          </Routes>
        ) : (
          <Routes>
            {redirects(location)}
            <Route
              path={sharedRoutes.preflightOAuth2RedirectRoute.splatDefinition}
              Component={PreflightScriptOAuthTokenFetcher}
            />
            <Route
              path={routes.logoutRouteConfig.splatDefinition}
              Component={LogoutPage}
            />
            <Route path={NotFoundRoute.splatDefinition} Component={NotFound} />
            <Route
              path={routes.loginWithProvider.definition}
              Component={LoginWithProvider}
            />
            {onboardingOwnedRoutes.map((definition) => (
              <Route
                key={definition}
                path={definition}
                Component={Onboarding}
              />
            ))}
            <Route
              path={routes.userProfileSurvey.splatDefinition}
              element={<LoggedInRoute Component={UserProfileSurvey} />}
            />
            <Route
              path={BuildRoute.splatDefinition}
              Component={BuildInfoPage}
            />
            <Route
              path={userEmailPreferencesRoute.splatDefinition}
              Component={EmailPreferencesPage}
            />
            <Route
              path={routes.embedAuthenticationRouteConfig.splatDefinition}
              element={
                <>
                  <EmbedAuthenticator />
                  <Toasts />
                </>
              }
            />
            <Route
              element={
                [
                  // List of app routes that do not require the user to be logged in
                  sandboxGraphRouteConfig.definition,
                  atlasExplorerRouteConfig.definition,
                  '/public',
                ].some((d) => location.pathname.startsWith(d)) ? (
                  <AppLayout />
                ) : (
                  <LoggedInRoute Component={AppLayout} />
                )
              }
            >
              <Route path={RootRoute.definition} Component={RootRedirect} />
              <Route
                path={invite.splatDefinition}
                Component={AccountInvitationHandler}
              />
              <Route
                path={atlasExplorerRouteConfig.splatDefinition}
                element={
                  <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden text-primary">
                    <GlobalHeader leftNavContext={null}>
                      <LinkV5 to={atlasExplorerRouteConfig.definition}>
                        <Badge variant="neutralOutlined">Directory</Badge>
                      </LinkV5>
                    </GlobalHeader>

                    <div className="flex h-full flex-1 overflow-y-auto">
                      <Toasts />
                      <ErrorBoundary>
                        <SwitchV5>
                          <RouteV5 component={AtlasLayout} />
                        </SwitchV5>
                      </ErrorBoundary>
                    </div>
                  </div>
                }
              />
              {variantRouteConfig.patchV5DefinitionsForV6(false).map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <ErrorBoundary>
                      <React.Suspense fallback={<Loading />}>
                        <VariantLayout />
                      </React.Suspense>
                    </ErrorBoundary>
                  }
                />
              ))}

              <Route
                path={sandboxGraphRouteConfig.splatDefinition}
                element={
                  <ErrorBoundary>
                    <React.Suspense fallback={<Loading />}>
                      <SandboxLayout />
                    </React.Suspense>
                  </ErrorBoundary>
                }
              />
              {proposalRouteConfig
                .patchV5DefinitionsForV6(false)
                .map((path) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <ErrorBoundary>
                        <React.Suspense fallback={<Loading />}>
                          <ProposalLayout />
                        </React.Suspense>
                      </ErrorBoundary>
                    }
                  />
                ))}
              {[
                ...persistedQueriesRouteConfig.patchV5DefinitionsForV6(false),
                ...graphProposalListRouteConfig.patchV5DefinitionsForV6(false),
              ].map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <ErrorBoundary>
                      <React.Suspense fallback={<Loading />}>
                        {/* Placeholder for new Graph IA layout */}
                        <GraphLayout />
                      </React.Suspense>
                    </ErrorBoundary>
                  }
                />
              ))}
              {graphRouteConfig.patchV5DefinitionsForV6(false).map((path) => (
                <Route
                  key={path}
                  path={path}
                  // this is redirecting to the variantRouteConfig right now, but it's basically
                  // a placeholder for our new "Graph Level IA" space
                  Component={RedirectFromGraphPathToVariantPath}
                />
              ))}
              {userSettingsRouteConfig
                .patchV5DefinitionsForV6(false)
                .map((path) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden text-primary">
                        <GlobalHeader leftNavContext="collapsed">
                          <OrgSwitcher collapseOrgName={false} />
                        </GlobalHeader>
                        <div className="flex h-full flex-1 overflow-y-auto">
                          <Toasts />
                          <ErrorBoundary>
                            <React.Suspense fallback={<Loading />}>
                              <SwitchV5>
                                <RouteV5 component={SettingsPageLayout} />
                              </SwitchV5>
                            </React.Suspense>
                          </ErrorBoundary>
                        </div>
                      </div>
                    }
                  />
                ))}
              <Route
                path={org.splatDefinition}
                element={
                  <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden text-primary">
                    <SwitchV5>
                      <RouteV5
                        path={[
                          invite.definition,
                          ...graphOnboardingRoutes, // TODO: Re-enable this once header is fixed
                        ]}
                      />

                      {/* Routes listed above are those for which the nav should be hidden */}
                      <RouteV5
                        render={() => (
                          <GlobalHeader leftNavContext="collapsed">
                            <OrgSwitcher collapseOrgName={false} />
                          </GlobalHeader>
                        )}
                      />
                    </SwitchV5>
                    <div className="flex h-full flex-1 overflow-y-auto">
                      <Toasts />
                      <ErrorBoundary>
                        <React.Suspense fallback={<Loading />}>
                          <AccountLayout />
                        </React.Suspense>
                      </ErrorBoundary>
                    </div>
                  </div>
                }
              />
              <Route
                path="*"
                element={
                  <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden text-primary">
                    <GlobalHeader leftNavContext="collapsed" />
                    <div className="flex h-full flex-1 overflow-y-auto">
                      <NotFound />
                    </div>
                  </div>
                }
              />
            </Route>
          </Routes>
        )}
      </React.Suspense>
    </ErrorBoundary>
  );
});
