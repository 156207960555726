import { GraphQLTypes } from 'src/lib/graphqlTypes';

interface Preferences {
  subscriptions: GraphQLTypes.EmailCategory[];
  unsubscribedFromAll: boolean;
}

/**
 * Get the new list of unsubscriptions after toggling a category
 * @param unsubscriptions current list of unsubscribed categories
 * @param selectedCategory category to toggle the unsubscription status of
 */
export const toggleSubscription = (
  preferences: Preferences,
  selectedCategory: GraphQLTypes.EmailCategory,
): GraphQLTypes.EmailCategory[] => {
  const { subscriptions, unsubscribedFromAll } = preferences;
  if (unsubscribedFromAll) {
    // Currently unsubscribed to all, remove that and add
    // just this category
    return [selectedCategory];
  }
  if (subscriptions.includes(selectedCategory)) {
    // Remove current category from subscriptions
    return subscriptions.filter(
      (subscription) => subscription !== selectedCategory,
    );
  }
  // Add current category to unsubscriptions
  return [...subscriptions, selectedCategory];
};
