import React, { createContext, useContext } from 'react';

const IsAppBackgroundContext = createContext<ReturnType<() => boolean> | null>(
  null,
);

export const IsAppBackgroundContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [appInBackground, setAppInBackground] = React.useState(false);

  React.useEffect(() => {
    const pageHide = () => setAppInBackground(true);
    const visibilityChange = () =>
      setAppInBackground(document.visibilityState !== 'visible');
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilityState
    // Safari doesn’t fire the visibilitychange event as expected when visibilityState
    // transitions to hidden; so for that case, you need to also include code
    // to listen for the pagehide event.
    document.addEventListener('pagehide', pageHide);
    document.addEventListener('visibilitychange', visibilityChange);
    return () => {
      document.removeEventListener('pagehide', pageHide);
      document.removeEventListener('visibilitychange', visibilityChange);
    };
  }, []);

  return (
    <IsAppBackgroundContext.Provider value={appInBackground}>
      {children}
    </IsAppBackgroundContext.Provider>
  );
};

export const useIsAppInBackgroundContext = () => {
  const appIsInBackground = useContext(IsAppBackgroundContext);
  // The context provider is used in `RoutedApp`, so this default is safe
  return appIsInBackground ?? false;
};
