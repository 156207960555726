import React from 'react';

import { useRouteParams } from 'src/hooks/useRouteParams';

import * as routes from '../../routes/routes';

import ImageDevMock from './dev-mock.png';
import ImageDotPattern from './dot-pattern.png';

export const OnboardingLayoutBackground = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { endpoint } = useRouteParams(routes.onboarding);
  return endpoint ? (
    <div className="flex justify-between bg-white">
      <div className="relative h-full overflow-y-auto lg:w-1/2">{children}</div>
      <div className="relative mx-auto max-w-3xl pt-32 lg:w-1/2">
        <div className="relative z-50">
          <img className="w-11/12" alt="studio explorer" src={ImageDevMock} />
        </div>
      </div>
      <div className="absolute right-0 top-0 z-0 h-24 lg:h-64">
        <img alt="dots" className="h-auto w-full" src={ImageDotPattern} />
      </div>
    </div>
  ) : (
    <div className="relative size-full overflow-y-hidden bg-white">
      <div className="absolute right-0 top-0 h-24 lg:h-64">
        <img alt="dots" className="h-auto w-full" src={ImageDotPattern} />
      </div>
      <div className="relative size-full overflow-y-auto">{children}</div>
    </div>
  );
};
