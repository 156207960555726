import { cssVar, defineStyle, defineStyleConfig } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const $size = cssVar('spinner-size');

const baseStyle = defineStyle({
  borderWidth: '2px',
  width: $size.reference,
  height: $size.reference,
});

const lg = defineStyle({
  [$size.variable]: '40px',
});

const md = defineStyle({
  [$size.variable]: '32px',
});

const sm = defineStyle({
  [$size.variable]: '24px',
});

const xs = defineStyle({
  [$size.variable]: '16px',
});

const tiny = defineStyle({
  [$size.variable]: '12px',
});

const variants = defineStyle({
  default: {
    borderColor: customColors.border.info,
    borderBottomColor: customColors.border.primary,
    borderLeftColor: customColors.border.primary,
  },
  neutral: {
    borderColor: 'currentColor',
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent',
  },
});

export const SpinnerStyle = defineStyleConfig({
  baseStyle,
  variants,
  sizes: {
    lg,
    md,
    sm,
    xs,
    tiny,
  },
  defaultProps: {
    size: 'md',
    variant: 'default',
  },
});
