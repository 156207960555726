import { avatarAnatomy } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const { border, text, bg, icon } = customColors;

const $avatarFontSize = cssVar('avatar-font-size');
const $avatarSize = cssVar('avatar-size');
const $avatarBorderColor = cssVar('avatar-border-color');
const $avatarBackground = cssVar('avatar-bg');
const $avatarBorderRadius = cssVar('avatar-border-radius');

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers([...avatarAnatomy.keys, 'icon' as const]);

const container = defineStyle((props) => ({
  [$avatarBorderColor.variable]: border.primary,
  borderColor: $avatarBorderColor.reference,
  [$avatarBackground.variable]: (props.bg, 'transparent'),
  bg: $avatarBackground.reference,
  [$avatarBorderRadius.variable]: 'radii.full',
  borderRadius: $avatarBorderRadius.reference,
  borderWidth: '1px',
  color: props.color,
  fontFamily: 'body',
  fontWeight: 700,
  '& .chakra-avatar__img': {
    borderRadius: $avatarBorderRadius.reference,
  },
  '& .chakra-avatar__initials': {
    lineHeight: 'unset',
  },
}));

const excessLabel = defineStyle({
  color: text.neutral,
  textAlign: 'center',
  fontFamily: 'body',
  fontStyle: 'normal',
  fontWeight: 700,
  textTransformation: 'uppercase',
  backgroundColor: bg.neutral,
});

const badge = defineStyle({
  [$avatarBorderColor.variable]: border.primary,
  borderColor: $avatarBorderColor.reference,
  bg: icon.success,
  border: '0.1em solid',
});

const baseStyle = definePartsStyle((props) => ({
  container: container(props),
  excessLabel,
  badge,
  icon: {
    h: '75%',
    w: '75%',
  },
}));

const org = definePartsStyle({
  container: {
    [$avatarBorderRadius.variable]: 'radii.base',
    '& .chakra-avatar__img': {
      borderRadius: `calc(${$avatarBorderRadius.reference} - 1px)`,
    },
  },
});

export const AvatarStyle = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    md: definePartsStyle({
      container: {
        gap: 3,
        [$avatarSize.variable]: '3rem',
        [$avatarFontSize.variable]: '1.125rem',
      },
      badge: {
        h: '1em',
        w: '1em',
      },
    }),
    sm: definePartsStyle({
      container: {
        gap: 2,
        [$avatarSize.variable]: '2rem',
        [$avatarFontSize.variable]: '0.75rem',
      },
      badge: {
        h: '0.75em',
        w: '0.75em',
      },
    }),
    xs: definePartsStyle({
      container: {
        gap: '0.3125rem',
        [$avatarSize.variable]: '1.25rem',
        [$avatarFontSize.variable]: '0.5rem',
      },
      badge: {
        h: '0.75em',
        w: '0.75em',
        border: '0.05em solid',
      },
    }),
    '2xs': definePartsStyle({
      container: {
        gap: 1,
        [$avatarSize.variable]: '1rem',
        [$avatarFontSize.variable]: '0.5rem',
      },
      badge: {
        h: '0.75em',
        w: '0.75em',
        border: '0.05em solid',
      },
    }),
  },
  variants: {
    org,
    user: baseStyle,
    graph: baseStyle,
  },
  defaultProps: {
    size: 'md',
    variant: 'user',
  },
});
