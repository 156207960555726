import { Switch } from '@apollo/space-kit/Switch';
import { uniqueId } from 'lodash';
import React, { useMemo } from 'react';

interface EmailPreferenceRowProps {
  name: string;
  description: string;
  isToggled: boolean;
  onToggle: () => void;
  disabled?: boolean;
}

export const EmailPreferenceRow = ({
  name,
  description,
  isToggled,
  onToggle,
  disabled,
}: EmailPreferenceRowProps) => {
  const idRef = useMemo(() => uniqueId('email-preference-row-toggle-'), []);
  const describedById = `${idRef}-description`;

  return (
    <Switch
      showTextualState={false}
      size="large"
      isSelected={isToggled}
      onChange={onToggle}
      isDisabled={disabled}
    >
      {name}
      <div
        aria-hidden
        className="mt-1 text-sm font-normal text-secondary"
        id={describedById}
      >
        {description}
      </div>
    </Switch>
  );
};
