import { ValueOf } from '@local/utlity-types';
import * as Sentry from '@sentry/react';
import { mapValues } from 'lodash';
import { parse, stringify } from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { Config } from '../lib/config/config';

export type AllowedQueryParamKey = ValueOf<(typeof Config)['queryParameters']>;
export type AllowedQueryParams = {
  [key in AllowedQueryParamKey]?: string;
};

export function migrateQueryParams() {
  const parsed = parse(window.location.search);
  if ('schemaTag' in parsed) {
    if (
      !(Config.queryParameters.SelectedVariant in parsed) &&
      parsed.schemaTag !== undefined
    ) {
      parsed[Config.queryParameters.SelectedVariant] = parsed.schemaTag;
    }
    delete parsed.schemaTag;
    window.location.search = stringify(parsed);
  }
}

function useQueryParamsRaw() {
  const location = useLocation();

  // Memoize so we don't create a new object every time an unrelated query param
  // changes.
  return useMemo(() => parse(location.search), [location.search]);
}

function normalizeValue(
  key: string,
  value: string | string[] | null | undefined,
) {
  // replace array values with `undefined` and log to sentry
  if (Array.isArray(value)) {
    Sentry.captureMessage(`query param ${key} had an array value`);
    return undefined;
  }
  // query params without an `=` sign will be `null`, map those values to undefined
  // as well to simplify usage
  return value ?? undefined;
}

// TODO(Maya): type this so it includes all query params from routes
/**
 * Read the search string from the current route, parse it, and return a
 * memoized version. This will return referentially equal objects on subsequent
 * runs if the source URL search string has not changed.
 * @deprecated use RouteConfigs instead
 */
export function useQueryParams<
  T extends AllowedQueryParams = AllowedQueryParams,
>(): T {
  const rawQueryParams = useQueryParamsRaw();

  // Memoize so we don't create a new object every time an unrelated query param
  // changes.
  return useMemo(
    () =>
      mapValues(rawQueryParams, (value, key) =>
        normalizeValue(key, value),
      ) as T, // TODO: use schemas to validate that we can safely do this
    [rawQueryParams],
  );
}

export function useQueryParam(key: AllowedQueryParamKey) {
  const rawQueryParams = useQueryParamsRaw();
  return useMemo(() => {
    const value = rawQueryParams[key];
    return normalizeValue(key, value);
  }, [key, rawQueryParams]);
}
