import { Link } from '@apollo/orbit';
import React from 'react';

import { EventCategory } from '../analytics';
import { Config } from '../config/config';
import { relativeURL } from '../relativeURL';

export const A = ({
  title,
  href,
  className,
  children,
  analyticsCategory,
  analyticsLabelPrefix,
}: {
  title: string;
  href: string | null;
  className: string | undefined;
  children: React.ReactNode;
  analyticsCategory?: EventCategory;
  analyticsLabelPrefix?: string;
}) => {
  if (!href) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link to="#" className={className} onClick={(e) => e.preventDefault()}>
        {children}
      </Link>
    );
  }
  const isInternalLink = href.indexOf(Config.absoluteUrl) === 0;
  const isInternalAnchor = href.startsWith('#');

  return isInternalLink ? (
    <Link
      className={className}
      to={relativeURL(href)}
      title={title}
      data-analytics-label={
        analyticsLabelPrefix
          ? `${analyticsLabelPrefix}: Internal link`
          : undefined
      }
      data-analytics-category={analyticsCategory}
    >
      {children}
    </Link>
  ) : isInternalAnchor ? (
    <Link
      className={className}
      to={href}
      title={title}
      rel="noreferrer"
      data-analytics-label={
        analyticsLabelPrefix
          ? `${analyticsLabelPrefix}: Internal anchor`
          : undefined
      }
      data-analytics-category={analyticsCategory}
    >
      {children}
    </Link>
  ) : (
    <Link
      isExternal
      className={className}
      to={href}
      title={title}
      data-analytics-label={
        analyticsLabelPrefix
          ? `${analyticsLabelPrefix}: External link`
          : undefined
      }
      data-analytics-category={analyticsCategory}
    >
      {children}
    </Link>
  );
};
