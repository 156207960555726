/* eslint-disable tailwindcss/no-custom-classname */
import classnames from 'classnames';
import React, { forwardRef } from 'react';

import { Heading, HeadingProps } from '../Heading/Heading';
import { useToastStyles } from '../Toast/Toast.context';

export type ToastTitleProps = Omit<
  HeadingProps<'div'>,
  'as' | 'size' | 'variant' | '__orbitStyleConfig'
>;

export const ToastTitle = forwardRef<HTMLDivElement, ToastTitleProps>(
  ({ className, ...props }, ref) => {
    const styles = useToastStyles();

    return (
      <Heading
        {...props}
        as="div"
        ref={ref}
        className={classnames('toast-title', className)}
        variant="title"
        __orbitStyleConfig={styles.title}
      />
    );
  },
);
