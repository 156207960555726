import React from 'react';

/**
 * Call a callback after a certain amount of user inactivity
 */
export function useIdleAction({
  stableCallback,
  timeMs,
}: {
  /**
   * Function called after inactivity
   */
  stableCallback: () => void;
  /**
   * Milliseconds to wait before calling `callback`
   */
  timeMs: number | null;
}) {
  const logoutTimeoutRef = React.useRef<number>();

  React.useEffect(() => {
    if (typeof timeMs !== 'number') {
      return;
    }

    /**
     * Stop the previous timeout and listen for a new one
     */
    const resetTimeout = () => {
      clearTimeout(logoutTimeoutRef.current);
      logoutTimeoutRef.current = window.setTimeout(stableCallback, timeMs);
    };

    // Set the first timeout
    resetTimeout();

    /**
     * Events that we'll listen for to indicate that the user is no longer idle.
     *
     * The type is hardcoded to prevent us from accidentally adding an event
     * that doesn't exist
     */
    const nonIdleEvents: ReadonlyArray<keyof WindowEventMap> = [
      'click',
      'mousemove',
      'keydown',
      'keypress',
      'keyup',
      'touchstart',
      'touchend',
    ];

    nonIdleEvents.forEach((event) =>
      window.addEventListener(event, resetTimeout),
    );

    return () => {
      window.clearTimeout(logoutTimeoutRef.current);

      nonIdleEvents.forEach((event) =>
        window.removeEventListener(event, resetTimeout),
      );
    };
  }, [stableCallback, timeMs]);
}
