export const buildSubscriptionUrlFromEndpoint = (endpointUrl: string) => {
  if (!endpointUrl.length) return '';

  let subscriptionUrl = endpointUrl;
  const prefix = endpointUrl.startsWith('https')
    ? 'https'
    : endpointUrl.startsWith('http')
      ? 'http'
      : null;
  if (prefix) {
    subscriptionUrl = subscriptionUrl.replace(
      prefix,
      prefix === 'https' ? 'wss' : 'ws',
    );
  }
  return subscriptionUrl;
};
