import { defineStyle } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

const { border, bg } = customColors;

export const MenuContentStyles = defineStyle({
  zIndex: 'popover',
  isolation: 'isolate',
  backgroundColor: bg.primary,
  border: '1px solid',
  borderColor: border.primary,
  boxShadow: false,
  borderRadius: 'lg',
  minWidth: '15.5rem',
  maxWidth: '22.5rem',
  maxHeight: '50vh',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  px: 0,
  py: 2,
});
