import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { calc, cssVar } from '@chakra-ui/theme-tools';

import { customColors } from '../../theme/customColors';

const { bg, text } = customColors;

const $bg = cssVar('switch-bg');
// Unfortunately something is overriding the `--switch-track-width` out of our
// control. We instead use this "patched" var so that the switch thumb moves the
// correct amount of space
const $width = cssVar('switch-track-width--patched');
const $height = cssVar('switch-track-height');
const $diff = cssVar('switch-track-diff');

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  thumb: {
    bg: bg.white,
    w: 3,
    h: 3,
  },
  track: {
    [$width.variable]: '32px',
    [$bg.variable]: bg.deselected,
    boxSizing: 'border-box',
    boxShadow: '0px 1px 0px 0px #00000014',
    width: $width.reference,
    _checked: {
      [$bg.variable]: bg.selected,
      boxShadow:
        '0px 1px 4px 0px #12151A14, 0px 0px 0px 0px #12151A33 inset, 0px -1px 0px 0px #12151A0D inset',
    },
    _disabled: {
      opacity: 0.4,
    },
  },
  container: {
    [$width.variable]: '32px',
    [$height.variable]: '16px',
    [$diff.variable]: calc.subtract($width, $height),
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    gap: 2,
  },
  label: {
    color: text.primary,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 700,
    fontFamily: 'var(--font-body)',
    textTransform: 'uppercase',
    _disabled: {
      color: text.disabled,
    },
  },
});

export const SwitchStyles = defineMultiStyleConfig({ baseStyle, sizes: {} });
