export const isEmbeddableExplorerRoute = (): boolean => {
  const hostname = window.location.hostname;

  return (
    // local dev
    (hostname === 'embed.apollo.local' &&
      !window.location.pathname.includes('sandbox')) ||
    // prod
    hostname.endsWith('explorer.embed.apollographql.com') ||
    // deploy previews
    hostname.endsWith('embeddable-explorer.netlify.app')
  );
};
