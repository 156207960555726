/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
interface Props extends Omit<React.SVGProps<SVGSVGElement>, "css"> {
  weight?: "thin" | "normal";
}
export const IconMarioFlower = React.forwardRef<SVGSVGElement, Props>(
  (
    { fill = "none", stroke = "currentColor", weight = "normal", ...props },
    ref,
  ) => (
    <svg
      viewBox="0 0 48 48"
      ref={ref}
      css={css`
        * {
          vector-effect: non-scaling-stroke;
        }
        overflow: visible;
        height: 48px;
      `}
      {...props}
    >
      <g
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={weight === "normal" ? 1.5 : 1}
      >
        <path
          strokeWidth={weight === "normal" ? 1.5 : 1}
          d="M46.2 15a13.5 13.5 0 0 1-13.5 13.5h-9a13.5 13.5 0 0 1 0-27h9A13.5 13.5 0 0 1 46.2 15z"
        />
        <path
          strokeWidth={weight === "normal" ? 1.5 : 1}
          d="M40.2 15a7.5 7.5 0 0 1-7.5 7.5h-9a7.5 7.5 0 0 1 0-15h9a7.5 7.5 0 0 1 7.5 7.5zM34.198 13.5v3M22.198 13.5v3M28.198 28.5l-7.5 18M16.2 46.5h-5.8a6 6 0 0 1-5-2.672l-2.664-4A1.5 1.5 0 0 1 4 37.5h2.986a6 6 0 0 1 5 2.672zM28.2 46.5h5.788a6 6 0 0 0 5-2.672l2.664-4A1.5 1.5 0 0 0 40.4 37.5h-3a6 6 0 0 0-5 2.672z"
        />
      </g>
    </svg>
  ),
);
