import { defineStyle } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';

export const AdmonitionFooterStyles = defineStyle({
  color: customColors.text.primary,
  fontFamily: 'body',
  alignSelf: 'stretch',
  display: 'flex',
  gap: 2,
  mt: 4,
});
