import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  Select,
} from '@apollo/orbit';
import { useFormikContext } from 'formik';
import { orderBy } from 'lodash';
import React from 'react';

import { countryCodes } from 'src/components/creditcards/creditCardFields/countryCodes';

import { FormValues } from '../UpgradeModal';

export const Billing = () => {
  const { handleChange, handleBlur, values, errors, touched } =
    useFormikContext<FormValues>();
  const countries = orderBy(
    Object.entries(countryCodes).map(([countryName, countryKey]) => ({
      name: countryName,
      key: countryKey,
    })),
    'name',
  );

  return (
    <ModalBody>
      <h1 className="font-heading text-lg font-medium text-heading">
        Billing address
      </h1>
      <div className="flex space-x-4">
        <FormControl
          className="w-3/4"
          isRequired
          isInvalid={!!touched.streetAddress && !!errors.streetAddress}
        >
          <FormLabel>Street Address</FormLabel>
          <Input
            onChange={handleChange}
            onBlur={handleBlur}
            name="streetAddress"
            value={values.streetAddress}
          />
          <FormErrorMessage>{errors.streetAddress}</FormErrorMessage>
        </FormControl>

        <FormControl
          className="w-1/4"
          isInvalid={!!touched.aptSuiteBldg && !!errors.aptSuiteBldg}
        >
          <FormLabel>Apt / Suite / Bldg</FormLabel>
          <Input
            onChange={handleChange}
            onBlur={handleBlur}
            name="aptSuiteBldg"
            value={values.aptSuiteBldg}
          />
          <FormErrorMessage>{errors.aptSuiteBldg}</FormErrorMessage>
        </FormControl>
      </div>
      <div className="flex space-x-4 ">
        <FormControl
          className="w-1/2"
          isRequired
          isInvalid={!!touched.city && !!errors.city}
        >
          <FormLabel>City</FormLabel>
          <Input
            onChange={handleChange}
            onBlur={handleBlur}
            name="city"
            value={values.city}
          />
          <FormErrorMessage>{errors.city}</FormErrorMessage>
        </FormControl>

        <FormControl
          className="w-1/2"
          isRequired
          isInvalid={!!touched.stateProvReg && !!errors.stateProvReg}
        >
          <FormLabel>State / Province / Region</FormLabel>
          <Input
            onChange={handleChange}
            onBlur={handleBlur}
            name="stateProvReg"
            value={values.stateProvReg}
          />
          <FormErrorMessage>{errors.stateProvReg}</FormErrorMessage>
        </FormControl>
      </div>
      <div className="flex space-x-4">
        <FormControl
          className="w-1/2"
          isRequired
          isInvalid={!!touched.zip && !!errors.zip}
        >
          <FormLabel>Zip / Postal code</FormLabel>
          <Input
            onChange={handleChange}
            onBlur={handleBlur}
            name="zip"
            value={values.zip}
          />
          <FormErrorMessage>{errors.zip}</FormErrorMessage>
        </FormControl>

        <FormControl
          className="flex w-3/4"
          isRequired
          isInvalid={!!touched.country && !!errors.country}
        >
          <FormLabel>Country</FormLabel>
          <Select
            className="w-full"
            name="country"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.country}
          >
            {countries.map((country) => (
              <option key={country.key} value={country.key}>
                {country.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.country}</FormErrorMessage>
        </FormControl>

        <FormControl
          className="w-1/2"
          isInvalid={!!touched.vat && !!errors.vat}
        >
          <FormLabel>VAT Number (optional)</FormLabel>
          <Input
            onChange={handleChange}
            onBlur={handleBlur}
            name="vat"
            value={values.vat}
          />
          <FormErrorMessage>{errors.vat}</FormErrorMessage>
        </FormControl>
      </div>
    </ModalBody>
  );
};
