import IconCopy from '@apollo/icons/small/IconCopy.svg';
import { chakra, useMultiStyleConfig } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

import {
  IconButton,
  Text,
  TextProps,
  Tooltip,
  useDisclosure,
} from '../../../index';

export interface CodeProps
  extends Omit<
    TextProps<'code'>,
    'as' | 'size' | 'variant' | '__orbitStyleConfig'
  > {
  variant?: 'mono' | 'fullColor';
  canCopy?: boolean;
}

export const Code = forwardRef<HTMLElement, CodeProps>(
  ({ children, canCopy, ...rest }, ref) => {
    const styles = useMultiStyleConfig('Code', rest);
    const { isOpen, onToggle, onClose } = useDisclosure({
      defaultIsOpen: false,
    });
    const copyText = async () => {
      try {
        onToggle();
        await navigator.clipboard.writeText(children as string);
        await asyncTimeout(() => onClose(), 2000);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to snippet', error);
      }
    };

    return (
      <chakra.code {...rest} ref={ref} as="code" __css={styles.container}>
        <Text size="sm" variant="code" __orbitStyleConfig={styles.text}>
          {children}
        </Text>
        {canCopy && (
          <Tooltip isOpen={isOpen} label="Copied" placement="top">
            <IconButton
              size="xs"
              icon={<IconCopy />}
              variant="hidden"
              aria-label="Copy"
              onClick={copyText}
              __orbitStyleConfig={styles.button}
            />
          </Tooltip>
        )}
      </chakra.code>
    );
  },
);

const asyncTimeout = (fn: () => void, ms: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      fn();
      resolve();
    }, ms);
  });
};
