import { gql, useQuery } from '@apollo/client';
import { AlertBanner, Link } from '@apollo/orbit';
import React from 'react';
import { useLocation } from 'react-router';

import { useGraphRef } from 'src/app/graph/hooks/useGraphRef';
import { useGraphVisibility } from 'src/app/graph/hooks/useGraphVisibility';
import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import { GraphQLTypes } from 'src/lib/graphqlTypes';

export const PublicVariantPreviewBanner = () => {
  const graphRef = useGraphRef();
  const { data, loading } = useQuery<
    GraphQLTypes.PublicVariantPreviewBannerQuery,
    GraphQLTypes.PublicVariantPreviewBannerQueryVariables
  >(
    gql`
      query PublicVariantPreviewBannerQuery($graphId: ID!) {
        service(id: $graphId) {
          id
          account {
            id
          }
        }
      }
    `,
    {
      variables: { graphId: graphRef?.graphId as string },
      skip: !graphRef,
      context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
    },
  );

  // Public viewers will not have access to the account for a graph
  const hasPrivateAccess = !!data?.service?.account;

  const graphVisibilityType = useGraphVisibility();
  const isPublicRoute = graphVisibilityType === 'public';
  const location = useLocation();
  const privateLocation = {
    ...location,
    pathname: location.pathname.replace(/^\/public\//, '/graph/'),
  };

  // Hide if this is not a public route, or if we don't have private access
  if (loading || !isPublicRoute || !hasPrivateAccess) {
    return null;
  }

  return (
    <AlertBanner status="info">
      You are viewing the preview of the public facing view of this variant.{' '}
      <Link to={privateLocation}>Go back to private view.</Link>
    </AlertBanner>
  );
};
