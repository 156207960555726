import {
  LazyQueryHookOptions,
  LazyQueryResult,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import useDeepCompareEffect from 'use-deep-compare-effect';

export interface DebounceOptions {
  /**
   * The time in ms to delay between query executions.
   */
  delayMs: number;
  /**
   * If true, query execution will be suspended.
   * Useful for preventing queries when input is not
   * yet ready.
   */
  suspend?: boolean;
}

export function useDebouncedQuery<T, TVariables extends OperationVariables>(
  query: DocumentNode,
  queryOptions: LazyQueryHookOptions<T, TVariables>,
  debounceOptions: DebounceOptions,
): LazyQueryResult<T, TVariables> {
  const [execute, result] = useLazyQuery<T, TVariables>(query);
  useDeepCompareEffect(() => {
    const fetchQuery = async () => {
      if (!debounceOptions.suspend) {
        execute(queryOptions);
      }
    };
    // queue the query to fire after a delay
    const timeout = setTimeout(() => fetchQuery(), debounceOptions.delayMs);
    // if our dependencies change according to deep comparison
    // (most likely, due to a change in variables),
    // useEffect's cleanup will cancel the previous query - effectively "debouncing".
    return () => clearTimeout(timeout);
  }, [execute, queryOptions, debounceOptions]);
  return result;
}
