const isLocalStorageAvailable = () => {
  try {
    const testKey = '__test_if_localstorage_is_available__';
    // eslint-disable-next-line no-restricted-globals
    localStorage.setItem(testKey, testKey);
    // eslint-disable-next-line no-restricted-globals
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

class MemoryStorage implements Storage {
  #_data: Record<string, string> = {};

  get length(): number {
    return Object.entries(this.#_data).length;
  }

  key(index: number): string | null {
    return Object.keys(this.#_data)[index] ?? null;
  }

  getItem(key: string): string | null {
    return this.#_data.hasOwnProperty.call(this, key)
      ? (this.#_data[key] ?? null)
      : null;
  }

  setItem(key: string, value: string): void {
    this.#_data[key] = String(value);
  }

  removeItem(key: string): void {
    delete this.#_data[key];
  }

  clear(): void {
    this.#_data = {};
  }
}

export const localStorageWithMemoryFallback = isLocalStorageAvailable()
  ? // eslint-disable-next-line no-restricted-properties
    window.localStorage
  : new MemoryStorage();
