import { GraphQLError } from 'graphql';
import { useCallback } from 'react';

import {
  PerGraphIdentifier,
  readFromLocalStorage,
  writeToLocalStorage,
} from 'src/hooks/useLocalStorage';

import {
  useLocalStorageGraphIdentifier,
  usePerGraphIdentifierLocalStorage,
} from '../usePerGraphIdentifierLocalStorage';

import { HeaderEntry } from './useHeadersManagerContext/shared';

const MAX_HISTORY_LENGTH = 25;

export type OperationHistoryItem = {
  operationString: string;
  operationName: string | undefined;
  variables: string;
  script: string; // aka preflightOperationScript, named script for history reasons
  postflightOperationScript: string | undefined;
  headers: Array<HeaderEntry>;
  timestamp: number;
  graphqlError?: GraphQLError;
  responseStatus: number;
};

const INITIAL_RUN_HISTORY: OperationHistoryItem[] = [];

export const useOperationHistory = (): [
  readonly OperationHistoryItem[],
  (item: OperationHistoryItem) => void,
] => {
  const localStorageGraphIdentifier = useLocalStorageGraphIdentifier({
    shouldNamespaceSandboxByEndpoint: true,
    shouldOnlyStoreForRegisteredGraphs: false,
  });

  const [operationHistory, setHistory] = usePerGraphIdentifierLocalStorage({
    key: 'studioOperationHistoryPerVariant',
    graphIdentifier: localStorageGraphIdentifier,
    stableInitialValue: INITIAL_RUN_HISTORY,
  });

  const pushToOperationHistory = useCallback(
    (item: OperationHistoryItem) =>
      setHistory?.((currentHistory) => [
        item,
        ...currentHistory.slice(0, MAX_HISTORY_LENGTH - 1),
      ]),
    [setHistory],
  );

  return [operationHistory, pushToOperationHistory];
};

export const migrateRunHistoryToMax10Items = () => {
  const currentRunHistory = readFromLocalStorage(
    'studioOperationHistoryPerVariant',
  );

  const newRunHistory: PerGraphIdentifier<OperationHistoryItem[]> = {};
  Object.entries(currentRunHistory).forEach(([domain, variantObject]) => {
    if (variantObject) {
      const newRunHistoryForVariant: Record<string, OperationHistoryItem[]> =
        {};
      Object.entries(variantObject).forEach(([variantId, value]) => {
        if (value) {
          const last10RunHistoryItems = value.slice(0, MAX_HISTORY_LENGTH);
          newRunHistoryForVariant[variantId] = last10RunHistoryItems;
        }
      });
      newRunHistory[domain] = newRunHistoryForVariant;
    }
  });
  writeToLocalStorage('studioOperationHistoryPerVariant', newRunHistory);
};
