import { FormErrors } from 'src/lib/forms/FormErrors';
import { OrgIDValidator } from 'src/lib/validators';

import { QUESTION_KEY } from './views/userProfileSurvey/constants';
export interface FormData {
  accountName: string;
  accountId: string;
  companyUrl: string;
  [QUESTION_KEY.OrgCreateIntent]?: string | null;
  [QUESTION_KEY.UserInitialIntent]?: string | null;
}

export const validateAccountInfo = (
  values: FormData,
): FormErrors<FormData> => ({
  ...(!values.accountName ? { accountName: 'is required' } : {}),
  ...validateAccountId(values.accountId),
});

export const validateAccountId = (slug: string) => {
  const reason = OrgIDValidator.validate(slug);
  return reason ? { accountId: reason } : {};
};
